function listPrivacyPolicyReducer(state = { privacyPolicies: [] }, action) {
    switch (action.type) {
        case 'PRIVACY_POLICY_LIST_REQUEST':
            return { loading: true, privacyPolicies: [] }
        case 'PRIVACY_POLICY_LIST_SUCCESS':
            return { loading: false, privacyPolicies: action.payload }
        case 'PRIVACY_POLICY_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

function addPrivacyPolicyReducer(state = {}, action) {
    switch(action.type) {
        case 'PRIVACY_POLICY_CREATE_REQUEST':
            return {loading: true}
        case 'PRIVACY_POLICY_CREATE_SUCCESS':
            return {loading: false, privacypolicy: action.payload, success: true}
        case 'PRIVACY_POLICY_CREATE_FAIL':
            return { loading: false, error: action.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updatePrivacyPolicyReducer(state = { privacyPolicy: {} }, action) {
    switch(action.type) {
        case 'PRIVACY_POLICY_UPDATE_REQUEST':
            return { loading: true }
        case 'PRIVACY_POLICY_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'PRIVACY_POLICY_UPDATE_FAIL':
            return { loading: false, error: action.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deletePrivacyPolicyReducer(state = { privacyPolicy: {} }, action) {
    switch(action.type) {
        case 'DELETE_PRIVACY_POLICY_REQUEST':
            return { loading: true }
        case 'DELETE_PRIVACY_POLICY_SUCCESS':
            return { loading: false, success: true }
        case 'DELETE_PRIVACY_POLICY_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}


export { addPrivacyPolicyReducer, listPrivacyPolicyReducer, updatePrivacyPolicyReducer, deletePrivacyPolicyReducer }