function listOrderReducer(state = { orders: [] }, action) {
    switch (action.type) {
        case 'ORDERS_LIST_REQUEST':
            return { loading: true, orders: [] }
        case 'ORDERS_LIST_SUCCESS':
            return { loading: false, orders: action?.payload }
        case 'ORDERS_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addOrderReducer(state = {}, action) {
    switch (action.type) {
        case 'ORDER_CREATE_REQUEST':
            return { loading: true }
        case 'ORDER_CREATE_SUCCESS':
            return { loading: false, order: action?.payload, success: true }
        case 'ORDER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateOrderReducer(state = { order: {} }, action) {
    switch(action.type) {
        case 'ORDER_UPDATE_REQUEST':
            return { loading: true }
        case 'ORDER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'ORDER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteOrderReducer(state = { order: {} }, action) {
    switch(action.type) {
        case 'ORDER_DELETE_REQUEST':
            return { loading: true }
        case 'ORDER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'ORDER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listOrderReducer, addOrderReducer, updateOrderReducer, deleteOrderReducer }