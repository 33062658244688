function listSubSubCategoryReducer(state = { subsubCategories: [] }, action) {
    switch (action.type) {
        case 'SUB_SUB_CATEGORIES_LIST_REQUEST':
            return { loading: true, subsubCategories: [] }
        case 'SUB_SUB_CATEGORIES_LIST_SUCCESS':
            return { loading: false, subsubCategories: action?.payload }
        case 'SUB_SUB_CATEGORIES_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addSubSubCategoryReducer(state = {}, action) {
    switch (action.type) {
        case 'SUB_SUB_CATEGORY_CREATE_REQUEST':
            return { loading: true }
        case 'SUB_SUB_CATEGORY_CREATE_SUCCESS':
            return { loading: false, subsubCategory: action?.payload, success: true }
        case 'SUB_SUB_CATEGORY_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateSubSubCategoryReducer(state = { subsubCategory: {} }, action) {
    switch(action.type) {
        case 'SUB_SUB_CATEGORY_UPDATE_REQUEST':
            return { loading: true }
        case 'SUB_SUB_CATEGORY_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'SUB_SUB_CATEGORY_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteSubSubCategoryReducer(state = { subsubCategory: {} }, action) {
    switch(action.type) {
        case 'SUB_SUB_CATEGORY_DELETE_REQUEST':
            return { loading: true }
        case 'SUB_SUB_CATEGORY_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'SUB_SUB_CATEGORY_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listSubSubCategoryReducer, addSubSubCategoryReducer, updateSubSubCategoryReducer, deleteSubSubCategoryReducer }