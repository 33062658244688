import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const bannerShopList = () => async (dispatch) => {
    try {
        dispatch({type: 'SHOP_BANNER_LIST_REQUEST'});
        const shopBannerData = await axios.get(`${apiUrl}/bannerShop`);
        dispatch({ type: 'SHOP_BANNER_LIST_SUCCESS', payload: shopBannerData?.data });
    } catch(error) {
        dispatch({ type: 'SHOP_BANNER_LIST_FAIL', payload: error });
    }
}

export const createBannerShop = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'SHOP_BANNER_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        formData.append('image', data.image);
        formData.append('link', data?.link);
        
        const shopBannerData = await axios.post(`${apiUrl}/bannerShop`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SHOP_BANNER_CREATE_SUCCESS', payload: shopBannerData?.data });
    } catch(error) {
        dispatch({ type: 'SHOP_BANNER_CREATE_FAIL', payload: error?.response?.data?.message });
    }
}

export const updateBannerShop = (id,data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'SHOP_BANNER_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        data.image instanceof Blob && formData.append('image', data.image, data.image.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
        formData.append('link', data?.link);

        const shopBannerData = await axios.patch(`${apiUrl}/bannerShop/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SHOP_BANNER_UPDATE_SUCCESS', payload: shopBannerData?.data });
    } catch(error) {
        dispatch({ type: 'SHOP_BANNER_UPDATE_FAIL', payload: error?.response?.data?.message  });
    }
}

export const deleteBannerShop = (bannerShopId, getState) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'SHOP_BANNER_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/bannerShop/${bannerShopId}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SHOP_BANNER_DELETE_SUCCESS', payload: data });

    } catch(error) {
        console.log(error);
        dispatch({ type: 'SHOP_BANNER_DELETE_FAIL', payload: error });
    }
}
