import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesList } from '../../actions/category_actions';
import { subCategoriesList } from '../../actions/subCategory_actions';
import { sizesList } from '../../actions/size_actions';
import { colorsList } from '../../actions/color_actions';
import { addToCart } from '../../actions/cart_actions';
import { bannerShopList } from '../../actions/bannerShop_actions';
import { addToWishlist } from '../../actions/wishlist_actions';
import { Link  } from 'react-router-dom';
import { apiUrl } from '../../constants/backendUrl';
import axios from 'axios';
import Collapsible from 'react-collapsible';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Modal from '../OpenModal/OpenModal';
import SearchBar from "../SearchBar/SearchBar";
import Loader from '../Loader/Loader';
import AddToCartModal from '../AddToCartModal/AddToCartModal.js';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import rightSideImage from '../../images/product-img-40.jpg';
import SubSubCategory from '../SubSubCategories/SubSubCategory';

import './style.css';

export default function Shop(props) {
    const [product, setProduct] = useState({});
    const [products, setProducts] = useState([]);
    const subCategorySearch = props.match.params.subCategory ? props.match.params.subCategory : ''; //props.match.params.subCategory -> subCategory sepse tek routes.js e kom /new/:subCategory
    const [subSubCategories, setSubSubCategories] = useState([]);
    const [lowPrice, setLowPrice] = useState(1);
    const [highPrice, setHighPrice] = useState(400);
    const [size, setSize] = useState('');
    const [color, setColor] = useState('');
    const menuWrapper = document.querySelector(".menu-wrapper");

    const dispatch = useDispatch();

    const [opend, setOpend] = useState(false); //na vyn qe kur t klikojm n filtera n mobile, kjo collapse filters me u mshel, psh klikoj ne categories veshje, filterat duhet me u mshel
    const [openCartModal, setCartModal] = useState('');
    const [qty, setQty] = useState(1);

    const { wishlistItems } = useSelector(state => state.wishlist);
    const { categories } = useSelector(state => state.categories_list);
    const { subCategories, loading: subCategoriesLoading } = useSelector(state => state.sub_category_list);
    
    const { sizes, loading: sizesLoading } = useSelector(state => state.sizes_list);
    const { colors, loading: colorsLoading } = useSelector(state => state.colors_list);
    const { shopBanners, loading: shopBannersLoading } = useSelector(state => state.banner_shop_list);
    
    function openModal(product) {
      var element = document.getElementById("modal_box409"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
      var header = document.getElementById("eltdf-page-header");
      var mobile_header = document.getElementById("eltdf-mobile-header");
      
      header.style.zIndex = '0';
      mobile_header.style.zIndex = '0';
      element.style.display = 'block';
      element.style.background = 'rgba( 0, 0, 0, 0.8)';
      
      setProduct(product); //m vyn me dit cilin produkt e kom kliku e ate me dergu si props tek OpenModal.js ne menyre qe me i shfaq t dhanat e produktit aty.
    }

    function openModalCart(id, quantity, color) {
        dispatch(addToCart(id, quantity, color));

        var element = document.getElementById("cart-modal-right-side"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
        var elementOverlay = document.getElementById("la-overlay-global"); //per me shfaq opacity pasi t hapet modali i add cartes
        var header = document.getElementById("eltdf-page-header");
        var mobile_header = document.getElementById("eltdf-mobile-header");

        header.style.zIndex = '0';
        mobile_header.style.zIndex = '0';
        elementOverlay.classList.add('la-overlay-global');
        setCartModal(element.classList.add("cart-flyout-open"));
    }

    function addWishlist(id, checkId, color) {
        const singleProduct = wishlistItems.find(x => x?.product === checkId);

        dispatch(addToWishlist(id, color));
          if(singleProduct?.product !== checkId) { //singleProduct.product -> sepse product eshte id e productit ashtu e kom lon  tek cart_actions-> product: data?._id
            Toastr.success(' Product added to wishlist');
        } else {
            Toastr.error('The product is already added to wishlist.');
        }
    }

    function toggleOpend() {
        setOpend(!opend);
    }

    function filterProducts() {
        axios.post(`${apiUrl}/products/searchProducts`, { size, color, lowPrice, highPrice }).then(res => {
            if(res.data.success) {
                setProducts(res.data.products);
                setColor('');
                setSize('');
                setLowPrice('1');
                setHighPrice('400');
                toggleOpend();
            }
        })
    }

    function resetFilters() {
        setColor('');
        setSize('');
        setLowPrice('1');
        setHighPrice('400');
    }

    function searchBySize(size) {
        axios.get(`${apiUrl}/products/by-size/${size}`).then(res => {
            if(res.data.success) {
                setProducts(res.data.products);
                toggleOpend();
            }
        })
    }

    function searchByCategory(category) {
        axios.get(`${apiUrl}/products/by-category/${category}`).then(res => {
            if(res.data.success) {
                setProducts(res.data.products);
                toggleOpend();
            }
        });
    }

    function searchBySubCategory(subCategory) {
        axios.get(`${apiUrl}/products/by-sub-category/${subCategory}`).then(res => {
            if(res.data.success) {
                setProducts(res.data.products);
                toggleOpend();
            }
        });
    }

    function searchBySubSubCategory(subSubCategory) {
        axios.get(`${apiUrl}/products/by-sub-sub-category/${subSubCategory}`).then(res => {
            if(res.data.success) {
                setProducts(res.data.products);
                toggleOpend();
            }
        });
    }

    function searchByColor(color) {
        axios.get(`${apiUrl}/products/by-color/${color}`).then(res => {
            if(res.data.success) {
                setProducts(res.data.products);
                toggleOpend();
            }
        })
    }

    function searchByPrice(lowPrice, highPrice) {
        axios.get(`${apiUrl}/products/by-price/${lowPrice}/${highPrice}`).then(res => {
            if(res.data.success) {
                setProducts(res.data.products);
                toggleOpend();
            }
        })
    }
    let div = '';
    function checkIsOutOfStock(item) {
      for(let index = 0; index < item.length; index++) {
        item[index].sizeObj?.every(function(element, index) {
           if(element?.countInStock < 1) {
                return div =  <span className="eltdf-outofstock">Back soon</span> 
           } else {
               return div = '';
           }
        });
        // for(let i = 0; i < item[index].sizeObj.length; i++) {
        //     // if(item[index].sizeObj[i].countInStock < 1) {
        //     //     div =  <span className="eltdf-outofstock">Back soon</span>            
        //     // }
        // }
      }
      return div;

    // console.log(result)
}
    useEffect(() => {
        if(subCategorySearch) {
            axios.get(`${apiUrl}/subsubCategories/sub-sub-category/${props?.location?.state?.id}`).then(res => {
                if(res.data.success) {
                    setSubSubCategories(res.data.subCategories);
                }
            });
            axios.get(`${apiUrl}/products/by-sub-category/${subCategorySearch}`).then(res => {
                if(res.data.success) {
                    setProducts(res?.data?.products);
                    menuWrapper?.classList.remove("offcanvas");
                }
            });

        } else if(props?.location?.state?.category) {
            axios.get(`${apiUrl}/products/by-category/${props?.location?.state?.category}`).then(res => {
                if(res.data.success) {
                    setProducts(res.data.products);
                }
            });
        } else {
            axios.get(`${apiUrl}/products`).then(res => {
                if(res.data.success) {
                    setProducts(res.data.products);
                }
            });
            axios.get(`${apiUrl}/subsubCategories`).then(res => {
                setSubSubCategories(res.data);
            });
        }        
        dispatch(categoriesList());
        dispatch(bannerShopList());
        dispatch(subCategoriesList());
        dispatch(sizesList());
        dispatch(colorsList());

    }, [subCategorySearch]);

    return (
        shopBannersLoading
        ||
        subCategoriesLoading
        ||
        sizesLoading
        ||
        colorsLoading
        ?
        <Loader />
        :
        <section id="shop_page" className="archive post-type-archive post-type-archive-product onea-core-1.0 eltdf-social-login-1.0 woocommerce woocommerce-page woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-sticky-header-on-scroll-up eltdf-dropdown-animate-height eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woo-main-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-header-top-enabled eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive">
            <TopHeader />
            {
                shopBanners?.slice(-1)?.map(shopBanner =>  <Slider image = { shopBanner?.image } link={shopBanner?.link} />)
            }
            <Header />
            { props?.location?.state?.subCategory && subSubCategories?.length > 0 && <SubSubCategory subsubCategories = { subSubCategories } searchBySubSubCategory = { searchBySubSubCategory } /> }
            <div className="eltdf-wrapper">
                <div className="eltdf-wrapper-inner">
                    <SearchBar />
                    <div className="eltdf-content">
                        <div className="eltdf-content-inner">
                            <div className="eltdf-container">
                                <div className="eltdf-container-inner clearfix eltdf-row-grid-section">
                                    <div className="eltdf-grid-row">    
                                        <div className="eltdf-sidebar-holder eltdf-grid-col-3">
                                            <div className="eltdf-sidebar">
                                                <div className="widget woocommerce widget_price_filter desktop_filter">
                                                    <div className="eltdf-widget-title-holder">
                                                        <h5 className="eltdf-widget-title">Filter By Price</h5>
                                                    </div>
                                                    <form>
                                                        <div className="price_slider_wrapper">
                                                            <div className="price_slider ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all" style={{background: 'none'}}>
                                                                <div className="rangeslider">
                                                                    <input 
                                                                        className="min" 
                                                                        name="lowPrice" 
                                                                        type="range" 
                                                                        step="1" 
                                                                        min="1" 
                                                                        max="400" 
                                                                        value={lowPrice}
                                                                        onChange={(e) => setLowPrice(e.target.value)}
                                                                    />
                                                                    <input 
                                                                        className="max" 
                                                                        name="highPrice" 
                                                                        type="range" 
                                                                        step="1" 
                                                                        min="1" 
                                                                        max="400" 
                                                                        value={highPrice}
                                                                        onChange={(e) => setHighPrice(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="price_slider_amount" style={{ margin: '26px 0 0' }}>
                                                                {/* <a 
                                                                    href="javascript:;" 
                                                                    className="button-filter-price-shop" 
                                                                    onClick={() => searchByPrice(lowPrice, highPrice)}
                                                                >Filtro</a> */}
                                                                <div className="price_label">
                                                                    Price: <span className="from">{lowPrice}€</span> — <span className="to">{highPrice}€</span>
                                                                </div>
                                                                <div className="clear"></div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                <Collapsible trigger="Filters" handleTriggerClick={() => setOpend(!opend)} open = {opend}>
                                                    <div className="widget woocommerce widget_price_filter">
                                                        <div className="eltdf-widget-title-holder">
                                                            <h5 className="eltdf-widget-title">Filter By Price</h5>
                                                        </div>
                                                        <form>
                                                            <div className="price_slider_wrapper">
                                                                <div className="price_slider ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all" style={{background: 'none'}}>
                                                                    <div className="rangeslider">
                                                                        <input 
                                                                            className="min" 
                                                                            name="vol" 
                                                                            type="range" 
                                                                            step="1" 
                                                                            min="1" 
                                                                            max="400" 
                                                                            value={lowPrice}
                                                                            onChange={(e) => setLowPrice(e.target.value)}
                                                                        />
                                                                        <input 
                                                                            className="max" 
                                                                            name="vol" 
                                                                            type="range" 
                                                                            step="1" 
                                                                            min="1" 
                                                                            max="400" 
                                                                            value={highPrice}
                                                                            onChange={(e) => setHighPrice(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="price_slider_amount" style={{ margin: '26px 0 0' }}>
                                                                    {/* <a 
                                                                        href="javascript:;" 
                                                                        className="button-filter-price-shop" 
                                                                        onClick={() => searchByPrice(lowPrice, highPrice)}
                                                                    >Filtro</a> */}
                                                                    <div className="price_label">
                                                                        Price: <span className="from">{lowPrice}€</span> — <span className="to">{highPrice}€</span>
                                                                    </div>
                                                                    <div className="clear"></div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    {/* <div className="widget eltdf-separator-widget">
                                                        <div className="eltdf-separator-holder clearfix eltdf-separator-custom-border-bottom eltdf-separator-center eltdf-separator-full-width">
                                                            <div className="eltdf-separator" style={{borderStyle: 'dotted', marginTop: '2px', marginBottom: '27px'}}></div>
                                                        </div>
                                                    </div>

                                                    <div className="widget woocommerce widget_product_categories">
                                                        <div className="eltdf-widget-title-holder">
                                                            <h5 className="eltdf-widget-title">Categories</h5>
                                                        </div>
                                                        <ul className="product-categories">
                                                            {
                                                                categories && categories?.map((category) => <li className="cat-item cat-item-103" id="aaa" key={category?._id}>
                                                                    <a 
                                                                        href="javascript:;"
                                                                        id="test"
                                                                        onClick={() => searchByCategory(category?.name)}
                                                                    >{ category?.name }</a> 
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>

                                                    <div className="widget eltdf-separator-widget">
                                                        <div className="eltdf-separator-holder clearfix eltdf-separator-custom-border-bottom eltdf-separator-center eltdf-separator-full-width">
                                                            <div className="eltdf-separator" style={{borderStyle: 'dotted', marginTop: '2px', marginBottom: '27px'}}></div>
                                                        </div>
                                                    </div>

                                                    <div className="widget woocommerce widget_product_categories">
                                                        <div className="eltdf-widget-title-holder">
                                                            <h5 className="eltdf-widget-title">Sub Categories</h5>
                                                        </div>
                                                        <ul className="product-categories">
                                                            {
                                                                subCategories && subCategories?.map((subCategory) => <li className="cat-item cat-item-103" key={subCategory?._id}>
                                                                    <a 
                                                                        href="javascript:;"
                                                                        onClick={() => searchBySubCategory(subCategory?.name)}
                                                                    >{ subCategory?.name }</a> 
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>

                                                    <div className="widget eltdf-separator-widget">
                                                        <div className="eltdf-separator-holder clearfix eltdf-separator-custom-border-bottom eltdf-separator-center eltdf-separator-full-width">
                                                            <div className="eltdf-separator" style={{borderStyle: 'dotted', marginTop: '2px', marginBottom: '27px'}}></div>
                                                        </div>
                                                    </div>

                                                    <div className="widget woocommerce widget_product_categories">
                                                        <div className="eltdf-widget-title-holder">
                                                            <h5 className="eltdf-widget-title">Sub Sub Categories</h5>
                                                        </div>
                                                        <ul className="product-categories">
                                                            {
                                                                subSubCategories && subSubCategories?.map((subCategory) => <li className="cat-item cat-item-103" key={subCategory?._id}>
                                                                    <a 
                                                                        href="javascript:;"
                                                                        onClick={() => searchBySubCategory(subCategory?.name)}
                                                                    >{ subCategory?.name }</a> 
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div> */}

                                                    <div className="widget eltdf-separator-widget">
                                                        <div className="eltdf-separator-holder clearfix eltdf-separator-custom-border-bottom eltdf-separator-center eltdf-separator-full-width">
                                                            <div className="eltdf-separator" style={{borderStyle: 'dotted', marginTop: '2px', marginBottom: '27px'}}></div>
                                                        </div>
                                                    </div>

                                                    <div className="widget woocommerce widget_layered_nav woocommerce-widget-layered-nav">
                                                        <div className="eltdf-widget-title-holder">
                                                            <h5 className="eltdf-widget-title">Sizes</h5>
                                                        </div>
                                                        <ul className="woocommerce-widget-layered-nav-list">
                                                            {
                                                                sizes && sizes?.map((s, index) => <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term select_size" key={ s?._id }>
                                                                    {/* <a 
                                                                        rel="nofollow" 
                                                                        href="javascript:;"
                                                                        onClick={() => searchBySize(s?.size)}
                                                                    >{ s?.size }</a>  */}
                                                                    <input  
                                                                        type="radio" 
                                                                        name="size_mobile"
                                                                        value={size}
                                                                        id={s?._id}
                                                                        // checked={ size ? true : false }
                                                                    />
                                                                    <label htmlFor={s?._id} onClick={() => setSize(s?.size)}>
                                                                        <span>{s?.size}</span>
                                                                    </label>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                    
                                                    <div className="widget eltdf-separator-widget">
                                                        <div className="eltdf-separator-holder clearfix eltdf-separator-custom-border-bottom eltdf-separator-center eltdf-separator-full-width">
                                                            <div className="eltdf-separator" style={{borderStyle: 'dotted', marginTop: '2px', marginBottom: '27px'}}></div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="widget woocommerce widget_layered_nav woocommerce-widget-layered-nav color-flex">
                                                        <div className="eltdf-widget-title-holder">
                                                            <h5 className="eltdf-widget-title">Colors</h5>
                                                        </div>
                                                        <ul className="woocommerce-widget-layered-nav-list color-flex">
                                                            {
                                                                colors && colors.map((c, index) => <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term color_select" key={c?._id}>
                                                                    {/* <a 
                                                                        rel="nofollow" 
                                                                        href="javascript:;" 
                                                                        style={{ color: c?.colorHex }}
                                                                        onClick={() => searchByColor(c?.colorName)}
                                                                    >
                                                                        <i className="color_picker far fa-circle" style={{ background: c?.colorHex, border: `1px solid rgba(211, 211, 211, 0.96)` }}></i>
                                                                    </a>  */}
                                                                    <input  
                                                                        type="radio" 
                                                                        name="color_mobile" 
                                                                        id={c?._id}
                                                                        // checked={color ? true : false}
                                                                    />
                                                                    <label 
                                                                        htmlFor={c?._id} 
                                                                        style={{ color: c?.colorHex }}
                                                                        onClick={() => setColor(c?.colorName)}
                                                                    >
                                                                        <span>
                                                                            <i className="color_picker far fa-circle" style={{ background: c?.colorHex, border: `1px solid rgba(211, 211, 211, 0.96)` }}></i>
                                                                        </span>
                                                                    </label>
                                                                </li>
                                                            )}
                                                        </ul>
                                                        <div className="widget woocommerce" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '22px' }}>
                                                            <a 
                                                                href="javascript:;" 
                                                                className="button-filter-price-shop button-filter-product-shop" 
                                                                style={{ border: '1px solid', padding: '0.5rem 15px' }}
                                                                onClick={filterProducts}
                                                            >Apply Filters</a>
                                                            {
                                                                size
                                                                ||
                                                                color
                                                                ?
                                                                <a 
                                                                    href="javascript:;" 
                                                                    className="button-filter-price-shop button-filter-product-shop" 
                                                                    style={{ border: '1px solid', padding: '0.5rem 15px' }}
                                                                    onClick={resetFilters}
                                                                >Reset Filters</a>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </Collapsible>

                                                <div className="widget eltdf-separator-widget desktop_filter">
                                                    <div className="eltdf-separator-holder clearfix eltdf-separator-custom-border-bottom eltdf-separator-center eltdf-separator-full-width">
                                                        <div className="eltdf-separator" style={{ borderStyle: 'dotted', marginTop: '2px', marginBottom: '27px' }}></div>
                                                    </div>
                                                </div>

                                                <div className="widget widget-sizes woocommerce widget_layered_nav woocommerce-widget-layered-nav desktop_filter">
                                                    <div className="eltdf-widget-title-holder">
                                                        <h5 className="eltdf-widget-title">Sizes</h5>
                                                    </div>
                                                    <ul className="woocommerce-widget-layered-nav-list">
                                                        {
                                                            sizes && sizes?.map((s, index) => <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term select_size" key={ s?._id }>
                                                                {/* <a 
                                                                    rel="nofollow" 
                                                                    href="javascript:;"
                                                                    onClick={() => setSize(s?.size)}
                                                                >{ s?.size }</a>  */}
                                                                <input  
                                                                    type="radio" 
                                                                    name="desktop_sizee"
                                                                    value={size}
                                                                    id={s?.size}
                                                                    // checked={ size ? true : false }
                                                                />
                                                                <label htmlFor={s?.size} onClick={() => setSize(s?.size)}>
                                                                    <span>{s?.size}</span>
                                                                </label>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>

                                                <div className="widget eltdf-separator-widget desktop_filter">
                                                    <div className="eltdf-separator-holder clearfix eltdf-separator-custom-border-bottom eltdf-separator-center eltdf-separator-full-width">
                                                        <div className="eltdf-separator" style={{borderStyle: 'dotted', marginTop: '2px', marginBottom: '27px'}}></div>
                                                    </div>
                                                </div>

                                                <div className="widget woocommerce widget_layered_nav woocommerce-widget-layered-nav color-flex desktop_filter">
                                                    <div className="eltdf-widget-title-holder">
                                                        <h5 className="eltdf-widget-title">Colors</h5>
                                                    </div>
                                                    <ul className="woocommerce-widget-layered-nav-list color-flex">
                                                        {
                                                            colors && colors.map((c, index) => <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term color_select" key={c?._id}>
                                                                {/* <a 
                                                                    rel="nofollow" 
                                                                    href="javascript:;" 
                                                                    style={{ color: c?.colorHex }}
                                                                    onClick={() => setColor(c?.colorName)}
                                                                >
                                                                    <i className="color_picker far fa-circle" style={{ background: c?.colorHex, border: `1px solid rgba(211, 211, 211, 0.96)` }}></i>
                                                                </a>  */}
                                                                <input  
                                                                    type="radio" 
                                                                    name="color_desktop" 
                                                                    id={c?.colorName}
                                                                    // checked={color ? true : false}
                                                                />
                                                                <label 
                                                                    htmlFor={c?.colorName} 
                                                                    style={{ color: c?.colorHex }}
                                                                    onClick={() => setColor(c?.colorName)}
                                                                >
                                                                    <span>
                                                                        <i className="color_picker far fa-circle" style={{ background: c?.colorHex, border: `1px solid rgba(211, 211, 211, 0.96)` }}></i>
                                                                    </span>
                                                                </label>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>

                                                <div className="widget woocommerce desktop_filter" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '18px' }}>
                                                    <a 
                                                        href="javascript:;" 
                                                        className="button-filter-price-shop button-filter-product-shop" 
                                                        style={{ border: '1px solid', padding: '0.5rem 15px' }}
                                                        onClick={filterProducts}
                                                    >Apply Filters</a>
                                                    {
                                                        size
                                                        ||
                                                        color
                                                        ?
                                                        <a 
                                                            href="javascript:;" 
                                                            className="button-filter-price-shop button-filter-product-shop" 
                                                            style={{ border: '1px solid', padding: '0.5rem 15px' }}
                                                            onClick={resetFilters}
                                                        >Reset Filters</a>
                                                        : null
                                                    }
                                                </div>
                                                
                                                <div className="widget widget_media_image desktop_filter">
                                                    <a href="javascript:;;;">
                                                        <img src={ rightSideImage } className="image wp-image-4732  attachment-medium size-medium" alt="h" style={{maxWidth: '100%', height: 'auto'}} />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="eltdf-page-content-holder eltdf-grid-col-9">
                                            <div className="eltdf-pl-main-holder">
                                            <SearchBar />
                                                <ul className="products columns-3">
                                                    {
                                                        products && products?.length > 0 ? products?.map((product) => {
                                                            if(product?.new) {
                                                                return <li className="post-24 product type-product status-publish has-post-thumbnail product_cat-lifestyle product_tag-clothes product_tag-stylish first instock sale shipping-taxable purchasable product-type-simple" key={product?._id}>
                                                                    <div className="eltdf-pl-inner">
                                                                        <div className="eltdf-pl-image">
                                                                            { product?.discountPrice ? <span className="eltdf-onsale">Sale</span> : null }
                                                                            {/* { product?.availableOptions[0]?.sizeObj[0]?.countInStock < 1 ? <span className="eltdf-outofstock">Out of stock</span> : null } */}
                                                                            {
                                                                            
                                                                                // product?.availableOptions?.map(item => 
                                                                                //     // item?.sizeObj?.every(function(sizObjItem) {
                                                                                //     //     console.log(sizeObjItem)
                                                                                //     //     // console.log(sizeObjItem?.countInStock < 1)

                                                                                //     // })   
                                                                                //     item?.sizeObj?.map(i => {
                                                                                //         // i?.countInStock < 1 ? alert('out') : alert('here')
                                                                                //     })
                                                                                    
                                                                                // )
                                                                            //   product?.availableOptions?.sizeObj?.every(checkIsOutOfStock)
                                                                              checkIsOutOfStock(product?.availableOptions)
                                                                            

                                                                            }
  
                                                                            <img 
                                                                                src={`${apiUrl}/images/${product?.availableOptions[0]?.images[0]}`} 
                                                                                className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" 
                                                                                alt="f" 
                                                                                // style={ product?.availableOptions[0]?.sizeObj[0]?.countInStock < 1 ? { opacity: '0.45' } : {} }
                                                                            />
                                                                        </div>
                                                                        <Link 
                                                                            to={`/product/${product?._id}`}
                                                                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                                                                        ></Link>
        
                                                                        <div className="eltdf-pli-text">
                                                                            <div className="eltdf-pli-text-outer">
                                                                                <div className="eltdf-pli-text-inner">
                                                                                    <div className="eltdf-pl-icons-holder">
                                                                                        {/* { product?.availableOptions[0]?.countInStock > 0 ? <a href="javascript:;" className="yith-cart-button yith-wcqv-button products-cart-button_1234098765" onClick={() => openModalCart(product?._id, qty, product?.availableOptions[0]?.color)}></a> : '' } */}
                                                                                        <a href="javascript:void" className="yith-quickview-button yith-wcqv-button products-cart-button_1234098765" onClick={() => openModal(product)}>Quick View</a>
                                                                                        <a 
                                                                                            href="javascript:;" 
                                                                                            className="yith-heart-button yith-wcqv-button products-cart-button_1234098765"
                                                                                            id="heart-button"
                                                                                            onClick={() => addWishlist(product?._id, product?.availableOptions[0]?._id, product?.availableOptions[0]?.color)}
                                                                                        ></a>
                                                                                        <div className="clear" />{" "}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="eltdf-pl-text-wrapper clearfix">
                                                                        <h5 className="eltdf-product-list-title">
                                                                            <Link                                                                     
                                                                               to={`/product/${product?._id}`}
                                                                            >{product?.title}</Link>
                                                                        </h5>
                                                                        <span className="eltdf-pl-category">
                                                                            <Link                                                                     
                                                                                to={`/product/${product?._id}`}
                                                                                rel="tag"
                                                                            >{product?.subtitle}</Link>
                                                                        </span>
                                                                        <span className="price">
                                                                            {
                                                                                product?.discountPrice && 
                                                                                <a style={{ marginRight: '8px' }}>
                                                                                    {product?.discountPrice?.toFixed(2)}€
                                                                                </a>
                                                                            }
                                                                            <Link                                                                     
                                                                                to={`/product/${product?._id}`}
                                                                                style={ product?.discountPrice ? { color: '#acabab', textDecoration: 'line-through' } : {} }
                                                                                rel="tag"
                                                                            >{product?.price?.toFixed(2)}€</Link>
                                                                        </span>
                                                                    </div>
                                                                    
                                                                </li> 
                                                            }
                                                        }
                                                    ) : <div className="shop_wrapper grid_3" id="shop_container_notfound_products">
                                                            <div className="col-lg-12 col-md-4 col-sm-6">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">We're sorry!</h5>
                                                                        <p className="card-text">We couldn't find what your're looking for.</p>
                                                                        <Link to="/" className="btn btn-not-found-products">Go back</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal product={product} setProduct={setProduct} />
                                    <AddToCartModal />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}