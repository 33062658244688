import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bannerHomeList } from '../../actions/bannerHome_actions';
import { cardBannerHomeList } from '../../actions/homeCardBanner_actions';
import { categoriesBannerHomeList } from '../../actions/categoriesBannerHome_actions';
import { transportsBannerHomeList } from '../../actions/transportsBannerHome_actions';
import { slidersList } from '../../actions/homeSliderBanner_actions';

// import newCollectionImageBanner from '../../images/Group 179.jpg';
import newCollectionImageBanner from '../../images/home-collection-new.gif';
import newCollectionText from '../../images/Group 180.png';
import truckTransport from '../../images/truck-transport.png';
import mask2 from '../../images/Mask Group 2.png';
import mask3 from '../../images/Mask Group 3.png';
import followUsOnInstagram from '../../images/follow us on instagram text.png';
import { apiUrl } from '../../constants/backendUrl';
import axios from 'axios';

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBar from '../SearchBar/SearchBar';
import Loader from '../Loader/Loader';

import './style.css';

export default function Home(props) {
    const [sliders, setSliders] = useState([]);

    const { homeBanners, loading: homeBannersLoading } = useSelector(state => state.banner_home_list);
    const { cardsHome, loading: cardsHomeLoading } = useSelector(state => state.cardHome_list);
    const { categoriesBannerHome, loading: categoriesBannerHomeLoading } = useSelector(state => state.categoriesBannerHome_list);
    const { transportsBannerHome, loading: transportsBannerHomeLoading } = useSelector(state => state.transportBannerHome_list);

    const dispatch = useDispatch();

    useEffect(() => {
        axios.get(`${apiUrl}/homeSliderBanner`).then((res) => {
          if (res.data) {
            setSliders(res.data[0].images)
          }
        });

        dispatch(bannerHomeList());
        dispatch(cardBannerHomeList());
        dispatch(categoriesBannerHomeList());
        dispatch(transportsBannerHomeList());
        dispatch(slidersList());

    }, []);

    return (
        homeBannersLoading
        ||
        cardsHomeLoading
        ||
        categoriesBannerHomeLoading
        ||
        transportsBannerHomeLoading
        ?
        <Loader />
        :
        <section>
            <TopHeader />
            {
                homeBanners?.slice(-1)?.map(homeBanner =>  <Slider image = { homeBanner?.image } link={homeBanner?.link} />)
            }
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <SearchBar />
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <div className="eltdf-full-width">
                                    <div className="eltdf-full-width-inner">
                                        <div className="eltdf-grid-row">
                                            <div className="eltdf-page-content-holder eltdf-grid-col-12">
                                                <div className="eltdf-row-grid-section-wrapper">
                                                    <div className="eltdf-row-grid-section" id="mobile_first_part_home">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521 first_part_home_padding">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner vc_custom_1537357253521 first_part_padding_bottom first_part_home_padding">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="eltdf-banner-holder  eltdf-banner-skin-light eltdf-banner-horizontal-center eltdf-banner-vertical-middle">
                                                                            <div className="eltdf-banner-image">
                                                                                <img width={1194} height={262} src={newCollectionImageBanner} className="attachment-full size-full" alt="m" />
                                                                            </div>
                                                                            <div className="eltdf-banner-text-holder d-none">
                                                                                <div className="eltdf-banner-text-outer">
                                                                                    <div className="eltdf-banner-text-inner">
                                                                                        <div className="eltdf-banner-inner-image">
                                                                                            <img src={newCollectionText} className="attachment-full size-full" alt="m" style={{ marginLeft: '20%' }} /> 
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="eltdf-row-grid-section-wrapper">
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521" id="second_part_home_padding">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="eltdf-pl-holder eltdf-grid-list eltdf-disable-bottom-space eltdf-standard-layout eltdf-four-columns eltdf-small-space eltdf-info-below-image  eltdf-product-add-to-cart-in-box     eltdf-hover-type-simple custom-text-position-left eltdf-navigation-bellow-slider" data-type="standard" data-info-position="info-below-image" data-number-of-posts={8} data-with-custom-text-position="left" data-number-of-columns="four" data-space-between-items="small" data-orderby="date" data-order="DESC" data-category-values="fall" data-show-all-item-in-filter="yes" data-taxonomy-to-display="category" data-show-category-filter="yes" data-image-size="full" data-display-title="yes" data-title-tag="h5" data-display-category="yes" data-display-excerpt="no" data-excerpt-length={50} data-display-rating="no" data-display-price="yes" data-display-button="yes" data-button-skin="default" data-hover-type="simple" data-number-of-visible-items={3} data-slider-loop="yes" data-slider-autoplay="yes" data-slider-speed={5000} data-slider-speed-animation={600} data-slider-navigation="yes" data-slider-navigation-position="bellow-slider" data-slider-pagination="yes" data-class-name="pli" data-max-num-pages={1} data-next-page={2}>
                                                                            <div className="eltdf-pl-outer eltdf-outer-space">
                                                                                {   categoriesBannerHome?.slice(-1)?.map(categoryHome => <div key={categoryHome?._id}>
                                                                                        <div className="eltdf-pli eltdf-item-space ">
                                                                                            <div className="eltdf-pli-inner">
                                                                                                <div className="eltdf-pli-image">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryHome?.title1
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        <img src={categoryHome?.image1} className="attachment-full size-full wp-post-image" alt="m" sizes="(max-width: 800px) 100vw, 800px" /> 

                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>
        
                                                                                            <div className="eltdf-pli-text-wrapper">
                                                                                                <h5 itemProp="name" className="entry-title eltdf-pli-title">
                                                                                                    <Link                                 
                                                                                                        to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryHome?.title1
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        {categoryHome?.title1}
                                                                                                    </Link>
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div> 

                                                                                        <div className="eltdf-pli eltdf-item-space ">
                                                                                            <div className="eltdf-pli-inner">
                                                                                                <div className="eltdf-pli-image">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryHome?.title2
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        <img src={categoryHome?.image2} className="attachment-full size-full wp-post-image" alt="m" sizes="(max-width: 800px) 100vw, 800px" /> 

                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>
        
                                                                                            <div className="eltdf-pli-text-wrapper">
                                                                                                <h5 itemProp="name" className="entry-title eltdf-pli-title">
                                                                                                    <Link                                 
                                                                                                        to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryHome?.title2
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        {categoryHome?.title2}
                                                                                                    </Link>
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div> 

                                                                                        <div className="eltdf-pli eltdf-item-space ">
                                                                                            <div className="eltdf-pli-inner">
                                                                                                <div className="eltdf-pli-image">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryHome?.title3
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        <img src={categoryHome?.image3} className="attachment-full size-full wp-post-image" alt="m" sizes="(max-width: 800px) 100vw, 800px" /> 

                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>
        
                                                                                            <div className="eltdf-pli-text-wrapper">
                                                                                                <h5 itemProp="name" className="entry-title eltdf-pli-title">
                                                                                                    <Link                                 
                                                                                                        to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryHome?.title3
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        {categoryHome?.title3}
                                                                                                    </Link>
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div> 
                                                                                    </div>
                                                                                )}
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="eltdf-row-grid-section-wrapper" style={{ background: '#D27F8B' }}>
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521a">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div id="projectFacts" className="sectionClass">
                                                                            <div className="fullWidth eight columns">
                                                                                <div className="projectFactsWrap">
                                                                                    {
                                                                                        transportsBannerHome && <>
                                                                                            <div className="item wow fadeInUpBig animated animated" key={transportsBannerHome[0]?._id} style={{visibility: 'visible'}}>
                                                                                                <img src={truckTransport} alt="Icon" />
                                                                                                <p id="number1" className="number">{transportsBannerHome[0]?.title}</p>
                                                                                                <span></span>
                                                                                                <p>{transportsBannerHome[0]?.text}</p>
                                                                                            </div>
                                                                                            <div className="item wow fadeInUpBig animated animated" key={transportsBannerHome[1]?._id} style={{visibility: 'visible'}}>
                                                                                                <img src={mask2} alt="Icon" />
                                                                                                <p id="number1" className="number">{transportsBannerHome[1]?.title}</p>
                                                                                                <span></span>
                                                                                                <p>{transportsBannerHome[1]?.text}</p>
                                                                                            </div>
                                                                                            <div className="item wow fadeInUpBig animated animated" key={transportsBannerHome[2]?._id} style={{visibility: 'visible'}}>
                                                                                                <img src={mask3} alt="Icon" />
                                                                                                <p id="number1" className="number">{transportsBannerHome[2]?.title}</p>
                                                                                                <span></span>
                                                                                                <p>{transportsBannerHome[2]?.text}</p>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="eltdf-row-grid-section-wrapper" style={{ marginTop: '35px' }} id="beach_hat_home">
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521a">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="eltdf-elements-holder eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                            <div className="eltdf-eh-item" data-item-class="eltdf-eh-custom-6424" data-680="100px 0 0">
                                                                                <div className="eltdf-eh-item-inner">
                                                                                    <div className="eltdf-eh-item-content eltdf-eh-custom-6424">
                                                                                        <div className="eltdf-product-exhibition">
                                                                                        {
                                                                                            cardsHome?.slice(-1)?.map(cardHome => <div className="eltdf-pe-item" key={cardHome?._id}>
                                                                                            <div className="eltdf-pe-background">
                                                                                                <div className="eltdf-pe-background-image">
                                                                                                    <img width={569} height={569} src={cardHome?.image} className="attachment-full size-full" alt="s" sizes="(max-width: 569px) 100vw, 569px" /> 
                                                                                                </div>
                                                                                                <div className="eltdf-pe-main-info clearfix">
                                                                                                    <div className="eltdf-pe-main-info-inner" data-parallax="{&quot;y&quot;: -40, &quot;smoothness&quot;: 10}" style={{transform: 'translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)', WebkitTransform: 'translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)'}}>
                                                                                                        <div className="eltdf-pe-main-image">
                                                                                                            <img src={cardHome?.image1} alt="a" width={245} height={314} /> 
                                                                                                        </div>
                                                                                                        <div className="eltdf-pe-main-text">
                                                                                                            <h3 itemProp="name" className="entry-title eltdf-pe-title"> {cardHome?.title} </h3>
                                                                                                            <p itemProp="description" className="eltdf-pe-excerpt">{cardHome?.text} </p>
                                                                                                            <div className="eltdf-pe-price">
                                                                                                                <span className="woocommerce-Price-amount amount">
                                                                                                                    {cardHome?.price}<span className="woocommerce-Price-currencySymbol">€</span>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <span className="eltdf-pe-button">
                                                                                                                <a itemProp="url" href="/new" target="_self" className="eltdf-btn eltdf-btn-small eltdf-btn-solid"> 
                                                                                                                    <span className="eltdf-btn-text">Shop Now</span> 
                                                                                                                </a> 
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>)
                                                                                        }

                                                                                        </div> 
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="eltdf-row-grid-section-wrapper" id="follow_us_part_home">
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                    <div className="eltdf-elements-holder eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                            <div className="eltdf-eh-item" data-item-class="eltdf-eh-custom-6424" data-680="100px 0 0">
                                                                                <div className="eltdf-eh-item-inner">
                                                                                    <div className="eltdf-eh-item-content eltdf-eh-custom-6424">
                                                                                        <div className="eltdf-product-exhibition">
                                                                                            <div className="eltdf-pe-item" className="follow_us_instagram_padding">
                                                                                                <div className="eltdf-pe-background">
                                                                                                    <div className="eltdf-pe-background-image">
                                                                                                        <img src={followUsOnInstagram} className="attachment-full size-full" alt="s" sizes="(max-width: 569px) 100vw, 569px" style={{ margin: '0 auto' }} /> 
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> 
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1538061460680 eltdf-content-aligment-left" id="slider_part-home" style={{ marginTop: '55px' }}>
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="eltdf-elements-holder   eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                    <div className="eltdf-eh-item eltdf-eh-expander eltdf-eh-expander-from-left eltdf-eh-custom-7279 eltdf-eh-expander-on" data-item-class="eltdf-eh-custom-7279" data-animation="eltdf-eh-expander eltdf-eh-expander-from-left" data-animation-delay={100} data-1367-1600="2px 165px 80px 67px" data-1025-1366="2px 157px 60px 50px" data-769-1024="2px 89px 60px 30px" data-481-680="2px 60px 36px 30px" data-480="2px 60px 36px 30px">
                                                                        <div className="eltdf-eh-item-inner">
                                                                            <div className="eltdf-eh-item-content" id="home_slider_padding_mob11" style={{padding: '42px 0px 20px 67px'}}>
                                                                                <div className="wpb_single_image wpb_content_element vc_align_left">
                                                                                    <div className="eltdf-row-grid-section" style={{width: '100%'}}>
                                                                                        <div className="desktop-slider-home">
                                                                                            <OwlCarousel items={4}  
                                                                                                className="owl-theme wpb_wrapper vc_figure"  
                                                                                                loop                                     
                                                                                                nav                                                                 
                                                                                                margin={8} 
                                                                                                autoplay = {true}
                                                                                                autoplayTimeout = {3000}
                                                                                            >  
                                                                                        
                                                                                                {
                                                                                                    // sliders?.map(slider => 
                                                                                                    //     slider?.images?.map(item => <div key={slider?._id}>
                                                                                                    //         <img  className="img" src={item} />
                                                                                                    //     </div>
                                                                                                    // )   
                                                                                                    // )

                                                                                                    sliders?.map(slider => <div key={slider?._id}>
                                                                                                        <img src={slider} className="img" />
                                                                                                    </div>)
                                                                                                
                                                                                                } 
                                                                                        
                                                                                            </OwlCarousel> 
                                                                                        </div>

                                                                                        <div className="mobile-slider-home">
                                                                                            <OwlCarousel items={2}  
                                                                                                className="owl-theme wpb_wrapper vc_figure"  
                                                                                                loop                                     
                                                                                                nav                                                                 
                                                                                                margin={8} 
                                                                                                autoplay = {true}
                                                                                                autoplayTimeout = {3000}
                                                                                            >  
                                                                                            
                                                                                                {
                                                                                                    sliders?.map(slider => <div key={slider?._id}>
                                                                                                        <img src={slider} className="img" />
                                                                                                    </div>)
                                                                                                }  
                                                                                        
                                                                                            </OwlCarousel> 
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="eltdf-elements-holder-background-outer" style={{padding: '70px 976px 0 0px'}} data-769-1024="36px 0px 0 0px">
                                                                            <div className="eltdf-elements-holder-background-inner" style={{backgroundColor: '#FFF4F3'}} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
   
}