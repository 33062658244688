function listCardBannerBlogReducer(state = { cardsBlog: [] }, action) {
    switch (action.type) {
        case 'CARD_BANNER_BLOG_LIST_REQUEST':
            return { loading: true }
        case 'CARD_BANNER_BLOG_LIST_SUCCESS':
            return { loading: false, cardsBlog: action?.payload }
        case 'CARD_BANNER_BLOG_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addCardBlogReducer(state = {}, action) {
    switch (action.type) {
        case 'CARD_BANNER_BLOG_CREATE_REQUEST':
            return { loading: true }
        case 'CARD_BANNER_BLOG_CREATE_SUCCESS':
            return { loading: false, cardBlog: action?.payload, success: true }
        case 'CARD_BANNER_BLOG_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateCardBlogReducer(state = { cardBlog: {} }, action) {
    switch(action.type) {
        case 'CARD_BANNER_BLOG_UPDATE_REQUEST':
            return { loading: true }
        case 'CARD_BANNER_BLOG_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'CARD_BANNER_BLOG_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteCardBlogReducer(state = { cardBlog: {} }, action) {
    switch(action.type) {
        case 'CATEGORIES_BANNER_BLOG_DELETE_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BANNER_BLOG_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'CATEGORIES_BANNER_BLOG_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listCardBannerBlogReducer, addCardBlogReducer, updateCardBlogReducer, deleteCardBlogReducer }