function listSizeReducer(state = { sizes: [] }, action) {
    switch (action.type) {
        case 'SIZE_LIST_REQUEST':
            return { loading: true, sizes: [] }
        case 'SIZE_LIST_SUCCESS':
            return { loading: false, sizes: action?.payload }
        case 'SIZE_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addSizeReducer(state = {}, action) {
    switch (action.type) {
        case 'SIZE_CREATE_REQUEST':
            return { loading: true }
        case 'SIZE_CREATE_SUCCESS':
            return { loading: false, size: action?.payload, success: true }
        case 'SIZE_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateSizeReducer(state = { size: {} }, action) {
    switch(action.type) {
        case 'SIZE_UPDATE_REQUEST':
            return { loading: true }
        case 'SIZE_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'SIZE_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteSizeReducer(state = { size: {} }, action) {
    switch(action.type) {
        case 'SIZE_DELETE_REQUEST':
            return { loading: true }
        case 'SIZE_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'SIZE_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listSizeReducer, addSizeReducer, updateSizeReducer, deleteSizeReducer }