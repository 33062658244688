import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const getAllContacts = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CONTACTS_LIST_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.get(`${apiUrl}/contacts`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CONTACTS_LIST_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'CONTACTS_LIST_FAILED', payload: error });
    }
}

export const createContact = (post) => async (dispatch) => {
    try {
        dispatch({ type: 'CREATE_CONTACT_REQUEST' });

        const { data } = await axios.post(`${apiUrl}/contacts`, post);
        dispatch({ type: 'CREATE_CONTACT_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'CREATE_CONTACT_FAILED', payload: error });
    }
}

export const deleteContact = (id) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'CONTACT_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/contacts/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CONTACT_DELETE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'CONTACT_DELETE_FAIL', payload: error });
    }
}