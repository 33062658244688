import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cardBannerHomeList, deleteCardBannerHome } from '../../../actions/homeCardBanner_actions';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css'

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';

const CardHomeList = () => {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { cardsHome, loading } = useSelector(state => state.cardHome_list);
    const { success } = useSelector(state => state.delete_cardHome);

    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        dispatch(cardBannerHomeList());
    },[success])

    const deleteHandler = (bannerId) => {
        dispatch(deleteCardBannerHome(bannerId));
    }

    const confirmToDelete = (bannerId) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>A jeni i sigurtë se dëshironi me fshi? </h1>
                        <button onClick={onClose}>Anulo</button>
                        <button
                            onClick={() => {
                                deleteHandler(bannerId)
                                onClose();
                            }}
                        >
                        Po, Fshije!
                        </button>
                    </div>
                );
            }
        });
        
    }

    return (
        <div className="wrapper">
            <AdminHeader />
            <AdminSideBar />
        
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Card Home</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        {loading ? <div className="lds-facebook"><div></div><div></div><div></div></div> : ''}
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Card Home</h3>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Fotoja</th>
                                                    <th>Fotoja1</th>
                                                    <th>Titulli</th>
                                                    <th>Qmimi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { cardsHome?.map(cardHome => <tr key={cardHome?._id}>
                                                    <td>
                                                        <div className="form-row">
                                                            <div className="col">
                                                                <img className="img img-responsive img-thumbnail" src={cardHome?.image} style={{width: '160px', height: 'auto'}} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-row">
                                                            <div className="col">
                                                                <img className="img img-responsive img-thumbnail" src={cardHome?.image1} style={{width: '160px', height: 'auto'}} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{cardHome?.title}</td>
                                                    <td>{cardHome?.price}</td>
                                                    <div className="btn-group">
                                                        <Link className="btn btn-warning btn-sm" data-toggle="tooltip" data-placement="top" title="Edito të dhënat" to={`/admin/add-home-card/${cardHome?._id}`}>
                                                            <span className="fa fa-edit "></span>
                                                        </Link>
                                                        <button className="btn btn-danger btn-sm" data-toggle="tooltip" data-placement="top" title="Fshijë" onClick={() => confirmToDelete(cardHome?._id)}>
                                                            <span className="fa fa-trash "></span>
                                                        </button>
                                                    </div>
                                                    
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default CardHomeList;
