import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../images/Group 48.png';
import './style.css';

const Footer = (props) => {
    return (
        <div id="section_footer">
            <section className="kc-elm kc-css-985990 kc_row">
                <div className="kc-row-container kc-container">
                    <div className="kc-wrap-columns">
                        <div className="kc-elm kc-css-415517 kc_col-sm-12 kc_column kc_col-sm-12" id="footer_icon">
                            <div className="kc-col-container container eltdf-grid">
                                <div className="kc-elm kc-css-859427 kc_row kc_row_inner">
                                    <div className="kc-elm kc-css-421427 kc_col-sm-3 kc_column_inner kc_col-sm-3">
                                        <div className="kc_wrapper kc-col-inner-container">
                                            <div className="ssc_icon_box kc-elm kc-css-889399 t_icon_left ">
                                                
                                                <div className="cont_box">
                                                      
                                                <div className="cont_box">
                                                    <div className="title">
                                                        <img src={Logo} style={{ marginTop: '-20px' }} />
                                                    </div>
                                                    <div className="description">
                                                        <p>none</p>
                                                    </div>
                                                </div>
                                             
                                                </div>
                                                <div className="cont_box first_cont_box">
                                                    <div className="description">
                                                        <p>none</p>
                                                    </div>
                                                </div>
                                            </div>
             
                                            <div className="kc-elm kc-css-433909 kc_text_block">
                                                <p style={{color: '#A65A6A'}}>
                                                    <Link to="/new"><span style={{color: '#A65A6A'}}>New Collection</span></Link><br />
                                                    <Link to="/sale"><span style={{color: '#A65A6A'}}>Items on Sale</span></Link><br />
                                                    <Link to="/privacy_policy"><span style={{color: '#A65A6A'}}>Privacy Policy</span></Link><br />
                                                    <Link to="/terms_of_service"><span style={{color: '#A65A6A'}}>Terms of Conditions</span></Link><br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kc-elm kc-css-421427 kc_col-sm-3 kc_column_inner kc_col-sm-3">
                                        <div className="kc_wrapper kc-col-inner-container">
                                            <div className="ssc_icon_box kc-elm kc-css-889399 t_icon_left ">
                                                <div className="cont_box">
                                                    <div className="title">Company</div>
                                                    <div className="description">
                                                        <p>none</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="kc-elm kc-css-433909 kc_text_block">
                                                <p style={{color: '#A65A6A'}}>
                                                    <Link to="/about-us"><span style={{color: '#A65A6A'}}>About us</span></Link><br />
                                                    <Link to="/contact"><span style={{color: '#A65A6A'}}>Reach out</span></Link><br />
                                                    <Link to="/blog"><span style={{color: '#A65A6A'}}>Newsletter</span></Link><br />
                                                    <Link to="/blog"><span style={{color: '#A65A6A'}}>Blog</span></Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kc-elm kc-css-421427 kc_col-sm-3 kc_column_inner kc_col-sm-3">
                                        <div className="kc_wrapper kc-col-inner-container">
                                            <div className="ssc_icon_box kc-elm kc-css-889399 t_icon_left ">
                                                <div className="cont_box">
                                                    <div className="title">Account</div>
                                                    <div className="description">
                                                        <p>none</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="kc-elm kc-css-433909 kc_text_block">
                                                <p style={{color: '#A65A6A'}}>
                                                    <Link to="/login"><span style={{color: '#A65A6A'}}>My Account</span></Link><br />
                                                    <Link to="/cart"><span style={{color: '#A65A6A'}}>Shopping cart</span></Link><br />
                                                    <Link to="/user/orders"><span style={{color: '#A65A6A'}}>Orders</span></Link><br />
                                                    <Link to="/wishlist"><span style={{color: '#A65A6A'}}>Wishlist</span></Link><br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kc-elm kc-css-421427 kc_col-sm-3 kc_column_inner kc_col-sm-3">
                                        <div className="kc_wrapper kc-col-inner-container">
                                            <div className="ssc_icon_box kc-elm kc-css-889399 t_icon_left ">
                                                <div className="cont_box">
                                                    <div className="title">Discover</div>
                                                    <div className="description">
                                                        <p>none</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="kc-elm kc-css-433909 kc_text_block">
                                                <p style={{color: '#A65A6A'}}>
                                                    <Link to="/new"><span style={{color: '#A65A6A'}}>Clothes</span></Link><br />
                                                    <Link to="/new"><span style={{color: '#A65A6A'}}>Shoes</span></Link><br />
                                                    <Link to="/new"><span style={{color: '#A65A6A'}}>Bags</span></Link><br />
                                                    <Link to="/new"><span style={{color: '#A65A6A'}}>Accessories</span></Link><br />
                                                </p>
                                            </div>
                                        </div>
                                    </div> 

                                    <div className="kc-elm kc-css-421427 kc_col-sm-3 kc_column_inner kc_col-sm-3">
                                        <div className="kc_wrapper kc-col-inner-container">
                                            <div className="ssc_icon_box kc-elm kc-css-889399 t_icon_left ">
                                                <div className="cont_box">
                                                    <div className="title">Connect</div>
                                                    <div className="description">
                                                        <p>none</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="kc-elm kc-css-433909 kc_text_block">
                                                <p style={{color: '#A65A6A'}}>
                                                    <a href="https://instagram.com/npikashop?utm_medium=copy_link" target="_blank"><span style={{color: '#A65A6A'}}>Instagram</span></a><br />
                                                    <a href="https://www.facebook.com/npikashop" target="_blank"><span style={{color: '#A65A6A'}}>Facebook</span></a><br />
                                                    <a href="https://vm.tiktok.com/ZMRWVa36h/" target="_blank"><span style={{color: '#A65A6A'}}>Tiktok</span></a><br />
                                                    <Link to="/contact"><span style={{color: '#A65A6A'}}>Contact us</span></Link><br />
                                                </p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>

                                <div className="kc-elm kc-css-976240 kc_row kc_row_inner">
                                    <div className="kc-elm kc-css-165393 kc_col-sm-12 kc_col-sm-12">
                                        <div className="kc_wrapper kc-col-inner-container">
                                            <div className=" kc-elm kc-css-851798">
                                                <div id="slmenu-882132" className="menu-simple-container">
                                                    <ul id="menu-simple" className="slmm">
                                                        <li id="nav-menu-item-2092" className="slm-dropd-item main-menu-item slm-mega-item menu-item-even slm-menu-item-depth-0 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item both" style={{zIndex: 1}}>
                                                            <Link to="/" className="menu-link main-menu-link">
                                                                <span>Home</span>
                                                            </Link>
                                                        </li>

                                                        <li id="nav-menu-item-2092" className="slm-dropd-item main-menu-item slm-mega-item menu-item-even slm-menu-item-depth-0 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item both" style={{zIndex: 1}}>
                                                            <Link to="/about-us" className="menu-link main-menu-link">
                                                                <span>About</span>
                                                            </Link>
                                                        </li>

                                                        <li id="nav-menu-item-2092" className="slm-dropd-item main-menu-item slm-mega-item menu-item-even slm-menu-item-depth-0 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item both" style={{zIndex: 1}}>
                                                            <Link to="/contact" className="menu-link main-menu-link">
                                                                <span>Contact</span>
                                                            </Link>
                                                        </li>
                                                    </ul>

                                                    <ul id="menu-simple" className="slmm2">
                                                        <li id="nav-menu-item-2092" className="slm-dropd-item main-menu-item slm-mega-item menu-item-even slm-menu-item-depth-0 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item both" style={{zIndex: 1}}>
                                                                <a href="https://vm.tiktok.com/ZMRWVa36h/" target="_blank" className="menu-link main-menu-link">
                                                                    <i className="fab fa-tiktok"></i>
                                                                </a>
                                                            </li>

                                                        <li id="nav-menu-item-2092" className="slm-dropd-item main-menu-item slm-mega-item menu-item-even slm-menu-item-depth-0 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item both" style={{zIndex: 1}}>
                                                            <a href="https://www.facebook.com/npikashop" target="_blank" className="menu-link main-menu-link">
                                                                <i className="fab fa-facebook-f"></i>
                                                            </a>
                                                        </li>

                                                        <li id="nav-menu-item-2092" className="slm-dropd-item main-menu-item slm-mega-item menu-item-even slm-menu-item-depth-0 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item both" style={{zIndex: 1}}>
                                                            <a href="https://instagram.com/npikashop?utm_medium=copy_link" target="_blank" className="menu-link main-menu-link">
                                                                <i className="fab fa-instagram"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer;