function listCategoryReducer(state = { categories: [] }, action) {
    switch (action.type) {
        case 'CATEGORIES_LIST_REQUEST':
            return { loading: true, categories: [] }
        case 'CATEGORIES_LIST_SUCCESS':
            return { loading: false, categories: action?.payload }
        case 'CATEGORIES_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addCategoryReducer(state = {}, action) {
    switch (action.type) {
        case 'CATEGORY_CREATE_REQUEST':
            return { loading: true }
        case 'CATEGORY_CREATE_SUCCESS':
            return { loading: false, category: action?.payload, success: true }
        case 'CATEGORY_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateCategoryReducer(state = { category: {} }, action) {
    switch(action.type) {
        case 'CATEGORY_UPDATE_REQUEST':
            return { loading: true }
        case 'CATEGORY_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'CATEGORY_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteCategoryReducer(state = { category: {} }, action) {
    switch(action.type) {
        case 'CATEGORY_DELETE_REQUEST':
            return { loading: true }
        case 'CATEGORY_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'CATEGORY_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listCategoryReducer, addCategoryReducer, updateCategoryReducer, deleteCategoryReducer }