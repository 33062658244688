import React, {  useEffect } from 'react';
import { Link } from 'react-router-dom';

import './plugins/fontawesome-free/css/all.min.css';
import './plugins/sweetalert2/sweetalert2.min.css';
import './plugins/toastr/toastr.min.css';
import './dist/css/adminlte.min.css';

const AdminFooter = () => {
    return (
        <div className="wrapper">    
            <footer className="main-footer">
                <strong>Copyright &copy; {new Date().getFullYear()} <a href="https://adminlte.io">Npika-shop</a>.</strong> All rights reserved.
            </footer>

        </div>
    )
}

export default AdminFooter;