import React from 'react';
import { withRouter } from 'react-router';

import TopHeader from '../components/TopHeader/TopHeader';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import Slider from '../components/Slider/Slider';

const Layout = withRouter((props) => {
    return (
        <div>
            {/* { props.location.pathname != '/kontakti' && <Header /> } */}
            {/* <Slider /> */}
            {/* <Header /> */}
            {/* <TopHeader /> */}
            <div>
                {props.children}
            </div>
            {/* <Footer /> */}
        </div>
    )
})

export default Layout;