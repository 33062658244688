import axios from 'axios';
import Cookie from 'js-cookie';
import { apiUrl } from '../constants/backendUrl';

export const usersList = () => async (dispatch, getState) => {
    try {
        dispatch({  type: 'USERS_LIST_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        
        const { data } = await axios.get(`${apiUrl}/users`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'USERS_LIST_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'USERS_LIST_FAIL', payload: error });
    }
}

export const login = (username, password) => async (dispatch) => {
    dispatch({ type: 'USER_SIGNIN_REQUEST', payload: { username, password }});

    try {
        const { data } = await axios.post(`${apiUrl}/users/login`, {username, password});
        dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: data });
        Cookie.set('userInfo', JSON.stringify(data));
    } catch(error) {
        dispatch({ type: 'USER_SIGNIN_FAIL', payload: error?.response?.data?.error });
    }
}

export const registerUser = (postData) => async (dispatch) => {
    dispatch({ type: 'USER_REGISTER_REQUEST' });

    try {
        const { data } = await axios.post(`${apiUrl}/users/register`, postData);
        dispatch({ type: 'USER_REGISTER_SUCCESS', payload: data });
    } catch (error) {
        dispatch({ type: 'USER_REGISTER_FAIL', payload: error?.response?.data?.error });
    }
}

export const updateUserInfo = ({ userId, name, username, password }) => async (dispatch, getState) => {
    dispatch({ type: 'USER_UPDATE_REQUEST', payload: { userId, name, username, password } });
    const { userSignIn : { userInfo }} = getState();
    try {
        const { data } = await axios.put(`${apiUrl}/users/${userId}`,{ name, username, password }, { headers: { 
            'Authorization': 'Bearer ' + userInfo?.token
        }});
        dispatch({ type: 'USER_UPDATE_SUCCESS', payload: data });
        Cookie.set('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({ type: 'USER_UPDATE_FAIL', payload: error.message });
    }
}

export const deleteUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'USER_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/users/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'USER_DELETE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'USER_DELETE_FAIL', payload: error?.message });
    }
}

export const logout = () => (dispatch) => {
    Cookie.remove("userInfo");
    dispatch({ type: 'USER_LOGOUT' })
}
