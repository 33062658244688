import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productsList, deleteProduct } from '../../../actions/product_actions';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';
import { apiUrl } from '../../../constants/backendUrl';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css'

import AdminSideBar from '../Admin-Sidebar';
import AdminFooter from '../AdminFooter';
import AdminHeader from '../AdminHeader';

const ProductsList = () => {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { success } = useSelector(state => state.delete_product);
    const { products, loading, error } = useSelector(state => state.products_list);
    
    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }
        
        dispatch(productsList());
    
        return () => {

        }
    },[success])

    const deleteHandler = (productId) => {
        dispatch(deleteProduct(productId));
    }

    const confirmToDelete = (productId) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>A jeni i sigurtë se dëshironi me fshi? </h1>
                        <button onClick={onClose}>Anulo</button>
                        <button
                            onClick={() => {
                                deleteHandler(productId)
                                onClose();
                            }}
                        >
                        Po, Fshije!
                        </button>
                    </div>
                );
            }
        });
        
    }

    return (
        <div className="wrapper">
            <AdminHeader />
            <AdminSideBar />

            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <h1>Produktet</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        {loading ? <div className="lds-facebook"><div></div><div></div><div></div></div> : ''}

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Produktet</h3>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Titulli</th>
                                                    <th>Nëntitulli</th>
                                                    <th>Kategoria</th>
                                                    <th>Çmimi</th>
                                                    <th>Fotoja</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { products?.products && products?.products?.map(product => <tr key={product?._id}>
                                                    <td>{product?.title}</td>
                                                    <td>{product?.subtitle}</td>
                                                    <td>{product?.category}</td>
                                                    <td>{product?.price?.toFixed(2)}€</td>
                                                    <td>
                                                        <div className="form-row">
                                                            <div className="col">
                                                                <img className="img img-responsive img-thumbnail" src={`${apiUrl}/images/${product?.availableOptions[0]?.images[0]}`} style={{width: '160px', height: 'auto'}} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <Link className="btn btn-warning btn-sm" data-toggle="tooltip" data-placement="top" title="Edito të dhënat gjenerale të produktit" to={`/admin/update-product/${product?._id}`}>
                                                                <span className="fa fa-edit "></span>
                                                            </Link>
                                                            <Link className="btn btn-primary btn-sm" data-toggle="tooltip" data-placement="top" title="Edito Sku, Foton e produktit" to={`/admin/details-products-list/${product?._id}`}>
                                                                <span className="fa fa-edit "></span>
                                                            </Link>
                                                            <Link className="btn btn-success btn-sm" data-toggle="tooltip" data-placement="top" title="Edito Sasinë e produktit" to={`/admin/size-details-products-list/${product?._id}`}>
                                                                <span className="fa fa-edit "></span>
                                                            </Link>
                                                            <button className="btn btn-danger btn-sm" data-toggle="tooltip" data-placement="top" title="Fshijë produktin" onClick={() => confirmToDelete(product?._id)}>
                                                                <span className="fa fa-trash "></span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <AdminFooter />
        </div>
    );
};

export default ProductsList;
