import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/user_actions';

import SideBar from './Sidebar';
import './style.css'
import './topHeader.scss';

export default function TopHeader(props) {
    const { userInfo } = useSelector(state => state.userSignIn); //sepse tek combineReducers e kemi lan userSignIn
    const dispatch = useDispatch();

    const handleLogout =  async () => {
        // dispatch(logout());  
        if(userInfo) {
            dispatch(logout());
        }
    }
    useEffect(() => {
    }, []);
    return (
        <div className="eltdf-top-bar eltdf-sticky-header" id="myHeader">
            <div className="eltdf-vertical-align-containers">
                <div className="eltdf-position-left-top-header">
                    <div className="eltdf-position-left-inner">
                        <div id="text-9" className="widget widget_text eltdf-top-bar-widget"> 
                            <div className="textwidget">
                                <SideBar />
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="eltdf-position-right-top-header">
                    <div className="eltdf-position-right-inner">                       
                        <div className="widget eltdf-login-register-widget eltdf-user-not-logged-in">
                            {
                                userInfo && userInfo?.isAdmin 
                                &&                             
                                <Link to="/admin-paneli" className="eltdf-login-opener">
                                    <span className="eltdf-login-text">ADMIN</span>
                                </Link>
                            }
                        </div>
                        <div className="widget eltdf-login-register-widget eltdf-user-not-logged-in">
                            {
                                !userInfo 
                                &&                             
                                <Link to="/login" className="eltdf-login-opener">
                                    <span className="eltdf-login-text">SIGN IN</span>
                                </Link>
                            }
                        </div>
                        <div className="widget eltdf-custom-font-widget">
                            <p className="eltdf-custom-font-holder  eltdf-cf-6596  " style={{fontSize: '11px', fontWeight: 400, letterSpacing: '.16em', margin: '0 27px 0 27px'}} data-item-class="eltdf-cf-6596">
                                <Link className="eltdf-custom-font-holder-link" itemProp="url" to="/contact" target="_self">
                                    CONTACT US
                                </Link>
                            </p>
                        </div>
                        <div className="eltdf-custom-font-widget">
                            {
                                userInfo
                                &&
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#A65A6A', fontFamily: 'Zona Pro' }}>
                                        { userInfo?.name }
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to="/user/orders" className="dropdown-item">Orders</Link></li>
                                        <li><Link to="/user/edit/data" className="dropdown-item">Your Data</Link></li>
                                        <li>
                                            <Link className="dropdown-item" to="/" onClick={handleLogout}>Log out</Link>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}