import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { subSubCategoriesList } from '../../actions/subSubCategories_actions';

import './style.css';

//t dhanat i marr si props, prej Shop.js sepse aty e thirri kete komponente
export default function SubSubCategory(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(subSubCategoriesList());

        return () => {
        }
    }, []);

    return (
        <div id="subSubCategory">
            <div className="eltdf-content">
                <div className="eltdf-content-inner">
                    <div className="eltdf-container">
                        <div className="eltdf-container-inner clearfix eltdf-row-grid-section">
                            <div className="eltdf-grid-row">  
                                <div className="col-sm-4 col-md-12 col-lg-9">
                                    <ul className="category-menu">
                                        {
                                            props?.subsubCategories?.map((subCategory) => <li key={subCategory?._id} className="sub_category-selected">
                                                {/* <a href="javascript:;" onClick={() => props?.searchBySubSubCategory(subCategory?.name)}>
                                                    {subCategory?.name}
                                                </a> */}
                                                <input  
                                                    type="radio" 
                                                    name="subCategory"
                                                    value={subCategory?.name}
                                                    id={subCategory?._id}
                                                    // checked={ subCategory?.name ? true : false }
                                                />
                                                <label htmlFor={subCategory?._id} onClick={() => props?.searchBySubSubCategory(subCategory?.name)}>
                                                    <span>{subCategory?.name}</span>
                                                </label>
                                            </li>)
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}