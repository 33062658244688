import React, {useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/user_actions';

import './plugins/fontawesome-free/css/all.min.css';
import './plugins/sweetalert2/sweetalert2.min.css';
import './plugins/toastr/toastr.min.css';
import './dist/css/adminlte.min.css';

import './plugins/jquery/jquery.min.js';
import './plugins/bootstrap/js/bootstrap.bundle.min.js';
import './dist/js/adminlte.min.js';
// import './dist/js/demo.js';

import userImage from './dist/img/user2-160x160.jpg';

import './admin-panel.css';

const AdminHeader = () => {
    const { userInfo } = useSelector(state => state.userSignIn);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const handleLogout =  async () => {
        if(userInfo) {
            dispatch(logout());
        }
    }

    useEffect(() => {
       
    },[location]);

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item" style={{paddingTop: '7px'}}>
                    <Link to="/login" className="btn btn-xs" title="Log Out" onClick={handleLogout}><i className="fas fa-power-off"></i></Link>   
                </li>
             
                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                        <i className="fas fa-expand-arrows-alt" />
                    </a>
                </li>
                </ul>
            </nav>
          
    )
}

export default AdminHeader;