import axios from 'axios';
import Cookie from 'js-cookie';
import { apiUrl } from '../constants/backendUrl';

//sqarim, ne e kemi objektin Produkti i cili e ka id qe e gjeneron mongo, dhe brenda tij kemi array t objekteve, ku secili e ka nga nje id, tash kur e shtojm ne cart produktin ne ja japim id e atij produkti qe gjendet ne arrayn e ketij objekti produkti, qe kur du me shtu ne cart produktin me gnjyr t kaltert dhe me ngjyr t verdh(qe i takojn t njejtit produkt) mem funksionu
export const addToCart = (id, quantity, color, size, countInStock, sizeDetailId) => async (dispatch, getState) => {
    try {
        const { data } = await axios.get(`${apiUrl}/products/single-product/color/${id}/${color}`);
        dispatch({ type: 'CART_ADD_ITEM', payload: {
            id: id, //id e produktit kryesor qe gjenerohet nga mongodb -> m duhet sepse kur jena n cart kur klikoj n produkt po du mem qu n faqen /product/:id
            product: data?.product?.availableOptions[0]?._id,
            title: data?.product?.title,
            subtitle: data?.product?.subtitle,
            color: color,
            category: data?.product?.category,
            image: data?.product?.availableOptions[0]?.images[0],
            price: data?.product?.price,
            discountPrice: data?.product?.discountPrice,
            countInStock: countInStock, //per me dit sa eshte countInStock per ate produkt
            quantity: quantity,
            size: size, //me dit cilin size e kemi shtu n cart (a 37, a 38)
            sizeDetailId: sizeDetailId //na vyn id secilit number (id e numrit 37psh) per me e bo edit ne backend sasin e atij numri kur bohet blerja
        }});
        const { cart: { cartItems }} = getState(); //getState from redux thunks
        Cookie.set("cartItems", JSON.stringify(cartItems));
    } catch(error) {
        console.log(error);
        dispatch({ type: 'ADD_TO_CART_FAILED', payload: error });
    }
}

//per me bo update carten, kur psh shkojm tek /cart athere nese e rriti quantityn tek butoni ather duhet me ndryshu quantityn me bo update pra
//size -> e kom unik kur i shtoj produkjtet sepse mundem psh produktin e njejt me shtu n cart dy her por me size t ndryshme
export const updateCart = ({id, quantity}) => async (dispatch, getState) => { 
    try {
        dispatch({ type: 'CART_UPDATE_ITEM', payload: {
            id: id,
            quantity: quantity
        }})
        const {cart:{cartItems}} = getState(); //getState from redux thunks
        Cookie.set("cartItems", JSON.stringify(cartItems));
    } catch(error) {
        console.log(error);
    }
}

export const decreaseQuantity = (items, product) => async (dispatch, getState) => {
    try {
        const itemsCart = items.slice();
        let productInCart = false;
        itemsCart.forEach((item) => {
            console.log(item)
            if(item?.product === product?.product) {
                item.quantity -= 1;
                productInCart = true;
            }
        })
        if(!productInCart) {
            itemsCart.push({...product, quantity: 1});
        }
        const {cart:{cartItems}} = getState(); //getState from redux thunks
        console.log(cartItems)
        Cookie.set("cartItems", JSON.stringify(cartItems));

        dispatch({ type: 'QUANTITY_MINUS', payload: itemsCart })
    } catch(err) {
        console.log(err)
    }
}

export const removeFromCart = (productId) => async (dispatch, getState) => {
    dispatch( { type: 'CART_REMOVE_ITEM', payload: productId });
    
    const { cart: { cartItems } } = getState(); //getState from redux thunks
    Cookie.set("cartItems", JSON.stringify(cartItems));
}

export const clearCart = () => (dispatch) => {
    Cookie.remove("cartItems");
    dispatch({ type: 'CLEAR_CART' });
}
