import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const cardBannerBlogList = () => async (dispatch) => {
    try {
        dispatch({type: 'CARD_BANNER_BLOG_LIST_REQUEST'});
        const cardBannerBlogData = await axios.get(`${apiUrl}/blogCards`);
        dispatch({ type: 'CARD_BANNER_BLOG_LIST_SUCCESS', payload: cardBannerBlogData?.data });
    } catch(error) {
        dispatch({ type: 'CARD_BANNER_BLOG_LIST_FAIL', payload: error });
    }
}

export const createCardBannerBlog = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'CARD_BANNER_BLOG_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        formData.append('image', data.image, data.image.name);
        formData.append('title', data.title);
        formData.append('subtitle', data.subtitle);
        formData.append('text', data.text);

        const cardBannerBlogData = await axios.post(`${apiUrl}/blogCards`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token },
              "Content-Type": "multipart/form-data",
        });
        dispatch({ type: 'CARD_BANNER_BLOG_CREATE_SUCCESS', payload: cardBannerBlogData?.data });
    } catch(error) {
        console.log(error?.response?.data?.message);
        console.log(error);
        dispatch({ type: 'CARD_BANNER_BLOG_CREATE_FAIL', payload: error?.response?.data?.message });
    }
}

export const updateCardBannerBlog = (id,data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'CARD_BANNER_BLOG_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        data.image instanceof Blob && formData.append('image', data.image, data.image.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
        formData.append('title', data.title);
        formData.append('subtitle', data.subtitle);
        formData.append('text', data.text);

        const cardBannerBlogData = await axios.patch(`${apiUrl}/blogCards/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CARD_BANNER_BLOG_UPDATE_SUCCESS', payload: cardBannerBlogData?.data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'CARD_BANNER_BLOG_UPDATE_FAIL', payload: error?.response?.data?.message  });
    }
}

export const deleteCardBannerBlog = (homeCardId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CATEGORIES_BANNER_BLOG_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const {data} = await axios.delete(`${apiUrl}/blogCards/${homeCardId}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CATEGORIES_BANNER_BLOG_DELETE_SUCCESS', payload: data });

    } catch(error) {
        console.log(error);
        dispatch({ type: 'CATEGORIES_BANNER_BLOG_DELETE_FAIL', payload: error });
    }
}
