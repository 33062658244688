import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sizesList } from '../../../actions/size_actions';
import { categoriesList } from '../../../actions/category_actions';
import { subCategoriesList } from '../../../actions/subCategory_actions';
import { subSubCategoriesList } from '../../../actions/subSubCategories_actions';
import { colorsList } from "../../../actions/color_actions";
import { clear } from '../../../actions/clear_action';

import { useHistory } from 'react-router-dom';
import axios from 'axios';
import crypto from "crypto";
import { Field, FieldArray, Form, Formik } from 'formik';
import InputColor from 'react-input-color';

import { apiUrl } from '../../../constants/backendUrl';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddProduct(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const [loading, setLoading] = useState(true);
    const [hexDecimal, setHexDecimal] = useState('');

    const [color, setColor] = useState(null);
    const [colorHex, setColorHex] = useState(null);
    const [countInStock, setCount] = useState(null);
    const [images, setImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [item, setItem] = useState([]);
    const [title, setTitle] = useState(null);
    const [subtitle, setSubTitle] = useState(null);
    const [category, setCategory] = useState(null);
    const [subCategory, setSubCategory] = useState(null);
    const [subSubCategory, setSubSubCategory] = useState(null);
    const [size, setSize] = useState(null);
    const [price, setPrice] = useState(null);
    const [discountPrice, setDiscountPrice] = useState('');
    const [description, setDescription] = useState(null);
    const [additionalInformation, setAdditionalInformation] = useState(null);
    const [sku, setSku] = useState(null);
    const [tags, setTags] = useState(null);
    const [sale, setSale] = useState(false);
    const [newIn, setNewIn] = useState(false);

    const { categories } = useSelector((state) => state.categories_list);
    const { subCategories } = useSelector((state) => state.sub_category_list);
    const { subsubCategories } = useSelector((state) => state.sub_sub_category_list);
    const { sizes } = useSelector((state) => state.sizes_list);
    const { colors } = useSelector((state) => state.colors_list);

    const emptySizeObj = { size: '', countInStock: '' };

    const dispatch = useDispatch();
    const history = useHistory();

    const renameFile = (originalFile, newName) => {
        return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("availableOptions", JSON.stringify(item));
        formData.append("title", title);
        formData.append("subtitle", subtitle);
        formData.append("category", category);
        formData.append("subCategory", subCategory);
        formData.append("subSubCategory", subSubCategory);
        formData.append("size", size);
        formData.append("price", price);
        formData.append('discountPrice', discountPrice);
        formData.append("description", description);
        formData.append("additionalInformation", additionalInformation);
        formData.append('tags', tags);
        formData.append('sale', sale);
        formData.append('new', newIn);
    
        for (const image of images) {
          formData.append("images", image);
        }
    
        axios.post(`${apiUrl}/products`, formData, {
            headers:
                { Authorization: 'Bearer ' + userInfo?.token }
        }).then((res) => {
            if (res.data.success) {
            Toastr.success("Produkti u krijua me sukses.");
            setLoading(false);
            setItem([]);
            dispatch(clear());
            clearInputs();
            } else {
            Toastr.error("Produkti nuk u krijua.Provo përsëri.");
            }
        });
        
    };

    const clearInputs = () => {
        setTitle('');
        setSubTitle('');
        setCategory('');
        setSubCategory('');
        setSubSubCategory('');
        setSize('');
        setPrice('');
        setDiscountPrice('');
        setDescription('');
        setAdditionalInformation('');
        setTags('');
        setSale(false);
    }

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        dispatch(categoriesList());
        dispatch(subCategoriesList());
        dispatch(subSubCategoriesList());
        dispatch(sizesList());
        dispatch(colorsList());
      }, []);

    return (
        <div>
           <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>Krijo Produktin</h1>
                                </div>
                            </div>
                        </div>
                    </section>
        
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">Krijo Produktin</h3>
                                        </div>

                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="title">Titulli</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="title"
                                                        placeholder="Titulli"
                                                        required = { true }
                                                        value={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="title">Nëntitulli</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="subtitle"
                                                        placeholder="Nëntitulli"
                                                        required
                                                        value={subtitle}
                                                        onChange={(e) => setSubTitle(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="category">Kategoria</label>
                                                    <select
                                                        className="form-select"
                                                        id="category"
                                                        required
                                                        value={category}
                                                        onChange={(e) => setCategory(e.target.value)}
                                                        >
                                                        <option selected>Selekto Kategorinë</option>
                                                        {categories && categories.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="category">Nën Kategoria</label>
                                                    <select
                                                        className="form-select"
                                                        id="category"
                                                        required
                                                        value={subCategory}
                                                        onChange={(e) => setSubCategory(e.target.value)}
                                                        >
                                                        <option selected>Selekto Nën Kategorinë</option>
                                                        {subCategories && subCategories?.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="category">Filterat</label>
                                                    <select
                                                        className="form-select"
                                                        id="category"
                                                        required
                                                        value={subSubCategory}
                                                        onChange={(e) => setSubSubCategory(e.target.value)}
                                                        >
                                                        <option selected>Selekto Filterat</option>
                                                        {subsubCategories && subsubCategories?.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                {/* <div className="form-group">
                                                    <label htmlFor="size">Madhësia</label>
                                                    <select
                                                        className="form-select"
                                                        id="size"
                                                        value={size}
                                                        onChange={(e) => setSize(e.target.value)}
                                                        >
                                                        <option selected>Selekto Madhësinë</option>
                                                        {sizes && sizes.map((s) => (
                                                            <option key={s?._id}>{s?.size}</option>
                                                        ))}
                                                    </select>
                                                </div> */}

                                                <h4>Shto Detajet</h4>

                                                {/* <div className="form-group">
                                                    <label htmlFor="color">Ngjyra</label>
                                                    <select
                                                        className="form-select"
                                                        id="color"
                                                        required
                                                        value={color}
                                                        onChange={(e) => setColor(e.target.value)}
                                                        >
                                                        <option selected>Selekto Ngjyrën</option>
                                                        {colors && colors.map((color) => (
                                                            <option key={color?._id}>{color?.colorName}</option>
                                                        ))}
                                                    </select>
                                                </div> */}

                                                <div className="form-group">
                                                    <label htmlFor="color-hex">Ngjyra</label> <br />
                                                    <InputColor
                                                        initialValue={hexDecimal}
                                                        value={colorHex?.hex}
                                                        onChange={setColorHex}
                                                        placement="right"
                                                    /> <br />
                                                    <label htmlFor="color-name">Emri</label>
                                                    {/* <input 
                                                        name="colorName" 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="color-name" 
                                                        placeholder="Emri Ngjyrës" 
                                                        value={color}
                                                        onChange={(e) => setColor(e.target.value)}  
                                                    /> */}
                                                    <select
                                                        className="form-select"
                                                        id="color"
                                                        required
                                                        value={color}
                                                        onChange={(e) => setColor(e.target.value)}
                                                        >
                                                        <option selected>Selekto Ngjyrën</option>
                                                        {colors && colors.map((color) => (
                                                            <option key={color?._id}>{color?.colorName}</option>
                                                        ))}
                                                    </select>
                                                    <div
                                                        style={{
                                                        width: 50,
                                                        height: 50,
                                                        marginTop: 20,
                                                        backgroundColor: colorHex?.rgba,
                                                        }}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <Formik
                                                        initialValues={{
                                                            sizeObj: [emptySizeObj]
                                                        }}
                                                    >
                                                        {({ values, handleChange, resetForm, setFieldValue }) => (
                                                            <>
                                                                <FieldArray name="sizeObj">
                                                                {({ push, remove }) => (
                                                                    <>
                                                                        { values?.sizeObj?.map((_, index) => (
                                                                            <>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="size" className="form-label"> Numri </label>
                                                                                    {/* <input
                                                                                        type="text"
                                                                                        name={`sizeObj[${index}].size`}
                                                                                        className="form-control"
                                                                                        id="size"
                                                                                        placeholder="Size"
                                                                                        onChange={handleChange}
                                                                                    /> */}
                                                                                    <select
                                                                                        className="form-select"
                                                                                        name={`sizeObj[${index}].size`}
                                                                                        id="size"
                                                                                        onChange={handleChange}
                                                                                    >
                                                                                        <option selected>Selekto Numrin</option>
                                                                                        {sizes && sizes.map((size) => (
                                                                                            <option key={size?._id}>{size?.size}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="countInStock" className="form-label"> Sasia </label>
                                                                                    <input
                                                                                        type="number"
                                                                                        pattern="[0-9]*"
                                                                                        name={`sizeObj[${index}].countInStock`}
                                                                                        className="form-control mt-4"
                                                                                        id="countInStock"
                                                                                        placeholder="Sasia"
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <button className="btn btn-danger mb-4 mt-4" onClick={() => remove(index)}>Delete</button> <br />
                                                                            </>
                                                                        )) }
                                                                        <button className="btn btn-primary mb-4" onClick={() => push(emptySizeObj)}>Shto numrin</button>
                                                                        <pre>{JSON.stringify(values.sizeObj, null, 4)}</pre>
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                            
                                                            <div className="form-group">
                                                                <label htmlFor="sku" className="form-label"> SKU </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="sku"
                                                                    placeholder="SKU"
                                                                    required
                                                                    value={sku}
                                                                    onChange={(e) => setSku(e.target.value)}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="images">5 Fotot</label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="images"
                                                                    name="images"
                                                                    multiple
                                                                    onChange={(e) => {
                                                                        const uploaded = [];
                                                                        for (const image of e.target.files) {
                                                                            let index = crypto.randomBytes(10).toString("hex");
                                                                            let finalImage = renameFile(image, `${index}@${image.name}`);
                                                                            uploaded.push(finalImage);
                                                                        }
                                                                        setImages([...images, ...uploaded]);
                                                                        setUploadedImages([...uploadedImages, ...uploaded]);
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <button
                                                                    className="btn btn-primary mb-4"
                                                                    onClick={() => {
                                                                    // setFieldValue("sizeObj", []);
                                                                    var imageIds = [];

                                                                    for (var image of uploadedImages) {
                                                                        const imageIndex = image.name;
                                                                        imageIds.push(imageIndex);
                                                                    }
                                                                    // setItem([...item, { images: imageIds, color, countInStock, sku }]);
                                                                    setItem([...item, { images: imageIds, color, colorHex: colorHex.hex, sizeObj: values.sizeObj, sku }]);
                                                                    resetForm([]);
                                                                    setUploadedImages([]);
                                                                    setColor('');
                                                                    setColorHex('');
                                                                    setCount('');
                                                                    setSku('');
                                                                    }}
                                                                >
                                                                    Add
                                                                </button>
                                                            </div>
                                                        </>
                                                        
                                                        )}
                                                    </Formik>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price" className="form-label"> Çmimi </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="price"
                                                        placeholder="Çmimi"
                                                        required
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price" className="form-label"> Çmimi me zbritje </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="discountprice"
                                                        placeholder="Çmimi me zbritje"
                                                        required
                                                        value={discountPrice}
                                                        onChange={(e) => setDiscountPrice(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="description" className="form-label"> Përshkrimi </label>
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        rows={3}
                                                        placeholder="Përshkrimi"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="additionalDescription" className="form-label"> Përshkrimi Shtesë </label>
                                                    <textarea
                                                        className="form-control"
                                                        id="additionalDescription"
                                                        placeholder="Përshkrimi Shtesë"
                                                        required
                                                        rows={3}
                                                        value={additionalInformation}
                                                        onChange={(e) => setAdditionalInformation(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="tags" className="form-label"> Tags </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="tags"
                                                        placeholder="Tags"
                                                        required
                                                        value={tags}
                                                        onChange={(e) => setTags(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group"> 
                                                    <div className="form-check">
                                                        <input 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            value = {newIn} 
                                                            onChange = {() => setNewIn(true)} id="flexCheckDefault" 
                                                        />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Shto në faqen New?
                                                        </label>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group"> 
                                                    <div className="form-check">
                                                        <input 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            value = {sale} 
                                                            onChange = {() => setSale(true)} id="flexCheckDefault" 
                                                        />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Shto në faqen Sale?
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="card-footer">
                                        
                                                    <button type="submit" className="btn btn-danger" onClick={handleSubmit}>
                                                        <span>Shto</span>
                                                    </button>
                                                
                                                </div>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}