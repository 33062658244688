import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const slidersList = () => async (dispatch) => {
    try {
        dispatch({type: 'SLIDER_LIST_REQUEST'});
        const sliders = await axios.get(`${apiUrl}/homeSliderBanner`);
        dispatch({ type: 'SLIDER_LIST_SUCCESS', payload: sliders?.data });
    } catch(error) {
        dispatch({ type: 'SLIDER_LIST_FAIL', payload: error });
    }
}

export const addSlider = (postData) => async (dispatch, getState) => {
    try {
        dispatch({type: 'SLIDER_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        for (const key of Object.keys(postData.images)) {
          formData.append('images', postData.images[key])
        }

        const sliderData = await axios.post(`${apiUrl}/homeSliderBanner`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        })
        dispatch({ type: 'SLIDER_CREATE_SUCCESS', payload: sliderData.data });
    } catch(error) {
        dispatch({ type: 'SLIDER_CREATE_FAIL', payload: error?.response?.data?.message });
    }
}

export const updateSlider = (id,postData) => async (dispatch, getState) => {
    try {
        dispatch({type: 'SLIDER_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        for (const key of Object.keys(postData.images)) {
          formData.append('images', postData.images[key])
        }

        const sliderData = await axios.patch(`${apiUrl}/homeSliderBanner/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SLIDER_UPDATE_SUCCESS', payload: sliderData.data });
    } catch(error) {
        dispatch({ type: 'SLIDER_UPDATE_FAIL', payload: error?.response?.data?.message});
    }
}

export const deleteSlider = (sliderId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'DELETE_SLIDER_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const {data} = await axios.delete(`${apiUrl}/homeSliderBanner/${sliderId}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'DELETE_SLIDER_SUCCESS', payload: data });

    } catch(error) {
        dispatch({ type: 'DELETE_SLIDER_FAIL', payload: error });
    }
}
