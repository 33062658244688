import axios from "axios";
import { apiUrl } from "../constants/backendUrl";

export const bannerAboutList = () => async (dispatch) => {
  try {
    dispatch({ type: "ABOUT_BANNER_LIST_REQUEST" });
    const {data} = await axios.get(`${apiUrl}/bannerAbout`)
    dispatch({ type: "ABOUT_BANNER_LIST_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "ABOUT_BANNER_LIST_FAIL", payload: error });
  }
};

export const createBannerAbout = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ABOUT_BANNER_CREATE_REQUEST" });
    const { userSignIn: { userInfo } } = getState();
    const formData = new FormData();
    formData.append("image", data?.image, data?.image?.name);
    formData.append('link', data?.link);
    formData.append('text', data?.text);
    const aboutBannerData = await axios.post(`${apiUrl}/bannerAbout`, formData, {
      headers:
        { Authorization: 'Bearer ' + userInfo?.token }
    });
    dispatch({
      type: "ABOUT_BANNER_CREATE_SUCCESS",
      payload: aboutBannerData?.data,
    });
  } catch (error) {
    dispatch({
      type: "ABOUT_BANNER_CREATE_FAIL",
      payload: error?.response?.data?.message,
    });
  }
};

export const updateBannerAbout = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ABOUT_BANNER_UPDATE_REQUEST" });
    const { userSignIn: { userInfo } } = getState();

    const formData = new FormData();
    data?.image instanceof Blob && formData.append('image', data?.image, data?.image.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
    formData.append('link', data?.link);
    formData.append('text', data?.text);

    const aboutBannerData = await axios.patch(
      `${apiUrl}/bannerAbout/${id}`,
      formData, {
        headers:
          { Authorization: 'Bearer ' + userInfo?.token }
    });
    dispatch({
      type: "ABOUT_BANNER_UPDATE_SUCCESS",
      payload: aboutBannerData?.data,
    });
  } catch (error) {
    dispatch({
      type: "ABOUT_BANNER_UPDATE_FAIL",
      payload: error?.response?.data?.message,
    });
  }
};

export const deleteBannerAbout = (bannerAboutId) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ABOUT_BANNER_DELETE_REQUEST" });
    const { userSignIn: { userInfo } } = getState();

    const { data } = await axios.delete(`${apiUrl}/bannerAbout/${bannerAboutId}`, {
      headers:
        { Authorization: 'Bearer ' + userInfo?.token }
    });
    dispatch({ type: "ABOUT_BANNER_DELETE_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "ABOUT_BANNER_DELETE_FAIL", payload: error?.response?.data?.message });
  }
};
