import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateUserInfo } from '../../actions/user_actions';
import { clear } from '../../actions/clear_action';
import  { useHistory } from 'react-router-dom';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBar from '../SearchBar/SearchBar';

import SliderBanner from '../../images/SLIDER.png';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axios from 'axios';
import util from '../../util';

import { apiUrl } from '../../constants/backendUrl';

import './style.css';

export default function EditUser(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { user, success, error } = useSelector(state => state.user_update);

    const [postData, setPostData] = useState({
        userId: userInfo?._id, name: '', surname: '', username: '', password: ''
    });
    const dispatch = useDispatch();
    const history = useHistory();

    function submitHanlder(e) {
        e.preventDefault();
        dispatch(updateUserInfo(
            {
                userId: postData?.userId, 
                name: postData?.name, 
                surname: postData?.surname,
                username: postData?.username, 
                password: postData?.password
            }
        ));
        if(success) {
            Toastr.success('Të dhënat u edituan me sukses.');
            dispatch(clear()); //per me bo clear staten.
            history.push('/');
        }
        console.log(postData);
    }

    useEffect(() => {
      
        return () => {
        }
    }, [success]);

    return (
        <section id="address_page">
            <TopHeader />
            <Slider image={SliderBanner} />
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <SearchBar />

                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <div className="eltdf-container">
                                    <div className="eltdf-container-inner clearfix eltdf-row-grid-section">
                                        <div className="eltdf-grid-row">
                                            <div className="eltdf-page-content-holder eltdf-grid-col-12">
                                                <div className="checkout-padding-bottom">
                                                    <div className="row justify-content-lg-between">
                                                        <div className="col-md-7">
                                                            <div className="checkout_wrapper">
                                                                <form className="checkout-form" onSubmit={submitHanlder}>
                                                                    <div>
                                                                    {
                                                                       error
                                                                       &&
                                                                        <div className="errors">
                                                                            <ul>
                                                                                <li className="text-danger">{error}</li>
                                                                            </ul>
                                                                        </div>
                                                                    }
                                                                        <h3>Edit Your data</h3>
                                                                        <div>
                                                                            <input 
                                                                                required 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                placeholder="First name*" 
                                                                                autoComplete="off" 
                                                                                value={postData?.name}
                                                                                onChange={(e) => setPostData({ ...postData, name: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                placeholder="Last name*" 
                                                                                autoComplete="off" 
                                                                                value={postData?.surname}
                                                                                onChange={(e) => setPostData({ ...postData, surname: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                placeholder="Username*" 
                                                                                autoComplete="off" 
                                                                                value={postData?.username}
                                                                                onChange={(e) => setPostData({ ...postData, username: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required 
                                                                                type="password" 
                                                                                className="form-control" 
                                                                                placeholder="New Password*" 
                                                                                autoComplete="off" 
                                                                                value={postData?.password}
                                                                                onChange={(e) => setPostData({ ...postData, password: e.target.value })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="woocommerce_checkout_place_order">Edit</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}