import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './plugins/fontawesome-free/css/all.min.css';
import './plugins/sweetalert2/sweetalert2.min.css';
import './plugins/toastr/toastr.min.css';
import './dist/css/adminlte.min.css';

import './plugins/jquery/jquery.min.js';
import './plugins/bootstrap/js/bootstrap.bundle.min.js';
import './plugins/bootstrap/js/bootstrap.min.js';
import './dist/js/adminlte.min.js';
// import './dist/js/link_highlight.js';

import logo from '../../images/npika_logo.png';

const AdminSideBar = () => {
    const productmenuCollapsed = localStorage.getItem('product-menu-collapsed');
    const categorymenuCollapsed = localStorage.getItem('category-menu-collapsed');
    const subCategorymenuCollapsed = localStorage.getItem('sub-category-menu-collapsed');
    const subSubCategorymenuCollapsed = localStorage.getItem('sub-sub-category-menu-collapsed');
    const colormenuCollapsed = localStorage.getItem('color-menu-collapsed');
    const sizemenuCollapsed = localStorage.getItem('size-menu-collapsed');
    const homemenuCollapsed = localStorage.getItem('home-menu-collapsed');
    const blogmenuCollapsed = localStorage.getItem('blog-menu-collapsed');
    const newmenuCollapsed = localStorage.getItem('new-menu-collapsed');
    const shopCollapsed = localStorage.getItem('shop-menu-collapsed');
    const bannersCollapsed = localStorage.getItem('banners-menu-collapsed');
    const ordersCollapsed = localStorage.getItem('orders-menu-collapsed');
    const usersCollapsed = localStorage.getItem('users-menu-collapsed');
    const contactsCollapsed = localStorage.getItem('contacts-menu-collapsed');
    const aboutCollapsed = localStorage.getItem('about-menu-collapsed');
    const generalCollapsed = localStorage.getItem('general-menu-collapsed');
    const productManagmentCollapsed = localStorage.getItem('product-managment-menu-collapsed');
    const countryCollapsed = localStorage.getItem('countries-menu-collapsed');
    const privacyPolicyCollapsed = localStorage.getItem('privacy-policy-menu-collapsed');
    const privacyTermsOfServiceCollapsed = localStorage.getItem('terms-of-service-menu-collapsed');

    const [isActiveProduct, setIsActiveProduct] = useState(productmenuCollapsed ? true : false);;
    const [isActiveCategory, setIsActiveCategory] = useState(categorymenuCollapsed ? true : false);
    const [isActiveSubCategory, setIsActiveSubCategory] = useState(subCategorymenuCollapsed ? true : false);
    const [isActiveColor, setColor] = useState(colormenuCollapsed ? true : false);
    const [isActiveSize, setSizeActive] = useState(sizemenuCollapsed ? true : false);
    const [isActiveHome, setIsHomeActive] = useState(homemenuCollapsed ? true : false);
    const [isActiveBlog, setBlogActive] = useState(blogmenuCollapsed ? true : false);
    const [isActiveNew, setNewActive] = useState(newmenuCollapsed ? true : false);
    const [isActiveShop, setShop] = useState(shopCollapsed ? true : false);
    const [isActiveBanners, setBanners] = useState(bannersCollapsed ? true : false);
    const [isActiveSubSubCategory, setSubSubCategory] = useState(subSubCategorymenuCollapsed ? true : false);
    const [isActiveOrder, setOrder] = useState(ordersCollapsed ? true : false);
    const [isActiveUser, setUser] = useState(usersCollapsed ? true : false);
    const [isActiveContact, setContact] = useState(contactsCollapsed ? true : false);
    const [isActiveAbout, setAbout] = useState(aboutCollapsed ? true : false);
    const [isActiveGeneral, setGeneral] = useState(generalCollapsed ? true : false);
    const [isActiveProductManagment, setProductManagment] = useState(productManagmentCollapsed ? true : false);
    const [isActiveCountry, setCountry] = useState(countryCollapsed ? true : false);
    const [isActivePrivacyPolicy, setPrivacyPolicy] = useState(privacyPolicyCollapsed ? true : false);
    const [isActiveTermsOfService, setTermsOfService] = useState(privacyTermsOfServiceCollapsed ? true : false);

    useEffect(() => {
        
    },[])
 
    const openMenu = (name) => {
        localStorage.setItem(name, true); 
    }

    return (   
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                    <img src={logo} className="img-circle elevation-2" alt="User Image" />
                </div>
                <div className="info">
                    <Link to="/admin-paneli" className="d-block">Pika Shop</Link>
                </div>
            </div>

            <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" id="side_menu">
                    <li className={isActiveProduct ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                        <a href="#" className="nav-link" onClick={() => localStorage.removeItem('product-menu-collapsed')}>
                            <i className="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Menaxhimi i produkteve
                                <i className="right fas fa-angle-left"></i>
                            </p> 
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('product-menu-collapsed')}>
                                <Link to="/admin/add-product" className="nav-link">
                                    <i className="nav-icon fas fa-th" />
                                    <p>
                                        Shto një produkt
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('product-menu-collapsed')}>
                                <Link to="/admin/products-list" className="nav-link">
                                    <i className="nav-icon fas fa-th" />
                                    <p>
                                        Menaxho Produktet
                                    </p>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={isActiveProductManagment ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                        <a href="#" className="nav-link" onClick={() => localStorage.removeItem('product-managment-menu-collapsed')}>
                            <i className="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Menaxhimi i Filterave
                                <i className="right fas fa-angle-left"></i>
                            </p> 
                        </a>
                        <ul className="nav nav-treeview">
                            <li className={isActiveSubCategory ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('sub-category-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Krijo Nën Kategorinë
                                    <i className="right fas fa-angle-left"></i>
                                </p> 
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('sub-category-menu-collapsed')}>
                                    <Link to="/admin/add-sub-category" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Nën Kategorinë
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('sub-category-menu-collapsed')}>
                                    <Link to="/admin/sub-categories-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Nën Kategoritë
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={isActiveCategory ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('category-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Krijo Kategorinë
                                    <i className="right fas fa-angle-left"></i>
                                </p> 
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('category-menu-collapsed')}>
                                    <Link to="/admin/add-category" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Kategorinë
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('category-menu-collapsed')}>
                                    <Link to="/admin/categories-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Kategoritë
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={isActiveSubSubCategory ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('sub-sub-category-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Krijo Filterat
                                    <i className="right fas fa-angle-left"></i>
                                </p> 
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('sub-sub-category-menu-collapsed')}>
                                    <Link to="/admin/add-sub-sub-category" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Filterat
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('sub-sub-category-menu-collapsed')}>
                                    <Link to="/admin/sub-sub-categories-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Filterat
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={isActiveColor ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('color-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Krijo Ngjyrën
                                    <i className="right fas fa-angle-left"></i>
                                </p> 
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('color-menu-collapsed')}>
                                    <Link to="/admin/add-color" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Ngjyrën
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('color-menu-collapsed')}>
                                    <Link to="/admin/colors-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                        Ngjyrat
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={isActiveSize ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('size-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Krijo Madhësitë
                                    <i className="right fas fa-angle-left"></i>
                                </p> 
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('size-menu-collapsed')}>
                                    <Link to="/admin/add-size" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Madhësitë
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('size-menu-collapsed')}>
                                    <Link to="/admin/sizes-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                        Sizet
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        </ul>
                    </li>

                    <li className={isActiveOrder ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                        <a href="#" className="nav-link" onClick={() => localStorage.removeItem('orders-menu-collapsed')}>
                            <i className="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Porositë
                                <i className="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item" onClick={() => openMenu('orders-menu-collapsed')}>
                                <Link to="/admin-all-orders" className="nav-link">
                                    <i className="nav-icon fas fa-th" />
                                    <p>
                                        Porositë
                                    </p>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={isActiveContact ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                        <a href="#" className="nav-link" onClick={() => localStorage.removeItem('contacts-menu-collapsed')}>
                            <i className="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Kontaktet
                                <i className="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item" onClick={() => openMenu('contacts-menu-collapsed')}>
                                <Link to="/admin/contacts" className="nav-link">
                                    <i className="nav-icon fas fa-th" />
                                    <p>
                                        Kontaktet
                                    </p>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={isActiveUser ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                        <a href="#" className="nav-link" onClick={() => localStorage.removeItem('users-menu-collapsed')}>
                            <i className="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Përdoruesit
                                <i className="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item" onClick={() => openMenu('users-menu-collapsed')}>
                                <Link to="/admin-all-users" className="nav-link">
                                    <i className="nav-icon fas fa-th" />
                                    <p>
                                        Përdoruesit
                                    </p>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className={isActiveGeneral ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                        <a href="#" className="nav-link" onClick={() => localStorage.removeItem('general-menu-collapsed')}>
                            <i className="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Faqet e Përgjithshme
                                <i className="right fas fa-angle-left"></i>
                            </p> 
                        </a>
                        <ul className="nav nav-treeview">
                            <li className={isActiveHome ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                                <a href="#" className="nav-link" onClick={() => localStorage.removeItem('home-menu-collapsed')}>
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        Faqet Home
                                        <i className="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/add-home-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Banner Home
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/home-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Banner Home List
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/add-home-card" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo Card Home
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/card-home-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Card Home
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/add-home-slider" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo Home Slider
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/home-slider-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Home Slider
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/add-categories-home-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Kategoritë Home
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/categories-home-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Lista Kategoritë Home
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/add-transport-home-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Home Transport
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('home-menu-collapsed')}>
                                    <Link to="/admin/transport-home-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Lista Home Tarnsport
                                        </p>
                                    </Link>
                                </li>
                                <li className={isActivePrivacyPolicy ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                                    <a href="#" className="nav-link" onClick={() => localStorage.removeItem('privacy-policy-menu-collapsed')}>
                                        <i className="nav-icon fas fa-tachometer-alt"></i>
                                        <p>
                                            Politika e Privatsisë
                                            <i className="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('privacy-policy-menu-collapsed')}>
                                            <Link to="/admin/create-privacy-policy" className="nav-link">
                                                <i className="nav-icon fas fa-th" />
                                                <p>
                                                    Krijo Politikat e Privatsisë
                                                </p>
                                            </Link>
                                        </li>

                                        <li className="nav-item" onClick={() => openMenu('privacy-policy-menu-collapsed')}>
                                            <Link to="/admin/privacy-policy-list" className="nav-link">
                                                <i className="nav-icon fas fa-th" />
                                                <p>
                                                    Politika e Privatsisë
                                                </p>
                                            </Link>
                                        </li>

                                        
                                    </ul>
                                </li>

                                <li className={isActivePrivacyPolicy ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                                    <a href="#" className="nav-link" onClick={() => localStorage.removeItem('terms-of-service-menu-collapsed')}>
                                        <i className="nav-icon fas fa-tachometer-alt"></i>
                                        <p>
                                            Kushtet e Shërbimit
                                            <i className="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item" name="about-menu-collapsed" onClick={() => openMenu('terms-of-service-menu-collapsed')}>
                                            <Link to="/admin/create-terms-service" className="nav-link">
                                                <i className="nav-icon fas fa-th" />
                                                <p>
                                                    Krijo Kushtet e Shërbimit
                                                </p>
                                            </Link>
                                        </li>

                                        <li className="nav-item" onClick={() => openMenu('terms-of-service-menu-collapsed')}>
                                            <Link to="/admin/terms-service-list" className="nav-link">
                                                <i className="nav-icon fas fa-th" />
                                                <p>
                                                    Kushtet e Shërbimit
                                                </p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className={isActiveCountry ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                        <a href="#" className="nav-link" onClick={() => localStorage.removeItem('countries-menu-collapsed')}>
                            <i className="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Shtetet
                                <i className="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item" onClick={() => openMenu('countries-menu-collapsed')}>
                                <Link to="/admin/add-country" className="nav-link">
                                    <i className="nav-icon fas fa-th" />
                                    <p>
                                        Krijo Shtetin
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item" onClick={() => openMenu('countries-menu-collapsed')}>
                                <Link to="/admin/country-list" className="nav-link">
                                    <i className="nav-icon fas fa-th" />
                                    <p>
                                        Shtetet
                                    </p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                            </ul>
                        </li>

                        <li className={isActiveBlog ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('blog-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Faqet Blog
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/add-blog-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Bannerin
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/blog-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Lista Bannerave
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/add-blog-categories" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Blog Category
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/blog-categories-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Blog Category
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/add-blog-card" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Blog Card
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/blog-card-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Blog Card
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('project-menu-collapsed')}>
                                    <Link to="/admin/add-blog-second-card" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Blog Second Card
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/blog-second-card-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Blog Second Card
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/add-blog-slider" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Blog Slider
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('blog-menu-collapsed')}>
                                    <Link to="/admin/blog-sliders-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Blog Slider
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={isActiveNew ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('new-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Faqet Sale
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" onClick={() => openMenu('new-menu-collapsed')}>
                                    <Link to="/admin/add-new-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Bannerin
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('new-menu-collapsed')}>
                                    <Link to="/admin/new-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Banneri Page
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('new-menu-collapsed')}>
                                    <Link to="/admin/add-new-collection-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijo Collection Banner
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('new-menu-collapsed')}>
                                    <Link to="/admin/new-collection-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Collection Banner
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={isActiveShop ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('shop-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Faqet New
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" onClick={() => openMenu('shop-menu-collapsed')}>
                                    <Link to="/admin/add-shop-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo Banner
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('shop-menu-collapsed')}>
                                    <Link to="/admin/shop-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Banneri Page
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={isActiveAbout ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('about-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    About
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" onClick={() => openMenu('about-menu-collapsed')}>
                                    <Link to="/admin/add-about-section" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo About
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('about-menu-collapsed')}>
                                    <Link to="/admin/about-section-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            About Page
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={isActiveBanners ? 'nav-item has-treeview menu-open' : 'nav-item has-treeview'}>
                            <a href="#" className="nav-link" onClick={() => localStorage.removeItem('banners-menu-collapsed')}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Krijo Bannerat
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/add-login-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo Login Banner
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/login-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Login Banner
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/add-cart-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo Cart Banner
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/cart-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Cart Banner
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/add-wishlist-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo Wishlist Banner
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/wishlist-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Wishlist Banner
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/add-contact-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo Contact Banner
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/about-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            About Banner
                                        </p>
                                    </Link>
                                </li>

                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/add-about-banner" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Krijoo About Banner
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => openMenu('banners-menu-collapsed')}>
                                    <Link to="/admin/about-banner-list" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            About Banner
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        </ul>
                    </li>
                </ul>
            </nav>
            </div>
        </aside>
    )
}

export default AdminSideBar;