function listSliderReducer(state = { sliders: [] }, action) {
    switch (action.type) {
        case 'SLIDER_LIST_REQUEST':
            return { loading: true }
        case 'SLIDER_LIST_SUCCESS':
            return { loading: false, sliders: action?.payload }
        case 'SLIDER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addSliderReducer(state = {}, action) {
    switch(action.type) {
        case 'SLIDER_CREATE_REQUEST':
            return {loading: true}
        case 'SLIDER_CREATE_SUCCESS':
            return {loading: false, slider: action?.payload, success: true}
        case 'SLIDER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateSliderReducer(state = { slider: {} }, action) {
    switch(action.type) {
        case 'SLIDER_UPDATE_REQUEST':
            return { loading: true }
        case 'SLIDER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'SLIDER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteSliderReducer(state = { slider: {} }, action) {
    switch(action.type) {
        case 'DELETE_SLIDER_REQUEST':
            return { loading: true }
        case 'DELETE_SLIDER_SUCCESS':
            return { loading: false, success: true }
        case 'DELETE_SLIDER_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}


export { addSliderReducer, listSliderReducer, updateSliderReducer, deleteSliderReducer }