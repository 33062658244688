import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { bannerAboutList } from '../../actions/bannerAbout_actions';
import { sectionAboutList } from '../../actions/aboutSection_actions';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBar from '../SearchBar/SearchBar';
import Loader from '../Loader/Loader';

import './style.css';

const About = () => {
    const { aboutBanners, loading: loadingBannerAbout } = useSelector(state => state.banner_about_list);
    const { sectionsAbout, loading: loadingSectionAbout } = useSelector(state => state.section_about_list);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(bannerAboutList());
        dispatch(sectionAboutList());
    },[]);
    return (
        loadingBannerAbout
        ||
        loadingSectionAbout
        ?
        <Loader />
        :
        <section>
            <TopHeader />
            {
                aboutBanners?.slice(-1)?.map(aboutBanner =>  <Slider image = { aboutBanner?.image } link={aboutBanner?.link}  key={aboutBanner?._id}/>)
            }
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner about_row_fluid_about1202929920">
                        <SearchBar />
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <div className="eltdf-full-width">
                                    <div className="eltdf-full-width-inner">
                                        <div className="eltdf-grid-row">
                                            <div className="eltdf-row-grid-section-wrapper eltdf-content-aligment-left">
                                                <div className="eltdf-row-grid-section">
                                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_15343201 about_row_fluid_about1202929920">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="eltdf-elements-holder   eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                        <div className="eltdf-eh-item     eltdf-eh-custom-4395" data-item-class="eltdf-eh-custom-4395" data-481-680="0 14% 0 0" data-480="0 14% 0 0">
                                                                            <div className="eltdf-eh-item-inner">
                                                                                <div style={{padding: '0 5% 0 0'}}>
                                                                                    <div className="eltdf-section-title-holder  eltdf-st-standard eltdf-st-title-left eltdf-st-normal-space " style={{textAlign: 'left'}}>
                                                                                        <div className="eltdf-st-inner">
                                                                                            <p className="eltdf-st-subtitle">
                                                                                                Collection 
                                                                                            </p>
                                                                                            <h2 className="eltdf-st-title">
                                                                                            Shopping Everyday </h2>
                                                                                            {
                                                                                                aboutBanners?.slice(-1)?.map(aboutBanner => <p className="eltdf-st-text" style={{color: '#3d3d3d', fontSize: '17px', fontWeight: 400, marginTop: '23px'}} key={aboutBanner?._id}>
                                                                                                {aboutBanner?.text}
                                                                                            </p>)
                                                                                            }
                                                                                        </div>
                                                                                    </div> 
                                                                                </div>
                                                                            </div>
                                                                            <div className="eltdf-elements-holder-background-outer">
                                                                                <div className="eltdf-elements-holder-background-inner" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12 vc_col-xs-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="eltdf-elements-holder   eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                        <div className="eltdf-eh-item  eltdf-eh-custom-2768" data-item-class="eltdf-eh-custom-2768" data-769-1024="30px 0 0 0px" data-681-768="30px 0 0 0px" data-481-680="30px 0 0 0px" data-480="30px 0 0 0px">
                                                                            <div className="eltdf-eh-item-inner">
                                                                                <div className="eltdf-eh-item-content-about_progress" style={{padding: '11px 0 0 20px'}}>
                                                                                    <div className="eltdf-progress-bar  ">
                                                                                        <h5 className="eltdf-pb-title-holder">
                                                                                            <span className="eltdf-pb-title">Clothing</span>
                                                                                            <span className="eltdf-pb-percent" style={{opacity: 1}}>55</span>
                                                                                        </h5>
                                                                                        <div className="eltdf-pb-content-holder">
                                                                                            <div data-percentage={55} className="eltdf-pb-content" style={{width: '55%'}} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="vc_empty_space" style={{height: '15px'}}>
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                    <div className="eltdf-progress-bar  ">
                                                                                        <h5 className="eltdf-pb-title-holder">
                                                                                            <span className="eltdf-pb-title">Black Dress</span>
                                                                                            <span className="eltdf-pb-percent" style={{opacity: 1}}>40</span>
                                                                                        </h5>
                                                                                        <div className="eltdf-pb-content-holder">
                                                                                            <div data-percentage={40} className="eltdf-pb-content" style={{width: '40%'}} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="vc_empty_space" style={{height: '15px'}}>
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                    <div className="eltdf-progress-bar  ">
                                                                                        <h5 className="eltdf-pb-title-holder">
                                                                                            <span className="eltdf-pb-title">Accessories</span>
                                                                                            <span className="eltdf-pb-percent" style={{opacity: 1}}>30</span>
                                                                                        </h5>
                                                                                        <div className="eltdf-pb-content-holder">
                                                                                            <div data-percentage={30} className="eltdf-pb-content" style={{width: '30%'}} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="vc_empty_space" style={{height: '15px'}}>
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                    <div className="eltdf-progress-bar  ">
                                                                                        <h5 className="eltdf-pb-title-holder">
                                                                                            <span className="eltdf-pb-title">Shoes</span>
                                                                                            <span className="eltdf-pb-percent" style={{opacity: 1}}>80</span>
                                                                                        </h5>
                                                                                        <div className="eltdf-pb-content-holder">
                                                                                            <div data-percentage={80} className="eltdf-pb-content" style={{width: '80%'}} />
                                                                                        </div>
                                                                                    </div> 
                                                                                </div>
                                                                            </div>
                                                                            <div className="eltdf-elements-holder-background-outer">
                                                                                <div className="eltdf-elements-holder-background-inner" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="eltdf-row-grid-section-wrapper ">
                                                <div className="eltdf-row-grid-section">
                                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1536319386963">
                                                        {
                                                            sectionsAbout?.slice(-3)?.map(about => <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12 vc_col-xs-12 about_page_grid_images" key={about?._id}>
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="eltdf-single-image-holder   ">
                                                                            <div className="eltdf-si-inner">
                                                                                <img src={about?.image} className="attachment-full size-full" alt="f" />                                                                         
                                                                            </div>
                                                                        </div>
                                                                        <div className="vc_empty_space-about" style={{height: '20px'}}>
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                        <div className="eltdf-elements-holder   eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                            <div className="eltdf-eh-item     eltdf-eh-custom-6930" data-item-class="eltdf-eh-custom-6930" data-769-1024="0 20% 0 0">
                                                                                <div className="eltdf-eh-item-inner">
                                                                                    <div style={{padding: '0 5% 0 0'}}>
                                                                                        <div className="eltdf-section-title-holder  eltdf-st-standard eltdf-st-title-left eltdf-st-normal-space ">
                                                                                            <div className="eltdf-st-inner">
                                                                                                <h3 className="eltdf-st-title">
                                                                                                    {about?.title} 
                                                                                                </h3>
                                                                                                <p className="eltdf-st-text" style={{color: '#3d3d3d', lineHeight: '25px', marginTop: '15px'}}>
                                                                                                    {about?.text}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div> 
                                                                                    </div>
                                                                                </div>
                                                                                <div className="eltdf-elements-holder-background-outer">
                                                                                    <div className="eltdf-elements-holder-background-inner" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="vc_empty_space-about" style={{height: '30px'}}>
                                                                            <span className="vc_empty_space_inner" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default About;