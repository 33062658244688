import React, { useEffect, useState } from 'react';
import { categoriesList } from '../../actions/category_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import menu from '../../images/Menu-mobile.svg';

export default function Sidebar(props) {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const { categories } = useSelector(state => state.categories_list);
  const [subCategories, setSubCategories] = useState([]);
  var elements = document.querySelectorAll(".show");
  const menuWrapper = document.querySelector(".menu-wrapper");

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(categoriesList());
  },[]);

  function removeShowClass() {
    
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.toggle('show');
    }
  }
  
  function openMenu() {
    setOpen(true)
  }

  function closeMenu() {
    setOpen(false);
    removeShowClass();
  }

  window.onclick = function(event) {
    if(event.target === menuWrapper) {
      setOpen(false);
      removeShowClass();
    }
  }

  //per me bo toggle subMenun -> me hap vetem ate qe e kemi kliku
  const toggleActive = (id) => setOpenSubMenu(openSubMenu === id ? undefined : id);

  // function toggleActive(categoryName) {
  //     let element = document.getElementById(categoryName);
  //     console.log(element);
  //     element?.classList?.toggle('show');
  //   // axios.get(`${apiUrl}/subCategories/sub-categories/${categoryId}`).then(res => {
  //   //   if(res.data.subCategories.length > 0) {
  //   //     let element = document.getElementById(categoryName);
  //   //     element?.classList?.toggle('show');

  //       // elements.forEach(function (otherCollapsible) {
  //       //   if (otherCollapsible !== element) {
  //       //     otherCollapsible.classList.remove("show");
  //       //   }
  //       // });
        
  //   //     setSubCategories(res.data.subCategories);
  //   //   } else {
  //   //     history.push({
  //   //       pathname: `/new`,
  //   //         state: {
  //   //           category: categoryName
  //   //         }
  //   //     })
  //   //   }
  //   // });
  // }

  return (
    <header className="header">
      <nav className="navbar">
        <span className="open-menu" onClick={openMenu}>
          <img src={menu} alt="menu" />
        </span>
        <div className={`menu-wrapper ${open ? "offcanvas" : ""}`}>
          <ul className="menu">
            {/* <li className="menu-block">
              <span className="close-menu" onClick={closeMenu}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path fill="#252a32" fillRule="evenodd" d="M17.778.808l1.414 1.414L11.414 10l7.778 7.778-1.414 1.414L10 11.414l-7.778 7.778-1.414-1.414L8.586 10 .808 2.222 2.222.808 10 8.586 17.778.808z" />
                </svg>
              </span>
            </li> */}

            <li className="menu-block" onClick={closeMenu}>
              <div className="close-container">
                <div className="leftright"></div>
                <div className="rightleft"></div>
              </div>
            </li>

              <div className="items-menu">
                <div className="menu-list-item">
                  <Link 
                    to="/new"
                    className="selected-item" 
                  >New in</Link>
                </div>
                  {
                      categories?.map((category, index) => <div key={category?._id}>
                          <div className="menu-list-item main-category">
                              <Link 
                                to={category?.subCategories?.length > 0 ? location?.pathname : { pathname:  '/new', state:{ id: category?.id, category: category?.name }} }
                                className="selected-item" 
                                onClick={() => toggleActive(index)}
                              >{category?.name}</Link>
                          </div>

                          <div className={`category-content ${openSubMenu === index ? 'show' : ''}`} id={category?.name}>
                              <div className="items-menu-columns">
                                  <div className="column left col-xs-8">
                                      {
                                        category?.subCategories?.map(sub => <div className="menu-list-item not-selected-class category-1466112" key={sub?._id}>
                                            <Link                                 
                                              to={{
                                                pathname: `/new/${sub?.name}`,
                                                state: {
                                                  id: sub?._id,
                                                  subCategory: sub?.name
                                                }
                                              }} 
                                              style={{ marginLeft: '17px' }}>{sub?.name}</Link>
                                        </div>)
                                      }
                                      {/* {
                                          subCategories?.map(subCategory => <div className="menu-list-item not-selected-class category-1466112" key={subCategory?._id}>
                                              <Link                                 
                                                to={{
                                                  pathname: `/new`,
                                                  state: {
                                                    id: subCategory?._id,
                                                    subCategory: subCategory?.name
                                                  }
                                                }} 
                                                style={{ marginLeft: '17px' }}>{subCategory?.name}</Link>
                                          </div>
                                      )} */}
                                  </div>
                              </div>
                          </div>
                      </div>
                  )}
                  <div className="menu-list-item">
                    <Link 
                      to="/sale"
                      className="selected-item" 
                    >Sale</Link>
                </div>
              </div>
          </ul>
          
        </div>
      </nav>
    </header>
  );
};