import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Hoc/Layout';

/*  Components   */   
import Home from './components/Home/Home';
import New from './components/New/New';
import Shop from './components/Shop/Shop';
import Blog from './components/Blog/Blog';
import Cart from './components/Cart/Cart';
import Product from './components/Product/Product';
import RelateProduct from './components/RelatedProduct/RelateProduct';
import Wishlist from './components/Wishlist/Wishlist';
import Address from './components/Order/Address';
import SearchedProducts from './components/SearchedProducts/SearchedProducts';

import AdminPanel from './components/AdminPanel/AdminPanel';
import AddCategory from './components/AdminPanel/Add/AddCategory';  
import CategoriesList from './components/AdminPanel/List/CategoriesList';

import AddSubCategory from './components/AdminPanel/Add/AddSubCategory';
import SubCategoriesList from './components/AdminPanel/List/SubCategoriesList';

import AddProduct from './components/AdminPanel/Add/AddProduct'; 
import UpdateProduct from './components/AdminPanel/Update/UpdateProduct';
import UpdateProductDetails from './components/AdminPanel/Update/UpdateProductDetails';
import ProductsList from './components/AdminPanel/List/ProductsList';
import ProductsDetailsList from './components/AdminPanel/List/AvailableOptionsProducts';
import SizeProductDetails from './components/AdminPanel/List/SizeProductsDetails';
import UpdateSizeProduct from './components/AdminPanel/Update/UpdateSizeProduct';

import AddSize from './components/AdminPanel/Add/AddSize'; 
import SizesList from './components/AdminPanel/List/SizesList'; 

import AddColor from './components/AdminPanel/Add/AddColor';
import ColorsList from './components/AdminPanel/List/ColorsList';

import AddNewCollectionBanner from './components/AdminPanel/Add/AddNewCollectionBanner';
import NewCollectionBannerList from './components/AdminPanel/List/NewCollectionBannerList';

import AddCategoriesBannerHome from './components/AdminPanel/Add/AddCategoriesBannerHome';
import CategoriesHomeList from './components/AdminPanel/List/CategoriesHomeList';

import AddTransportBannerHome from './components/AdminPanel/Add/AddTransportBannerHome';
import TransportHomeList from './components/AdminPanel/List/TransportHomeList';

import AddHomeCard from './components/AdminPanel/Add/AddCardHome';
import CardHomeList from './components/AdminPanel/List/CardHomeList';
import AddHomeSlider from './components/AdminPanel/Add/AddHomeSlider';
import SliderHomeList from './components/AdminPanel/List/SliderHomeList';

import AddBlogCategories from './components/AdminPanel/Add/AddBlogCategory';
import CategoriesBlogList from './components/AdminPanel/List/CategoriesBlogList';

import AddBlogCard from './components/AdminPanel/Add/AddCardBlog';
import CardBlogList from './components/AdminPanel/List/CardBlogList';

import AddBlogSlider from './components/AdminPanel/Add/AddBlogSlider';
import SliderBlogList from './components/AdminPanel/List/SliderBlogList';

import AddSecondBlogCard from './components/AdminPanel/Add/AddBlogSecondCard';
import CardSecondBlogList from './components/AdminPanel/List/CardSecondBlogList';

import AddBannerHome from './components/AdminPanel/Add/AddBannerHome';
import BannerHomeList from './components/AdminPanel/List/BannerHomeList';

import AddBannerNew from './components/AdminPanel/Add/AddNewBanner';
import BannerNewList from './components/AdminPanel/List/BannerNewList';

import AddBannerShop from './components/AdminPanel/Add/AddShopBanner';
import BannerShopList from './components/AdminPanel/List/BannerShopList';

import AddBannerBlog from './components/AdminPanel/Add/AddBannerBlog';
import BannerBlogList from './components/AdminPanel/List/BannerBlogList';

import AddBannerLogin from './components/AdminPanel/Add/AddBannerLogin';
import BannerLoginList from './components/AdminPanel/List/BannerLoginList';

import AddBannerWishlist from './components/AdminPanel/Add/AddBannerWishlist';
import BannerWishList from './components/AdminPanel/List/BannerWishList';

import AddBannerCart from './components/AdminPanel/Add/AddCartBanner';
import BannerCartList from './components/AdminPanel/List/bannersCartList';

import AddBannerContact from './components/AdminPanel/Add/AddBannerContact';
import BannerContactList from './components/AdminPanel/List/BannerContactList';

import AddSubSubCategory from './components/AdminPanel/Add/AddSubSubCategory';
import subSubCategoriesList from './components/AdminPanel/List/subSubCategoriesList';

import AddBannerAbout from './components/AdminPanel/Add/AddBannerAbout';
import BannerAboutList from './components/AdminPanel/List/BannerAboutList';

import AddSectionAbout from './components/AdminPanel/Add/AddSectionAbout';
import AboutSectionList from './components/AdminPanel/List/AboutSectionList';

import AddCountry from './components/AdminPanel/Add/AddCountry';
import CountriesList from './components/AdminPanel/List/CountryList';

import AddPrivacyPolicy from './components/AdminPanel/Add/addPrivacyPolicy';
import PrivacyPolicyList from './components/AdminPanel/List/privacyPolicy';

import AddTermsOfService from './components/AdminPanel/Add/addTermsOfService';
import TermsOfServiceList from './components/AdminPanel/List/termsOfService';

import UserOrders from './components/AdminPanel/UserOrders/UserOrders';
import OrdersList from './components/AdminPanel/List/OrdersList';
import ViewOrder from './components/AdminPanel/List/ViewOrder';

import UsersList from './components/AdminPanel/List/UsersList';

import ScrollToTop from './scroll';
import NotFound from './components/NotFound/Notfound';  
import FinishedOrder from './components/FinishedOrderMessage/FinishedOrder';   
import Contact from './components/Contact/Contact';         
import ContactsList from './components/AdminPanel/List/ContactsList';
import Login from './components/Login/Login';
import Register from './components/Login/Register';
import About from './components/About';
import PrivacyPolicy from './components/PolitikaPrivatsisë/PolitikaPrivatsise';
import TermsOfService from './components/TermsOfService/TermsOfService';
import EditUser from './components/EditUserData/EditUser';

export default function Routes() {
    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/new" component={Shop} />
                <Route exact path="/new/:subCategory" component={Shop} />
                {/* <Route exact path="/new" render={() => (<Shop id={id} />)} /> per mos me ja qu id permes url, por si props */}
                <Route exact path="/sale" component={New} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/cart" component={Cart} />
                <Route exact path="/wishlist" component={Wishlist} />
                <Route exact path="/product/:id" component={Product} />
                {/* <Route exact path="/product/:category/:title" component={Product} /> */}
                <Route exact path="/product/related/:category/:title" component={RelateProduct} />

                <Route exact path="/checkout" component={Address} />
                <Route exact path="/shop/:search" component={SearchedProducts} />
                
                <Route path="/admin-paneli" exact component={AdminPanel} />
                <Route exact path="/admin-all-orders" component={OrdersList} />
                <Route exact path="/admin/order/:id" component={ViewOrder} />

                <Route exact path="/admin-all-users" component={UsersList} />

                <Route exact path="/admin/add-category" component={AddCategory} />
                <Route exact path="/admin/add-category/:id" component={AddCategory} />
                <Route exact path="/admin/categories-list" component={CategoriesList} />

                <Route exact path="/admin/add-sub-category" component={AddSubCategory} />
                <Route exact path="/admin/add-sub-category/:id" component={AddSubCategory} />
                <Route exact path="/admin/sub-categories-list" component={SubCategoriesList} />

                <Route exact path="/admin/add-product" component={AddProduct} />
                <Route exact path="/admin/update-product/:id" component={UpdateProduct} />
                <Route exact path="/admin/update-product-details/:id" component={UpdateProductDetails} />
                <Route exact path="/admin/update-size-details-products-list/:id" component={UpdateSizeProduct} />
                <Route exact path="/admin/size-details-products-list/:id" component={SizeProductDetails} />
                <Route exact path="/admin/products-list" component={ProductsList} />
                <Route exact path="/admin/details-products-list/:id" component={ProductsDetailsList} />

                <Route exact path="/admin/add-new-collection-banner" component={AddNewCollectionBanner} />
                <Route exact path="/admin/add-new-collection-banner/:id" component={AddNewCollectionBanner} />
                <Route exact path="/admin/new-collection-banner-list" component={NewCollectionBannerList} />

                <Route exact path="/admin/add-categories-home-banner" component={AddCategoriesBannerHome} />
                <Route exact path="/admin/add-categories-home-banner/:id" component={AddCategoriesBannerHome} />
                <Route exact path="/admin/categories-home-list" component={CategoriesHomeList} />

                <Route exact path="/admin/add-transport-home-banner" component={AddTransportBannerHome} />
                <Route exact path="/admin/add-transport-home-banner/:id" component={AddTransportBannerHome} />
                <Route exact path="/admin/transport-home-list" component={TransportHomeList} />

                <Route exact path="/admin/add-home-slider" component={AddHomeSlider} />
                <Route exact path="/admin/add-home-slider/:id" component={AddHomeSlider} />
                <Route exact path="/admin/home-slider-list" component={SliderHomeList} />

                <Route exact path="/admin/add-home-card" component={AddHomeCard} />
                <Route exact path="/admin/add-home-card/:id" component={AddHomeCard} />
                <Route exact path="/admin/card-home-list" component={CardHomeList} />

                <Route exact path="/admin/add-blog-categories" component={AddBlogCategories} />
                <Route exact path="/admin/add-blog-categories/:id" component={AddBlogCategories} />
                <Route exact path="/admin/blog-categories-list" component={CategoriesBlogList} />

                <Route exact path="/admin/add-blog-card" component={AddBlogCard} />
                <Route exact path="/admin/add-blog-card/:id" component={AddBlogCard} />
                <Route exact path="/admin/blog-card-list" component={CardBlogList} />

                <Route exact path="/admin/add-blog-second-card" component={AddSecondBlogCard} />
                <Route exact path="/admin/add-blog-second-card/:id" component={AddSecondBlogCard} />
                <Route exact path="/admin/blog-second-card-list" component={CardSecondBlogList} />

                <Route exact path="/admin/add-blog-slider" component={AddBlogSlider} />
                <Route exact path="/admin/add-blog-slider/:id" component={AddBlogSlider} />
                <Route exact path="/admin/blog-sliders-list" component={SliderBlogList} />

                <Route exact path="/admin/add-size" component={AddSize} />
                <Route exact path="/admin/add-size/:id" component={AddSize} />
                <Route exact path="/admin/sizes-list" component={SizesList} />

                <Route exact path="/admin/add-color" component={AddColor} />
                <Route exact path="/admin/add-color/:id" component={AddColor} />
                <Route exact path="/admin/colors-list" component={ColorsList} />

                <Route exact path="/admin/add-home-banner" component={AddBannerHome} />
                <Route exact path="/admin/add-home-banner/:id" component={AddBannerHome} />
                <Route exact path="/admin/home-banner-list" component={BannerHomeList} />

                <Route exact path="/admin/add-new-banner" component={AddBannerNew} />
                <Route exact path="/admin/add-new-banner/:id" component={AddBannerNew} />
                <Route exact path="/admin/new-banner-list" component={BannerNewList} />

                <Route exact path="/admin/add-shop-banner" component={AddBannerShop} />
                <Route exact path="/admin/add-shop-banner/:id" component={AddBannerShop} />
                <Route exact path="/admin/shop-banner-list" component={BannerShopList} />

                <Route exact path="/admin/add-blog-banner" component={AddBannerBlog} />
                <Route exact path="/admin/add-blog-banner/:id" component={AddBannerBlog} />
                <Route exact path="/admin/blog-banner-list" component={BannerBlogList} />

                <Route exact path="/admin/add-login-banner" component={AddBannerLogin} />
                <Route exact path="/admin/add-login-banner/:id" component={AddBannerLogin} />
                <Route exact path="/admin/login-banner-list" component={BannerLoginList} />

                <Route exact path="/admin/add-wishlist-banner" component={AddBannerWishlist} />
                <Route exact path="/admin/add-wishlist-banner/:id" component={AddBannerWishlist} />
                <Route exact path="/admin/wishlist-banner-list" component={BannerWishList} />

                <Route exact path="/admin/add-cart-banner" component={AddBannerCart} />
                <Route exact path="/admin/add-cart-banner/:id" component={AddBannerCart} />
                <Route exact path="/admin/cart-banner-list" component={BannerCartList} />

                <Route exact path="/admin/add-contact-banner" component={AddBannerContact} />
                <Route exact path="/admin/add-contact-banner/:id" component={AddBannerContact} />
                <Route exact path="/admin/contact-banner-list" component={BannerContactList} />

                <Route exact path="/admin/add-sub-sub-category" component={AddSubSubCategory} />
                <Route exact path="/admin/add-sub-sub-category/:id" component={AddSubSubCategory} />
                <Route exact path="/admin/sub-sub-categories-list" component={subSubCategoriesList} />

                <Route exact path="/admin/add-about-banner" component={AddBannerAbout} />
                <Route exact path="/admin/add-about-banner/:id" component={AddBannerAbout} />
                <Route exact path="/admin/about-banner-list" component={BannerAboutList} />

                <Route exact path="/admin/add-about-section" component={AddSectionAbout} />
                <Route exact path="/admin/add-about-section/:id" component={AddSectionAbout} />
                <Route exact path="/admin/about-section-list" component={AboutSectionList} />

                <Route exact path="/admin/add-country" component={AddCountry} />
                <Route exact path="/admin/add-country/:id" component={AddCountry} />
                <Route exact path="/admin/country-list" component={CountriesList} />

                <Route path="/admin/create-privacy-policy" exact component={AddPrivacyPolicy} />
                <Route path="/admin/create-privacy-policy/:id" exact component={AddPrivacyPolicy} />
                <Route path="/admin/privacy-policy-list" exact component={PrivacyPolicyList} />

                <Route path="/admin/create-terms-service" exact component={AddTermsOfService} />
                <Route path="/admin/create-terms-service/:id" exact component={AddTermsOfService} />
                <Route path="/admin/terms-service-list" exact component={TermsOfServiceList} />

                <Route exact path="/user/orders" component={UserOrders} />

                <Route exact path="/order-finished" component={FinishedOrder} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/admin/contacts" component={ContactsList} />
                <Route exact path="/about-us" component={About} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/privacy_policy" component={PrivacyPolicy} />
                <Route exact path="/terms_of_service" component={TermsOfService} />

                <Route exact path="/user/edit/data" component={EditUser} />

                <Route exact path="/notfound" component={NotFound} />

                <Route exact path="/*" component={NotFound} />
            </Switch>
            <ScrollToTop />
        </Layout>
    )
}