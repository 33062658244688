import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const getAllCountries = () => async (dispatch) => {
    try {
        dispatch({ type: 'COUNTRIES_LIST_REQUEST' });

        const { data } = await axios.get(`${apiUrl}/country`);
        dispatch({ type: 'COUNTRIES_LIST_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'COUNTRIES_LIST_FAILED', payload: error });
    }
}

export const createCountry = (post, shipping) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CREATE_COUNTRY_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        const formData = new FormData();
        formData.append('shipping', shipping)
        const { data } = await axios.post(`${apiUrl}/country`, {post, formData}, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CREATE_COUNTRY_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'CREATE_COUNTRY_FAILED', payload: error });
    }
}

export const updateCountry = (id,dataa) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'COUNTRY_UPDATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.patch(`${apiUrl}/country/${id}`, dataa, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'COUNTRY_UPDATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'COUNTRY_UPDATE_FAIL', payload: error });
    }
}

export const deleteCountry = (id) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'COUNTRY_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/country/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'COUNTRY_DELETE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'COUNTRY_DELETE_FAIL', payload: error });
    }
}