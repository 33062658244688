import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

export default function FinishedOrder(props) {
    return (
        <div>
            <div className="thankyou-page">
                <div className="_header">
                    <h1>Thank You!</h1>
                </div>
                <div className="_body">
                    <div className="_box">
                        <h2>
                        <strong>Please check your email</strong> for further instructions on how to complete your account setup.
                        </h2>
                        <p>
                            Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.            </p>
                    </div>
                </div>
                <div className="_footer">
                    <Link className="btn" to="/">Back to homepage</Link>
                </div>
            </div>
        </div>
    )
}