import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, updateCategory } from '../../../actions/category_actions';
import { clear } from '../../../actions/clear_action';
import { useHistory, useParams } from 'react-router-dom';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';
import { apiUrl } from '../../../constants/backendUrl';
import axios from 'axios';
import { useFormik } from 'formik';
import Multiselect from 'multiselect-react-dropdown';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddCategory(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { success, loading: loadingCreate, error: errorAddCategory } = useSelector(state => state.add_category);
    const { update_success, loading, error: errorUpdateCategory } = useSelector(state => state.update_category);
    const [options, setOptions] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const validate = values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'This field is required';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
          name: '',
          subCategories: []
        },
        validate,
        onSubmit: (values, {resetForm}) => {
          if(id) {
            dispatch(updateCategory(id, values));
          } else {
            dispatch(createCategory(values));
          }
        },
    });

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        axios.get(`${apiUrl}/subCategories`).then(res => {
            setOptions(res.data);
        }).catch(error => console.log(error));

        if(id) {
            axios.get(`${apiUrl}/categories/${id}`, {
                headers:
                  { Authorization: 'Bearer ' + userInfo?.token }
            })
          .then(response => {
            formik.setFieldValue('name', response.data.name);
          }).catch(error => console.log(error));
        }

        if(success) {
            Toastr.success('Kategoria u krijua me sukses.');
            history.push('/admin/categories-list');
            dispatch(clear()); //per me bo clear staten.
        }
        if(update_success) {
            Toastr.success('Kategoria u editua me sukses.');
            history.push('/admin/categories-list');
            dispatch(clear()); //per me bo clear staten.
        }
        return () => {
        }
    }, [success, update_success]);

    return (
        <div>
            <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>{id ? 'Edito Kategorinë' : 'Krijo Kategorinë'}</h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        errorAddCategory ? <div className="col-sm-12 mt-3" style={{textAlign: 'center'}}>
                            <p className="text-danger"> {errorAddCategory}</p>
                        </div>
                        :
                        null
                    }

                    {
                        errorUpdateCategory ? <div className="col-sm-12 mt-3" style={{textAlign: 'center'}}>
                            <p className="text-danger"> {errorUpdateCategory}</p>
                        </div>
                        :
                        null
                    }
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">{id ? 'Edito Kategorinë' : 'Krijo Kategorinë'}</h3>
                                        </div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="category-name">Emri</label>
                                                    <input 
                                                        name="name" 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="category-name" 
                                                        placeholder="Emri Kategorisë" 
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name} 
                                                    />
                                                    {formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                                </div>
                                                <Multiselect
                                                    options={options} // Options to display in the dropdown
                                                    onSelect={(e) => formik.setFieldValue('subCategories', e)} // Function will trigger on select event
                                                    value={formik.values.subCategories}
                                                    displayValue="name" // Property name to display in the dropdown options(duhet mja cek emrin e atributit ne model qe e kemi)
                                                    placeholder="Selekto Nën Kategoritë"
                                                />
                                            </div>
                                            <div className="card-footer">
                                                {
                                                    loading || loadingCreate ?
                                                    <button class="btn btn-danger" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span> Loading...</span>
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-danger" disabled={!(formik.isValid && formik.dirty)}>
                                                        <span>{id ? 'Edito' : 'Shto'}</span>
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}