import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPrivacyPolicy, updatePrivacyPolicy } from '../../../actions/privacy_policy';
import { clear } from '../../../actions/clear_action';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../../../constants/backendUrl';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

require('react-quill/dist/quill.snow.css'); // CommonJS
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddPrivacyPolicy(props){
    const { register, handleSubmit, formState: { errors } } = useForm({});
    const [description, setdescription] = useState('');

    const { userInfo } = useSelector(state => state.userSignIn);
    const { id } = useParams();

    const add_privacy_policy = useSelector(state => state.add_privacy_policy);
    const { success, loading } = add_privacy_policy;

    const update_privacy_policy = useSelector(state => state.update_privacy_policy);
    const { update_success, loading: updateLoading } = update_privacy_policy;

    const dispatch = useDispatch();
    const history = useHistory();

    const submitHandler = () => {
      if(id) {
        dispatch(updatePrivacyPolicy(id, { description }));
      } else {
        dispatch(addPrivacyPolicy({ description }));
      }       
    }
    
    const handleChange = (value) => {
      setdescription(value);
    }

    const clearInputs = () => {
      setdescription('');
    }

    useEffect(() => {
        if(!userInfo) {
          history.push('/')
        }
        if(id) {
            axios.get(`${apiUrl}/privacy/policy/${id}`, {
                headers:
                  { Authorization: 'Bearer ' + userInfo?.token }
            })
          .then(response => {
              setdescription(response.data.description);
          }).catch(error => console.log(error));
        }

        if(success) {
            Toastr.success('Privacy Policy u krijua me sukses.');
            props.history.push('/admin/privacy-policy-list');
            clearInputs();
            dispatch(clear());
        }

        if(update_success) {
            Toastr.success('Privacy Policy u editua me sukses.');
            props.history.push('/admin/privacy-policy-list');
            clearInputs();
            dispatch(clear());
        }
        return () => {
            // dispatch(clear()); //per me bo clear staten.
        }
    }, [success, update_success])
  
      return (
        <div className="wrapper">
          <AdminHeader />
          <AdminSideBar />
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-12" style={{textAlign: 'center'}}>
                    <h1>Krijo Privacy Policy</h1>
                  </div>
                </div>
              </div>
            </section>
  
            <section className="content">
              <div className="container-fluid">
                <div className="row" style={{justifyContent: 'center'}}>
                  <div className="col-md-6">
                    <div className="card card-danger">
                      <div className="card-header">
                        <h3 className="card-title">Krijo Privacy Policy</h3>
                      </div>
                      <form onSubmit={handleSubmit(submitHandler)}>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="description">Përshkrimi</label>
                                <ReactQuill 
                                  value={description}
                                  onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                          <button type="submit" className="btn btn-danger">
                            { loading && <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            }
                            { updateLoading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            )}
                            { id && !updateLoading && <span>Edito</span> }
                            { !id && !loading && <span>Shto</span>}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        <AdminFooter />
        <aside className="control-sidebar control-sidebar-dark">
        </aside>
      </div>
    )
}
