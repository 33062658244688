import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const privacyPolicyList = () => async (dispatch) => {
    try {
        dispatch({type: 'PRIVACY_POLICY_LIST_REQUEST'});
        const user = JSON.parse(localStorage.getItem('profile'));
        const {data} = await axios.get(`${apiUrl}/privacy/policy`);
        dispatch({ type: 'PRIVACY_POLICY_LIST_SUCCESS', payload: data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'PRIVACY_POLICY_LIST_FAIL', payload: error });
    }
}

export const addPrivacyPolicy = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'PRIVACY_POLICY_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();
        const privacyPolicy = await axios.post(`${apiUrl}/privacy/policy`, data, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'PRIVACY_POLICY_CREATE_SUCCESS', payload: privacyPolicy.data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'PRIVACY_POLICY_CREATE_FAIL', payload: error });
    }
}

export const updatePrivacyPolicy = (id,data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'PRIVACY_POLICY_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();
        const privacyPolicy = await axios.patch(`${apiUrl}/privacy/policy/${id}`, data, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'PRIVACY_POLICY_UPDATE_SUCCESS', payload: privacyPolicy.data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'PRIVACY_POLICY_UPDATE_FAIL', payload: error });
    }
}

export const deletePrivacyPolicy = (privacyPolicyId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'DELETE_PRIVACY_POLICY_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        const {data} = await axios.delete(`${apiUrl}/privacy/policy/${privacyPolicyId}`,{
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'DELETE_PRIVACY_POLICY_SUCCESS', payload: data });

    } catch(error) {
        console.log(error);
        dispatch({ type: 'DELETE_PRIVACY_POLICY_FAIL', payload: error });
    }
}
