import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCountry, updateCountry } from '../../../actions/country_actions';
import { clear } from '../../../actions/clear_action';
import { useHistory, useParams } from 'react-router-dom';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { apiUrl } from '../../../constants/backendUrl';
import axios from 'axios';
import { useFormik } from 'formik';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddCountry(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { success, loading: loadingCreate } = useSelector(state => state.add_country);
    const { update_success, loading } = useSelector(state => state.update_country);

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const validate = values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'This field is required';
        }
        if (!values.shipping) {
            errors.shipping = 'This field is required';
          }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
          name: '',
          shipping: ''
        },
        validate,
        onSubmit: (values, {resetForm}) => {
          if(id) {
            dispatch(updateCountry(id, values));
          } else {
              dispatch(createCountry(values));
          }
        },
    });

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        if(id) {
            axios.get(`${apiUrl}/country/${id}`, {
                headers:
                  { Authorization: 'Bearer ' + userInfo?.token }
            })
            .then(response => {
                formik.setFieldValue('name', response.data.name);
                formik.setFieldValue('shipping', response.data.shipping);
          }).catch(error => console.log(error));
        }
        if(success) {
            Toastr.success('Shteti u krijua me sukses.');
            history.push('/admin/country-list');
            dispatch(clear()); //per me bo clear staten.
        }
        if(update_success) {
            Toastr.success('Shteti u editua me sukses.');
            history.push('/admin/country-list');
            dispatch(clear()); //per me bo clear staten.
        }
        return () => {
        }
    }, [success, update_success]);

    return (
        <div>
        <div className="wrapper">
            <AdminHeader />
            <AdminSideBar />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12" style={{textAlign: 'center'}}>
                                <h1>{id ? 'Edito Shtetin' : 'Krijo Shtetin'}</h1>
                            </div>
                        </div>
                    </div>
                </section>
    
                <section className="content">
                    <div className="container-fluid">
                        <div className="row" style={{justifyContent: 'center'}}>
                            <div className="col-md-6">
                                <div className="card card-danger">
                                    <div className="card-header">
                                        <h3 className="card-title">{id ? 'Edito Shtetin' : 'Krijo Shtetin'}</h3>
                                    </div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="name">Emri</label>
                                                <input 
                                                    name="name" 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="name" 
                                                    placeholder="Emri" 
                                                    onChange={formik.handleChange}
                                                    value={formik.values.name}  
                                                />
                                                {formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="shipping">Posta</label>
                                                <input 
                                                    name="shipping" 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="shipping" 
                                                    placeholder="Posta" 
                                                    onChange={formik.handleChange}
                                                    value={formik.values.shipping}  
                                                />
                                                {formik.errors.shipping ? <div className="text-danger">{formik.errors.shipping}</div> : null}
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            {
                                                loading || loadingCreate ?
                                                <button class="btn btn-danger" type="button" disabled>
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span> Loading...</span>
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-danger" disabled={!(formik.isValid && formik.dirty)}>
                                                    <span>{id ? 'Edito' : 'Shto'}</span>
                                                </button>
                                                }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AdminFooter />
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
        </div>
    </div>
    )
}