function listCountriesReducer(state = { countries: [] }, action) {
    switch (action.type) {
        case 'COUNTRIES_LIST_REQUEST':
            return { loading: true, countries: [] }
        case 'COUNTRIES_LIST_SUCCESS':
            return { loading: false, countries: action?.payload }
        case 'COUNTRIES_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addCountryReducer(state = {}, action) {
    switch (action.type) {
        case 'CREATE_COUNTRY_REQUEST':
            return { loading: true }
        case 'CREATE_COUNTRY_SUCCESS':
            return { loading: false, country: action?.payload, success: true }
        case 'CREATE_COUNTRY_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateCountryReducer(state = { contact: {} }, action) {
    switch(action.type) {
        case 'COUNTRY_UPDATE_REQUEST':
            return { loading: true }
        case 'COUNTRY_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'COUNTRY_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteCountryReducer(state = { country: {} }, action) {
    switch(action.type) {
        case 'COUNTRY_DELETE_REQUEST':
            return { loading: true }
        case 'COUNTRY_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'COUNTRY_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listCountriesReducer, addCountryReducer, updateCountryReducer, deleteCountryReducer }