function listColorReducer(state = { colors: [] }, action) {
    switch (action.type) {
        case 'COLORS_LIST_REQUEST':
            return { loading: true, colors: [] }
        case 'COLORS_LIST_SUCCESS':
            return { loading: false, colors: action?.payload }
        case 'COLORS_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addColorReducer(state = {}, action) {
    switch (action.type) {
        case 'COLOR_CREATE_REQUEST':
            return { loading: true }
        case 'COLOR_CREATE_SUCCESS':
            return { loading: false, color: action?.payload, success: true }
        case 'COLOR_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateColorReducer(state = { color: {} }, action) {
    switch(action.type) {
        case 'COLOR_UPDATE_REQUEST':
            return { loading: true }
        case 'COLOR_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'COLOR_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteColorReducer(state = { color: {} }, action) {
    switch(action.type) {
        case 'COLOR_DELETE_REQUEST':
            return { loading: true }
        case 'COLOR_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'COLOR_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listColorReducer, addColorReducer, updateColorReducer, deleteColorReducer }