import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const bannerHomeList = () => async (dispatch) => {
    try {
        dispatch({type: 'HOME_BANNER_LIST_REQUEST'});
        const homeBannerData = await axios.get(`${apiUrl}/homeBanner`);
        dispatch({ type: 'HOME_BANNER_LIST_SUCCESS', payload: homeBannerData?.data });
    } catch(error) {
        dispatch({ type: 'HOME_BANNER_LIST_FAIL', payload: error });
    }
}

export const createBannerHome = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'HOME_BANNER_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();
        
        const formData = new FormData();
        formData.append('image', data?.image, data?.image.name);
        formData.append('link', data?.link);

        const homeBannerData = await axios.post(`${apiUrl}/homeBanner`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'HOME_BANNER_CREATE_SUCCESS', payload: homeBannerData?.data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'HOME_BANNER_CREATE_FAIL', payload: error });
    }
}

export const updateBannerHome = (id,data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'HOME_BANNER_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        data.image instanceof Blob && formData.append('image', data.image, data.image.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
        formData.append('link', data?.link);
        
        const homeBannerData = await axios.patch(`${apiUrl}/homeBanner/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'HOME_BANNER_UPDATE_SUCCESS', payload: homeBannerData?.data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'HOME_BANNER_UPDATE_FAIL', payload: error?.response?.data?.message  });
    }
}

export const deleteBannerHome = (homeBannerId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'HOME_BANNER_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const {data} = await axios.delete(`${apiUrl}/homeBanner/${homeBannerId}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'HOME_BANNER_DELETE_SUCCESS', payload: data });

    } catch(error) {
        console.log(error);
        dispatch({ type: 'HOME_BANNER_DELETE_FAIL', payload: error });
    }
}
