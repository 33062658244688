import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBannerAbout, updateBannerAbout } from '../../../actions/bannerAbout_actions';
import { clear } from '../../../actions/clear_action';
import { useHistory, useParams } from 'react-router-dom';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { apiUrl } from '../../../constants/backendUrl';
import axios from 'axios';
import { useFormik } from 'formik';
import imageCompression from 'browser-image-compression';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddBannerAbout(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { success, loading: loadingCreate, error: errorAddBannerAbout } = useSelector(state => state.add_banner_about);
    const { update_success, loading, error: errorUpdateBannerAbout } = useSelector(state => state.update_banner_about);

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const validate = values => {
        const errors = {};
        if (!values.image) {
          errors.image = 'This field is required';
        }
      
        if (!values.link) {
          errors.link = 'This field is required';
        }
        
        if(!values.text) {
            errors.text = 'This field is required';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
          image: '',
          link: '',
          text: ''
        },
        validate,
        onSubmit: (values, {resetForm}) => {
          if(id) {
            dispatch(updateBannerAbout(id, values));
            // resetForm();
          } else {
              dispatch(createBannerAbout(values));
          }
        },
    });

    const handleImageUpload = async (e) => {
        const imageFile = e.target.files[0];
      
        const options = {
        //   maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);      
          formik.setFieldValue('image', compressedFile);
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        if(id) {
            axios.get(`${apiUrl}/bannerAbout/${id}`, {
                headers:
                  { Authorization: 'Bearer ' + userInfo?.token }
            })
            .then(response => {
                console.log(response);
                formik.setFieldValue('image', response.data.image);
                formik.setFieldValue('link', response.data.link);
                formik.setFieldValue('text', response.data.text);
          }).catch(error => console.log(error));
        }
        if(success) {
            Toastr.success('About Banner u krijua me sukses.');
            history.push('/admin/about-banner-list');
            dispatch(clear()); //per me bo clear staten.
        }
        if(update_success) {
            Toastr.success('About Banner u editua me sukses.');
            history.push('/admin/about-banner-list');
            dispatch(clear()); //per me bo clear staten.
        }
    }, [success, update_success]);

    return (
        <div>
            <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>{ id ? 'Edito About Banner' : 'Krijo About Banner'}</h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        errorAddBannerAbout ? <div className="col-sm-12 mt-3" style={{textAlign: 'center'}}>
                            <p className="text-danger"> {errorAddBannerAbout}</p>
                        </div>
                        :
                        null
                    }

                    {
                        errorUpdateBannerAbout ? <div className="col-sm-12 mt-3" style={{textAlign: 'center'}}>
                            <p className="text-danger"> {errorUpdateBannerAbout}</p>
                        </div>
                        :
                        null
                    }
        
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">{ id ? 'Edito About Banner' : 'Krijo About Banner'}</h3>
                                        </div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="banner-blog">Zgjidh Foton</label>
                                                    <input 
                                                        type="file"
                                                        name="image"  
                                                        className="form-control" 
                                                        id="banner-blog"
                                                        type="file"  
                                                        accept=".png, .jpg, .jpeg"
                                                        onChange={(e) => handleImageUpload(e)}
                                                    />
                                                    {formik.errors.image ? <div className="text-danger">{formik.errors.image}</div> : null}
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="link">Linku</label>
                                                    <select
                                                        className="form-select"
                                                        id="link"
                                                        name="link"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.link}
                                                        >
                                                        <option selected>Selekto Linkun</option>
                                                        <option value="/new">New</option>
                                                        <option value="/sale">Sale</option>
                                                        <option value="/blog">Blog</option>
                                                    </select>
                                                    {formik.errors.link ? <div className="text-danger">{formik.errors.link}</div> : null}
                                                </div>
                                            </div>
                                            
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="link">Teksti</label>
                                                    <textarea 
                                                        name="text"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.text}
                                                    >

                                                    </textarea>
                                                    {formik.errors.text ? <div className="text-danger">{formik.errors.text}</div> : null}
                                                </div>
                                            </div>

                                            <div className="card-footer">
                                                {
                                                    loading || loadingCreate ?
                                                    <button class="btn btn-danger" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span> Loading...</span>
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-danger" disabled={!(formik.isValid && formik.dirty)}>
                                                        <span>{id ? 'Edito' : 'Shto'}</span>
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}