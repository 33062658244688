function listNewBannerReducer(state = { newBanners: [] }, action) {
    switch (action.type) {
        case 'NEW_BANNER_LIST_REQUEST':
            return { loading: true, newBanners: [] }
        case 'NEW_BANNER_LIST_SUCCESS':
            return { loading: false, newBanners: action?.payload }
        case 'NEW_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addNewBannerReducer(state = {}, action) {
    switch (action.type) {
        case 'NEW_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'NEW_BANNER_CREATE_SUCCESS':
            return { loading: false, newBanner: action?.payload, success: true }
        case 'NEW_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateNewBannerReducer(state = { newBanner: {} }, action) {
    switch(action.type) {
        case 'NEW_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'NEW_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'NEW_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteNewBannerReducer(state = { newBanner: {} }, action) {
    switch(action.type) {
        case 'NEW_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'NEW_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'NEW_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listNewBannerReducer, addNewBannerReducer, updateNewBannerReducer, deleteNewBannerReducer }