import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { privacyPolicyList } from '../../actions/privacy_policy';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Loader from '../Loader/Loader';

import './style.css';

const PolitikaPrivatesise = (props) => {
    const privacy_policy_list = useSelector(state => state.privacy_policy_list);
    const { privacyPolicies, loading, error } = privacy_policy_list;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(privacyPolicyList());
        return () => {

        }
    },[])

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } }); //sepse description e kemi ne db psh <p>e prandaj duhet me bo decode, Implement HTML Entity Decode in react.js</p>

    return (
        <div>
            { loading || error && error.message ? <Loader /> :
                <>
                    <Header />
                    <section className="privacy_section"> 
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        
                                        renderHTML(privacyPolicies && privacyPolicies.length > 0 && privacyPolicies[0].description)
                                    }          
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            }
        </div>
    )
}

export default PolitikaPrivatesise;