function listTermsOfServiceReducer(state = { termsOfService: [] }, action) {
    switch (action.type) {
        case 'TERMS_OF_SERVICE_LIST_REQUEST':
            return { loading: true, termsOfService: [] }
        case 'TERMS_OF_SERVICE_LIST_SUCCESS':
            return { loading: false, termsOfService: action.payload }
        case 'TERMS_OF_SERVICE_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

function addTermsOfServiceReducer(state = {}, action) {
    switch(action.type) {
        case 'TERMS_OF_SERVICE_CREATE_REQUEST':
            return {loading: true}
        case 'TERMS_OF_SERVICE_CREATE_SUCCESS':
            return {loading: false, termsOfService: action.payload, success: true}
        case 'TERMS_OF_SERVICE_CREATE_FAIL':
            return { loading: false, error: action.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateTermsOfServiceReducer(state = { termsOfService: {} }, action) {
    switch(action.type) {
        case 'TERMS_OF_SERVICE_UPDATE_REQUEST':
            return { loading: true }
        case 'TERMS_OF_SERVICE_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'TERMS_OF_SERVICE_UPDATE_FAIL':
            return { loading: false, error: action.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteTermsOfServiceReducer(state = { termsOfService: {} }, action) {
    switch(action.type) {
        case 'DELETE_TERMS_OF_SERVICE_REQUEST':
            return { loading: true }
        case 'DELETE_TERMS_OF_SERVICE_SUCCESS':
            return { loading: false, success: true }
        case 'DELETE_TERMS_OF_SERVICE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}


export { addTermsOfServiceReducer, listTermsOfServiceReducer, updateTermsOfServiceReducer, deleteTermsOfServiceReducer }