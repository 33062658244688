function listAboutBannerReducer(state = { aboutBanners: [] }, action) {
    switch (action.type) {
        case 'ABOUT_BANNER_LIST_REQUEST':
            return { loading: true, aboutBanners: [] }
        case 'ABOUT_BANNER_LIST_SUCCESS':
            return { loading: false, aboutBanners: action?.payload }
        case 'ABOUT_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addAboutBannerReducer(state = {}, action) {
    switch (action.type) {
        case 'ABOUT_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'ABOUT_BANNER_CREATE_SUCCESS':
            return { loading: false, aboutBanner: action?.payload, success: true }
        case 'ABOUT_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateAboutBannerReducer(state = { aboutBanner: {} }, action) {
    switch(action.type) {
        case 'ABOUT_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'ABOUT_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'ABOUT_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteAboutBannerReducer(state = { aboutBanner: {} }, action) {
    switch(action.type) {
        case 'ABOUT_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'ABOUT_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'ABOUT_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listAboutBannerReducer, addAboutBannerReducer, updateAboutBannerReducer, deleteAboutBannerReducer }