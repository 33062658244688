import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { addToCart } from '../../actions/cart_actions';
import { addToWishlist } from '../../actions/wishlist_actions';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axios from 'axios';
import { apiUrl } from "../../constants/backendUrl";
import './style.css';

/*Kjo duhet me pas si props nje atribut open nese open eshte false mshefet nese open o true hapet(kjo hapet kur e klikojm butonin quick view tek produkti) */
export default function OpenModal(props) {
    const [qty, setQty] = useState(1);
    const [sizeSelected, setSizeSelected] = useState('');
    const [countInStock, setCountInStock] = useState('');
    const [sizeDetailId, setSizeDetailId] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [productColors, setProductColors] = useState([]);

    var modal = document.getElementById("modal_box409");

    const { cartItems } = useSelector(state => state.cart);

    const { wishlistItems } = useSelector(state => state.wishlist);

    const dispatch = useDispatch();
    const location = useLocation();

    function closeModal() {
        modal.style.display = 'none';
        var mobile_header = document.getElementById("eltdf-mobile-header");
        mobile_header.style.zIndex = '110';
    }

    function encreaseQuantity() {
        setQty(qty + 1);
    }

    function decreaseQuantity() {
        setQty(qty - 1);
    }

    //kjo dallon pak sepse ktu jemi ne singel product -> dhe ketu useri mundet me vendos n cart produktin me ngjyr t ver dhe me ngjyr t kaltert qe i takojn t njetit produkt, por me ngjyra t ndryshme
    function addCart(productId, quantity) {
        const singleProduct = cartItems.find(x => x?.sizeDetailId === sizeDetailId); //sizeDetailId na duhet per me kqyr a ekziston n cart produkti, productId na nevoitet tek cart_actions per me lyp n backend me id e tabeles produkti qe na gjenerohet nga mongodb.

        if(singleProduct?.sizeDetailId !== sizeDetailId && countInStock > 0 && quantity <= countInStock) { //singleProduct.product -> sepse product eshte id e productit ashtu e kom lon  tek cart_actions-> product: data?._id
            dispatch(addToCart(productId, quantity, selectedColor, sizeSelected, countInStock, sizeDetailId));
            Toastr.success('Product added to cart.');
            setSizeSelected('');
        } else if(countInStock < 1 || quantity > countInStock) {
            Toastr.error('Come back soon!');
        } 
        else {
            Toastr.error('This product is already added to cart!');
        }    
    }

    function addWishlist(id, checkId, color) {
        const singleProduct = wishlistItems.find(x => x?.product === checkId);

        dispatch(addToWishlist(id, color));
          if(singleProduct?.product !== checkId) { //singleProduct.product -> sepse product eshte id e productit ashtu e kom lon  tek cart_actions-> product: data?._id
            Toastr.success(' Product added to wishlist.');
        } else {
            Toastr.error('The product is already added to wishlist.');
        }
    }

    function setSizeAndCountInStock(size, countInStock, sizeDetailId) {
        if(countInStock > 0) {
            setSizeSelected(size);
            setCountInStock(countInStock);
            setSizeDetailId(sizeDetailId);
        }
    }

    window.onclick = function(event) {
        if(event.target == modal) {
            modal.style.display = 'none';
            var mobile_header = document.getElementById("eltdf-mobile-header");
            mobile_header.style.zIndex = '110';
        }
    }

    function searchByColor(id, color) {
        setSelectedColor(color);
        setSizeSelected('');
        setCountInStock('');
        axios.get(`${apiUrl}/products/single-product/color/${id}/${color}`).then(res => {
            if(res.data.success) {
                // setCartProductId(res.data.product.availableOptions[0]._id);
                props?.setProduct(res.data.product);
            }
        }).catch(err => {
            console.log(err);
            Toastr.error('Produkti me ngjyrën e caktuar nuk ekziston.');
        });
    }

    useEffect(() => {
        if(props?.product?._id) {
            axios.get(`${apiUrl}/products/${props?.product?._id}`).then(response => {
                setSelectedColor(response?.data?.product?.availableOptions[0]?.color)
                // setProduct(response?.data?.product);
                setProductColors(response?.data?.colors);
            }).catch(error => console.log(error));
        }
  
    }, [props?.product?._id]);

    return (        
        <div className="modal fade show" id="modal_box409" style={{display: 'none' , paddingRight: '17px', paddingLeft: '17px'}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                        <span aria-hidden="true">
                            <i className="fas fa-times"></i>
                        </span>
                    </button>
                    <div className="modal_body">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <div className="modal_tab">
                                        <div className="tab-content product-details-large">
                                            <div className="tab-pane fade show active" id="tab_873" role="tabpanel">
                                                <div className="modal_tab_img" id="modal_featured_409">
                                                    <a href="#">
                                                        {props?.product?.availableOptions?.slice(0,1)?.map(item => (<img src={`${apiUrl}/images/${item.images[0]}`} className="attachment-full size-full wp-post-image" alt="m" /> )) }

                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="modal_right">
                                        <div className="modal_title mb-10">
                                            <h2>{props?.product?.title}</h2>
                                        </div>
                                        <div className="modal_price mb-10">
                                            <span className="new_price" id="sale_price_409">{props?.product?.discountPrice ? props?.product?.discountPrice?.toFixed(2) : props?.product?.price?.toFixed(2)}€</span>
                                        </div>
                                        <div className="modal_description mb-15">
                                            <p>{props?.product?.description}</p>
                                        </div>

                                        <div className="filter-item_content collapse show" id="product-sizes-pr12345678">
                                        <div className="product-color">
                                                <div className="color-choose">
                                                    {
                                                        productColors?.map((c, index) => <div key={c?._id}>
                                                            <input  
                                                                type="radio" 
                                                                id={c?.color} 
                                                                name="sizes" 
                                                                defaultChecked = {index === 0 ? true : false }
                                                            />
                                                            <label htmlFor={c?.color} onClick={() => searchByColor(props?.product?._id, c?.color)}>
                                                                <span style={{ backgroundColor: c?.colorHex }}></span>
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            
                                            <div className="product-size">
                                                <div className="size-choose">
                                                    {
                                                        props?.product?.availableOptions && props?.product?.availableOptions[0]?.sizeObj?.map((item, index) => <div key={item?._id}>
                                                            <input  
                                                                type="radio" 
                                                                id={`size${item?.size}`} 
                                                                name="colors" 
                                                            />
                                                            <label htmlFor={`${item?.countInStock < 1 ? 'out-of-stock' : `size${item?.size}`}`} onClick={() => setSizeAndCountInStock(item?.size, item?.countInStock, item?._id)}>
                                                                <span className={`${item?.countInStock < 1 ? 'out-of-stock' : ''}`}>{item?.size}</span>
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            
                                            {/* <ul className="list-inline">
                                                {
                                                    props?.product?.availableOptions && props?.product?.availableOptions[0]?.sizeObj?.map((item, index) => <li className="list-inline-item" key={index} onClick={() => setSizeAndCountInStock(item?.size, item?.countInStock, item?._id)}>
                                                        <span className="filter-option-value">{item?.size}</span>
                                                    </li>)
                                                }
                                            </ul> */}
                                        </div>

                                        <div>
                                        <form className="cart-modal">
                                            <div className="eltdf-quantity-buttons quantity">
                                                <label className="screen-reader-text" htmlFor="quantity_60dc64bd25e1d">Black Heals quantity</label>
                                                <span 
                                                    className="eltdf-quantity-minus icon_minus-06 product-page-eltdf-quantity-minus" 
                                                    style={ qty < 2 ? { pointerEvents: 'none' } : {} }
                                                    onClick={decreaseQuantity}
                                                />
                                                <input 
                                                    type="text" 
                                                    id="quantity_60dc64bd25e1d" 
                                                    className="input-text qty text eltdf-quantity-input" 
                                                    title="Qty" 
                                                    value={qty}
                                                    style={{border: 'none'}} 
                                                />
                                                <span 
                                                    className="eltdf-quantity-plus icon_plus product-page-eltdf-quantity-plus" 
                                                    style={ countInStock < 1 || qty >= countInStock ? { pointerEvents: 'none' } : {} }
                                                    onClick={encreaseQuantity}
                                                />
                                            </div>
                                            <div className="yith-wcwl-add-to-wishlist add-to-wishlist-293 exists wishlist-fragment on-first-load">
                                                <div className="btn-add-to-cart-open-modal">
                                                    <Link 
                                                        to={location.pathname} 
                                                        onClick={() => addCart(props?.product?._id, qty, props?.product?.availableOptions[0]?.color)} 
                                                        style={ !sizeSelected  ? { pointerEvents: 'none' } : {} }
                                                    >
                                                        { !sizeSelected ? 'Select a size' : 'Add to Cart' }
                                                    </Link>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="product_meta">
                                            <span className="sku_wrapper">SKU: 
                                                {/* <span className="sku">{props?.product?.availableOptions[0]?.sku}</span> */}
                                            </span>
                                            <span className="posted_in">Category: 
                                                <span className="sku">{props?.product?.category}</span>
                                            </span>
                                            <span className="tagged_as">Tag: <a href="https://onea.qodeinteractive.com/product-tag/clothes/" rel="tag">{props?.product?.tags}</a></span>
                                        </div>

                                        <div className="yith-wcwl-add-to-wishlist add-to-wishlist-293 exists wishlist-fragment on-first-load">
                                                <div className="btn-add-to-cart-open-modal">
                                                    <Link 
                                                        to={location.pathname} 
                                                        onClick={() => addWishlist(props?.product?._id, props?.product?.availableOptions[0]?._id, props?.product?.availableOptions[0]?.color)}
                                                        style={{ background: '#a65a6a' }}
                                                    >
                                                        Add to Wishlist
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}