function listBlogBannerReducer(state = { blogBanners: [] }, action) {
    switch (action.type) {
        case 'BLOG_BANNER_LIST_REQUEST':
            return { loading: true }
        case 'BLOG_BANNER_LIST_SUCCESS':
            return { loading: false, blogBanners: action?.payload }
        case 'BLOG_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addBlogBannerReducer(state = {}, action) {
    switch (action.type) {
        case 'BLOG_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'BLOG_BANNER_CREATE_SUCCESS':
            return { loading: false, blogBanner: action?.payload, success: true }
        case 'BLOG_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateBlogBannerReducer(state = { blogBanner: {} }, action) {
    switch(action.type) {
        case 'BLOG_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'BLOG_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'BLOG_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteBlogBannerReducer(state = { blogBanner: {} }, action) {
    switch(action.type) {
        case 'BLOG_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'BLOG_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'BLOG_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listBlogBannerReducer, addBlogBannerReducer, updateBlogBannerReducer, deleteBlogBannerReducer }