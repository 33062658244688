import React, { useState, useEffect } from 'react';
import { removeFromWishlist } from '../../actions/wishlist_actions';
import { addToCart } from '../../actions/cart_actions';
import { bannerWishlistList } from '../../actions/bannerWishlist_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiUrl } from "../../constants/backendUrl";
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBar from '../SearchBar/SearchBar';
import Loader from '../Loader/Loader';
import AddToCartModal from '../AddToCartModal/AddToCartModal';

import './style.css';

export default function Wishlist(props) {
    const [openCartModal, setCartModal] = useState('');
    const [qty, setQty] = useState(1);

    const { cartItems } = useSelector(state => state.cart);
    const { wishlistItems } = useSelector(state => state.wishlist);
    const { wishlistBanners, loading: wishlistBannersLoading } = useSelector(state => state.banner_wishlist_list);

    const dispatch = useDispatch();

    function openModalCart(productId, quantity, color, size, countInStock, sizeDetailId) {
        // dispatch(addToCart(productId, quantity, color));
    
        // var element = document.getElementById("cart-modal-right-side"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
        // var elementOverlay = document.getElementById("la-overlay-global"); //per me shfaq opacity pasi t hapet modali i add cartes
        // var header = document.getElementById("eltdf-page-header");
        // header.style.zIndex = '0';
        // elementOverlay.classList.add('la-overlay-global');
        // setCartModal(element.classList.add("cart-flyout-open"));

        const singleProduct = cartItems?.find(x => x?.sizeDetailId === sizeDetailId); //sizeDetailId na duhet per me kqyr a ekziston n cart produkti, productId na nevoitet tek cart_actions per me lyp n backend me id e tabeles produkti qe na gjenerohet nga mongodb.

        if(singleProduct?.sizeDetailId !== sizeDetailId && countInStock > 0 && quantity <= countInStock) { //singleProduct.product -> sepse product eshte id e productit ashtu e kom lon  tek cart_actions-> product: data?.id
            dispatch(addToCart(productId, quantity, color, size, countInStock, sizeDetailId));
                
            // var element = document.getElementById("cart-modal-right-side"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
            // var elementOverlay = document.getElementById("la-overlay-global"); //per me shfaq opacity pasi t hapet modali i add cartes
            // var header = document.getElementById("eltdf-page-header");
            // var mobile_header = document.getElementById("eltdf-mobile-header");

            // header.style.zIndex = '0';
            // mobile_header.style.zIndex = '0';
            // elementOverlay.classList.add('la-overlay-global');
            // setCartModal(element?.classList?.add("cart-flyout-open"));

            Toastr.success('Produkti u shtua në shportë.');
        } else if(countInStock < 1 || quantity > countInStock) {
            Toastr.error('Out of stock!');
        } 
        else {
            Toastr.error('Produkti ekziston në shportë!');
        }
    }

    useEffect(() => {
        dispatch(bannerWishlistList());

    }, []);

    return (
        wishlistBannersLoading
        ?
        <Loader />
        :
        <section id="cart_page">
            <TopHeader />
            {
                wishlistBanners?.slice(-1)?.map(wishlistBanner =>  <Slider image = { wishlistBanner?.image } link={wishlistBanner?.link} />)
            }
            <Header />
            {/* <div className="page-template page-template-full-width page-template-full-width-php page page-id-688 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js woocommerce-wishlist woocommerce woocommerce-page onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-sticky-header-on-scroll-up eltdf-dropdown-animate-height eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-header-top-enabled eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive">
                <div className="eltdf-content" style={{marginBottom: '523px'}}>
                    <div className="eltdf-content-inner">
                        <SearchBar />
                        <div className="eltdf-full-width" id="wishlist_table_part_000">
                            <div className="eltdf-full-width-inner">
                                <div className="eltdf-grid-row" style={{ marginRight: '0' }}>
                                    <div className="eltdf-page-content-holder eltdf-grid-col-12">
                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1536674114012">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner vc_custom_1536674119996">
                                                    <div className="wpb_wrapper">
                                                        <div className="eltdf-elements-holder   eltdf-one-column  eltdf-responsive-mode-768 ">
                                                            <div className="eltdf-eh-item eltdf-eh-custom-1035" data-item-class="eltdf-eh-custom-1035" data-769-1024="38px 11% 0 11%" data-480="25px 0px 0px 0px">
                                                                <div className="eltdf-eh-item-inner">
                                                                    <div className="eltdf-eh-item-content" style={{padding: '38px 15% 0 15%'}}>
                                                                        <div className="wpb_text_column wpb_content_element ">
                                                                            <div className="wpb_wrapper">
                                                                                <div id="yith-wcwl-messages"></div>
                                                                                <form id="yith-wcwl-form" className="woocommerce">
                                                                                    <table className="shop_table cart wishlist_table" data-pagination="no" data-per-page={5} data-page={1} data-id data-token>
                                                                                            <thead>
                                                                                            <tr>
                                                                                                <th className="product-remove" />
                                                                                                <th className="product-thumbnail" />
                                                                                                <th className="product-name">
                                                                                                <span className="nobr">Product Name</span>
                                                                                                </th>
                                                                                                <th className="product-price">
                                                                                                    <span className="nobr">
                                                                                                        Color
                                                                                                    </span>
                                                                                                </th>
                                                                                                <th className="product-price">
                                                                                                    <span className="nobr">
                                                                                                        Size
                                                                                                    </span>
                                                                                                </th>
                                                                                                <th className="product-price">
                                                                                                    <span className="nobr">
                                                                                                        Price    
                                                                                                    </span>
                                                                                                </th>
                                                                                                <th className="product-stock-status">
                                                                                                    <span className="nobr">
                                                                                                        Stock Status 
                                                                                                    </span>
                                                                                                </th>
                                                                                                <th className="product-add-to-cart" />
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    wishlistItems.length > 0 ?  
                                                                                                        wishlistItems?.map((wishlistItem) => <tr id="yith-wcwl-row-74" key={wishlistItem?.product}>
                                                                                                            <td className="product-remove">
                                                                                                                <div>
                                                                                                                    <Link 
                                                                                                                        href="javascript:;"
                                                                                                                        className="remove remove_from_wishlist" 
                                                                                                                        title="Remove this product"
                                                                                                                        onClick={() => dispatch(removeFromWishlist(wishlistItem?.product))}
                                                                                                                    >×</Link>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="product-thumbnail">
                                                                                                                <Link
                                                                                                                    to={{
                                                                                                                        pathname: `/product/${wishlistItem?.category}/${wishlistItem?.title}`,
                                                                                                                        state: {
                                                                                                                        id: wishlistItem?.id,
                                                                                                                        }
                                                                                                                    }} 
                                                                                                                >
                                                                                                                    <img src={`${apiUrl}/images/${wishlistItem?.image}`} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="f" /> 
                                                                                                                </Link>
                                                                                                            </td>
                                                                                                            <td className="product-name">
                                                                                                                <Link
                                                                                                                    to={{
                                                                                                                        pathname: `/product/${wishlistItem?.category}/${wishlistItem?.title}`,
                                                                                                                        state: {
                                                                                                                        id: wishlistItem?.id,
                                                                                                                        }
                                                                                                                    }}
                                                                                                                >{wishlistItem?.title}</Link>
                                                                                                            </td>
                                                                                                            <td className="product-price">
                                                                                                                <span className="woocommerce-Price-amount amount">
                                                                                                                    <span className="woocommerce-Price-currencySymbol">{wishlistItem?.color}</span>
                                                                                                                </span> 
                                                                                                            </td>
                                                                                                            <td className="product-price">
                                                                                                                <span className="woocommerce-Price-amount amount">
                                                                                                                    <span className="woocommerce-Price-currencySymbol">{wishlistItem?.size}</span>
                                                                                                                </span> 
                                                                                                            </td>
                                                                                                            <td className="product-price">
                                                                                                                <span className="woocommerce-Price-amount amount">
                                                                                                                    <span className="woocommerce-Price-currencySymbol">{wishlistItem?.price}€</span>
                                                                                                                </span> 
                                                                                                            </td>
                                                                                                            <td className="product-stock-status">
                                                                                                                <span className="wishlist-in-stock">In Stock</span>
                                                                                                            </td>
                                                                                                            <td className="product-add-to-cart">
                                                                                                                <a 
                                                                                                                    href="javascript:;"  
                                                                                                                    id="wishlist-button" 
                                                                                                                    className="button product_type_simple add_to_cart_button ajax_add_to_cart add_to_cart button alt" 
                                                                                                                    // onClick={() => openModalCart(wishlistItem?.id, 1, wishlistItem?.color)}
                                                                                                                    onClick={() => openModalCart(wishlistItem?.id, 1, wishlistItem?.color, wishlistItem?.size, wishlistItem?.countInStock, wishlistItem?.sizeDetailId )}
                                                                                                                > Add to Cart</a>
                                                                                                            </td>
                                                                                                        </tr>)

                                                                                                    : 
                                                                                                        <tr id="yith-wcwl-row-2830">
                                                                                                            <td colspan="16" className="wishlist-empty">No products were added to the wishlist</td>
                                                                                                        </tr>
                                                                                                }
                                                                                               
                                                                                            </tbody>
                                                                                            <tfoot>
                                                                                                <tr>
                                                                                                    <td colSpan={6}>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tfoot>
                                                                                        </table>
                                                                                    <input type="hidden" id="yith_wcwl_edit_wishlist" name="yith_wcwl_edit_wishlist" defaultValue="7d5872e87e" />
                                                                                    <input type="hidden" name="_wp_http_referer" />
                                                                                    <input type="hidden" defaultValue name="wishlistid" id="wishlistid" />
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="eltdf-elements-holder-background-outer">
                                                                    <div className="eltdf-elements-holder-background-inner"></div>
                                                                </div>
                                                                <AddToCartModal  />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div> */}
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <SearchBar />
                                <div className="eltdf-full-width">
                                    <div className="eltdf-full-width-inner">
                                        <div className="eltdf-grid-row">
                                            <div className="eltdf-page-content-holder eltdf-grid-col-12">
                                                <div className="eltdf-row-grid-section-wrapper">
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner vc_custom_1537357253521 first_part_padding_bottom">
                                                                    <div className="wpb_wrapper">                                                                        
                                                                        <div className="woocommerce">
                                                                            <div className="woocommerce-notices-wrapper"></div> 
                                                                            {   wishlistItems?.length > 0 ?
                                                                                <form className="woocommerce-cart-form desktop-cart"> 
                                                                                    <h2>Wishlist</h2> 
                                                                                    <table className="shop_table shop_table_responsive cart woocommerce-cart-form__contents" cellSpacing={0}> 
                                                                                        <thead> 
                                                                                            <tr> 
                                                                                                <th className="product-thumbnail">&nbsp;</th> 
                                                                                                <th className="product-name">Product</th> 
                                                                                                <th className="product-price">Price</th> 
                                                                                                <th className="product-quantity">Quantity</th> 
                                                                                                <th className="product-subtotal">Total</th> 
                                                                                                <th className="product-remove">&nbsp;</th> 
                                                                                            </tr> 
                                                                                        </thead> 
                                                                                        <tbody> 
                                                                                            {
                                                                                                wishlistItems?.map((wishlistItem) => <tr className="woocommerce-cart-form__cart-item cart_item" key={wishlistItem?.product}> 
                                                                                                <td className="product-thumbnail"> 
                                                                                                    <Link to={`/product/${wishlistItem?.id}`}
                                                                                                    > 
                                                                                                        <img src={`${apiUrl}/images/${wishlistItem?.image}`} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="f" /> 
                                                                                                    </Link> 
                                                                                                </td> 
                                                                                                <td className="product-name" data-title="Product">
                                                                                                    <Link 
                                                                                                        to={`/product/${wishlistItem?.id}`}
                                                                                                    >{wishlistItem?.title}</Link> 
                                                                                                    <div className="product-price" data-title="Price"> 
                                                                                                        <span className="woocommerce-Price-amount amount">
                                                                                                            <span className="woocommerce-Price-currencySymbol">{wishlistItem?.price?.toFixed(2)}€</span>
                                                                                                        </span> 
                                                                                                    </div>
                                                                                                    <div className="product-price" data-title="Price"> 
                                                                                                        <span className="woocommerce-Price-amount amount">
                                                                                                            <span className="woocommerce-Price-currencySymbol">Size: {wishlistItem?.size}</span>
                                                                                                        </span> 
                                                                                                    </div>
                                                                                                    <div className="product-price" data-title="Price"> 
                                                                                                        <span className="woocommerce-Price-amount amount">
                                                                                                            <span className="woocommerce-Price-currencySymbol">Color: {wishlistItem?.color}</span>
                                                                                                        </span> 
                                                                                                    </div>   
                                                                                                </td> 

                                                                                                <td className="add-to-cart-wishlist_ab11">
                                                                                                    <a 
                                                                                                        href="javascript:;"  
                                                                                                        id="wishlist-button" 
                                                                                                        className="alt" 
                                                                                                        onClick={() => openModalCart(wishlistItem?.id, 1, wishlistItem?.color, wishlistItem?.size, wishlistItem?.countInStock, wishlistItem?.sizeDetailId )}
                                                                                                    > Add to Cart</a>
                                                                                                </td> 

                                                                                                <td className="add-to-cart-wishlist_ab11">
                                                                                                    <Link 
                                                                                                        to="/checkout"  
                                                                                                        id="wishlist-button" 
                                                                                                        className="alt" 
                                                                                                    > Buy Now</Link>
                                                                                                </td> 
 
                                                                                                <td className="product-remove"> 
                                                                                                    <Link 
                                                                                                        href="javascript:;;" 
                                                                                                        className="remove" 
                                                                                                        aria-label="Remove this item"
                                                                                                        onClick={() => dispatch(removeFromWishlist(wishlistItem?.product))}
                                                                                                    >×</Link> 
                                                                                                </td> 
                                                                                             </tr> 
                                                                                                        
                                                                                            )}
                                                                                        </tbody> 
                                                                                    </table> 
                                                                                </form> 
                                                                            : 
                                                                                <div>
                                                                                    <p className="cart-empty">Your wishlist is currently empty.</p> 
                                                                                        <p className="return-to-shop">
                                                                                            <Link className="button-cart-empty wc-backward" to="/new">
                                                                                                Return to shop 
                                                                                            </Link>
                                                                                        </p>
                                                                                </div>
                                                                            }

                                                                            {
                                                                                wishlistItems?.length > 0
                                                                                ?
                                                                                <div id="cartMobile">
                                                                                    <div className="shopping-cart-mobile">
                                                                                        <div className="title">
                                                                                            <h2>Wishlist</h2>
                                                                                        </div>
                                                                                        {
                                                                                            wishlistItems?.map(wishlistItem => <div className="item">
                                                                                            <div className="buttons">
                                                                                                <Link 
                                                                                                    href="javascript:;;" 
                                                                                                    className="delete-btn" 
                                                                                                    aria-label="Remove this item"
                                                                                                    onClick={() => dispatch(removeFromWishlist(wishlistItem?.product))}
                                                                                                ></Link> 
                                                                                            </div>
                                                                                            <div className="image">
                                                                                                <Link
                                                                                                    to={`/product/${wishlistItem?.id}`}
                                                                                                >
                                                                                                    <img src={`${apiUrl}/images/${wishlistItem?.image}`}  alt="cart-item image" />
                                                                                                </Link>
                                                                                            </div>
                                                                                            <div className="description">
                                                                                                <Link 
                                                                                                    to={`/product/${wishlistItem?.id}`}
                                                                                                >{wishlistItem?.title}</Link>
                                                                                                <Link 
                                                                                                    to={`/product/${wishlistItem?.id}`}
                                                                                                >{wishlistItem?.subtitle}
                                                                                                </Link>                                                                                                                                                                                      
                                                                                                <Link 
                                                                                                    to={`/product/${wishlistItem?.id}`}
                                                                                                >Color: {wishlistItem?.color}</Link>
                                                                                                <Link 
                                                                                                    to={`/product/${wishlistItem?.id}`}
                                                                                                >Size: {wishlistItem?.size}</Link>                                                                                           
                                                                                            </div>
                                                                                            <div className="cart-mobile-quantity">
                                                                                                <a 
                                                                                                    href="javascript:;"  
                                                                                                    id="wishlist-button" 
                                                                                                    className="alt" 
                                                                                                    // onClick={() => openModalCart(wishlistItem?.id, 1, wishlistItem?.color)}
                                                                                                    onClick={() => openModalCart(wishlistItem?.id, 1, wishlistItem?.color, wishlistItem?.size, wishlistItem?.countInStock, wishlistItem?.sizeDetailId )}
                                                                                                > Add to Cart</a>
                                                                                            </div>
                                                                                            <div className="cart-mobile-quantity">
                                                                                                <Link 
                                                                                                    to="/checkout"  
                                                                                                    id="wishlist-button" 
                                                                                                    className="alt" 
                                                                                                > 
                                                                                                    Buy Now
                                                                                                </Link>  
                                                                                            </div>
                                                                                            <div className="total-price">{wishlistItem?.price?.toFixed(2)}€</div>
                                                                                        </div>
                                                                                    )}
                                                                                    </div>          
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <Footer />
        </section>
    )
}