import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const ordersList = () => async (dispatch, getState) => {
    try {
        dispatch({  type: 'ORDERS_LIST_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        
        const { data } = await axios.get(`${apiUrl}/orders`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'ORDERS_LIST_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'ORDERS_LIST_FAIL', payload: error });
    }
}

export const createOrder = (dataa) => async (dispatch) => {
    try {
        dispatch({  type: 'ORDER_CREATE_REQUEST' });
        
        const { data } = await axios.post(`${apiUrl}/orders`, dataa);
        dispatch({ type: 'ORDER_CREATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'ORDER_CREATE_FAIL', payload: error });
    }
}

export const updateOrderStatus = (id,status) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'ORDER_UPDATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        
        const { data } = await axios.patch(`${apiUrl}/orders/${id}/${status}`, {}, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'ORDER_UPDATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'ORDER_UPDATE_FAIL', payload: error });
    }
}

export const deleteOrder = (id) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'ORDER_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/orders/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'ORDER_DELETE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'ORDER_DELETE_FAIL', payload: error });
    }
}   