import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colorsList } from "../../../actions/color_actions";
import { updateProductDetailssss } from '../../../actions/product_actions';
import { clear } from '../../../actions/clear_action';

import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import crypto from "crypto";

import { apiUrl } from '../../../constants/backendUrl';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import InputColor from 'react-input-color';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function UpdateSizeProductDetails(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { update_success, error } = useSelector(state => state.update_product_details);

    const [loading, setLoading] = useState(false);

    const [sku, setSku] = useState(null);
    const [images, setImages] = useState([]);
    const [size, setSize] = useState('');
    const [countInStock, setCountInStock] = useState('');

    const { id } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();

        // setLoading(true);
        
        const formData = new FormData();
        formData.append('productId', props?.location?.state?.productId);
        formData.append('sizeObjId', props?.location?.state?.sizeObjId);
        formData.append('size', size);
        formData.append('countInStock', countInStock);
        
        // if(id) {
        //     dispatch(updateProductDetailssss(id, size, countInStock));
        // }

        if(id) {
            axios.put(`${apiUrl}/products/size-details/${id}`, {productId: props?.location?.state?.productId, sizeObjId: props?.location?.state?.sizeObjId,  id, size, countInStock}, {
                headers:
                    { Authorization: 'Bearer ' + userInfo?.token }
            }).then((res) => {
              if (res.data.success) {
                Toastr.success("Produkti u editua me sukses.");
                setLoading(false);
                history.push(`/admin/size-details-products-list/${props?.location?.state?.productId}`);
                dispatch(clear());
                clearInputs();
              } else {
                Toastr.error("Produkti nuk u editua.Provo përsëri.");
              }
            }).catch(error => console.log('Error: ', error?.message));
        }
    };

    const clearInputs = () => {
       setColorName('');
       setColorHex('');
       setSku('');
    }

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        if(id) {
            axios.get(`${apiUrl}/products/by-details/${props?.location?.state?.productId}/${id}`).then(res => {
                setSize(res?.data?.product?.availableOptions[0]?.sizeObj[0].size);
                setCountInStock(res?.data?.product?.availableOptions[0]?.sizeObj[0].countInStock);
            }).catch(error => console.log(error?.response?.data?.message));
        }

        dispatch(colorsList());
        if(update_success) {
            Toastr.success('Product Details u editua me sukses.');
            history.push(`/admin/details-products-list/${props?.location?.state?.productId}`);
            dispatch(clear()); //per me bo clear staten.
        }
      }, [update_success]);

    return (
        <div>
           <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>Edito Produktin</h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        error ? <div className="col-sm-12 mt-3" style={{textAlign: 'center'}}>
                            <p className="text-danger"> {error}</p>
                        </div>
                        :
                        null
                    }
        
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">Edito Produktin</h3>
                                        </div>

                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="sku" className="form-label"> Size </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="sku"
                                                        placeholder="Size"
                                                        required
                                                        value={size}
                                                        onChange={(e) => setSize(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="sku" className="form-label"> Sasia </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="sku"
                                                        placeholder="SKU"
                                                        required
                                                        value={countInStock}
                                                        onChange={(e) => setCountInStock(e.target.value)}
                                                    />
                                                </div>

                                                <div className="card-footer">
                                                    {
                                                        loading ?
                                                        <button class="btn btn-danger" type="button" disabled>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span> Loading...</span>
                                                        </button>
                                                        :
                                                        <button type="submit" className="btn btn-danger" onClick={handleSubmit}>
                                                            <span>Edito</span>
                                                        </button>
                                                    }
                                                
                                                </div>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}