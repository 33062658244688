import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './style.css';

export default function SearchBar(props) {
    const [search, setSearch] = useState("");
    const history = useHistory();

    function closeSearchBar() {
        var element = document.getElementById("search_bar");
        element.style.visibility = 'hidden';
        element.style.opacity = '0';

        var mobile_header = document.getElementById("eltdf-mobile-header");
        mobile_header.style.zIndex = '110';
    }

    function searchProduct(e) {
        e.preventDefault();
        
        if(search) {
            var element = document.getElementById("search_bar");
            element.style.visibility = 'hidden';
            element.style.opacity = '0';
            
            history.push(`/shop/${search}`);
        }
    }

    return (
        <div className="eltdf-fullscreen-search-holder eltdf-animate" id="search_bar">
            <a className="eltdf-search-close eltdf-search-close-svg-path" onClick={closeSearchBar} href="javascript:void(0)">
                <svg x="0px" y="0px" width="17px" height="16px" viewBox="-0.26 -0.512 17 16" enableBackground="new -0.26 -0.512 17 16" xmlSpace="preserve" style={{}}>
                    <line stroke="currentColor" strokeMiterlimit={10} x2="0.583" y2="14.593" x1="15.895" y1="0.353" />
                    <line stroke="currentColor" strokeMiterlimit={10} x2="15.896" y2="14.593" x1="0.584" y1="0.353" />
                </svg> 
            </a>

            <div className="eltdf-fullscreen-search-table">
                <div className="eltdf-fullscreen-search-cell">
                    <div className="eltdf-fullscreen-search-inner">
                        <form className="eltdf-fullscreen-search-form" onSubmit={searchProduct}>
                            <div className="eltdf-form-holder">
                                <div className="eltdf-form-holder-inner">      
                                    <div className="eltdf-field-holder">
                                        <input 
                                            type="text" 
                                            placeholder="Search..." 
                                            className="eltdf-search-field" 
                                            autoComplete="off"
                                            style={{ borderBottom: 'none' }}
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value.toLowerCase())}
                                        />
                                    </div>
                                    <button type="submit" className="eltdf-search-submit eltdf-search-submit-svg-path">
                                        <svg x="0px" y="0px" width="16.458px" height="16.5px" viewBox="0 0 16.458 16.5" enableBackground="new 0 0 16.458 16.5" xmlSpace="preserve">
                                            <path fill="currentColor" d="M16.239,6.75c0,1.792-0.636,3.323-1.906,4.593c-1.271,1.271-2.803,1.906-4.594,1.906c-1.521,0-2.886-0.479-4.094-1.438
                                                l-4.281,4.281c-0.125,0.146-0.287,0.219-0.484,0.219c-0.198,0-0.365-0.067-0.5-0.203c-0.136-0.135-0.203-0.302-0.203-0.5
                                                c0-0.197,0.073-0.359,0.219-0.484l4.281-4.281C3.718,9.636,3.239,8.271,3.239,6.75c0-1.792,0.635-3.323,1.906-4.594
                                                C6.416,0.886,7.947,0.25,9.739,0.25c1.791,0,3.322,0.636,4.594,1.906C15.604,3.427,16.239,4.958,16.239,6.75z M15.239,6.75
                                                c0-1.521-0.537-2.817-1.609-3.891C12.557,1.787,11.26,1.25,9.739,1.25c-1.521,0-2.818,0.537-3.891,1.609
                                                C4.775,3.933,4.239,5.229,4.239,6.75c0,1.521,0.536,2.818,1.609,3.89c1.072,1.074,2.369,1.609,3.891,1.609
                                                c1.521,0,2.817-0.535,3.891-1.609C14.702,9.568,15.239,8.271,15.239,6.75z" />
                                        </svg> 
                                    </button>
                                    <div className="eltdf-line"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}