function listTransportBannerHomeReducer(state = { transportsBannerHome: [] }, action) {
    switch (action.type) {
        case 'TRANSPORT_BANNER_HOME_LIST_REQUEST':
            return { loading: true }
        case 'TRANSPORT_BANNER_HOME_LIST_SUCCESS':
            return { loading: false, transportsBannerHome: action?.payload }
        case 'TRANSPORT_BANNER_HOME_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addTransportBannerHomeReducer(state = {}, action) {
    switch (action.type) {
        case 'TRANSPORT_BANNER_HOME_CREATE_REQUEST':
            return { loading: true }
        case 'TRANSPORT_BANNER_HOME_CREATE_SUCCESS':
            return { loading: false, transportBannerHome: action?.payload, success: true }
        case 'TRANSPORT_BANNER_HOME_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateTransportBannerHomeReducer(state = { transportBannerHome: {} }, action) {
    switch(action.type) {
        case 'TRANSPORT_BANNER_HOME_UPDATE_REQUEST':
            return { loading: true }
        case 'TRANSPORT_BANNER_HOME_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'TRANSPORT_BANNER_HOME_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteTransportBannerHomeReducer(state = { transportBannerHome: {} }, action) {
    switch(action.type) {
        case 'TRANSPORT_BANNER_HOME_DELETE_REQUEST':
            return { loading: true }
        case 'TRANSPORT_BANNER_HOME_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'TRANSPORT_BANNER_HOME_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listTransportBannerHomeReducer, addTransportBannerHomeReducer, updateTransportBannerHomeReducer, deleteTransportBannerHomeReducer }