import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productsList } from '../../actions/product_actions';
import { Link } from 'react-router-dom';
import { apiUrl } from "../../constants/backendUrl";

import "./style.css";

export default function NewProducts(props) {
    const { products } = useSelector(state => state.products_list);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productsList());
  
        return () => {
        }
    }, []);

    return (
        <section id="new-products">
            {
                products?.products?.slice(Math.max(products?.products?.length - 4, 0))?.map((product) => <div className="product-item-space" key={product?._id}>
                    <Link 
                        to={`/product/${product?._id}`} 
                    >
                        <div className="product-pli-inner">
                            <div className="product-pli-image">
                                <img src={`${apiUrl}/images/${product?.availableOptions[0]?.images[0]}`} className="attachment-full size-full wp-post-image" alt="m" />
                            </div>
                        </div>

                        <div className="product-pli-text-wrapper">
                            <h5  className="entry-title eltdf-pli-title" id="new_Products_title">
                                <Link 
                                   to={`/product/${product?._id}`}
                                >{product?.title}</Link>
                            </h5>
                            <p className="eltdf-pli-category">{product?.subtitle}</p>
                            {/* <p className="eltdf-pli-category price-products"><span className="woocommerce-Price-currencySymbol">{product?.price?.toFixed(2)}</span>€</p> */}
                            <span className="price" style={{color: '#A06464'}}>
                                {
                                    product?.discountPrice && 
                                    <a style={{ marginRight: '8px' }}>
                                        {product?.discountPrice?.toFixed(2)}€
                                    </a>
                                }
                                <Link                                                                     
                                    to={`/product/${product?._id}`}
                                    style={ product?.discountPrice ? { color: '#acabab', textDecoration: 'line-through' } : { color: '#A06464' } }
                                    rel="tag"
                                >{product?.price?.toFixed(2)}€</Link>
                            </span>
                        </div>
                    </Link>
                </div>
            )}
        </section>
    );
}
