/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bannerNewList } from '../../actions/bannerNew_actions';
import { newCollectionBannerList } from '../../actions/newCollectionBanner_actions'
import newCollection from '../../images/new_collection.png';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import NewProducts from '../NewProducts/newProducts';
import Products from '../Products/Products';
import SearchBar from '../SearchBar/SearchBar';
import Loader
 from '../Loader/Loader';
import './style.css';

export default function New(props) {
    const { newBanners, loading: newBannersLoading } = useSelector(state => state.banner_new_list);
    const { newCollectionBanners, loading: newCollectionBannersLoading } = useSelector(state => state.newCollectionBanner_list);
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(bannerNewList());
        dispatch(newCollectionBannerList());

    }, []);

    return (
       newBannersLoading
       ||
       newCollectionBannersLoading
       ?
       <Loader />
       :
       <section>
        <TopHeader />
        {
            newBanners?.slice(-1)?.map(newBanner =>  <Slider image = { newBanner?.image } link={newBanner?.link} />)
        }
        <Header />
        <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
            <div className="eltdf-wrapper">
                <div className="eltdf-wrapper-inner">
                    <SearchBar />
                    <div className="eltdf-content">
                        <div className="eltdf-content-inner">
                            <div className="eltdf-full-width">
                                <div className="eltdf-full-width-inner">
                                    <div className="eltdf-grid-row">
                                        <div className="eltdf-page-content-holder eltdf-grid-col-12">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1538061460680 eltdf-content-aligment-left">
                                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-md-7" style={{float: 'left'}}>
                                                    <div className="vc_column-inner">
                                                        <div className="wpb_wrapper">
                                                            <div className="eltdf-elements-holder   eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                <div className="eltdf-eh-item eltdf-eh-expander eltdf-eh-expander-from-left eltdf-eh-custom-1288 eltdf-eh-expander-on">
                                                                    <div className="eltdf-eh-item-inner">
                                                                        <div className="eltdf-eh-item-content" style={{padding: '2px 0px 80px 246px'}}>
                                                                            <div className="wpb_single_image wpb_content_element vc_align_left">
                                                                                <figure className="wpb_wrapper vc_figure">
                                                                                    <div className="vc_single_image-wrapper vc_box_border_grey">
                                                                                        {
                                                                                            newCollectionBanners?.slice(-1)?.map(newCollection => <img src={newCollection?.image} className="vc_single_image-img attachment-full" alt="m" />)
                                                                                        }
                                                                                    </div>
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="eltdf-elements-holder-background-outer" style={{padding: '70px 33px 0 0px'}} data-769-1024="36px 0px 0 0px">
                                                                        <div className="eltdf-elements-holder-background-inner" style={{backgroundColor: 'rgb(255, 244, 243)'}} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-md-5" style={{float: 'left'}}>
                                                    <div className="vc_column-inner vc_custom_1538062551467">
                                                        <div className="wpb_wrapper">
                                                            <div className="eltdf-elements-holder eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                <div className="eltdf-eh-item eltdf-eh-custom-1548">
                                                                    <div className="eltdf-eh-item-inner">
                                                                        <div className="eltdf-eh-item-content" style={{padding: '10px 114px 0 8px'}}>
                                                                            <NewProducts />
                                                                        </div>
                                                                    </div>
                                                                    <div className="eltdf-elements-holder-background-outer">
                                                                        <div className="eltdf-elements-holder-background-inner" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="eltdf-row-grid-section-wrapper">
                                                <div className="eltdf-row-grid-section">
                                                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521" style={{ marginTop: '130px' }}>
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="eltdf-elements-holder eltdf-one-column  eltdf-responsive-mode-768">
                                                                        <div className="eltdf-eh-item" data-item-class="eltdf-eh-custom-6424">
                                                                            <div className="eltdf-eh-item-inner">
                                                                                <div className="eltdf-eh-item-content eltdf-eh-custom-6424">
                                                                                    <div className="eltdf-product-exhibition">
                                                                                        <div className="eltdf-pe-item" className="follow_us_instagram_padding">
                                                                                            <div className="eltdf-pe-background">
                                                                                                <div className="eltdf-pe-background-image">
                                                                                                    <img src={newCollection} className="attachment-full size-full" alt="s" style={{ margin: '0 auto' }} /> 
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> 
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Products />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </section>
    )
}