import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const categoriesBannerHomeList = () => async (dispatch) => {
    try {
        dispatch({type: 'CATEGORIES_BANNER_LIST_REQUEST'});
        const categoriesBannerHomeData = await axios.get(`${apiUrl}/categoriesBannerHome`);
        dispatch({ type: 'CATEGORIES_BANNER_LIST_SUCCESS', payload: categoriesBannerHomeData?.data });
    } catch(error) {
        dispatch({ type: 'CATEGORIES_BANNER_LIST_FAIL', payload: error });
    }
}

export const createCategoriesBannerHome = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'CATEGORIES_BANNER_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        formData.append('image1', data.image1, data.image1.name);
        formData.append('title1', data.title1);
        formData.append('image2', data.image2, data.image2.name);
        formData.append('title2', data.title2);
        formData.append('image3', data.image3, data.image3.name);
        formData.append('titl3', data.title3);

        const categoriesBannerHomeData = await axios.post(`${apiUrl}/categoriesBannerHome`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CATEGORIES_BANNER_CREATE_SUCCESS', payload: categoriesBannerHomeData?.data });
    } catch(error) {
        dispatch({ type: 'CATEGORIES_BANNER_CREATE_FAIL', payload: error?.response?.data?.message });
    }
}

export const updateCategoriesBannerHome = (id,data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'CATEGORIES_BANNER_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();
        const formData = new FormData();
        data.image1 instanceof Blob && formData.append('image1', data.image1, data.image1.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
        formData.append('title1', data.title1);
        data.image2 instanceof Blob && formData.append('image2', data.image2, data.image2.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
        formData.append('title2', data.title2);
        data.image3 instanceof Blob && formData.append('image3', data.image3, data.image3.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
        formData.append('title3', data.title3);

        const categoriesBannerHomeData = await axios.patch(`${apiUrl}/categoriesBannerHome/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CATEGORIES_BANNER_UPDATE_SUCCESS', payload: categoriesBannerHomeData?.data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'CATEGORIES_BANNER_UPDATE_FAIL', payload: error?.response?.data?.message  });
    }
}

export const deleteCategoriesBannerHome = (categoriesId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CATEGORIES_BANNER_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const {data} = await axios.delete(`${apiUrl}/categoriesBannerHome/${categoriesId}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CATEGORIES_BANNER_DELETE_SUCCESS', payload: data });

    } catch(error) {
        console.log(error);
        dispatch({ type: 'CATEGORIES_BANNER_DELETE_FAIL', payload: error });
    }
}
