import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCategoriesBannerHome, updateCategoriesBannerHome } from '../../../actions/categoriesBannerHome_actions';
import { categoriesList } from '../../../actions/category_actions';
import { clear } from '../../../actions/clear_action';
import { useHistory, useParams } from 'react-router-dom';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { apiUrl } from '../../../constants/backendUrl';
import axios from 'axios';
import { useFormik } from 'formik';
import imageCompression from 'browser-image-compression';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddProduct2(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { success, loading: loadingCreate, error: errorCreateCategoriesHome } = useSelector(state => state.add_categoriesBannerHome);
    const { update_success, loading, error: errorUpdateCategoriesHome } = useSelector(state => state.update_categoriesBannerHome);
    const { categories } = useSelector(state => state.categories_list);

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const validate = values => {
        const errors = {};
        if (!values.image1) {
          errors.image1 = 'This field is required';
        }
        if (!values.image2) {
            errors.image2 = 'This field is required';
          }
        if (!values.image3) {
            errors.image3 = 'This field is required';
        }
        if (!values.title1) {
            errors.title1 = 'This field is required';
        }
        if (!values.title2) {
            errors.title2 = 'This field is required';
        }
        if (!values.title3) {
            errors.title3 = 'This field is required';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
          image1: '',
          image2: '',
          image3: '',
          title1: '',
          title2: '',
          title3: ''
        },
        validate,
        onSubmit: (values, {resetForm}) => {
          if(id) {
            dispatch(updateCategoriesBannerHome(id, values));
            // resetForm();
          } else {
            dispatch(createCategoriesBannerHome(values));
          }
        },
    });

    const handleImageUpload = async (e) => {
        const imageFile = e.target.files[0];
      
        const options = {
        //   maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);      
          formik.setFieldValue([e.target.name], compressedFile);
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }
        dispatch(categoriesList());

        if(id) {
            axios.get(`${apiUrl}/categoriesBannerHome/${id}`, {
                headers:
                  { Authorization: 'Bearer ' + userInfo?.token }
            })
            .then(response => {
                formik.setFieldValue('image1', response.data.image1);
                formik.setFieldValue('image2', response.data.image2);
                formik.setFieldValue('image3', response.data.image3);
                formik.setFieldValue('title1', response.data.title1);
                formik.setFieldValue('title2', response.data.title2);
                formik.setFieldValue('title3', response.data.title3);
          }).catch(error => console.log(error));
        }
        if(success) {
            Toastr.success('Categories Home u krijua me sukses.');
            history.push('/admin/categories-home-list');
            dispatch(clear()); //per me bo clear staten.
        }
        if(update_success) {
            Toastr.success('Categories Home u editua me sukses.');
            history.push('/admin/categories-home-list');
            dispatch(clear()); //per me bo clear staten.
        }
    }, [success, update_success]);

    return (
        <div>
            <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>{id ? 'Edito Categories Banner Home' : 'Krijo Categories Banner Home'}</h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        errorCreateCategoriesHome ? <div className="col-sm-12 mt-3" style={{textAlign: 'center'}}>
                            <p className="text-danger"> {errorCreateCategoriesHome}</p>
                        </div>
                        :
                        null
                    }

                    {
                        errorUpdateCategoriesHome ? <div className="col-sm-12 mt-3" style={{textAlign: 'center'}}>
                            <p className="text-danger"> {errorUpdateCategoriesHome}</p>
                        </div>
                        :
                        null
                    }
        
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">{id ? 'Edito Categories Banner Home' : 'Krijo Categories Banner Home'}</h3>
                                        </div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="categories-banner-home">Zgjidh foton</label>
                                                    <input 
                                                        name="image1"  
                                                        className="form-control" 
                                                        id="categories-banner-home"
                                                        type="file"  
                                                        accept=".png, .jpg, .jpeg"
                                                        onChange={(e) => handleImageUpload(e)}                                                        
                                                    />
                                                    {formik.errors.image1 ? <div className="text-danger">{formik.errors.image1}</div> : null}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="category">Title1</label>
                                                    <select
                                                        className="form-select"
                                                        name="title1"
                                                        id="category"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.title1} 
                                                        >
                                                        <option selected>Selekto Kategorin</option>
                                                        {categories && categories.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                    {formik.errors.title1 ? <div className="text-danger">{formik.errors.title1}</div> : null}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="card-home">Zgjidh foton</label>
                                                    <input 
                                                        name="image2"  
                                                        className="form-control" 
                                                        id="card-home"
                                                        type="file"  
                                                        accept=".png, .jpg, .jpeg"
                                                        onChange={(e) => handleImageUpload(e)}                                                      
                                                    />
                                                    {formik.errors.image2 ? <div className="text-danger">{formik.errors.image2}</div> : null}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="category">Title2</label>
                                                    <select
                                                        className="form-select"
                                                        name="title2"
                                                        id="category"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.title2} 
                                                        >
                                                        <option selected>Selekto Kategorin</option>
                                                        {categories && categories.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                    {formik.errors.title2 ? <div className="text-danger">{formik.errors.title2}</div> : null}
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="card-home">Zgjidh foton</label>
                                                    <input
                                                        name="image3"  
                                                        className="form-control" 
                                                        id="card-home"
                                                        type="file"  
                                                        accept=".png, .jpg, .jpeg"
                                                        onChange={(e) => handleImageUpload(e)}    
                                                    />  
                                                    {formik.errors.image3 ? <div className="text-danger">{formik.errors.image3}</div> : null}                                             
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="category">Title3</label>
                                                    <select
                                                        className="form-select"
                                                        name="title3"
                                                        id="category"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.title3} 
                                                        >
                                                        <option selected>Selekto Kategorin</option>
                                                        {categories && categories.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                    {formik.errors.title3 ? <div className="text-danger">{formik.errors.title3}</div> : null}
                                                </div>

                                            </div>
                                            <div className="card-footer">
                                                {
                                                    loading || loadingCreate ?
                                                    <button class="btn btn-danger" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span> Loading...</span>
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-danger" disabled={!(formik.isValid && formik.dirty)}>
                                                        <span>{id ? 'Edito' : 'Shto'}</span>
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}