import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { productsList } from '../../actions/product_actions';
import { addToCart } from '../../actions/cart_actions';
import { addToWishlist } from '../../actions/wishlist_actions';
import { apiUrl } from "../../constants/backendUrl";
import axios from 'axios';

import RelatedProducs from '../../images/Related Products.png';
import Modal from '../OpenModal/OpenModal';
import AddToCartModal from '../AddToCartModal/AddToCartModal.js';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import './style.css';

export default function RelatedProducts(props) {
    const [product, setProduct] = useState({});
    const [products, setProducts] = useState([]);
    const [qty, setQty] = useState(1);
    const [openCartModal, setCartModal] = useState('');

    const { wishlistItems } = useSelector(state => state.wishlist);

    const dispatch = useDispatch();
    const location = useLocation();

    function openModal(product) {
        var element = document.getElementById("modal_box409"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
        var header = document.getElementById("eltdf-page-header");
        var mobile_header = document.getElementById("eltdf-mobile-header");
        
        header.style.zIndex = '0';
        mobile_header.style.zIndex = '0';
        element.style.display = 'block';
        element.style.background = 'rgba( 0, 0, 0, 0.8)';
        
        setProduct(product); //m vyn me dit cilin produkt e kom kliku e ate me dergu si props tek OpenModal.js ne menyre qe me i shfaq t dhanat e produktit aty.
    }

    function openModalCart(id, quantity, color) {
        dispatch(addToCart(id, quantity, color));

        var element = document.getElementById("cart-modal-right-side"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
        var elementOverlay = document.getElementById("la-overlay-global"); //per me shfaq opacity pasi t hapet modali i add cartes
        var header = document.getElementById("eltdf-page-header");
        header.style.zIndex = '0';
        elementOverlay.classList.add('la-overlay-global');
        setCartModal(element.classList.add("cart-flyout-open"));
    }

    function addWishlist(id, checkId, color) {
        const singleProduct = wishlistItems.find(x => x?.product === checkId);

        dispatch(addToWishlist(id, color));
          if(singleProduct?.product !== checkId) { //singleProduct.product -> sepse product eshte id e productit ashtu e kom lon  tek cart_actions-> product: data?._id
            Toastr.success('Produkti u shtua në wishlist.');
        } else {
            Toastr.error('Produkti ekziston në wishlist.');
        }
    }

    useEffect(() => {
        dispatch(productsList());
        axios.get(`${apiUrl}/products/by-color/${props.color}`).then(res => {
            if(res.data.success) {
                setProducts(res.data.products);
            }
        })
 
    }, [product, props.color]);

    return (
        <div id="related_products">
            <div className="product-template-default single single-product postid-74 onea-core-1.0 eltdf-social-login-1.0 woocommerce woocommerce-page woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-sticky-header-on-scroll-up eltdf-dropdown-animate-height eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woo-single-page eltdf-woo-single-thumb-on-left-side eltdf-woocommerce-columns-4 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-header-top-enabled eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive">
                <section className="related products">
                    <h2>
                        <img src={RelatedProducs} className="related-products-img-banner" alt="l" />
                    </h2>
                    
                    <ul className="products columns-4">
                        {
                            products?.slice(0,7)?.map((product) => <li className="post-174 product type-product status-publish has-post-thumbnail product_cat-basics product_tag-clothes product_tag-lifestyle first instock shipping-taxable purchasable product-type-simple">
                                <div className="eltdf-pl-inner">
                                    <div className="eltdf-pl-image">
                                        <img width={600} height={800} src={`${apiUrl}/images/${product?.availableOptions[0]?.images[0]}`} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="m" />
                                    </div>
                                    <Link 
                                        to={`/product/${product?._id}`}
                                        className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                                    ></Link>

                                    <div className="eltdf-pl-text">
                                        <div className="eltdf-pl-text-outer">
                                            <div className="eltdf-pl-text-inner">
                                                <div className="eltdf-pl-icons-holder">
                                                    <a href="javascript:void()" className="yith-quickview-button products-cart-button_1234098765" onClick={() => openModal(product)}></a>
                                                    <a href="javascript:;" className="yith-heart-button products-cart-button_1234098765" onClick={() => addWishlist(product?._id, product?.availableOptions[0]?._id, product?.availableOptions[0]?.color)}></a>
                                                    <div className="clear"></div>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>  
                                <div className="eltdf-pl-text-wrapper clearfix">
                                    <h5 className="eltdf-product-list-title">
                                        <Link 
                                           to={`/product/${product?._id}`}
                                        >{product?.title}</Link>
                                    </h5>
                                    <span className="eltdf-pl-category">
                                        <Link 
                                            to={`/product/${product?._id}`}
                                        >{product?.subtitle}</Link>
                                    </span>
                                    <span className="price">
                                        <span className="woocommerce-Price-amount amount">
                                            {
                                              product?.discountPrice && 
                                              <a style={{ marginRight: '8px', color: '#a06464' }}>
                                                  {product?.discountPrice?.toFixed(2)}€
                                              </a>
                                            }
                                            <span className="woocommerce-Price-currencySymbol" style={ product?.discountPrice ? { color: '#acabab', textDecoration: 'line-through' } : {} }>{product?.price?.toFixed(2)}</span>€ 
                                        </span>
                                    </span>
                                </div> 
                            </li>

                        )}

                    </ul>
                    <Modal product={product} />
                    <AddToCartModal />
                </section>
            </div>
        </div>
    )
}