import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const sizesList = () => async (dispatch) => {
    try {
        dispatch({  type: 'SIZE_LIST_REQUEST' });

        const { data } = await axios.get(`${apiUrl}/sizes`);
        dispatch({ type: 'SIZE_LIST_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'SIZE_LIST_FAIL', payload: error });
    }
}

export const createSize = (dataa) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'SIZE_CREATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        
        const { data } = await axios.post(`${apiUrl}/sizes`, dataa, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SIZE_CREATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'SIZE_CREATE_FAIL', payload: error });
    }
}

export const updateSize = (id,dataa) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'SIZE_UPDATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.patch(`${apiUrl}/sizes/${id}`, dataa, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SIZE_UPDATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'SIZE_UPDATE_FAIL', payload: error });
    }
}

export const deleteSize= (id) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'SIZE_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/sizes/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SIZE_DELETE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'SIZE_DELETE_FAIL', payload: error });
    }
}