import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { termsOfServiceList } from '../../actions/terms_of_service';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Loader from '../Loader/Loader';

const TermsOfService = (props) => {
    const terms_of_service_list = useSelector(state => state.terms_of_service_list);
    const { termsOfService, loading, error } = terms_of_service_list;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(termsOfServiceList());
        return () => {

        }
    },[])

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } }); //sepse description e kemi ne db psh <p>e prandaj duhet me bo decode, Implement HTML Entity Decode in react.js</p>

    return (
        <div>
            { loading || error && error.message ? <Loader /> :
                <>
                    <Header />
                    <section className="privacy_section"> 
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        
                                        renderHTML(termsOfService && termsOfService.length > 0 && termsOfService[0].description)
                                    }          
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </>
            }
        </div>
    )
}

export default TermsOfService;