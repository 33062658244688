import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import { logout } from '../../actions/user_actions';

export default function AsideMenu(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { wishlistItems } = useSelector(state => state.wishlist);
    const { cartItems } = useSelector(state => state.cart);

    const handleLogout =  async () => {
        if(userInfo) {
            dispatch(logout());
        }
    }

    return (
        <>
            <div className={`c-offcanvas-bg c-offcanvas-bg--full-fade c-offcanvas-bg--overlay ${props?.openAsideUserMenu ? 'is-open' : 'is-closed'}`}></div>

            <aside ref={props?.asideUserMenuRef } className={`js-offcanvas c-offcanvas c-offcanvas--full-fade c-offcanvas--overlay ${props?.openAsideUserMenu ? 'is-open' : 'is-closed'}`} id="mobile-header-canvas">
                <div className="offcanvas-content">
                    <div className="mobile-header-screen-inner">
                        <div className="mobile-header-title">
                            <h3>User Menu</h3>
                            <button data-focus className="js-offcanvas-close fisino-close-btn c-button c-button--m1 c-button--m2" onClick={() => props?.setOpenUserMenu(!props?.openAsideUserMenu)}>
                                <span className="c-button__text">
                                    <svg viewBox="0 0 16 16" className=" fm-icon fill">
                                        <use xlinkHref="#ico_close">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" id="ico_close">
                                            <path d="M14.17 0h1.35C53.06 37.52 90.45 75.18 128 112.69 165.55 75.18 202.94 37.51 240.48 0h1.36c4.73 4.7 9.43 9.44 14.16 14.15v.14c-37.69 37.8-75.43 75.54-113.06 113.39C180.6 165.5 218.31 203.26 256 241.05v.13c-4.89 4.98-9.86 9.88-14.78 14.82h-.12c-37.7-37.78-75.37-75.6-113.1-113.36C90.26 180.39 52.61 218.24 14.88 256h-.08c-4.95-4.92-9.89-9.85-14.8-14.81v-.16c37.7-37.77 75.42-75.54 113.06-113.38C75.39 89.85 37.69 52.08 0 14.29v-.13C4.73 9.44 9.43 4.7 14.17 0z" />
                                            </svg>
                                        </use>
                                    </svg>                            
                                    <span className="button-title">Close</span>
                                </span>
                            </button>
                        </div>
                        <nav className="mobile-navigation default-nav-menu">
                            <ul id="menu-main-menu-1" className="menu">                                
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-476 menu-level-0" key='1abcdefgh'>
                                    <NavLink to='/cart'>CART</NavLink>
                                    <span className="eltdf-cart-text-holder">
                                        <span className="eltdf-cart-quantity">
                                            { cartItems?.length }
                                        </span>
                                    </span>
                                </li>

                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-476 menu-level-0" key='2abcdefgh'>
                                    <NavLink to='/wishlist'>WISHLIST</NavLink>
                                    <span className="eltdf-cart-text-holder">
                                        <span className="eltdf-cart-quantity">
                                            { wishlistItems?.length }
                                        </span>
                                    </span>
                                </li>

                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-476 menu-level-0" key='2abcdefgh'>
                                    <NavLink to='/user/orders'>Orders</NavLink>
                                </li>

                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-476 menu-level-0" key='2abcdefgh'>
                                    <NavLink to='/user/edit/data'>Your Data</NavLink>
                                </li>
                                
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-476 menu-level-0">
                                {
                                    userInfo
                                    ?
                                    <Link to="/" className="top-level-link" onClick={handleLogout}>
                                        <span className="text-wrap">
                                        <i className="fas fa-sign-out-alt"></i> <span className="menu-text">Logout</span>
                                        </span>
                                    </Link>
                                    :
                                    <NavLink to="/login" className="top-level-link">
                                        <span className="text-wrap">
                                            <span className="menu-text">Login</span>
                                        </span>
                                    </NavLink>
                                }
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </aside>
        </>
    )
}