import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const newCollectionBannerList = () => async (dispatch) => {
    try {
        dispatch({  type: 'NEW_COLLECTION_LIST_REQUEST' });

        const { data } = await axios.get(`${apiUrl}/newCollectionBanner`);
        dispatch({ type: 'NEW_COLLECTION_LIST_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'NEW_COLLECTION_LIST_FAIL', payload: error });
    }
}

export const createNewCollection = (dataa) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'NEW_COLLECTION_CREATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        formData.append('image', dataa.image, dataa.image.name);

        const { data } = await axios.post(`${apiUrl}/newCollectionBanner`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'NEW_COLLECTION_CREATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'NEW_COLLECTION_CREATE_FAIL', payload: error });
    }
}

export const updateNewCollection = (id,dataa) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'NEW_COLLECTION_UPDATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        dataa.image instanceof Blob && formData.append('image', dataa.image, dataa.image.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update

        const { data } = await axios.patch(`${apiUrl}/newCollectionBanner/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'NEW_COLLECTION_UPDATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'NEW_COLLECTION_UPDATE_FAIL', payload: error });
    }
}

export const deleteNewCollection = (id) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'NEW_COLLECTION_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/newCollectionBanner/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'NEW_COLLECTION_DELETE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'NEW_COLLECTION_DELETE_FAIL', payload: error });
    }
}