import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createColor, updateColor } from '../../../actions/color_actions';
import { clear } from '../../../actions/clear_action';
import { useHistory, useParams } from 'react-router-dom';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { apiUrl } from '../../../constants/backendUrl';
import axios from 'axios';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import InputColor from 'react-input-color';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddColor(props) {
    const { userInfo } = useSelector(state => state.userSignIn);

    const [hexDecimal, setHexDecimal] = useState('');
    const [colorHex, setColorHex] = useState('');
    const [colorName, setColorName] = useState('');

    const { success, loading: loadingCreate } = useSelector(state => state.add_color);
    const { update_success, loading } = useSelector(state => state.update_color);

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }
        
        if(id) {
            axios.get(`${apiUrl}/colors/${id}`, {
                headers:
                  { Authorization: 'Bearer ' + userInfo?.token }
            })
            .then(response => {
                setHexDecimal(response.data.colorHex);
                setColorName(response.data.colorName);
          }).catch(error => console.log(error));
        }
        if(success) {
            Toastr.success('Ngjyra u krijua me sukses.');
            clearInputs();
            dispatch(clear()); //per me bo clear staten.
        }
        if(update_success) {
            Toastr.success('Ngjyra u editua me sukses.');
            history.push('/admin/colors-list');
            clearInputs();
            dispatch(clear()); //per me bo clear staten.
        }
        return () => {
        }
    }, [success, update_success]);

    const submitHandler = (e) => {
        e.preventDefault();
       if(id) {
        dispatch(updateColor(id, { colorHex: colorHex.hex, colorName: colorName }));
       } else {
        dispatch(createColor({ colorHex: colorHex.hex, colorName: colorName }));
       }
    }

    const clearInputs = () => {
        setColorHex('');
        setColorName('');
    }

    return (
        <div>
            <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>{id ? 'Edito Ngjyrën' : 'Krijo Ngjyrën'}</h1>
                                </div>
                            </div>
                        </div>
                    </section>
        
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">{id ? 'Edito Ngjyrën' : 'Krijo Ngjyrën'}</h3>
                                        </div>
                                        <form onSubmit={submitHandler}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="color-hex">Selekto</label> <br />
                                                        <InputColor
                                                            initialValue={hexDecimal}
                                                            value={colorHex?.hex}
                                                            onChange={setColorHex}
                                                            placement="right"
                                                        /> <br />
                                                        <label htmlFor="color-name">Vendos emrin</label>
                                                        <input 
                                                            name="colorName" 
                                                            type="text" 
                                                            className="form-control" 
                                                            id="color-name" 
                                                            placeholder="Emri Ngjyrës" 
                                                            required = { id ? false : true }
                                                            value={colorName}
                                                            onChange={(e) => setColorName(e.target.value)}  
                                                        />
                                                        <div
                                                            style={{
                                                            width: 50,
                                                            height: 50,
                                                            marginTop: 20,
                                                            backgroundColor: colorHex.rgba,
                                                            }}
                                                        />
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                {   
                                                    loading || loadingCreate ?
                                                    <button class="btn btn-danger" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span> Loading...</span>
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-danger">
                                                        <span>{id ? 'Edito' : 'Shto'}</span>
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}