import axios from 'axios';
import Cookie from 'js-cookie';
import { apiUrl } from '../constants/backendUrl';

export const addToWishlist = (id, color) => async (dispatch, getState) => {
    try {
        // const { data } = await axios.get(`${apiUrl}/products/${productId}`);
        const { data } = await axios.get(`${apiUrl}/products/single-product/color/${id}/${color}`);

        dispatch({ type: 'WISHLIST_ADD_ITEM', payload: {
            id: id, //id e produktit kryesor qe gjenerohet nga mongodb -> m duhet sepse kur jena n cart kur klikoj n produkt po du mem qu n faqen /product/:id
            product: data?.product?.availableOptions[0]?._id,
            title: data?.product?.title,
            subtitle: data?.product?.subtitle,
            category: data?.product?.category,
            image: data?.product?.availableOptions[0]?.images[0],
            price: data?.product?.price,
            color: data?.product?.availableOptions[0]?.color,
            size: data?.product?.availableOptions[0]?.sizeObj[0]?.size,
            countInStock: data?.product?.availableOptions[0]?.sizeObj[0]?.countInStock,
            quantity: 1,
            sizeDetailId: data?.product?.availableOptions[0]?.sizeObj[0]?._id
        }});
        const {wishlist:{wishlistItems}} = getState(); //getState from redux thunks
        Cookie.set("wishlistItems", JSON.stringify(wishlistItems));
    } catch(error) {
        console.log(error)
        dispatch({ type: 'ADD_TO_WISHLIST_FAILED', payload: error });
    }
}

export const removeFromWishlist = (productId) => async (dispatch, getState) => {
    dispatch( { type: 'WISHLIST_REMOVE_ITEM', payload: productId });
    
    const { wishlist: { wishlistItems } } = getState(); //getState from redux thunks
    Cookie.set("wishlistItems", JSON.stringify(wishlistItems));
}