function listCategoryBannerHomeReducer(state = { categoriesBannerHome: [] }, action) {
    switch (action.type) {
        case 'CATEGORIES_BANNER_LIST_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BANNER_LIST_SUCCESS':
            return { loading: false, categoriesBannerHome: action?.payload }
        case 'CATEGORIES_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addBannerHomeReducer(state = {}, action) {
    switch (action.type) {
        case 'CATEGORIES_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BANNER_CREATE_SUCCESS':
            return { loading: false, newCategoryBannerHome: action.payload, success: true }
        case 'CATEGORIES_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateBannerHomeReducer(state = { newCategoryBannerHome: {} }, action) {
    switch(action.type) {
        case 'CATEGORIES_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'CATEGORIES_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteBannerHomeReducer(state = { newCategoryBannerHome: {} }, action) {
    switch(action.type) {
        case 'CATEGORIES_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'CATEGORIES_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listCategoryBannerHomeReducer, addBannerHomeReducer, updateBannerHomeReducer, deleteBannerHomeReducer }