function wishlistReducer(state={ wishlistItems:[] }, action) {
    switch (action.type) {
        case 'WISHLIST_ADD_ITEM':
            const item = action?.payload;
            const product = state?.wishlistItems.find(x => x?.product === item?.product); //e kqyrim a ekziston ai produkt apo jo
            if(product) {
                return { wishlistItems: state?.wishlistItems.map( x => x?.product === product?.product ? item : x ) };
            } else {
                return { wishlistItems: [...state.wishlistItems, item] }
            }
        case 'WISHLIST_REMOVE_ITEM':
            return { wishlistItems: state.wishlistItems.filter(x => x.product !== action?.payload)}
        default:
            return state;
    }

}

export { wishlistReducer }