import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createSubscribe } from '../../actions/subscribes_actions';
import { bannerBlogList } from '../../actions/bannerBlog_actions';
import { categoriesBannerBlogList } from '../../actions/categoriesBannerBlog_actions';
import { cardBannerBlogList } from '../../actions/blogCard_actions';
import { secondCardBannerBlogList } from '../../actions/blogSecondCard_actions';
import { clear } from '../../actions/clear_action';
import { apiUrl } from '../../constants/backendUrl';
import axios from 'axios';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBar from '../SearchBar/SearchBar';
import Loader from '../Loader/Loader';

import latestFromBlog from '../../images/Latest from the blog.png';
import latestNews from '../../images/The latest news.png';
import categories from '../../images/Categories.png';
import loriHoxha from '../../images/Lori Hoxha.png';

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 


import './style.css';

export default function Blog(props) {
    const [postData, setPostData] = useState({
        name: '', email: ''
    });

    const [sliders, setSliders] = useState([]);
    const { success } = useSelector(state => state.add_subscribe);
    const { blogBanners, loading: blogBannersLoading } = useSelector(state => state.banner_blog_list);
    const { categoriesBannerBlog, loading: categoriesBannerLoading } = useSelector(state => state.categories_blog_list);
    const { cardsBlog, loading: cardsBlogLoading } = useSelector(state => state.card_blog_list);
    const { secondCardsBlog, loading: secondCardsBlogLoading } = useSelector(state => state.second_card_blog_list);

    const dispatch = useDispatch();

    useEffect(() => {
        axios.get(`${apiUrl}/blogSliderBanner`).then((res) => {
            if (res.data) {
              setSliders(res.data[0].images)
            }
        });

        dispatch(bannerBlogList());
        dispatch(categoriesBannerBlogList());
        dispatch(cardBannerBlogList());
        dispatch(secondCardBannerBlogList());

        if(success) {
            Toastr.success('Subscribe u krijua me sukses.');
            clearInputs();
            dispatch(clear()); //per me bo clear staten.
        }

    }, [success]);

    const submitHandler = (e) => {
        e.preventDefault();
       dispatch(createSubscribe({ ...postData }));
    }

    const clearInputs = () => {
        setPostData({ name: '', email: '' });
    }

    return (
        blogBannersLoading
        ||
        categoriesBannerLoading
        ||
        cardsBlogLoading
        ||
        secondCardsBlogLoading
        ?
        <Loader />
        :
        <section id="blog_page">
            <TopHeader />
            {
                blogBanners?.slice(-1)?.map(blogBanner =>  <Slider image = { blogBanner?.image } link={blogBanner?.link} key={blogBanner?._id} />)
            }
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <SearchBar />
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <div className="eltdf-full-width">
                                    <div className="eltdf-full-width-inner">
                                        <div className="eltdf-grid-row">
                                            <div className="eltdf-page-content-holder eltdf-grid-col-12 blog-part-5">
                                                <div className="eltdf-row-grid-section-wrapper">
                                                    <div className="eltdf-row-grid-section" id="eltdf-row-grid-section-blog_first_part_0081111">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521" id="blog-first_part_0091111">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="eltdf-pl-holder eltdf-grid-list eltdf-disable-bottom-space eltdf-standard-layout eltdf-four-columns eltdf-small-space eltdf-info-below-image  eltdf-product-add-to-cart-in-box     eltdf-hover-type-simple custom-text-position-left eltdf-navigation-bellow-slider" data-type="standard" data-info-position="info-below-image" data-number-of-posts={8} data-with-custom-text-position="left" data-number-of-columns="four" data-space-between-items="small" data-orderby="date" data-order="DESC" data-category-values="fall" data-show-all-item-in-filter="yes" data-taxonomy-to-display="category" data-show-category-filter="yes" data-image-size="full" data-display-title="yes" data-title-tag="h5" data-display-category="yes" data-display-excerpt="no" data-excerpt-length={50} data-display-rating="no" data-display-price="yes" data-display-button="yes" data-button-skin="default" data-hover-type="simple" data-number-of-visible-items={3} data-slider-loop="yes" data-slider-autoplay="yes" data-slider-speed={5000} data-slider-speed-animation={600} data-slider-navigation="yes" data-slider-navigation-position="bellow-slider" data-slider-pagination="yes" data-class-name="pli" data-max-num-pages={1} data-next-page={2}>
                                                                            <div className="eltdf-pl-outer eltdf-outer-space">
                                                                                {
                                                                                    categoriesBannerBlog?.slice(-1)?.map(categoryBlog => <div key={categoryBlog?._id}>
                                                                                        <div className="eltdf-pli eltdf-item-space ">
                                                                                            <div className="eltdf-pli-inner">
                                                                                                <div className="eltdf-pli-image">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryBlog?.title1
                                                                                                        }
                                                                                                        }}>
                                                                                                        <img src={categoryBlog?.image1} className="attachment-full size-full wp-post-image" alt="m" /> 

                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="eltdf-pli-text-wrapper">
                                                                                                <h5 itemProp="name" className="entry-title eltdf-pli-title blog-title">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryBlog?.title1
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        {categoryBlog?.title1}
                                                                                                    </Link>
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="eltdf-pli eltdf-item-space ">
                                                                                            <img src={categories} alt="" style={{ paddingBottom: '30px' }} />
                                                                                            <div className="eltdf-pli-inner">
                                                                                                <div className="eltdf-pli-image">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryBlog?.title2
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        <img src={categoryBlog?.image2} className="attachment-full size-full wp-post-image" alt="m" /> 

                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="eltdf-pli-text-wrapper">
                                                                                                <h5 itemProp="name" className="entry-title eltdf-pli-title blog-title">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryBlog?.title2
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        {categoryBlog?.title2}
                                                                                                    </Link>
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="eltdf-pli eltdf-item-space" id="eltdf-item-space-shoes">
                                                                                            <div className="eltdf-pli-inner">
                                                                                                <div className="eltdf-pli-image">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryBlog?.title3
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        <img src={categoryBlog?.image3} className="attachment-full size-full wp-post-image" alt="m" /> 
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="eltdf-pli-text-wrapper">
                                                                                                <h5 itemProp="name" className="entry-title eltdf-pli-title blog-title">
                                                                                                    <Link to={{
                                                                                                        pathname: `/new`,
                                                                                                        state: {
                                                                                                            category: categoryBlog?.title3
                                                                                                        }
                                                                                                        }}
                                                                                                    >
                                                                                                        {categoryBlog?.title3}
                                                                                                    </Link>
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                
                                                                                    </div>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="eltdf-row-grid-section-wrapper blog-second-part-mobile" style={{ background: '#FBF5F4', padding: '57px', marginBottom: '127px', marginTop: '80px' }}>
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                    <div className="eltdf-pl-holder eltdf-grid-list eltdf-disable-bottom-space eltdf-standard-layout eltdf-four-columns eltdf-small-space eltdf-info-below-image  eltdf-product-add-to-cart-in-box     eltdf-hover-type-simple custom-text-position-left eltdf-navigation-bellow-slider" data-type="standard" data-info-position="info-below-image" data-number-of-posts={8} data-with-custom-text-position="left" data-number-of-columns="four" data-space-between-items="small" data-orderby="date" data-order="DESC" data-category-values="fall" data-show-all-item-in-filter="yes" data-taxonomy-to-display="category" data-show-category-filter="yes" data-image-size="full" data-display-title="yes" data-title-tag="h5" data-display-category="yes" data-display-excerpt="no" data-excerpt-length={50} data-display-rating="no" data-display-price="yes" data-display-button="yes" data-button-skin="default" data-hover-type="simple" data-number-of-visible-items={3} data-slider-loop="yes" data-slider-autoplay="yes" data-slider-speed={5000} data-slider-speed-animation={600} data-slider-navigation="yes" data-slider-navigation-position="bellow-slider" data-slider-pagination="yes" data-class-name="pli" data-max-num-pages={1} data-next-page={2}>
                                                                            <div className="eltdf-pl-outer eltdf-outer-space">
                                                                                {
                                                                                    cardsBlog?.slice(-1)?.map(cardBlog => <div key={cardBlog?._id}>
                                                                                        <div className="eltdf-pli eltdf-item-space " style={{ width: '50%' }}>
                                                                                            <div className="eltdf-single-image-holder">
                                                                                                <div className="eltdf-si-inner">
                                                                                                    <img src={cardBlog?.image} className="attachment-full size-full" alt="f" /> 
                                                                                                </div>
                                                                                            </div> 
                                                                                        </div> 

                                                                                        <div className="eltdf-pli eltdf-item-space eltdf-item-space_blog-lori_hoxha " style={{ width: '50%' }}>
                                                                                            <div className="vc_empty_space" style={{height: '120px'}}>
                                                                                                <span className="vc_empty_space_inner"></span>
                                                                                            </div>
                                                                                            <div className="eltdf-elements-holder eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                                                <div className="eltdf-eh-item eltdf-eh-custom-8688">
                                                                                                    <div className="eltdf-eh-item-inner">
                                                                                                        <div className="eltdf-eh-item-content" id="blog-lori_hoxha-eltdf-eh-item-content" style={{padding: '0 5% 0 0'}}>
                                                                                                            <div className="eltdf-section-title-holder  eltdf-st-standard eltdf-st-title-left eltdf-st-normal-space ">
                                                                                                                <div className="eltdf-st-inner second-part">
                                                                                                                    <h3 className="eltdf-st-title">
                                                                                                                        {cardBlog?.title}
                                                                                                                    </h3>
                                                                                                                    <h3 className="eltdf-st-title blog-st-title-mobile">
                                                                                                                        {cardBlog?.subtitle}
                                                                                                                    </h3>
                                                                                                                    {/* <img src={loriHoxha} alt="l" /> */}
                                                                                                                    <p className="eltdf-st-text" style={{color: '#3d3d3d', lineHeight: '25px', marginTop: '15px'}}>
                                                                                                                        {cardBlog?.text}
                                                                                                                    </p>
                                                                                                                    <div style={{textAlign: 'center'}}>
                                                                                                                        <Link to="/new" className="btn btn-blog">Explore the shop</Link>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> 
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="eltdf-elements-holder-background-outer">
                                                                                                        <div className="eltdf-elements-holder-background-inner" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>  
                                                                                        </div> 
                                                                                    </div>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="eltdf-row-grid-section-wrapper subscribe-part_blog_0011111" style={{ background: '#D27F8B', padding: '57px', marginBottom: '80px' }}>
                                                    <div className="eltdf-row-grid-section mobile-subscribe">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521a">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="subscribe-part text-center">
                                                                            <h1>Subsribe and let's be friends</h1>
                                                                            <img src={latestNews} alt="l" />
                                                                            <p>Subscribe to our newsletter and get up to date with the latest fashion trends</p>
                                                                        </div>
                                                                        <div className="subscribe-part-2">
                                                                            <form onSubmit={submitHandler}>
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <input 
                                                                                            type="text" 
                                                                                            className="form-control" 
                                                                                            placeholder="First Name" 
                                                                                            required
                                                                                            value={postData.name}
                                                                                            onChange={(e) => setPostData({ ...postData, name: e.target.value })}  
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <input 
                                                                                            type="text" 
                                                                                            className="form-control" 
                                                                                            placeholder="Email Address" 
                                                                                            required
                                                                                            value={postData.email}
                                                                                            onChange={(e) => setPostData({ ...postData, email: e.target.value })}  
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-4 btn-blog-center">
                                                                                        <button type="submit" className="btn btn-blog">Subscribe</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="eltdf-row-grid-section-wrapper">
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521 padding-latest-from-blog">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="eltdf-elements-holder eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                            <div className="eltdf-eh-item" data-item-class="eltdf-eh-custom-6424" data-680="100px 0 0">
                                                                                <div className="eltdf-eh-item-inner">
                                                                                    <div className="eltdf-eh-item-content eltdf-eh-custom-6424">
                                                                                        <div className="eltdf-product-exhibition">
                                                                                            <div className="follow_us_instagram_padding">
                                                                                                <div className="eltdf-pe-background">
                                                                                                    <div className="eltdf-pe-background-image">
                                                                                                        <img src={latestFromBlog} className="attachment-full size-full" alt="s" style={{margin: '0px auto'}} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <div className="eltdf-row-grid-section-wrapper">
                                                    <div className="eltdf-row-grid-section" id="blog_eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521" id="latest_from_blog">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="eltdf-pl-holder eltdf-grid-list eltdf-disable-bottom-space eltdf-standard-layout eltdf-four-columns eltdf-small-space eltdf-info-below-image  eltdf-product-add-to-cart-in-box     eltdf-hover-type-simple custom-text-position-left eltdf-navigation-bellow-slider" data-type="standard" data-info-position="info-below-image" data-number-of-posts={8} data-with-custom-text-position="left" data-number-of-columns="four" data-space-between-items="small" data-orderby="date" data-order="DESC" data-category-values="fall" data-show-all-item-in-filter="yes" data-taxonomy-to-display="category" data-show-category-filter="yes" data-image-size="full" data-display-title="yes" data-title-tag="h5" data-display-category="yes" data-display-excerpt="no" data-excerpt-length={50} data-display-rating="no" data-display-price="yes" data-display-button="yes" data-button-skin="default" data-hover-type="simple" data-number-of-visible-items={3} data-slider-loop="yes" data-slider-autoplay="yes" data-slider-speed={5000} data-slider-speed-animation={600} data-slider-navigation="yes" data-slider-navigation-position="bellow-slider" data-slider-pagination="yes" data-class-name="pli" data-max-num-pages={1} data-next-page={2}>
                                                                            <div className="eltdf-pl-outer eltdf-outer-space">
                                                                                {
                                                                                    secondCardsBlog?.slice(-2)?.map(secondCard => <div className="eltdf-pli eltdf-item-space " key={secondCard?._id} style={{ width: '50%' }}>
                                                                                        <div className="eltdf-single-image-holder">
                                                                                            <div className="eltdf-si-inner">
                                                                                                <img src={secondCard?.image} className="attachment-full size-full" alt="f" /> 
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="vc_empty_space" style={{height: '20px'}}>
                                                                                            <span className="vc_empty_space_inner"></span>
                                                                                        </div>
                                                                                        <div className="eltdf-elements-holder eltdf-one-column  eltdf-responsive-mode-768 ">
                                                                                            <div className="eltdf-eh-item eltdf-eh-custom-8688">
                                                                                                <div className="eltdf-eh-item-inner">
                                                                                                    <div className="eltdf-eh-item-content" style={{padding: '0 5% 0 0'}} id="blog-eltdf-eh-item-content">
                                                                                                        <div className="eltdf-section-title-holder  eltdf-st-standard eltdf-st-title-left eltdf-st-normal-space ">
                                                                                                            <div className="eltdf-st-inner blog_mobile-center">
                                                                                                                <h3 className="eltdf-st-title blog-lastpart_title">
                                                                                                                    {secondCard?.title}
                                                                                                                </h3>
                                                                                                                <p className="eltdf-st-text" style={{color: '#3d3d3d', lineHeight: '25px', textAlign: 'left', marginTop: '15px'}}>
                                                                                                                    {secondCard?.text}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div> 
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="eltdf-elements-holder-background-outer">
                                                                                                    <div className="eltdf-elements-holder-background-inner" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>  
                                                                                    </div> 
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                
                                                <div className="eltdf-row-grid-section-wrapper" style={{ background: '#FBF5F4', marginBottom: '25px', marginTop: '40px' }}>
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521a">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner" style={{ paddingTop: '40px' }}>
                                                                    <div className="wpb_wrapper">
                                                                        <div className="desktop-blog-slider">
                                                                            <OwlCarousel items={5}  
                                                                                className="owl-theme wpb_wrapper vc_figure"  
                                                                                loop                                     
                                                                                nav                                                                 
                                                                                margin={8} 
                                                                                autoplay = {true}
                                                                                autoplayTimeout = {3000}
                                                                            >  
                                                                        
                                                                                {
                                                                                    sliders?.map(slider => <div key={slider?._id}>
                                                                                        <img src={slider} className="img" />
                                                                                    </div>)
                                                                                }
                                                                        
                                                                            </OwlCarousel> 
                                                                        </div>

                                                                        <div className="mobile-blog-slider">
                                                                            <OwlCarousel items={1}  
                                                                                className="owl-theme wpb_wrapper vc_figure"  
                                                                                loop                                     
                                                                                nav                                                                 
                                                                                margin={8} 
                                                                                autoplay = {true}
                                                                                autoplayTimeout = {3000}
                                                                            >  
                                                                        
                                                                                {
                                                                                    sliders?.map(slider => <div key={slider?._id}>
                                                                                        <img src={slider} className="img" />
                                                                                    </div>)
                                                                                }
                                                                            </OwlCarousel> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}