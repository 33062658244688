import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const termsOfServiceList = () => async (dispatch) => {
    try {
        dispatch({type: 'TERMS_OF_SERVICE_LIST_REQUEST'});
        const user = JSON.parse(localStorage.getItem('profile'));
        const {data} = await axios.get(`${apiUrl}/terms/service`);
        dispatch({ type: 'TERMS_OF_SERVICE_LIST_SUCCESS', payload: data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'TERMS_OF_SERVICE_LIST_FAIL', payload: error });
    }
}

export const addTermOfService = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'TERMS_OF_SERVICE_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();
        const termsOfService = await axios.post(`${apiUrl}/terms/service`, data, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'TERMS_OF_SERVICE_CREATE_SUCCESS', payload: termsOfService.data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'TERMS_OF_SERVICE_CREATE_FAIL', payload: error });
    }
}

export const updateTermsOfService = (id,data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'TERMS_OF_SERVICE_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();
        const termsOfService = await axios.patch(`${apiUrl}/terms/service/${id}`, data, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'TERMS_OF_SERVICE_UPDATE_SUCCESS', payload: termsOfService.data });
    } catch(error) {
        console.log(error);
        dispatch({ type: 'TERMS_OF_SERVICE_UPDATE_FAIL', payload: error });
    }
}

export const deleteTermsOfService = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'DELETE_TERMS_OF_SERVICE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        const {data} = await axios.delete(`${apiUrl}/terms/service/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'DELETE_TERMS_OF_SERVICE_SUCCESS', payload: data });

    } catch(error) {
        console.log(error);
        dispatch({ type: 'DELETE_TERMS_OF_SERVICE_FAIL', payload: error });
    }
}
