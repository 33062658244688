import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOrder } from '../../../actions/order_actions';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';
import { apiUrl } from '../../../constants/backendUrl';
import axios from 'axios';
import moment from 'moment-js';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css'

import TopHeader from '../../TopHeader/TopHeader';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import SearchBar from '../../SearchBar/SearchBar';

const UserOrders = () => {    
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true)
    const { userInfo } = useSelector(state => state.userSignIn);
    const { success } = useSelector(state => state.delete_order);

    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {

    axios.get(`${apiUrl}/orders/my-orders/${userInfo?._id}`, {
        headers:
          { Authorization: 'Bearer ' + userInfo?.token }
    }).then(res => {
        if(res.data.success) {
            setLoading(false);
            setOrders(res.data.orders);
        }
    });
    
    },[success])

    const deleteHandler = (orderId) => {
        dispatch(deleteOrder(orderId));
    }

    const confirmToDelete = (orderId) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>A jeni i sigurtë se dëshironi me fshi? </h1>
                        <button onClick={onClose}>Anulo</button>
                        <button
                            onClick={() => {
                                deleteHandler(orderId)
                                onClose();
                            }}
                        >
                        Po, Fshije!
                        </button>
                    </div>
                );
            }
        });
        
    }

    return (
        <div className="wrapper" style={{ margin: '20px 0 0 0' }}>
            <TopHeader />
            <div style={{height: '20px'}}></div>
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrapper" style={{ margin: '40px 0 90px 0' }}>
                <div className="header-container">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                <h1>{ orders?.length} {orders?.length > 1 ? '- Orders' : '- Order'}</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            {loading ? <div className="lds-facebook"><div></div><div></div><div></div></div> : ''}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Orders</h3>
                                        </div>
                                        <div className="card-body table-responsive p-0">
                                            <table className="table table-hover text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Category</th>
                                                        <th>Color</th>
                                                        <th>Size</th>
                                                        <th>Image</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        orders?.map(order => 
                                                            order?.orderItems?.map(item =>
                                                                <tr key={item?._id}>
                                                                    <td>{item?.title}</td>
                                                                    <td>{item?.category}</td>
                                                                    <td>{item?.color}</td>
                                                                    <td>{item?.size}</td>
                                                                    <td>
                                                                        <div className="form-row">
                                                                            <div className="col">
                                                                                <img className="img img-responsive img-thumbnail" src={`${apiUrl}/images/${item?.image}`} style={{width: '160px', height: 'auto'}} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item?.quantity}</td>
                                                                    <td>{item?.price}€</td>
                                                                    <td>{moment(order?.createdAt).format('YYYY-MM-DD HH:mm:ss') }</td>
                                                                    <td>{order?.status}</td>
                                                                    <td>
                                                                        <div className="btn-group">
                                                                            <button className="btn btn-danger btn-sm" onClick={() => confirmToDelete(order?._id)}>
                                                                                <span className="fa fa-trash "></span>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr> 
                                                            )    
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    </div>
            </div>
            <Footer />
        </div>
    );
};

export default UserOrders;
