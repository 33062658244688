import React, { useEffect, useRef, useState } from 'react';
import { removeFromCart } from '../../actions/cart_actions';
import { logout } from '../../actions/user_actions';
import { productsList } from '../../actions/product_actions';
import { categoriesList } from '../../actions/category_actions';

import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import { useDetectOutsideClick } from "./useDetectOutsideClick";
import AsideMenu from './AsideMenu';
import AsideUserMenu from './AsideUserMenu';

import headerLogo from '../../images/header_logo.png';
import heartIcon from '../../images/heart.svg';
import searchIcon from '../../images/search.svg';
import bagIcon from '../../images/bag.svg';
import mobileIcon from '../../images/Menu-mobile.svg';
import mobileIcon2 from '../../images/menu (2) (1).svg';
import mobileIcon3 from '../../images/user.svg';
import { apiUrl } from "../../constants/backendUrl";
import util from '../../util';
import $ from 'jquery';

import './style.css';
import './new_header.css';

export default function Header(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { categories } = useSelector(state => state.categories_list);
    const { cartItems } = useSelector(state => state.cart);
    const headerRef = useRef(null);
    const asideMenuRef = useRef(null);
    const asideUserRef = useRef(null);
    const [open, setOpen] = useDetectOutsideClick(headerRef, false);
    const [openCategoryMenu, setOpenCategoryMenu] = useDetectOutsideClick(headerRef, false);
    const [openCategorySubMenu, setOpenCategorySubMenu] = useState(false); 
    const [indexSubMenu, setIndexSubMenu] = useState(null);
    const [openMenuGeneral, setOpenMenuGeneral] = useDetectOutsideClick(asideMenuRef, false);
    const [openUserMenu, setOpenUserMenu] = useDetectOutsideClick(asideMenuRef, false);
    const { wishlistItems } = useSelector(state => state.wishlist);

    const $menu = $('#left-menu-mobile');

    const dispatch = useDispatch();

    const handleLogout =  async () => { 
        if(userInfo) {
            dispatch(logout());
        }
    }

    // const myFunction = () => {
    //     if (window.pageYOffset > sticky) {
    //       mobileHeader?.classList?.add("sticky");
    //     } else {
    //       mobileHeader?.classList?.remove("sticky");
    //     }
    // }

    // window.onscroll = () => {
    //     myFunction();
    // };

    function openSearchBar() {
        var element = document.getElementById("search_bar");
        var element2 = document.getElementById("eltdf-page-header");
        var element3 = document.getElementById('eltdf-mobile-header');
        var element4 = document.getElementById('footer_icon');
        element.style.visibility = 'visible';
        element.style.opacity = '1';
        element2.style.zIndex = 'unset'; //duhet me i bo unset z-index sepse ndryshe kur e hapi search barin n prapavije ma qet edhe mobile headerin tani(logon dhe ikonat)
        element3.style.zIndex = 'unset';
        element4.style.zIndex = '0';
    }

    //per me bo toggle subMenun -> me hap vetem ate qe e kemi kliku
    const toggle = (id) => setOpenCategorySubMenu(openCategorySubMenu === id ? undefined : id);

    useEffect(() => {
        dispatch(productsList());
        dispatch(categoriesList());
    }, []);

    return (
        <section>
            <header id="eltdf-page-header" className="eltdf-page-header" style={{marginBottom: '0px'}}>
                <div className="header-container eltdf-fixed-wrapper">
                    <div className="eltdf-menu-area">
                        <div className="eltdf-vertical-align-containers">
                            <div className="eltdf-position-left">
                                <div className="eltdf-position-left-inner">
                                    <div className="eltdf-bottom-menu-left-widget-holder">
                                        <div className="eltdf-logo-wrapper">
                                            <Link itemProp="url" to="/" style={{height: '53px'}}>
                                                <img itemProp="image" className="eltdf-normal-logo" src={headerLogo} alt="logo" />
                                        
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="eltdf-position-center">
                                <div className="eltdf-position-center-inner" style={{ paddingTop: '18px' }}>
                                    <nav className="eltdf-main-menu eltdf-drop-down eltdf-default-nav">
                                        <ul id="menu-main-menu-1" className="clearfix">
                                            <li id="nav-menu-item-166" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor menu-item-has-children  has_sub wide">
                                                <NavLink to="/" exact className=" current no_link">
                                                    <span className="item_outer">
                                                        <span className="item_text">Home</span>
                                                        <i className="eltdf-menu-arrow eltdf-icon-ion-icon ion-chevron-down" />
                                                    </span>
                                                </NavLink>
                                            
                                            </li>

                                            <li id="nav-menu-item-167" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has_sub narrow">
                                                <NavLink to="/new" className=" no_link">
                                                    <span className="item_outer">
                                                        <span className="item_text">New</span>
                                                        <i className="eltdf-menu-arrow eltdf-icon-ion-icon ion-chevron-down" />
                                                    </span>
                                                </NavLink>
                                        
                                            </li>

                                            <li id="nav-menu-item-167" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has_sub narrow">
                                                <NavLink to="/sale" className=" no_link">
                                                    <span className="item_outer">
                                                        <span className="item_text">Sale</span>
                                                        <i className="eltdf-menu-arrow eltdf-icon-ion-icon ion-chevron-down" />
                                                    </span>
                                                </NavLink>
                                        
                                            </li>
                                            
                                            <li id="nav-menu-item-167" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has_sub narrow">
                                                <NavLink to="/blog" className="no_link">
                                                    <span className="item_outer">
                                                        <span className="item_text">Blog</span>
                                                        <i className="eltdf-menu-arrow eltdf-icon-ion-icon ion-chevron-down" />
                                                    </span>
                                                </NavLink>
                                        
                                            </li>
            
                                            <li id="nav-menu-item-6110" className="menu-item menu-item-type-custom menu-item-object-custom  narrow" />
                                            <li id="nav-menu-item-6111" className="menu-item menu-item-type-custom menu-item-object-custom  narrow" />
                                            <li id="nav-menu-item-6112" className="menu-item menu-item-type-custom menu-item-object-custom  narrow" />
                                            <li id="nav-menu-item-6113" className="menu-item menu-item-type-custom menu-item-object-custom  narrow" />
                                        </ul> 
                                    </nav>
                                </div>
                            </div>
                            <div className="eltdf-position-right">
                                <div className="eltdf-position-right-inner" style={{ paddingTop: '18px' }}>
                                    <div className="eltdf-header-bottom-opener-outer">
                                        <div className="eltdf-header-bottom-opener-inner">
                                            <a className="eltdf-search-opener eltdf-icon-has-hover eltdf-search-opener-svg-path" onClick={openSearchBar} href="javascript:;;" title="Search">
                                                <span className="eltdf-search-opener-wrapper">
                                                    <img src={searchIcon} alt="Search Icon" style={{ height: '30px' }} />
                                                </span>
                                            </a>
                                            <Link className="eltdf-wishlist-widget-holder eltdf-wishlist-widget-link" to="/wishlist" title="View Wish list">
                                                <span className="eltdf-search-opener-wrapper">
                                                    <img src={heartIcon} alt="Heart Icon" style={{ height: '30px' }} />
                                                </span>
                                                <span className="eltdf-cart-text-holder">
                                                    <span className="eltdf-cart-quantity">
                                                        { wishlistItems?.length }
                                                    </span>
                                                </span>
                                            </Link>

                                            <Link className="eltdf-wishlist-widget-holder eltdf-wishlist-widget-link bag-header-link" to="/cart" title="View Cart">
                                                <span className="eltdf-search-opener-wrapper">
                                                    <img src={bagIcon} alt="Bag Icon" style={{ height: '30px' }} />
                                                </span>
                                                <span className="eltdf-cart-text-holder">
                                                    <span className="eltdf-cart-quantity">
                                                        { cartItems?.length }
                                                    </span>
                                                </span>

                                                <div className="eltdf-shopping-cart-dropdown">
                                                    <a itemProp="url" className="eltdf-header-cart eltdf-header-cart-icon-pack" href="https://onea.qodeinteractive.com/cart/"></a>
                                                    <ul>
                                                        {
                                                            cartItems?.length > 0 ?
                                                                cartItems?.map((cartItem) => <li key={cartItem?.product}>
                                                                <div className="eltdf-item-image-holder">
                                                                    <a itemProp="url" className="eltdf-header-cart eltdf-header-cart-icon-pack" href="https://onea.qodeinteractive.com/cart/"></a>
                                                                    <a itemProp="url" href="https://onea.qodeinteractive.com/product/bright-match/">
                                                                        <img src={`${apiUrl}/images/${cartItem?.image}`} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="f" />                              
                                                                    </a>
                                                                </div>
                                                                <div className="eltdf-item-info-holder">
                                                                    <h5 itemProp="name" className="eltdf-product-title">
                                                                        <a itemProp="url" href="https://onea.qodeinteractive.com/product/bright-match/">{cartItem?.title}</a>
                                                                    </h5>
                                                                    <span className="eltdf-quantity">{cartItem?.quantity}</span>
                                                                    <span className="woocommerce-Price-amount amount">
                                                                        {cartItem?.price?.toFixed(2)}€
                                                                    </span>                               
                                                                    <a 
                                                                        href="javascript:void()" 
                                                                        className="remove" 
                                                                        title="Remove this item"
                                                                        onClick={() => dispatch(removeFromCart(cartItem?.sizeDetailId))}
                                                                    >
                                                                        <span className="icon-arrows-remove" />
                                                                    </a>                          
                                                                </div>
                                                            </li> )
                                                            :
                                                            <li className="eltdf-empty-cart">No products in the cart.</li>
                                                        }

                                                        {
                                                            cartItems?.length > 0 ? 
                                                                <li className="eltdf-cart-bottom">
                                                                <div className="eltdf-subtotal-holder clearfix">
                                                                    <span className="eltdf-total">Total bag:</span>
                                                                    <span className="eltdf-total-amount">
                                                                        <span className="woocommerce-Price-amount amount">
                                                                            <span className="woocommerce-Price-currencySymbol">{ util.formatCurrency(cartItems?.reduce((a, product) => a + product.price * product.quantity, 0))}€</span>
                                                                        </span>							
                                                                    </span>
                                                                </div>
                                                                <div className="eltdf-btn-holder clearfix">
                                                                    <Link itemProp="url" to="/checkout" className="eltdf-view-checkout">Checkout</Link>
                                                                    <Link itemProp="url" to="/cart" className="eltdf-view-cart">View Cart</Link>
                                                                </div>
                                                            </li>
                                                            : ''
                                                        }
                                                    </ul>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <header className="site-header fmc-transparent-header eltdf-mobile-header eltdf-sticky-header" id="eltdf-mobile-header">
                <div className={`c-offcanvas-bg c-offcanvas-bg--full-fade c-offcanvas-bg--overlay ${openCategoryMenu ? 'is-open' : 'is-closed'}`}></div>

                <div className="header-wrapper">
                    <div className="header-mobile header-mobile-layout1 not_device_hidden">
                        <div className="container">
                            <div className="mobile-nav-btn">
                                <a href="javascript:;;;" className="js-offcanvas-trigger" onClick={() => setOpenCategoryMenu(!openCategoryMenu)}>
                                    <img src={mobileIcon} className="fm-icon fill" alt="mobile-icon" />
                                </a>
                            </div>
    
                            <aside ref={headerRef} className={`js-offcanvas c-offcanvas c-offcanvas--full-fade c-offcanvas--overlay ${openCategoryMenu ? 'is-open' : 'is-closed'}`} id="mobile-header-canvas">
                                <div className="offcanvas-content">
                                    <div className="mobile-header-screen-inner">
                                        <div className="mobile-header-title">
                                            <h3>Menu</h3>
                                            <button data-focus className="js-offcanvas-close fisino-close-btn c-button c-button--m1 c-button--m2" onClick={() => setOpenCategoryMenu(!openCategoryMenu)}>
                                                <span className="c-button__text">
                                                    <svg viewBox="0 0 16 16" className=" fm-icon fill">
                                                        <use xlinkHref="#ico_close">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" id="ico_close">
                                                            <path d="M14.17 0h1.35C53.06 37.52 90.45 75.18 128 112.69 165.55 75.18 202.94 37.51 240.48 0h1.36c4.73 4.7 9.43 9.44 14.16 14.15v.14c-37.69 37.8-75.43 75.54-113.06 113.39C180.6 165.5 218.31 203.26 256 241.05v.13c-4.89 4.98-9.86 9.88-14.78 14.82h-.12c-37.7-37.78-75.37-75.6-113.1-113.36C90.26 180.39 52.61 218.24 14.88 256h-.08c-4.95-4.92-9.89-9.85-14.8-14.81v-.16c37.7-37.77 75.42-75.54 113.06-113.38C75.39 89.85 37.69 52.08 0 14.29v-.13C4.73 9.44 9.43 4.7 14.17 0z" />
                                                            </svg>
                                                        </use>
                                                    </svg>                            
                                                    <span className="button-title">Close</span>
                                                </span>
                                            </button>
                                        </div>
                                        <nav className="mobile-navigation default-nav-menu">
                                            <ul id="menu-main-menu-1" className="menu">
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-level-0">
                                                    <Link to="/new">New In</Link>
                                                </li>
                                                {
                                                    categories?.map((category,index) => <li className={`menu-item menu-item-type-post_type menu-item-object-page menu-level-0 ${openCategorySubMenu === index ? 'open' : ''}`} key={category?._id}>
                                                        <Link to={category?.subCategories?.length > 0  ? location?.pathname : {pathname:  '/new', state:{ id: category?.id, category: category?.name }}} onClick={() => toggle(index)}>{category?.name}</Link>
                                                        <a href="javascript:void()" className="toggle-submenu" onClick={() => toggle(index)}>
                                                            {category?.subCategories?.length > 0 && <span className="toggle-icon" />}
                                                        </a>
                                                        <ul className="sub-menu">
                                                            {
                                                                category?.subCategories?.map(sub => <li id="menu-item-122" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-122 menu-level-1" onClick={() => setOpenCategorySubMenu(!openCategorySubMenu)} key={sub?._id}>
                                                                    <Link  to={{
                                                                        pathname: `/new/${sub?.name}`,
                                                                        state: {
                                                                        id: sub?._id,
                                                                        subCategory: sub?.name
                                                                        }
                                                                    }} >{sub?.name}</Link>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </li>
                                                )}
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-level-0">
                                                    <Link to="/sale">Sale</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </aside>

                            <div className="site-branding">
                                <div className="site-logo">
                                    <Link to="/" className="logoimage">
                                        <img src={headerLogo} alt="Npika Shop Logo" className="img-responsive" />
                                    </Link>
                                </div>
                            </div>

                            <div className="header-control-wrap header-mobile-right-control">
                                <div className="header-element search_box button">
                                    <a onClick={openSearchBar} href="javascript:void(0)" className="search-btn search-btn-canvas">
                                        <img src={searchIcon} className="fm-icon fill" alt="Search Icon" />
                                    </a>
                                </div>

                                <div className="header-element menu_box">
                                    <a href="javascript:void()" className="menu-btn menu-canvas">
                                        <img src={mobileIcon2} onClick={() => setOpenMenuGeneral(!openMenuGeneral)} alt="Mobile Icon" style={{ width: '19px' }} />
                                        <span className="mini-menu-contents">
                                            <img src={mobileIcon3} alt="Mobile Icon" onClick={() => setOpenUserMenu(!openUserMenu)} style={{ width: '19px' }} />
                                            <span class="badge badge-light" id="mobile-badge" style={{ background: '#A65A6A', color: '#fff' }}>{wishlistItems?.length + cartItems?.length}</span>
                                        </span>
                                    </a>
                                </div>
                            </div>

                            <AsideMenu 
                                asideMenuRef={asideMenuRef}  
                                openAsideMenu={openMenuGeneral} 
                                setOpenMenuGeneral={setOpenMenuGeneral} 
                                items={[{ name: 'Home', link: '/' }, { name: 'Shop', link: '/new' }, { name: 'Blog', link: '/blog' }, { name: 'Contact', link: '/contact' }]}
                            />
                            <AsideUserMenu asideMenuRef={asideUserRef}  openAsideUserMenu={openUserMenu} setOpenUserMenu={setOpenUserMenu} items={[{ name: 'Cart', link: '/cart' }, { name: 'Wishlist', link: '/wishlist' }]} />

                        </div>
                    </div>
                </div>
            </header>
            
            {/* <header className="eltdf-mobile-header eltdf-sticky-header" id="eltdf-mobile-header">
                <div className="eltdf-mobile-header-inner">
                    <div className="eltdf-mobile-header-holder">
                        <div className="eltdf-grid">
                            <div className="eltdf-vertical-align-containers">
                                <div className="eltdf-vertical-align-containers" id="myHeader">
                                    <div className="eltdf-position-left">
                                        <div className="eltdf-position-left-inner">
                                            <div className="eltdf-mobile-logo-wrapper" style={{display:'flex'}}>
                                                <Link itemProp="url" to="/" style={{height: '23px'}}>
                                                    <img itemProp="image" src={mobileIcon} style={{ height: '30px' }} alt="Mobile Logo" />
                                                </Link>
                                                <Link itemProp="url" to="/" style={{height: '23px'}}>
                                                    <img itemProp="image" src={headerLogo} width={186} height={46} alt="Mobile Logo" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="eltdf-position-right">
                                        <div className="eltdf-position-right-inner">
                                            <div className="eltdf-mobile-menu-opener eltdf-mobile-menu-opener-svg-path">
                                                <a className="eltdf-search-opener eltdf-icon-has-hover eltdf-search-opener-svg-path" onClick={openSearchBar} href="javascript:void(0)" title="Search">
                                                    <span className="eltdf-search-opener-wrapper">
                                                        <img src={searchIcon} alt="Search Icon" style={{ height: '30px' }} />
                                                    </span>
                                                </a>
                                                <a href="javascript:;" className="mobile-menu-toggle eltdf-menu-opener-wrapper" onClick={leftSideMobileToggle}>
                                                    <span className="eltdf-mobile-menu-icon">
                                                        <img src={mobileIcon2} alt="Icon" style={{ height: '30px' }}  />
                                                    </span>
                                                </a>
                                                <a href="javascript:;" className="mobile-menu-toggle" onClick={() => setOpenCategoryMenu(true)}>
                                                    <span className="eltdf-mobile-menu-icon">
                                                        <img src={mobileIcon3} alt="Icon" style={{ height: '30px' }}  />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="left-menu-mobile" ref={headerRef} className={`lahb-element--dontcopy hm-res_m-pos--left lahb-responsive-menu-wrap lahb-responsive-menu-1570441659347 ${open ? "open" : ""}`}>
                                        <div className="close-responsive-nav open-icon-wrap">
                                            <div className="lahb-menu-cross-icon open" onClick={leftSideMobileToggle}></div>
                                        </div>
                                        {
                                           <div data-ajaxnavtmp="true" data-menu-id={16} data-options="{&quot;menu_class&quot;:&quot;responav menu&quot;,&quot;container&quot;:false,&quot;container_class&quot;:&quot;&quot;}" className="template-loaded" data-cache-id="aWQ6MTZ8YXJnczp7Im1lbnVfY2xhc3MiOiJyZXNwb25hdiBtZW51IiwiY29udGFpbmVyIjpmYWxzZSwiY29udGFpbmVyX2NsYXNzIjoiIn0=">
                                                <ul id="menu-menu-primary" className="responav menu has-inited">
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        <NavLink to="/" exact className="top-level-link">
                                                            <span className="text-wrap">
                                                                <span className="menu-text">Home</span>
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        <NavLink to="/new" className="top-level-link">
                                                            <span className="text-wrap">
                                                                <span className="menu-text">Shop</span>
                                                            </span>
                                                        </NavLink>
                                                    </li>

                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        <NavLink to="/blog" className="top-level-link">
                                                            <span className="text-wrap">
                                                                <span className="menu-text">Blog</span>
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        <NavLink to="/contact" className="top-level-link">
                                                            <span className="text-wrap">
                                                                <span className="menu-text">Contact</span>
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        <NavLink to="/cart" className="top-level-link">
                                                            <span className="text-wrap">
                                                                <span className="menu-text">Cart</span>
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        <NavLink to="/wishlist" className="top-level-link">
                                                            <span className="text-wrap">
                                                                <span className="menu-text">Wishlist</span>
                                                            </span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        {
                                                            userInfo && userInfo?.isAdmin
                                                            &&
                                                            <NavLink to="/admin-paneli" className="top-level-link">
                                                                <span className="text-wrap">
                                                                    <span className="menu-text">Admin</span>
                                                                </span>
                                                            </NavLink>
                                                        }
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        {
                                                            userInfo
                                                            ?
                                                            <Link to="/" className="top-level-link" onClick={handleLogout}>
                                                                <span className="text-wrap">
                                                                <i className="fas fa-sign-out-alt"></i> <span className="menu-text">Logout</span>
                                                                </span>
                                                            </Link>
                                                            :
                                                            <NavLink to="/login" className="top-level-link">
                                                                <span className="text-wrap">
                                                                    <span className="menu-text">Login</span>
                                                                </span>
                                                            </NavLink>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>

                                    <div id="left-menu-mobile" ref={headerRef} className={`lahb-element--dontcopy hm-res_m-pos--left lahb-responsive-menu-wrap lahb-responsive-menu-1570441659347 ${openCategoryMenu ? "open" : ""}`}>
                                        <div className="close-responsive-nav open-icon-wrap">
                                            <div className="lahb-menu-cross-icon open" onClick={() => setOpenCategoryMenu(false)}></div>
                                        </div>
                                        {
                                           <div data-ajaxnavtmp="true" data-menu-id={16} data-options="{&quot;menu_class&quot;:&quot;responav menu&quot;,&quot;container&quot;:false,&quot;container_class&quot;:&quot;&quot;}" className="template-loaded" data-cache-id="aWQ6MTZ8YXJnczp7Im1lbnVfY2xhc3MiOiJyZXNwb25hdiBtZW51IiwiY29udGFpbmVyIjpmYWxzZSwiY29udGFpbmVyX2NsYXNzIjoiIn0=">
                                                <ul id="menu-menu-primary" className="responav menu has-inited">
                                                    {
                                                        categories?.map(category => <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent menu-item-has-children menu-item-28 mm-lv-0 mm-menu-item active">
                                                        <Link to={{pathname: '/new', state:{ id: category?.id, category: category?.name }}} className="top-level-link">
                                                            <span className="text-wrap">
                                                                <span className="menu-text">{category?.name}</span>
                                                            </span>
                                                        </Link>
                                                    </li>)
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}
        </section>
    )
}