import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createOrder } from '../../actions/order_actions';
import { clearCart } from '../../actions/cart_actions';
import { getAllCountries } from '../../actions/country_actions';
import { registerUser } from '../../actions/user_actions';
import { clear } from '../../actions/clear_action';
import  { useHistory } from 'react-router-dom';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBar from '../SearchBar/SearchBar';

import SliderBanner from '../../images/SLIDER.png';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axios from 'axios';
import util from '../../util';

import { apiUrl } from '../../constants/backendUrl';

// import './style.css';
// import './order-style.css';
import './new-style.css';

export default function Address(props) {
    const [shipping, setShipping] = useState(0);
    const [newAccount, setNewAccount] = useState(false);
    const { userInfo } = useSelector(state => state.userSignIn);
    const { userInfo: userInfoRegister, error, success: userRegisterSuccess } = useSelector(state => state.user_register);
    const { cartItems } = useSelector(state => state?.cart);
    const { success } = useSelector(state => state.add_order);
    const { countries } = useSelector(state => state.countries_list);

    const [postData, setPostData] = useState({
        userId: userInfo?._id, 
        orderItems: cartItems, 
        firstName: userInfo?.name ? userInfo?.name : '', 
        lastName: userInfo?.surname ? userInfo?.surname : '', 
        city: '', 
        address: '', 
        country: '', 
        zip: '', 
        phone: '', 
        email: userInfo?.email ? userInfo?.email : '', 
        password: ''
    });
    const dispatch = useDispatch();
    const history = useHistory();

    function submitHanlder(e) {
        e.preventDefault();
        if(newAccount && postData?.password) {
            dispatch(registerUser({ name: postData?.firstName, surname: postData?.lastName, email: postData?.email, password: postData?.password }));
            if(userRegisterSuccess) {
                dispatch(createOrder({ ...postData, shipping }) );
            }
        } else {
            console.log(cartItems);
            dispatch(createOrder({ ...postData, shipping }) ); 
        }
    }

    const setCountry = (country) => {
        setPostData({...postData, country: country });
        const found = countries?.filter(function(item) { return item?.name === country });
        
        setShipping(found[0]?.shipping);
        // localStorage.setItem('shipping', found[0]?.shipping);
        // setPostData({ ...postData, shipping: found[0]?.shipping });
        
    }

    const clearInputs = () => {
        setPostData({ firstName: '', lastName: '', city: '', address: '', country: '', zip: '', phone: '', email: '' });
    }

    useEffect(() => {
        dispatch(getAllCountries());
        if(success) {
            //kjo for loop eshte per me ja zvoglu quantityn atin produkti qe blehet nga nje user psh jon kan 9 kur t blehet bohet 8 nese e ka ble veq 1, e tani e krijoj qat order.
            for(let i = 0; i < cartItems.length; i++) {
                cartItems[i].countInStock = cartItems[i].countInStock -= cartItems[i].quantity;
                axios.patch(`${apiUrl}/products/quantity/${cartItems[i].size}`, cartItems[i])
                .then((res) =>  { 
                    if(res.data.success) {
                        Toastr.success('Porosia u përfundua me sukses.');
                        clearInputs();
                        dispatch(clearCart()); // e bejme clear carten kur behet porosia.
                        localStorage.removeItem('shipping');    
                        history.push('/order-finished')
                    }
                })
            };
            
            dispatch(clear()); //per me bo clear staten.
        }
        return () => {
        }
    }, [success, shipping]);

    return (
        <section id="address_page">
            <TopHeader />
            <Slider image={SliderBanner} />
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <SearchBar />

                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <div className="eltdf-container">
                                    <div className="eltdf-container-inner clearfix eltdf-row-grid-section">
                                        <div className="eltdf-grid-row">
                                            <div className="eltdf-page-content-holder eltdf-grid-col-12">
                                                <div className="checkout-padding-bottom">
                                                    <div className="row justify-content-lg-between">
                                                        <div className="col-md-7">
                                                            <div className="checkout_wrapper">
                                                                <form className="checkout-form" onSubmit={submitHanlder}>
                                                                    <div>
                                                                    {
                                                                       error
                                                                       &&
                                                                        <div className="errors">
                                                                            <ul>
                                                                                <li className="text-danger">{error}</li>
                                                                            </ul>
                                                                        </div>
                                                                    }
                                                                        <h3>Checkout</h3>
                                                                        <div>
                                                                            <input 
                                                                                required={true} 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                placeholder="First name*" 
                                                                                autoComplete="off" 
                                                                                // value={postData?.firstName}
                                                                                value={ userInfo?.name ? userInfo?.name : postData?.firstName }
                                                                                onChange={(e) => setPostData({ ...postData, firstName: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required={true} 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                placeholder="Last name*" 
                                                                                autoComplete="off" 
                                                                                value={ userInfo?.surname ? userInfo?.surname : postData?.lastName }
                                                                                onChange={(e) => setPostData({ ...postData, lastName: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required={true} 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                placeholder="City*" 
                                                                                autoComplete="off" 
                                                                                value={postData?.city}
                                                                                onChange={(e) => setPostData({ ...postData, city: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <select
                                                                                className="form-select"
                                                                                id="category"
                                                                                required={true}
                                                                                value={postData?.country}
                                                                                // onChange={(e) => setPostData({ ...postData, country: e.target.value })}
                                                                                // onChange={(e) => setShipping(Number(e.target.value))}
                                                                                onChange={(e) => setCountry(e.target.value)}
                                                                                >
                                                                                <option selected>Country*</option>
                                                                                {countries?.map((country) => (
                                                                                    <option key={country?._id}>{country?.name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required={true} type="text" 
                                                                                className="form-control" 
                                                                                placeholder="Address*" 
                                                                                autoComplete="off" 
                                                                                value={postData?.address}
                                                                                onChange={(e) => setPostData({ ...postData, address: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required={true} 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                placeholder="Zip*" 
                                                                                autoComplete="off" 
                                                                                value={postData?.zip}
                                                                                onChange={(e) => setPostData({ ...postData, zip: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required={true} 
                                                                                type="tel" 
                                                                                className="form-control" 
                                                                                placeholder="Phone*" 
                                                                                autoComplete="off" 
                                                                                value={postData?.phone}
                                                                                onChange={(e) => setPostData({ ...postData, phone: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                required={true} 
                                                                                type="email" 
                                                                                className="form-control" 
                                                                                placeholder="Email*" 
                                                                                autoComplete="off" 
                                                                                value={ userInfo?.email ? userInfo?.email : postData?.email }
                                                                                onChange={(e) => setPostData({ ...postData, email: e.target.value })}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <input 
                                                                                id="account" 
                                                                                type="checkbox" 
                                                                                data-target="create_account" 
                                                                                name="create_account"
                                                                                value={newAccount}
                                                                                onChange={() => setNewAccount(!newAccount)} 
                                                                            />
                                                                            <label for="account" data-toggle="collapse" data-target="#collapseOne" aria-controls="collapseOne">Create a new account?</label>
                                                                        </div>
                                                                        {
                                                                            newAccount
                                                                            ?
                                                                            <div>
                                                                                <input 
                                                                                    required={true} 
                                                                                    type="password" 
                                                                                    className="form-control" 
                                                                                    placeholder="Password*" 
                                                                                    autoComplete="off" 
                                                                                    value={postData?.password}
                                                                                    onChange={(e) => setPostData({ ...postData, password: e.target.value })}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    <button type="submit" className="woocommerce_checkout_place_order">Place Order</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 offset-lg-1 offset-md-1">
                                                            <div className="checkout-items">
                                                                <div className="fs-sm py-p75 padding-1">
                                                                    <span style={{ fontWeight: '700' }}>Your Order </span>
                                                                </div>
                                                                <div className="bgc-white">
                                                                    <div>
                                                                        {
                                                                            cartItems?.map(cartItem => <div className="w-full bwt-1 bc-grey-300">
                                                                                <div className="py-p5 px-1">
                                                                                    <div className="flex">
                                                                                        <a href="#" className="flex-none -ml-5 w-4">
                                                                                            <div className="p-relative h-0 w-full" style={{ paddingBottom: '125%' }}>
                                                                                                <div className="flex justify-center p-absolute pin-t pin-l w-full h-full">
                                                                                                    <img src={`${apiUrl}/images/${cartItem?.image}`} alt="Limited edition" className="h-full" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </a>
                                                                                        <div data-bdd="item-info" className="flex-grow p-relative minw-1 ml-p5">
                                                                                            <div className="flex" data-bdd="campaign-name-item">
                                                                                                <Link 
                                                                                                    className="flex-grow truncate color-black fs-sm"
                                                                                                    to={`/product/${cartItem?.id}`}
                                                                                                >{cartItem?.subtitle}</Link>
                                                                                            </div>
                                                                                            <div className="break-words fs-xs-n">{cartItem?.title}/{cartItem?.color}/{cartItem?.size}</div>
                                                                                            <div className="flex">
                                                                                                <div className="flex-none self-center">
                                                                                                    <div className="fs-xs" data-bdd="qty-item">
                                                                                                        <span>Qty</span>: {cartItem?.quantity}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="flex-grow self-center ta-right" data-bdd="price-item">
                                                                                                    <span>
                                                                                                        <span className="ml-p25">
                                                                                                            <span>{cartItem?.discountPrice ? cartItem?.discountPrice?.toFixed(2) : cartItem?.price?.toFixed(2)}€</span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="py-p75 bwb-1 bwt-1 bc-grey-300 padding-1">
                                                                    <div>
                                                                        <div className="flex flex-no-wrap">
                                                                            <div className="flex-grow">
                                                                                <span>Subtotal</span>
                                                                            </div>
                                                                            <div className="flex-none">
                                                                                <span>{ cartItems?.reduce((a, product) => a + (product?.discountPrice ? product?.discountPrice : product?.price) * product.quantity, 0)?.toFixed(2)}€</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-p25">
                                                                        <div className="flex flex-no-wrap">
                                                                            <div className="flex-grow">
                                                                                <span>Shipping</span>
                                                                            </div>
                                                                            <div className="flex-none">
                                                                                <span>{shipping ? shipping?.toFixed(2) : '0.00'}€</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="py-p75 padding-1">
                                                                    <div className="fs-lg">
                                                                        <div className="flex flex-no-wrap">
                                                                            <div className="flex-grow">
                                                                                <span>Total</span>
                                                                            </div>
                                                                            <div className="flex-none">
                                                                                <span>   
                                                                                    {/* cartItems.reduce((a, product) => a + product.price * product.quantity, 2) -> 2 sepse shipping eshte 2 */}
                                                                                    { cartItems?.reduce((a, product) => a + (product?.discountPrice ? product?.discountPrice : product?.price) * product.quantity, shipping)?.toFixed(2)}€
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="address-woocommerce">
                                                    <form className="checkout woocommerce-checkout" onSubmit={submitHanlder}>
                                                        <div className="col2-set" id="customer_details">
                                                            <div className="about-col-1">
                                                                <div className="woocommerce-billing-fields">
                                                                    <h3>Billing details</h3>
                                                                    <div className="woocommerce-billing-fields__field-wrapper">
                                                                        <p className=" about-form-row form-row-first validate-required" id="billing_first_name_field">
                                                                            <label htmlFor="billing_first_name">First name&nbsp;
                                                                                <abbr className="required" title="required">*</abbr>
                                                                            </label>
                                                                            <span className="woocommerce-input-wrapper">
                                                                                <input 
                                                                                    type="text" 
                                                                                    className="input-text " 
                                                                                    name="billing_first_name" 
                                                                                    id="billing_first_name" 
                                                                                    autoComplete="given-name"
                                                                                    required 
                                                                                    value={postData.firstName}
                                                                                    onChange={(e) => setPostData({ ...postData, firstName: e.target.value })}
                                                                                />
                                                                            </span>
                                                                        </p>
                                                                        <p className="about-form-row form-row-last validate-required" id="billing_last_name_field">
                                                                            <label htmlFor="billing_last_name">Last name&nbsp;
                                                                                <abbr className="required" title="required">*</abbr>
                                                                            </label>
                                                                            <span className="woocommerce-input-wrapper">
                                                                                <input 
                                                                                    type="text" 
                                                                                    className="input-text " 
                                                                                    name="billing_last_name" 
                                                                                    id="billing_last_name" 
                                                                                    autoComplete="family-name" 
                                                                                    required
                                                                                    value={postData.lastName}
                                                                                    onChange={(e) => setPostData({ ...postData, lastName: e.target.value })}
                                                                                />
                                                                            </span>
                                                                        </p>

                                                                        <p className="about-form-row form-row-last validate-required" id="billing_last_name_field">
                                                                            <label htmlFor="billing_last_name">City&nbsp;
                                                                                <abbr className="required" title="required">*</abbr>
                                                                            </label>
                                                                            <span className="woocommerce-input-wrapper">
                                                                                <input 
                                                                                    type="text" 
                                                                                    className="input-text " 
                                                                                    name="billing_last_name" 
                                                                                    id="billing_last_name" 
                                                                                    autoComplete="city"
                                                                                    required 
                                                                                    value={postData.city}
                                                                                    onChange={(e) => setPostData({ ...postData, city: e.target.value })}
                                                                                />
                                                                            </span>
                                                                        </p>

                                                                        <p className="about-form-row form-row-wide address-field validate-required" id="billing_address_1_field">
                                                                            <label htmlFor="billing_address_1">Street address&nbsp;
                                                                                <abbr className="required" title="required">*</abbr>
                                                                            </label>
                                                                            <span className="woocommerce-input-wrapper">
                                                                                <input 
                                                                                    type="text" 
                                                                                    className="input-text " 
                                                                                    name="billing_address_1" id="billing_address_1" 
                                                                                    placeholder="Street name" 
                                                                                    autoComplete="address-line1" 
                                                                                    value={postData.address}
                                                                                    onChange={(e) => setPostData({ ...postData, address: e.target.value })}
                                                                                />
                                                                            </span>
                                                                        </p>
                                                                        <p className="about-form-row form-row-wide address-field validate-required validate-state" id="billing_state_field">
                                                                            <label htmlFor="billing_state">State / Country&nbsp;
                                                                                <abbr className="required" title="required">*</abbr>
                                                                            </label>
                                                                            <span className="woocommerce-input-wrapper">
                                                                                <input 
                                                                                    type="text" 
                                                                                    className="input-text " 
                                                                                    name="billing_state" 
                                                                                    id="billing_state" 
                                                                                    autoComplete="address-level1" 
                                                                                    value={postData.country}
                                                                                    onChange={(e) => setPostData({ ...postData, country: e.target.value })}
                                                                                />
                                                                            </span>
                                                                        </p>
                                                                        <p className="about-form-row form-row-wide address-field validate-required validate-postcode" id="billing_postcode_field">
                                                                            <label htmlFor="billing_postcode">Postcode / ZIP&nbsp;
                                                                                <abbr className="required" title="required">*</abbr>
                                                                            </label>
                                                                            <span className="woocommerce-input-wrapper">
                                                                                <input 
                                                                                    type="text" 
                                                                                    className="input-text " 
                                                                                    name="billing_postcode" 
                                                                                    id="billing_postcode" 
                                                                                    autoComplete="postal-code" 
                                                                                    required
                                                                                    value={postData.zip}
                                                                                    onChange={(e) => setPostData({ ...postData, zip: e.target.value })}
                                                                                />
                                                                            </span>
                                                                        </p>
                                                                        <p className="about-form-row form-row-wide validate-required validate-phone" id="billing_phone_field">
                                                                            <label htmlFor="billing_phone">Phone&nbsp;
                                                                                <abbr className="required" title="required">*</abbr>
                                                                            </label>
                                                                            <span className="woocommerce-input-wrapper">
                                                                                <input 
                                                                                    type="tel" 
                                                                                    className="input-text " 
                                                                                    name="billing_phone" 
                                                                                    id="billing_phone" 
                                                                                    autoComplete="tel"
                                                                                    required 
                                                                                    value={postData.phone}
                                                                                    onChange={(e) => setPostData({ ...postData, phone: e.target.value })}
                                                                                />
                                                                            </span>
                                                                        </p>
                                                                        <p className="about-form-row form-row-wide validate-required validate-email" id="billing_email_field">
                                                                            <label htmlFor="billing_email">Email address&nbsp;
                                                                                <abbr className="required" title="required">*</abbr>
                                                                            </label>
                                                                            <span className="woocommerce-input-wrapper">
                                                                                <input 
                                                                                    type="email" 
                                                                                    className="input-text " 
                                                                                    name="billing_email" 
                                                                                    id="billing_email" 
                                                                                    autoComplete="email username"
                                                                                    required
                                                                                    value={postData.email}
                                                                                    onChange={(e) => setPostData({ ...postData, email: e.target.value })} 
                                                                                />
                                                                            </span>
                                                                        </p> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h3 id="order_review_heading">Your order</h3>
                                                        <div id="order_review" className="woocommerce-checkout-review-order">
                                                            <table className="shop_table woocommerce-checkout-review-order-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="product-name">Product</th>
                                                                        <th className="product-total">Total</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    cartItems?.map(cartItem => <tbody key={cartItem?._id}>
                                                                        <tr className="cart_item">
                                                                            <td className="product-name">
                                                                                <Link 
                                                                                    to={{
                                                                                        pathname: `/product/${cartItem?.category}/${cartItem?.title}`,
                                                                                        state: {
                                                                                        id: cartItem?.id,
                                                                                        }
                                                                                    }}
                                                                                    style={{color: '#215529'}}
                                                                                >{cartItem?.title}</Link> 
                                                                                &nbsp;							 
                                                                                <strong className="product-quantity">× {cartItem?.quantity}</strong>
                                                                                &nbsp;							 
                                                                                <strong className="product-quantity">,Color: {cartItem?.color}</strong>
                                                                                &nbsp;							 
                                                                                <strong className="product-quantity">,Size: {cartItem?.size}</strong>													
                                                                            </td>
                                                                            <td className="product-total">
                                                                                <span className="woocommerce-Price-amount amount">
                                                                                    <span className="woocommerce-Price-currencySymbol"></span>{cartItem?.price}€
                                                                                </span>						
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                )}
                                                                <tfoot>
                                                                    <tr className="cart-subtotal">
                                                                        <th>Subtotal</th>
                                                                        <td>
                                                                            <span className="woocommerce-Price-amount amount">
                                                                                <span className="woocommerce-Price-currencySymbol"></span>
                                                                                { util.formatCurrency(cartItems?.reduce((a, product) => a + product.price * product.quantity, 0))}€
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="woocommerce-shipping-totals shipping">
                                                                        <th>Shipping</th>
                                                                        <td data-title="Shipping">
                                                                            <ul id="shipping_method" className="woocommerce-shipping-methods">
                                                                                <li>
                                                                                    <label htmlFor="shipping_method_0_flat_rate2" style={{fontWeight: 'unset'}}>Flat rate: 
                                                                                        <span className="woocommerce-Price-amount amount">
                                                                                            <span className="woocommerce-Price-currencySymbol"></span>2.00€
                                                                                        </span>
                                                                                    </label>					
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="order-total">
                                                                        <th>Total</th>
                                                                        <td>
                                                                            <strong>
                                                                                <span className="woocommerce-Price-amount amount">
                                                                                    <span className="woocommerce-Price-currencySymbol"></span>
                                                                                    cartItems.reduce((a, product) => a + product.price * product.quantity, 2) -> 2 sepse shipping eshte 2
                                                                                    { util.formatCurrency(cartItems?.reduce((a, product) => a + product.price * product.quantity, 2.00))}€
                                                                                </span>
                                                                            </strong> 
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                            <div id="payment" className="woocommerce-checkout-payment">
                                                                <div className="form-row place-order">
                                                                    <div className="woocommerce-terms-and-conditions-wrapper">
                                                                        <div className="woocommerce-privacy-policy-text">
                                                                            <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our 
                                                                                privacy policy.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="button alt woocommerce_checkout_place_order" name="woocommerce_checkout_place_order" id="place_order" value="Place order" data-value="Place order">Place order</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>




                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}