function listBlogSliderReducer(state = { blogSliders: [] }, action) {
    switch (action.type) {
        case 'BLOG_SLIDER_LIST_REQUEST':
            return { loading: true }
        case 'BLOG_SLIDER_LIST_SUCCESS':
            return { loading: false, blogSliders: action?.payload }
        case 'BLOG_SLIDER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addBlogSliderReducer(state = {}, action) {
    switch(action.type) {
        case 'BLOG_SLIDER_CREATE_REQUEST':
            return {loading: true}
        case 'BLOG_SLIDER_CREATE_SUCCESS':
            return {loading: false, blogSlider: action?.payload, success: true}
        case 'BLOG_SLIDER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateBlogSliderReducer(state = { blogSlider: {} }, action) {
    switch(action.type) {
        case 'BLOG_SLIDER_UPDATE_REQUEST':
            return { loading: true }
        case 'BLOG_SLIDER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'BLOG_SLIDER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteBlogSliderReducer(state = { blogSlider: {} }, action) {
    switch(action.type) {
        case 'BLOG_DELETE_SLIDER_REQUEST':
            return { loading: true }
        case 'BLOG_DELETE_SLIDER_SUCCESS':
            return { loading: false, success: true }
        case 'BLOG_DELETE_SLIDER_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}


export { addBlogSliderReducer, listBlogSliderReducer, updateBlogSliderReducer, deleteBlogSliderReducer }