import axios from "axios";
import { apiUrl } from "../constants/backendUrl";

export const bannerBlogList = () => async (dispatch) => {
  try {
    dispatch({ type: "BLOG_BANNER_LIST_REQUEST" });
    const blogBannerData = await axios.get(`${apiUrl}/bannerBlog`)
    dispatch({ type: "BLOG_BANNER_LIST_SUCCESS", payload: blogBannerData?.data });
  } catch (error) {
    dispatch({ type: "BLOG_BANNER_LIST_FAIL", payload: error });
  }
};

export const createBannerBLog = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: "BLOG_BANNER_CREATE_REQUEST" });
    const { userSignIn: { userInfo } } = getState();
    const formData = new FormData();
    formData.append("image", data.image, data.image.name);
    formData.append('link', data?.link);
    const blogBannerData = await axios.post(`${apiUrl}/bannerBlog`, formData, {
      headers:
        { Authorization: 'Bearer ' + userInfo?.token }
    });
    dispatch({
      type: "BLOG_BANNER_CREATE_SUCCESS",
      payload: blogBannerData?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "BLOG_BANNER_CREATE_FAIL",
      payload: error?.response?.data?.message,
    });
  }
};

export const updateBannerBlog = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: "BLOG_BANNER_UPDATE_REQUEST" });
    const { userSignIn: { userInfo } } = getState();

    const formData = new FormData();
    data.image instanceof Blob && formData.append('image', data.image, data.image.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
    formData.append('link', data?.link);

    const blogBannerData = await axios.patch(
      `${apiUrl}/bannerBlog/${id}`,
      formData, {
        headers:
          { Authorization: 'Bearer ' + userInfo?.token }
    });
    dispatch({
      type: "BLOG_BANNER_UPDATE_SUCCESS",
      payload: blogBannerData?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "BLOG_BANNER_UPDATE_FAIL",
      payload: error?.response?.data?.message,
    });
  }
};

export const deleteBannerBlog = (bannerBlogId) => async (dispatch, getState) => {
  try {
    dispatch({ type: "BLOG_BANNER_DELETE_REQUEST" });
    const { userSignIn: { userInfo } } = getState();

    const { data } = await axios.delete(`${apiUrl}/bannerBlog/${bannerBlogId}`, {
      headers:
        { Authorization: 'Bearer ' + userInfo?.token }
    });
    dispatch({ type: "BLOG_BANNER_DELETE_SUCCESS", payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "BLOG_BANNER_DELETE_FAIL", payload: error?.response?.data?.message });
  }
};
