function listContactBannerReducer(state = { bannerContacts: [] }, action) {
    switch (action.type) {
        case 'CONTACT_BANNER_LIST_REQUEST':
            return { loading: true, bannerContacts: [] }
        case 'CONTACT_BANNER_LIST_SUCCESS':
            return { loading: false, bannerContacts: action?.payload }
        case 'CONTACT_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addContactBannerReducer(state = {}, action) {
    switch (action.type) {
        case 'CONTACT_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'CONTACT_BANNER_CREATE_SUCCESS':
            return { loading: false, bannerContact: action?.payload, success: true }
        case 'CONTACT_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateContactBannerReducer(state = { contact: {} }, action) {
    switch(action.type) {
        case 'CONTACT_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'CONTACT_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'CONTACT_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteContactBannerReducer(state = { bannerContact: {} }, action) {
    switch(action.type) {
        case 'CONTACT_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'CONTACT_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'CONTACT_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listContactBannerReducer, addContactBannerReducer, updateContactBannerReducer, deleteContactBannerReducer }