function listHomeBannerHomeReducer(state = { homeBanners: [] }, action) {
    switch (action.type) {
        case 'HOME_BANNER_LIST_REQUEST':
            return { loading: true }
        case 'HOME_BANNER_LIST_SUCCESS':
            return { loading: false, homeBanners: action?.payload }
        case 'HOME_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addHomeBannerReducer(state = {}, action) {
    switch (action.type) {
        case 'HOME_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'HOME_BANNER_CREATE_SUCCESS':
            return { loading: false, homeBanner: action?.payload, success: true }
        case 'HOME_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateHomeBannerReducer(state = { bannerHome: {} }, action) {
    switch(action.type) {
        case 'HOME_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'HOME_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'HOME_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteHomeBannerReducer(state = { bannerHome: {} }, action) {
    switch(action.type) {
        case 'HOME_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'HOME_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'HOME_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listHomeBannerHomeReducer, addHomeBannerReducer, updateHomeBannerReducer, deleteHomeBannerReducer }