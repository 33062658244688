function listProductReducer(state = { products: [] }, action) {
    switch (action.type) {
        case 'PRODUCTS_LIST_REQUEST':
            return { loading: true, products: [] }
        case 'PRODUCTS_LIST_SUCCESS':
            return { loading: false, products: action?.payload }
        case 'PRODUCTS_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function getProductReducer(state = { product: {} }, action) {
    switch(action.type) {
        case 'PRODUCT_REQUEST':
            return { loading: true }
        case 'PRODUCT_SUCCESS':
            return { loading: false, product: action?.payload, success: true }
        case 'PRODUCT_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addProductReducer(state = {}, action) {
    switch (action.type) {
        case 'PRODUCT_CREATE_REQUEST':
            return { loading: true }
        case 'PRODUCT_CREATE_SUCCESS':
            return { loading: false, products: action?.payload, success: true }
        case 'PRODUCT_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateProductReducer(state = { product: {} }, action) {
    switch(action.type) {
        case 'PRODUCT_UPDATE_REQUEST':
            return { loading: true }
        case 'PRODUCT_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'PRODUCT_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function updateProductDetailsReducer(state = { product: {} }, action) {
    switch(action.type) {
        case 'PRODUCT_DETAILS_UPDATE_REQUEST':
            return { loading: true }
        case 'PRODUCT_DETAILS_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'PRODUCT_DETAILS_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteProductReducer(state = { product: {} }, action) {
    switch(action.type) {
        case 'PRODUCT_DELETE_REQUEST':
            return { loading: true }
        case 'PRODUCT_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'PRODUCT_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listProductReducer, getProductReducer, addProductReducer, updateProductReducer, updateProductDetailsReducer, deleteProductReducer }