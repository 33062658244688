import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../actions/user_actions';
import { bannerLoginList } from '../../actions/bannerLogin_actions';
import { useHistory, Link } from 'react-router-dom';
import { useFormik } from 'formik';

import TopHeader from '../TopHeader/TopHeader';
import SearchBar from "../SearchBar/SearchBar";
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import './style.css';
import Loader from '../Loader/Loader';

export default function Register(props) {
    const { userInfo, error, success } = useSelector(state => state.user_register);
    const { loginBanners, loading: loginBannersLoading } = useSelector(state => state.banner_login_list);
    const dispatch = useDispatch();
    const history = useHistory();

    const validate = values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'This field is required';
        }
        if (!values.surname) {
            errors.surname = 'This field is required';
        }
        if (!values.username) {
            errors.username = 'This field is required';
        }
        if (!values.email) {
            errors.email = 'This field is required';
        }  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (!values.password) {
            errors.password = 'This field is required';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
          name: '',
          surname: '',
          username: '',
          email: '',
          password: ''
        },
        validate,
        onSubmit: (values) => {
            dispatch(registerUser(values));
        }
    });

    useEffect(() => {
        dispatch(bannerLoginList());
        if(success) {
            history.push('/login');
        }
    }, [success])

    return (
        loginBannersLoading
        ?
        <Loader />
        :
        <section id="login2_page">
            <TopHeader />
            {
                loginBanners?.slice(-1)?.map(loginBanner =>  <Slider image = { loginBanner?.image } link={loginBanner?.link} key={loginBanner?._id} />)
            }
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div className="page-width pb-30 account_customers">
                <div className="container login-container">
                    <div className="row justify-content-center">
                        <div className="col-6 col-xs-12 overflow-hidden">
                            <div id="CustomerLoginForm" className="form-vertical">
                                <form onSubmit={formik.handleSubmit} id="customer_login" acceptCharset="UTF-8"><input type="hidden" name="form_type" defaultValue="customer_login" /><input type="hidden" name="utf8" defaultValue="✓" />
                                    <div className="title_block">
                                        <span className="text-bold">Create An Account</span>
                                    </div>
                                    {
                                        error
                                        &&
                                        <div className="errors">
                                            <ul>
                                                <li>{error}</li>
                                            </ul>
                                        </div>
                                    }

                                    <div className="block-form-login">
                                        <div className="title_form"><span>Insert your account information:</span></div>
                                        <div className="form-group novform-email">
                                            <input 
                                                type="text" 
                                                id="Name-login" 
                                                name="name"
                                                placeholder="Name" 
                                                onChange={formik.handleChange}
                                                value={formik.values.name} 
                                            />
                                            {formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                        </div>
                                        <div className="form-group novform-email">
                                            <input 
                                                type="text" 
                                                id="Surname-login" 
                                                name="surname"
                                                placeholder="Surname" 
                                                onChange={formik.handleChange}
                                                value={formik.values.surname} 
                                            />
                                            {formik.errors.surname ? <div className="text-danger">{formik.errors.surname}</div> : null}
                                        </div>
                                        <div className="form-group novform-email">
                                            <input 
                                                type="text" 
                                                id="username-login" 
                                                name="username"
                                                placeholder="Username" 
                                                onChange={formik.handleChange}
                                                value={formik.values.username} 
                                            />
                                            {formik.errors.username ? <div className="text-danger">{formik.errors.username}</div> : null}
                                        </div>
                                        <div className="form-group novform-email">
                                            <input 
                                                type="email" 
                                                id="Email-login" 
                                                name="email"
                                                placeholder="Email" 
                                                onChange={formik.handleChange}
                                                value={formik.values.email} 
                                            />
                                            {formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                        </div>
                                        <div className="form-group novform-password">
                                            <input 
                                                type="password" 
                                                name="password" 
                                                id="HeaderPassword" 
                                                placeholder="Password" 
                                                onChange={formik.handleChange}
                                                value={formik.values.password} 
                                            />
                                            {formik.errors.password ? <div className="text-danger">{formik.errors.password}</div> : null}
                                        </div>
                                        <div className="bank_register">If you have an account, please 
                                            <Link to="/login"> Login Here</Link>
                                        </div>
                                        <div className="form_submit">
                                            <input type="submit" className="btn" value="Register" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}