import React, { useEffect, useState } from 'react';
import { removeFromCart, updateCart } from '../../actions/cart_actions';
import { bannersCartList } from '../../actions/bannerCart_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiUrl } from "../../constants/backendUrl";

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBar from '../SearchBar/SearchBar';
import Loader from '../Loader/Loader';

import minusIcon from '../../images/minus.svg';
import plusIcon from '../../images/plus.svg';

import './style.css';
import './cart-mobile.css';

export default function Cart(props) {
    const { cartItems } = useSelector(state => state.cart);
    const { cartBanners, loading: cartBannersLoading } = useSelector(state => state.banner_cart_list);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(bannersCartList());
    }, []);

    return (
        cartBannersLoading
        ?
        <Loader />
        :
        <section id="cart_page">
            <TopHeader />
            {
                cartBanners?.slice(-1)?.map(cartBanner =>  <Slider image = { cartBanner?.image } />)
            }
            <Header />
            
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <SearchBar />
                                <div className="eltdf-full-width">
                                    <div className="eltdf-full-width-inner">
                                        <div className="eltdf-grid-row">
                                            <div className="eltdf-page-content-holder eltdf-grid-col-12">
                                                <div className="eltdf-row-grid-section-wrapper">
                                                    <div className="eltdf-row-grid-section">
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner vc_custom_1537357253521 first_part_padding_bottom">
                                                                    <div className="wpb_wrapper">                                                                        
                                                                        <div className="woocommerce">
                                                                            <div className="woocommerce-notices-wrapper"></div> 
                                                                            {   cartItems?.length > 0 ?
                                                                                <form className="woocommerce-cart-form desktop-cart"> 
                                                                                    <h2>Shopping Cart</h2> 
                                                                                    <table className="shop_table shop_table_responsive cart woocommerce-cart-form__contents" cellSpacing={0}> 
                                                                                        <thead> 
                                                                                            <tr> 
                                                                                                <th className="product-thumbnail">&nbsp;</th> 
                                                                                                <th className="product-name">Product</th> 
                                                                                                <th className="product-price">Price</th> 
                                                                                                <th className="product-quantity">Quantity</th> 
                                                                                                <th className="product-subtotal">Total</th> 
                                                                                                <th className="product-remove">&nbsp;</th> 
                                                                                            </tr> 
                                                                                        </thead> 
                                                                                        <tbody> 
                                                                                            {
                                                                                                cartItems.map((cartItem) => <tr className="woocommerce-cart-form__cart-item cart_item" key={cartItem?.product}> 
                                                                                                <td className="product-thumbnail"> 
                                                                                                    <Link 
                                                                                                        to={`/product/${cartItem?.id}`}
                                                                                                    > 
                                                                                                        <img src={`${apiUrl}/images/${cartItem?.image}`} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="f" /> 
                                                                                                    </Link> 
                                                                                                </td> 
                                                                                                <td className="product-name" data-title="Product">
                                                                                                    <Link to={`/product/${cartItem?.id}`}
                                                                                                    >{cartItem?.title}</Link> 
                                                                                                    <div className="product-price" data-title="Price"> 
                                                                                                        <span className="woocommerce-Price-amount amount">
                                                                                                            <span className="woocommerce-Price-currencySymbol">{cartItem?.price?.toFixed(2)}€</span>
                                                                                                        </span> 
                                                                                                    </div>
                                                                                                    <div className="product-price" data-title="Price"> 
                                                                                                        <span className="woocommerce-Price-amount amount">
                                                                                                            <span className="woocommerce-Price-currencySymbol">Size: {cartItem?.size}</span>
                                                                                                        </span> 
                                                                                                    </div> 
                                                                                                    <div className="product-price" data-title="Price"> 
                                                                                                        <span className="woocommerce-Price-amount amount">
                                                                                                            <span className="woocommerce-Price-currencySymbol">Color: {cartItem?.color}</span>
                                                                                                        </span> 
                                                                                                    </div>   
                                                                                                </td> 
                                                                                                <td className="product-quantity" id="product_quantity" data-title="Quantity"> 
                                                                                                    <div className="eltdf-quantity-buttons quantity" id="eltdf-quantity-buttons"> 
                                                                                                        <label className="screen-reader-text" htmlFor="quantity_60db2ca24bea5">Quantity</label> 
                                                                                                        <span 
                                                                                                            className="eltdf-quantity-minus arrow_carrot-down" 
                                                                                                            id="eltdf-quantity-minus"
                                                                                                            style={ cartItem?.quantity < 2 ? { pointerEvents: 'none' } : {} }
                                                                                                            onClick={() => dispatch(updateCart({ id:cartItem?.sizeDetailId, quantity: cartItem?.quantity - 1 }))}
                                                                                                        ></span>
                                                                                                        <input 
                                                                                                            type="text" 
                                                                                                            id="quantity_60db2ca24bea5" 
                                                                                                            className="input-text qty text eltdf-quantity-input" 
                                                                                                            value={cartItem?.quantity}
                                                                                                            onChange={() => {}}
                                                                                                            title="Qty" 
                                                                                                            size={4} 
                                                                                                            pattern="[0-9]*" 
                                                                                                            inputMode="numeric" 
                                                                                                            aria-labelledby="Bright Match quantity" 
                                                                                                        /> 
                                                                                                        <span 
                                                                                                            className="eltdf-quantity-plus arrow_carrot-up" 
                                                                                                            id="eltdf-quantity-plus" 
                                                                                                            onClick={() => dispatch(updateCart({ id:cartItem?.sizeDetailId, quantity: cartItem?.quantity + 1 }))}
                                                                                                        /> 
                                                                                                    </div> 
                                                                                                </td> 

                                                                                                <td className="product-remove"> 
                                                                                                    <Link 
                                                                                                        href="javascript:;;" 
                                                                                                        className="remove" 
                                                                                                        aria-label="Remove this item"
                                                                                                        onClick={() => dispatch(removeFromCart(cartItem?.sizeDetailId))}
                                                                                                    >×</Link> 
                                                                                                </td> 
                                                                                             </tr> 
                                                                                                        
                                                                                            )}

                                                                                            <tr> 
                                                                                                <td colSpan="6" className="actions">
                                                                                                    <div className="coupon">
                                                                                                        <Link to="/checkout" className="btn btn-cart">Continue</Link>
                                                                                                    </div>    
                                                                                                </td>
                                                                                            </tr> 
                                                                                        </tbody> 
                                                                                    </table> 
                                                                                </form> 
                                                                            : 
                                                                                <div>
                                                                                    <p className="cart-empty">Your cart is currently empty.</p> 
                                                                                        <p className="return-to-shop">
                                                                                            <Link className="button-cart-empty wc-backward" to="/new">
                                                                                                Return to shop 
                                                                                            </Link>
                                                                                        </p>
                                                                                </div>
                                                                            }

                                                                            {
                                                                                cartItems?.length > 0
                                                                                ?
                                                                                <div id="cartMobile">
                                                                                    <div className="shopping-cart-mobile">
                                                                                        <div className="title">
                                                                                            <h2>Shopping Cart</h2>
                                                                                        </div>
                                                                                        {
                                                                                            cartItems?.map(cartItem => <div className="item">
                                                                                            <div className="buttons">
                                                                                                <Link 
                                                                                                    href="javascript:;;" 
                                                                                                    className="delete-btn" 
                                                                                                    aria-label="Remove this item"
                                                                                                    onClick={() => dispatch(removeFromCart(cartItem?.sizeDetailId))}
                                                                                                ></Link> 
                                                                                            </div>
                                                                                            <div className="image">
                                                                                                <Link
                                                                                                    to={`/product/${cartItem?.id}`}
                                                                                                >
                                                                                                    <img src={`${apiUrl}/images/${cartItem?.image}`}  alt="cart-item image" />
                                                                                                </Link>
                                                                                            </div>
                                                                                            <div className="description">
                                                                                                <Link 
                                                                                                    to={`/product/${cartItem?.id}`}
                                                                                                >{cartItem?.title}</Link>
                                                                                                <Link 
                                                                                                    to={`/product/${cartItem?.id}`}
                                                                                                >{cartItem?.subtitle}
                                                                                                </Link>                                                                                                                                                                                      
                                                                                                <Link 
                                                                                                    to={`/product/${cartItem?.id}`}
                                                                                                >Color: {cartItem?.color}</Link>
                                                                                                <Link 
                                                                                                    to={`/product/${cartItem?.id}`}
                                                                                                >Size: {cartItem?.size}</Link>
                                                                                            </div>
                                                                                            <div className="cart-mobile-quantity">
                                                                                                <button 
                                                                                                    className="minus-btn" 
                                                                                                    type="button" 
                                                                                                    name="button"
                                                                                                    style={ cartItem?.quantity < 2 ? { pointerEvents: 'none' } : {} }
                                                                                                    onClick={() => dispatch(updateCart({ id: cartItem?.sizeDetailId, quantity: cartItem?.quantity - 1 }))}
                                                                                                >
                                                                                                    <img src={minusIcon} alt="" />
                                                                                                </button>
                                                                                                <input 
                                                                                                    type="text" 
                                                                                                    name="name" 
                                                                                                    value={cartItem?.quantity}
                                                                                                />
                                                                                                <button 
                                                                                                    className="plus-btn" 
                                                                                                    type="button" 
                                                                                                    name="button"
                                                                                                    onClick={() => dispatch(updateCart({ id: cartItem?.sizeDetailId, quantity: cartItem?.quantity + 1 }))}
                                                                                                >
                                                                                                    <img src={plusIcon} alt="" />
                                                                                                </button>
                                                                                            </div>
                                                                                            <div className="total-price">{cartItem?.price?.toFixed(2)}€</div>
                                                                                        </div>
                                                                                    )}
                                                                                    </div> 
                                                                                    <div className="coupon" style={{marginTop: '25px'}}>
                                                                                        <Link to="/checkout" className="btn btn-cart" style={{width: '100%'}}>Continue</Link>
                                                                                    </div>           
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }

                                                                            {
                                                                                cartItems?.length > 0 ? 
                                                                                    <div className="cart-collaterals">
                                                                                        <div className="cart_totals ">
                                                                                            <h2>Cart totals</h2>
                                                                                            <table cellSpacing={0} className="shop_table shop_table_responsive">
                                                                                                <tbody>
                                                                                                    <tr className="cart-subtotal">
                                                                                                        <th>Subtotal</th>
                                                                                                        <td data-title="Subtotal" className="subtotal-right">
                                                                                                            <span className="woocommerce-Price-amount amount price-sb_total">
                                                                                                                <span className="woocommerce-Price-currencySymbol">
                                                                                                                    {cartItems.reduce((a, product) => a + (product?.discountPrice ? product?.discountPrice : product?.price) * product.quantity, 0).toFixed(2)}€
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr className="cart-subtotal">
                                                                                                        <th>Shipping</th>
                                                                                                        <td data-title="Subtotal" className="subtotal-right">
                                                                                                            <span className="woocommerce-Price-amount amount price-sb_total">
                                                                                                                <span className="woocommerce-Price-currencySymbol">2.00€</span>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr className="cart-subtotal" className="subtotal-right">
                                                                                                        <th>Total</th>
                                                                                                        <td data-title="Subtotal">
                                                                                                            <span className="woocommerce-Price-amount amount price-sb_total">
                                                                                                                {/* cartItems.reduce((a, product) => a + product.price * product.quantity, 2) -> 2 sepse shipping eshte 2 */}
                                                                                                                <span className="woocommerce-Price-currencySymbol">{ cartItems.reduce((a, product) => a + (product?.discountPrice ? product?.discountPrice : product?.price) * product.quantity, 2.00).toFixed(2)}€
                                                                                                            </span>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
            <Footer />                                       
        </section>
    )
}