import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function cartReducer( state = { cartItems:[] }, action) {
    switch (action.type) {
        case 'CART_ADD_ITEM':
            const item = action?.payload;
            const product = state?.cartItems?.find(x => x?.product === item?.product); //e kqyrim a ekziston ai produkt apo jo
            // if(product) {
            //     return { cartItems: state?.cartItems.map( x => x?.product === product?.product ? item : x ) };
            // } else {
            //     return { cartItems: [...state.cartItems, item] }
            // }
            return { cartItems: [...state?.cartItems, item] }
        case 'CART_UPDATE_ITEM':
                const payload = action?.payload; //payload e ka sizen(e cila osht unike) dhe quantityn qe e dergoj prej frontit kur e preku aty butonin per me ndryshu quantityn

                const existingProduct = state?.cartItems?.find(p => p?.sizeDetailId === payload?.id);

                if(existingProduct) {
                    if(existingProduct?.countInStock < 1 || payload?.quantity > existingProduct?.countInStock) {
                        Toastr.error('Out of stock!');
                    } else {
                        existingProduct.quantity = payload.quantity;
                    }
     
                    return { cartItems: state?.cartItems?.map(x => x?.sizeDetailId === existingProduct?.sizeDetailId ? existingProduct : x ) };
                } else {
                    return { cartItems: [...state.cartItems, payload] }
                }

        case 'CART_REMOVE_ITEM':
            return { cartItems: state?.cartItems?.filter(x => x.sizeDetailId !== action?.payload)}
        case 'CLEAR_CART':
            return { ...state, cartItems: [] }
        default:
            return state;
    }

}

export { cartReducer }