import React from 'react';
import { removeFromCart } from '../../actions/cart_actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiUrl } from "../../constants/backendUrl";
import util from '../../util';

import closeIcon from '../../images/close-icon-modal.svg';

import './style.css';

export default function AddToCartModal(props) {
    const cart = useSelector(state => state.cart);
    const { cartItems } = cart;

    const dispatch = useDispatch();
    
    function closeModal() {
        var element = document.getElementById("cart-modal-right-side");
        var elementOverlay = document.getElementById("la-overlay-global");
        var header = document.getElementById("eltdf-page-header");
        var mobile_header = document.getElementById("eltdf-mobile-header");

        header.style.zIndex = '112';
        mobile_header.style.zIndex = '112';
        element.classList.toggle("cart-flyout-open");
        elementOverlay.classList.toggle('la-overlay-global');
    }

    return (
        <div className="page-template-default page page-id-327 wp-embed-responsive theme-luxsa woocommerce-js ltr luxsa-body lastudio-luxsa body-col-1c page-title-vhide header-v-luxsa-header-01 enable-header-sticky active_page_loading page-use-builder active-prod_btn_mb elementor-default elementor-kit-13 elementor-page elementor-page-327 body-completely-loaded lahb-body lahmb-right e--ua-blink e--ua-chrome e--ua-webkit body-loaded pace-done open-cart-aside">
            <div id="la-overlay-global" className=""></div>
            <div id="cart-modal-right-side" className={`cart-flyout ${props.open}`}>
                <div className="cart-flyout--inner">
                    <a href="javascript:;;;" className="btn-close-cart" onClick={closeModal}>
                        <img src={closeIcon} className="lastudioicon-e-remove" alt="Close Icon" />
                    </a>
                    <div className="cart-flyout__content">
                        <div className="cart-flyout__heading theme-heading">Shopping Cart</div>
                        <div className="cart-flyout__loading">
                            <div className="la-loader spinner3">
                                <div className="dot1" />
                                <div className="dot2" /><div className="bounce1" />
                                <div className="bounce2" /><div className="bounce3" />
                            </div>
                        </div>
                        <div className="widget_shopping_cart_content" style={{opacity: 1}}>
                            <ul className="woocommerce-mini-cart cart_list product_list_widget ">
                                {
                                    cartItems?.map((cartItem) =><li className="woocommerce-mini-cart-item mini_cart_item" key={cartItem?.product}>
                                        <a
                                            href="javascript:;;" 
                                            className="remove remove_from_cart_button" 
                                            aria-label="Remove this item"
                                            onClick={() => dispatch(removeFromCart(cartItem?.sizeDetailId))}
                                        >×</a>											
                                        <Link to={`/product/${cartItem?.id}`}>
                                            <img src={`${apiUrl}/images/${cartItem?.image}`} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" />
                                            {cartItem.title}					
                                        </Link>
                                        <span className="quantity">{cartItem?.quantity} × 
                                            <span className="woocommerce-Price-amount amount">
                                                <bdi>
                                                    <span className="woocommerce-Price-currencySymbol">{cartItem?.discountPrice ? cartItem?.discountPrice : cartItem?.price}€</span>
                                                </bdi>
                                            </span>
                                        </span>				
                                    </li>
                                )}

                            </ul>
                            <p className="woocommerce-mini-cart__total total">
                                <strong>Subtotal:</strong> 
                                <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                            { util.formatCurrency(cartItems?.reduce((a, product) => a + (product?.discountPrice ? product?.discountPrice : product?.price ) * product.quantity, 0))}€
                                        </span>
                                    </bdi>
                                </span>	
                            </p>
                            <p className="woocommerce-mini-cart__buttons buttons">
                                <Link to="/cart" className="button-cart-modal-right-side">View cart</Link>
                                <Link to="/checkout" className="button-cart-modal-right-side checkout">Checkout</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}