import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sizesList } from '../../../actions/size_actions';
import { categoriesList } from '../../../actions/category_actions';
import { subCategoriesList } from '../../../actions/subCategory_actions';
import { subSubCategoriesList } from '../../../actions/subSubCategories_actions';
import { colorsList } from "../../../actions/color_actions";
import { clear } from '../../../actions/clear_action';

import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import crypto from "crypto";

import { apiUrl } from '../../../constants/backendUrl';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function UpdateProduct(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [title, setTitle] = useState(null);
    const [subtitle, setSubTitle] = useState(null);
    const [category, setCategory] = useState(null);
    const [subCategory, setSubCategory] = useState(null);
    const [subSubCategory, setSubSubCategory] = useState(null);
    const [size, setSize] = useState(null);
    const [price, setPrice] = useState(null);
    const [discountPrice, setDiscountPrice] = useState(null);
    const [description, setDescription] = useState(null);
    const [additionalInformation, setAdditionalInformation] = useState(null);
    const [tags, setTags] = useState(null);
    const [sale, setSale] = useState(false);
    const [newIn, setNewIn] = useState(false);

    const { categories } = useSelector((state) => state.categories_list);
    const { subCategories } = useSelector((state) => state.sub_category_list);
    const { subsubCategories } = useSelector((state) => state.sub_sub_category_list);
    const { sizes } = useSelector((state) => state.sizes_list);

    const { id } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if(id) {
            axios.patch(`${apiUrl}/products/${id}`, {title, subtitle, category, subCategory, subSubCategory, size, price, discountPrice, description, additionalInformation, tags, sale, newIn}, {
                headers:
                    { Authorization: 'Bearer ' + userInfo?.token }
            }).then((res) => {
              if (res.data.success) {
                Toastr.success("Produkti u editua me sukses.");
                setLoading(false);
                history.push('/admin/products-list');
                dispatch(clear());
                clearInputs();
              } else {
                Toastr.error("Produkti nuk u editua.Provo përsëri.");
              }
            }).catch(error => setError(error?.response?.data?.message));
        }
      };

    const clearInputs = () => {
        setTitle('');
        setSubTitle('');
        setCategory('');
        setSubCategory('');
        setSubSubCategory('');
        setSize('');
        setPrice('');
        setDiscountPrice('');
        setDescription('');
        setAdditionalInformation('');
        setTags('');
        setSale(false);
    }

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        if(id) {
            axios.get(`${apiUrl}/products/${id}`).then(res => {
                setTitle(res?.data?.product?.title);
                setSubTitle(res?.data?.product?.subtitle);
                setCategory(res?.data?.product?.category);
                setSubCategory(res?.data?.product?.subCategory);
                setSubSubCategory(res?.data?.product?.subSubCategory);
                setSize(res?.data?.product?.size);
                setPrice(res?.data?.product?.price);
                setDiscountPrice(res?.data?.product?.discountPrice);
                setDescription(res?.data?.product?.description);
                setAdditionalInformation(res?.data?.product?.additionalInformation);
                setTags(res?.data?.product?.tags);
                setSale(res?.data?.product?.sale);
                setNewIn(res?.data?.product?.new);
            }).catch(error => console.log(error));
        }

        dispatch(categoriesList());
        dispatch(subCategoriesList());
        dispatch(subSubCategoriesList());
        dispatch(sizesList());
        dispatch(colorsList());
      }, []);

    return (
        <div>
           <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>Edito Produktin</h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        error ? <div className="col-sm-12 mt-3" style={{textAlign: 'center'}}>
                            <p className="text-danger"> {error}</p>
                        </div>
                        :
                        null
                    }
        
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">Edito Produktin</h3>
                                        </div>

                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="title">Titulli</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="title"
                                                        placeholder="Titulli"
                                                        required = { true }
                                                        value={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="title">Nëntitulli</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="subtitle"
                                                        placeholder="Nëntitulli"
                                                        required
                                                        value={subtitle}
                                                        onChange={(e) => setSubTitle(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="category">Kategoria</label>
                                                    <select
                                                        className="form-select"
                                                        id="category"
                                                        required
                                                        value={category}
                                                        onChange={(e) => setCategory(e.target.value)}
                                                        >
                                                        <option selected>Selekto Kategorin</option>
                                                        {categories && categories.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="category">Nën Kategoria</label>
                                                    <select
                                                        className="form-select"
                                                        id="category"
                                                        required
                                                        value={subCategory}
                                                        onChange={(e) => setSubCategory(e.target.value)}
                                                        >
                                                        <option selected>Selekto Nën Kategorin</option>
                                                        {subCategories && subCategories?.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="category">Filterat</label>
                                                    <select
                                                        className="form-select"
                                                        id="category"
                                                        required
                                                        value={subSubCategory}
                                                        onChange={(e) => setSubSubCategory(e.target.value)}
                                                        >
                                                        <option selected>Selekto Filterat</option>
                                                        {subsubCategories && subsubCategories?.map((category) => (
                                                            <option key={category?._id}>{category?.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price" className="form-label"> Çmimi </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="price"
                                                        placeholder="Çmimi"
                                                        required
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price" className="form-label"> Çmimi me zbritje </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="discountprice"
                                                        placeholder="Çmimi me zbritje"
                                                        required
                                                        value={discountPrice}
                                                        onChange={(e) => setDiscountPrice(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="description" className="form-label"> Përshkrimi </label>
                                                    <textarea
                                                        className="form-control"
                                                        id="description"
                                                        rows={3}
                                                        placeholder="Përshkrimi"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="additionalDescription" className="form-label"> Përshkrimi Shtesë </label>
                                                    <textarea
                                                        className="form-control"
                                                        id="additionalDescription"
                                                        placeholder="Additional Description"
                                                        required
                                                        rows={3}
                                                        value={additionalInformation}
                                                        onChange={(e) => setAdditionalInformation(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="tags" className="form-label"> Tags </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="tags"
                                                        placeholder="Tags"
                                                        required
                                                        value={tags}
                                                        onChange={(e) => setTags(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group"> 
                                                    <div className="form-check">
                                                        <input 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            checked={newIn}
                                                            value = {newIn} 
                                                            onChange = {() => setNewIn(!newIn)} id="flexCheckDefault" 
                                                        />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Shto në faqen New?
                                                        </label>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group"> 
                                                    <div className="form-check">
                                                        <input 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            checked={sale}
                                                            value = {sale} 
                                                            onChange = {() => setSale(!sale)} id="flexCheckDefault" 
                                                        />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Shto në faqen Sale?
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="card-footer">
                                                    {
                                                        loading ?
                                                        <button class="btn btn-danger" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span> Loading...</span>
                                                        </button>
                                                        :
                                                        <button type="submit" className="btn btn-danger" onClick={handleSubmit}>
                                                            <span>Edito</span>
                                                        </button>
                                                    }
                                                
                                                </div>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}