function listShopBannerReducer(state = { shopBanners: [] }, action) {
    switch (action.type) {
        case 'SHOP_BANNER_LIST_REQUEST':
            return { loading: true, shopBanners: [] }
        case 'SHOP_BANNER_LIST_SUCCESS':
            return { loading: false, shopBanners: action?.payload }
        case 'SHOP_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addShopBannerReducer(state = {}, action) {
    switch (action.type) {
        case 'SHOP_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'SHOP_BANNER_CREATE_SUCCESS':
            return { loading: false, shopBanner: action?.payload, success: true }
        case 'SHOP_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateShopBannerReducer(state = { shopBanner: {} }, action) {
    switch(action.type) {
        case 'SHOP_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'SHOP_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'SHOP_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteShopBannerReducer(state = { shopBanner: {} }, action) {
    switch(action.type) {
        case 'SHOP_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'SHOP_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'SHOP_BANNER_DELETE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state;
    }
}

export { listShopBannerReducer, addShopBannerReducer, updateShopBannerReducer, deleteShopBannerReducer }