import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { termsOfServiceList, deleteTermsOfService } from '../../../actions/terms_of_service';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminFooter from '../AdminFooter';
import AdminHeader from '../AdminHeader';

const TermsOfService = () => {
    const { userInfo } = useSelector(state => state.userSignIn);
    const terms_of_service_list = useSelector(state => state.terms_of_service_list);
    const { termsOfService, error, loading } = terms_of_service_list;

    const delete_terms_of_service = useSelector(state => state.delete_terms_of_service);
    const { success } = delete_terms_of_service;

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if(!userInfo) {
            history.push('/notfound');
        }
        
        dispatch(termsOfServiceList());
        return () => {

        }
    },[success])

    const deleteHandler = (termsId) => {
        dispatch(deleteTermsOfService(termsId));
    }

    const confirmToDelete = (termsId) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>A jeni i sigurtë se dëshironi me fshi? </h1>
                        <button onClick={onClose}>Anulo</button>
                        <button
                            onClick={() => {
                                deleteHandler(termsId)
                                onClose();
                            }}
                        >
                        Po, Fshije!
                        </button>
                    </div>
                );
            }
        });
        
    }

    return (
        <div className="wrapper">
            <AdminHeader />
            <AdminSideBar />

            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <h1>Terms of Service</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        {loading ? <div className="lds-facebook"><div></div><div></div><div></div></div> : ''}

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Terms Of Service</h3>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {termsOfService.map(terms => <tr key={terms?._id}>
                                                    <td>{Math.random().toString(36).slice(2)}</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <Link className="btn btn-warning btn-sm" to={`/admin/create-terms-service/${terms?._id}`}>
                                                                <span className="fa fa-edit "></span>
                                                            </Link>
                                                            <button className="btn btn-danger btn-sm" onClick={() => confirmToDelete(terms?._id)}>
                                                                <span className="fa fa-trash "></span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                       
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <AdminFooter />
        </div>
    );
};

export default TermsOfService;
