import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersList, deleteUser } from '../../../actions/user_actions';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css'

import AdminSideBar from '../Admin-Sidebar';
import AdminFooter from '../AdminFooter';
import AdminHeader from '../AdminHeader';

const UsersList = () => {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { users, loading, error } = useSelector(state => state.users_list);
    const { success } = useSelector(state => state.userDelete);
    
    const dispatch = useDispatch();
    const history = useHistory();

    const deleteHandler = (userId) => {
        dispatch(deleteUser(userId));
    }

    const confirmToDelete = (userId) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>A jeni i sigurtë se dëshironi me fshi? </h1>
                        <button onClick={onClose}>Anulo</button>
                        <button
                            onClick={() => {
                                deleteHandler(userId)
                                onClose();
                            }}
                        >
                        Po, Fshije!
                        </button>
                    </div>
                );
            }
        });
        
    }
    
    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }
        
        dispatch(usersList());
    
        return () => {

        }
    },[success]);

    return (
        <div className="wrapper">
            <AdminHeader />
            <AdminSideBar />

            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <h1>Përdoruesit</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        {loading ? <div className="lds-facebook"><div></div><div></div><div></div></div> : ''}

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Përdoruesit</h3>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Emri</th>
                                                    <th>Mbiemri</th>
                                                    <th>Username</th>
                                                    <th>Email-i</th>
                                                    <th>Admin</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { users?.map(user => <tr key={user?._id}>
                                                    <td>{user?.name}</td>
                                                    <td>{user?.surname}</td>
                                                    <td>{user?.username}</td>
                                                    <td>{user?.email}</td>
                                                    <td>{user?.isAdmin ? 'Po': 'Jo'}</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button className="btn btn-danger btn-sm" data-toggle="tooltip" data-placement="top" title="Fshijë" onClick={() => confirmToDelete(user?._id)}>
                                                                <span className="fa fa-trash "></span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <AdminFooter />
        </div>
    );
};

export default UsersList;
