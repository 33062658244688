import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';

import { updateOrderStatus } from '../../../actions/order_actions';
import { clear } from '../../../actions/clear_action';

import axios from 'axios';
import { apiUrl } from '../../../constants/backendUrl';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css'

import AdminSideBar from '../Admin-Sidebar';
import AdminFooter from '../AdminFooter';
import AdminHeader from '../AdminHeader';

const ViewOrder = () => {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { update_success } = useSelector(state => state.update_order);
    const [order, setOrder] = useState({});
    
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams(); 

    const updateOrderHanlder = (orderStatus) => {
        dispatch(updateOrderStatus(id, orderStatus));
    }
    
    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }
        
        axios.get(`${apiUrl}/orders/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
          }).then(res =>{
            setOrder(res.data)
        }).catch(error => console.log('Error:', error?.response?.data.msg));

        if(update_success) {
            Toastr.success('Statusi i porosisë u editua me sukses.');
            dispatch(clear()); //per me bo clear staten.
        }

    },[update_success])


    return (
        <div className="wrapper">
            <AdminHeader />
            <AdminSideBar />

            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <h1>Orders</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        {/* {loading ? <div className="lds-facebook"><div></div><div></div><div></div></div> : ''} */}

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Orders</h3>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Fotot e Porosisë</th>
                                                    <th>Titulli</th>
                                                    <th>Nëntitulli</th>
                                                    <th>Sasia</th>
                                                    <th>Ngjyra</th>
                                                    <th>Numri</th>
                                                    <th>Adresa e Porosisë</th>
                                                    <th>Cmimi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    order
                                                    &&
                                                    order?.orderItems?.map(item =>
                                                        <tr key={order?._id}>
                                                            <td>                                                                
                                                                <img className="img img-responsive img-thumbnail" src={`${apiUrl}/images/${item?.image}`} style={{width: '160px', height: 'auto'}} />
                                                            </td>
                                                            <td>{item?.title}</td>
                                                            <td>{item?.subtitle}</td>
                                                            <td>{item?.quantity}</td>
                                                            <td>{item?.color}</td>
                                                            <td>{item?.size}</td>
                                                            <td>
                                                            {order?.firstName} { order?.lastName } <br />
                                                            { order?.email } <br />
                                                            {order?.address} <br />
                                                            Shipping:{order?.shipping?.toFixed(2)}€ <br />
                                                            {order?.phone} <br />
                                                            {order?.city} <br />
                                                            {order?.country} <br />
                                                            </td>
                                                            <td>{item?.discountPrice ? item?.discountPrice?.toFixed(2) : item?.price?.toFixed(2)}€</td>
                                                        </tr>    
                                                    )
                                                }
                                                <td>
                                                    <div className="btn-group">
                                                        {
                                                            order?.status === 'Në Pritje' || order?.status === 'Anuluar'
                                                            ?
                                                            <>
                                                                <Link to={`/admin/order/${order?._id}`} onClick={() => updateOrderHanlder('Sent') } className="btn btn-success btn-sm mr-1 mb-sm-1">Shëno si të Dërguar</Link>
                                                                <button className="btn btn-danger btn-sm mr-1 mb-sm-1" onClick={() => updateOrderHanlder('Anuluar')}>
                                                                    Anulo Porosinë
                                                                </button>
                                                            </>
                                                            :
                                                            order?.status === 'Sent'
                                                            ?
                                                            <>
                                                                <Link to={`/admin/order/${order?._id}`} onClick={() => updateOrderHanlder('Finished') } className="btn btn-success btn-sm mr-1 mb-sm-1">Shëno si të Përfunduar</Link>
                                                                <button className="btn btn-danger btn-sm mr-1 mb-sm-1" onClick={() => updateOrderHanlder('Anuluar')}>
                                                                    Anulo Porosinë
                                                                </button>
                                                            </>
                                                            :
                                                            order?.status === 'Finished'
                                                            ?
                                                            <button className="btn btn-danger btn-sm mr-1 mb-sm-1" onClick={() => updateOrderHanlder('Anuluar')}>
                                                                Anulo Porosinë
                                                            </button>
                                                            :
                                                            ''


                                                        }

                                                        <Link to={`/admin-all-orders`} className="btn btn-info btn-sm mr-1 mb-sm-1">All Orders</Link>
                                                        <p style={{ marginLeft: '2em' }}>Statusi i porosisë: <b>{ order?.status }</b></p>
                                                    </div>
                                                </td>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-sm-5 ml-auto">
                                            <table className="table table-clear">
                                                <tbody>
                                                <tr>
                                                    <td className="left">
                                                    <strong>Nëntotali</strong>
                                                    </td>
                                                    <td className="right">{ order?.orderItems?.reduce((a, product) => a + (product?.discountPrice ? product?.discountPrice : product?.price) * product.quantity, 0).toFixed(2)}€</td>
                                                </tr>
                                                <tr>
                                                    <td className="left">
                                                    <strong>Posta</strong>
                                                    </td>
                                                    <td className="right">€{order?.shipping?.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="left">
                                                    <strong>Totali</strong>
                                                    </td>
                                                    <td className="right">
                                                    <strong>{ order?.orderItems?.reduce((a, product) => a + (product?.discountPrice ? product?.discountPrice : product?.price) * product.quantity, order?.shipping).toFixed(2)}€</strong>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <AdminFooter />
        </div>
    );
};

export default ViewOrder;
