import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const productsList = () => async(dispatch) => {
    try {
        dispatch({ type: 'PRODUCTS_LIST_REQUEST' });
        
        const { data } = await axios.get(`${apiUrl}/products`);
        dispatch({ type: 'PRODUCTS_LIST_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'PRODUCTS_LIST_FAIL', payload: error });
    }
}

export const getProduct = (productId) => async (dispatch) => {
    try {
        dispatch({type: 'PRODUCT_REQUEST'});
        const product = await axios.get(`${apiUrl}/products/${productId}`);
        dispatch({ type: 'PRODUCT_SUCCESS', payload: product.data });
    } catch(error) {
        dispatch({ type: 'PRODUCT_FAIL', payload: error });
    }
}

export const createProduct = (data) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'PRODUCT_CREATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('subtitle', data.subtitle);
        formData.append('category', data.category);
        formData.append('color', data.color);
        formData.append('price', data.price);
        formData.append('description', data.description);
        formData.append('additionalInformation', data.additionalInformation);
        formData.append('countInStock', data.countInStock);
       
        for (const key of Object.keys(data.images)) {
          formData.append('images', data.images[key])
        }

        const productData = await axios.post(`${apiUrl}/products`, data, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'PRODUCT_CREATE_SUCCESS', payload: productData });
    } catch(error) {
        dispatch({ type: 'PRODUCT_CREATE_FAIL', payload: error });
    }
}

export const updateProduct = (id,dataa) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'PRODUCT_UPDATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.patch(`${apiUrl}/products/${id}`, dataa, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'PRODUCT_UPDATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'PRODUCT_UPDATE_FAIL', payload: error });
    }
}

export const updateProductDetailssss = (id,color,colorHex,sku, images) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'PRODUCT_DETAILS_UPDATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        const formData = new FormData();
        formData.append('color', color);
        formData.append('colorHex', colorHex);
        formData.append('sku', sku);
        for (const key of Object.keys(images)) {
            formData.append('images', images[key])
        }

        const { data } = await axios.put(`${apiUrl}/products/details/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'PRODUCT_DETAILS_UPDATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'PRODUCT_DETAILS_UPDATE_FAIL', payload: error });
    }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'PRODUCT_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/products/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'PRODUCT_DELETE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'PRODUCT_DELETE_FAIL', payload: error });
    }
}