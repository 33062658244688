function listContactsReducer(state = { contacts: [] }, action) {
    switch (action.type) {
        case 'CONTACTS_LIST_REQUEST':
            return { loading: true, contacts: [] }
        case 'CONTACTS_LIST_SUCCESS':
            return { loading: false, contacts: action?.payload }
        case 'CONTACTS_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addContactReducer(state = {}, action) {
    switch (action.type) {
        case 'CREATE_CONTACT_REQUEST':
            return { loading: true }
        case 'CREATE_CONTACT_SUCCESS':
            return { loading: false, contact: action?.payload, success: true }
        case 'CREATE_CONTACT_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function deleteContactReducer(state = { contact: {} }, action) {
    switch(action.type) {
        case 'CONTACT_DELETE_REQUEST':
            return { loading: true }
        case 'CONTACT_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'CONTACT_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listContactsReducer, addContactReducer, deleteContactReducer }