import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../actions/user_actions';
import { bannerLoginList } from '../../actions/bannerLogin_actions'
import { useHistory, Link } from 'react-router-dom';
import TopHeader from '../../components/TopHeader/TopHeader';
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from '../Loader/Loader';
import Slider from '../../components/Slider/Slider';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import './style.css';

export default function Login(props) {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const { userInfo, error, success } = useSelector(state => state.userSignIn);
    const { loginBanners, loading: loginBannersLoading } = useSelector(state => state.banner_login_list);

    const dispatch = useDispatch();
    const history = useHistory();

    function loginHandler(e) {
        e.preventDefault();
        dispatch(login(username, password));
    }

    useEffect(() => {
        if(success) {
            history.push('/');
        }
        dispatch(bannerLoginList());

    }, [success])

    return (
        loginBannersLoading
        ?
        <Loader />
        :
        <section id="login2_page">
            <TopHeader />
            {
                loginBanners?.slice(-1)?.map(loginBanner =>  <Slider image = { loginBanner?.image } link={loginBanner?.link} />)
            }
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div className="page-width pb-30 account_customers">
                <div className="container login-container">
                    <div className="row justify-content-center">
                        <div className="col-6 col-xs-12 overflow-hidden">
                            <div id="CustomerLoginForm" className="form-vertical">
                                <form onSubmit={loginHandler} id="customer_login" acceptCharset="UTF-8">
                                    <div className="title_block">
                                        <span className="text-bold">Sign In</span>
                                    </div>
                                    {
                                        error
                                        &&
                                        <div className="errors">
                                            <ul>
                                                <li>{error}</li>
                                            </ul>
                                        </div>
                                    }
                                    <div className="block-form-login">
                                        <div className="title_form"><span>Insert your account information:</span></div>
                                        <div className="form-group novform-email">
                                            <input 
                                                type="text" 
                                                name="customer[email]" 
                                                id="Email-login" 
                                                className 
                                                placeholder="Username" 
                                                required 
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group novform-password">
                                            <input 
                                                type="password" 
                                                name="customer[password]" 
                                                id="HeaderPassword" 
                                                className 
                                                placeholder="Password" 
                                                required 
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="bank_register">If you don't have an account, please 
                                            <Link to="/register"> Register Here</Link>
                                        </div>
                                        <div className="form_submit">
                                            <input type="submit" className="btn" value="Login"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}