import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const cardBannerHomeList = () => async (dispatch) => {
    try {
        dispatch({type: 'CARD_BANNER_LIST_REQUEST'});
        const cardBannerHomeData = await axios.get(`${apiUrl}/homeCardsBannerHome`);
        dispatch({ type: 'CARD_BANNER_LIST_SUCCESS', payload: cardBannerHomeData?.data });
    } catch(error) {
        dispatch({ type: 'CARD_BANNER_LIST_FAIL', payload: error });
    }
}

export const createCardBannerHome = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'CARD_BANNER_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        formData.append('image', data.image, data.image.name);
        formData.append('image1', data.image1, data.image1.name);
        formData.append('title', data.title);
        formData.append('text', data.text);
        formData.append('price', data.price);

        const cardBannerHomeData = await axios.post(`${apiUrl}/homeCardsBannerHome`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CARD_BANNER_CREATE_SUCCESS', payload: cardBannerHomeData?.data });
    } catch(error) {
        dispatch({ type: 'CARD_BANNER_CREATE_FAIL', payload: error });
    }
}

export const updateCardBannerHome = (id,data) => async (dispatch, getState) => {
    try {
        dispatch({type: 'CARD_BANNER_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        data.image instanceof Blob && formData.append('image', data.image, data.image.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
        data.image1 instanceof Blob && formData.append('image1', data.image1, data.image1.name); //sepse ndryshe sbon me bo update, sepse nese nuk selektojm kurgjo ne input na qet error, e prandaj duhet me check a kemi selektu ne input vetem ather dergo imagen n backend per update
        formData.append('title', data.title);
        formData.append('text', data.text);
        formData.append('price', data.price);

        const cardBannerHomeData = await axios.patch(`${apiUrl}/homeCardsBannerHome/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CARD_BANNER_UPDATE_SUCCESS', payload: cardBannerHomeData?.data });
    } catch(error) {
        dispatch({ type: 'CARD_BANNER_UPDATE_FAIL', payload: error?.response?.data?.message  });
    }
}

export const deleteCardBannerHome = (homeCardId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CARD_BANNER_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();
        
        const {data} = await axios.delete(`${apiUrl}/homeCardsBannerHome/${homeCardId}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'CARD_BANNER_DELETE_SUCCESS', payload: data });

    } catch(error) {
        dispatch({ type: 'CARD_BANNER_DELETE_FAIL', payload: error });
    }
}
