import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory, Redirect  } from 'react-router-dom';

import { addToCart } from '../../actions/cart_actions';
import { colorsList } from '../../actions/color_actions';
import { addToWishlist } from '../../actions/wishlist_actions';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import RelatedProducts from '../RelatedProducts/RelatedProducts';
import SearchBar from '../SearchBar/SearchBar';
import Loader from '../Loader/Loader';
import AddToCartModal from '../AddToCartModal/AddToCartModal.js';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axios from 'axios';
import { apiUrl } from "../../constants/backendUrl";

import productBanner from '../../images/product_banner.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import './style.css';
import './product.scss';

export default function Product(props) {
    const [qty, setQty] = useState(1);
    const [product, setProduct] = useState(null);
    const [productColors, setProductColors] = useState([]);
    const [openCartModal, setCartModal] = useState('');

    const [selectedColor, setSelectedColor] = useState('');
    const [sizeSelected, setSizeSelected] = useState('');
    const [countInStock, setCountInStock] = useState('');
    const [sizeDetailId, setSizeDetailId] = useState('');
    const [cartProductId, setCartProductId] = useState(null);

    const { cartItems } = useSelector(state => state.cart);
    const { wishlistItems } = useSelector(state => state.wishlist);
    // const sizes_list = useSelector(state => state.sizes_list);
    // const { sizes } = sizes_list;

    const { colors, loading: colorsLoading } = useSelector(state => state.colors_list);
    // const id = props?.location?.state?.id; // e marr si props id, per mos me marr prej url-se.
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
  
    function encreaseQuantity() {
        setQty(qty + 1);
    }

    function decreaseQuantity() {
        setQty(qty - 1);
    }

    //kjo dallon pak sepse ktu jemi ne singel product -> dhe ketu useri mundet me vendos n cart produktin me ngjyr t ver dhe me ngjyr t kaltert qe i takojn t njetit produkt, por me ngjyra t ndryshme
    function addCart(productId, quantity, isBuyNow) {
        const singleProduct = cartItems.find(x => x?.sizeDetailId === sizeDetailId); //sizeDetailId na duhet per me kqyr a ekziston n cart produkti, productId na nevoitet tek cart_actions per me lyp n backend me id e tabeles produkti qe na gjenerohet nga mongodb.

        if(singleProduct?.sizeDetailId !== sizeDetailId && countInStock > 0 && quantity <= countInStock) { //singleProduct.product -> sepse product eshte id e productit ashtu e kom lon  tek cart_actions-> product: data?._id
            dispatch(addToCart(productId, quantity, selectedColor, sizeSelected, countInStock, sizeDetailId));
                
            var element = document.getElementById("cart-modal-right-side"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
            var elementOverlay = document.getElementById("la-overlay-global"); //per me shfaq opacity pasi t hapet modali i add cartes
            var header = document.getElementById("eltdf-page-header");
            var mobile_header = document.getElementById("eltdf-mobile-header");

            header.style.zIndex = '0';
            mobile_header.style.zIndex = '0';
            elementOverlay.classList.add('la-overlay-global');
            setCartModal(element.classList.add("cart-flyout-open"));
           
            Toastr.success('Produkti u shtua në shportë.');
            setSizeSelected('');
            
        } else if(countInStock < 1 || quantity > countInStock) {
            Toastr.error('Come back soon!');
        } 
        else {
            Toastr.error('Produkti ekziston në shportë!');
        }    
    }

    function searchByColor(id, color) {
        setSelectedColor(color);
        setSizeSelected('');
        setCountInStock('');
        axios.get(`${apiUrl}/products/single-product/color/${id}/${color}`).then(res => {
            if(res.data.success) {
                setCartProductId(res.data.product.availableOptions[0]._id);
                setProduct(res.data.product);
            }
        }).catch(err => {
            Toastr.error('Produkti me ngjyrën e caktuar nuk ekziston.');
        });
    }

    function setSizeAndCountInStock(size, countInStock, sizeDetailId) {
        if(countInStock > 0) {
            setSizeSelected(size);
            setCountInStock(countInStock);
            setSizeDetailId(sizeDetailId);
        }
    }

    function addWishlist(id, checkId, color) {
        const singleProduct = wishlistItems.find(x => x?.product === checkId);

        dispatch(addToWishlist(id, color));
          if(singleProduct?.product !== checkId) { //singleProduct.product -> sepse product eshte id e productit ashtu e kom lon  tek cart_actions-> product: data?._id
            Toastr.success('This product is already added to wishlist.');
        } else {
            Toastr.error('The product is already added to wishlist.');
        }
    }

    useEffect(() => {
        if(id) {
            axios.get(`${apiUrl}/products/${id}`).then(response => {
                setSelectedColor(response?.data?.product?.availableOptions[0]?.color)
                setProduct(response?.data?.product);
                setProductColors(response?.data?.colors);
            }).catch(error => console.log(error));
        }

        dispatch(colorsList());
  
    }, []);

    return (
        colorsLoading
        ?
        <Loader />
        :
        <section id="product_page">
            <TopHeader />
            <Slider image={productBanner} />
            <Header />
            <div className="product-template-default single single-product postid-74 onea-core-1.0 eltdf-social-login-1.0 woocommerce woocommerce-page woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-sticky-header-on-scroll-up eltdf-dropdown-animate-height eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woo-single-page eltdf-woo-single-thumb-on-left-side eltdf-woocommerce-columns-4 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-header-top-enabled eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <div className="eltdf-full-width">
                                    <div className="eltdf-full-width-inner">
                                        <div className="eltdf-grid-row">
                                            <div className="eltdf-page-content-holder eltdf-grid-col-12">
                                                <div className="eltdf-row-grid-section-wrapper">
                                                    <div className="eltdf-row-grid-section" style={{ padding: '122px 0 45px 0' }}>
                                                        <div className="vc_row wpb_row vc_row-fluid vc_custom_1537357253521">
                                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner vc_custom_1537357253521 first_part_padding_bottom">
                                                                    <div className="wpb_wrapper">                                                                        
                                                                        <div className="woocommerce-notices-wrapper"></div> 
                                                                        {
                                                                            product ?
                                                                            <div id="product-74" className="post-74 product type-product status-publish has-post-thumbnail product_cat-casual product_tag-clothes first instock shipping-taxable purchasable product-type-simple">
                                                                                <div className="eltdf-single-product-content">
                                                                                    <div className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-5 images" data-columns={5} style={{opacity: 1, transition: 'opacity 0.25s ease-in-out 0s'}}>
                                                                                        <figure className="woocommerce-product-gallery__wrapper">
                                                                                            <div data-toggle="modal" data-target="#exampleModal" className="woocommerce-product-gallery__image">
                                                                                                <a href="javascript:void">
                                                                                                    <img src={`${apiUrl}/images/${product?.availableOptions[0]?.images[0]}`} className alt="c" title="shop-single-img-3-gallery-1" />
                                                                                                </a>
                                                                                            </div>
                                                                                            <div data-toggle="modal" data-target="#exampleModal" className="woocommerce-product-gallery__image">
                                                                                                <a href="javascript:void">
                                                                                                    <img src={`${apiUrl}/images/${product?.availableOptions[0]?.images[0]}`} className alt="c" title="shop-single-img-3-gallery-1" />
                                                                                                </a>
                                                                                            </div>
                                                                                            <div data-toggle="modal" data-target="#exampleModal" className="woocommerce-product-gallery__image">
                                                                                                <a href="javascript:void">
                                                                                                    <img src={`${apiUrl}/images/${product?.availableOptions[0]?.images[1]}`} className alt="x" title="shop-single-img-3-gallery-2" />
                                                                                                </a>
                                                                                            </div>
                                                                                            <div data-toggle="modal" data-target="#exampleModal" className="woocommerce-product-gallery__image">
                                                                                                <a href="javascript:void">
                                                                                                    <img src={`${apiUrl}/images/${product?.availableOptions[0]?.images[2]}`} className alt="z" title="shop-single-img-3-gallery-3" />
                                                                                                </a>
                                                                                            </div>
                                                                                            <div data-toggle="modal" data-target="#exampleModal" className="woocommerce-product-gallery__image">
                                                                                                <a href="javascript:void">
                                                                                                    <img src={`${apiUrl}/images/${product?.availableOptions[0]?.images[3]}`} className alt="c" title="shop-single-img-3-gallery-4" />
                                                                                                </a>
                                                                                            </div>
                                                                                            <div data-toggle="modal" data-target="#exampleModal" className="woocommerce-product-gallery__image">
                                                                                                <a href="javascript:void" data-rel="prettyPhoto[woo_single_pretty_photo]">
                                                                                                    <img src={`${apiUrl}/images/${product?.availableOptions[0]?.images[4]}`} className alt="f" title="shop-single-img-4-gallery-5"  />
                                                                                                </a>
                                                                                            </div>
                                                                                        </figure>
                                                                                    </div>
                                                                                    <div className="eltdf-single-product-summary">
                                                                                        <div className="summary entry-summary">
                                                                                            <h2 itemProp="name" className="eltdf-single-product-title">{product?.title}</h2>
                                                                                            <p className="price">
                                                                                                <span className="woocommerce-Price-amount amount">
                                                                                                    <span className="woocommerce-Price-currencySymbol">{product?.discountPrice ? product?.discountPrice.toFixed(2) : product?.price.toFixed(2)}€</span>
                                                                                                </span>
                                                                                            </p>
                                                                                            {
                                                                                                product?.discountPrice ? <p                                                                     
                                                                                                style={ product?.discountPrice ? { color: '#acabab', textDecoration: 'line-through' } : {} }
                                                                                                rel="tag"
                                                                                                >{product?.price?.toFixed(2)}€</p>
                                                                                            : null
                                                                                            }

                                                                                            <div className="woocommerce-product-details__short-description" style={{ margin: '30px 0 0 0' }}>
                                                                                                <p>{product?.description}</p>
                                                                                            </div>

                                                                                            <div className="filter-item_content collapse show" id="product-sizes-pr12345678">
                                                                                                <ul className="list-inline">
                                                                                                    {/* {
                                                                                                        product?.availableOptions[0]?.sizeObj?.map(item => 
                                                                                                            item?.sizeObj?.map(i => <li className="list-inline-item" key={i._id} onClick={() => setSizeSelected(i?.size)}>
                                                                                                            <span className="filter-option-value">{i?.size}</span>
                                                                                                        </li>)
                                                                                                        )
                                                                                                    } */}
                                                                                                    <div className="product-size">
                                                                                                        <div className="size-choose">
                                                                                                            {
                                                                                                                product?.availableOptions[0]?.sizeObj?.map((item, index) => <div key={item?._id}>
                                                                                                                    <input  
                                                                                                                        type="radio" 
                                                                                                                        id={`size${item?.size}`} 
                                                                                                                        name="sizes" 
                                                                                                                    />
                                                                                                                    <label htmlFor={`${item?.countInStock < 1 ? 'out-of-stock' : `size${item?.size}`}`} onClick={() => setSizeAndCountInStock(item?.size, item?.countInStock, item?._id)}>
                                                                                                                        <span className={`${item?.countInStock < 1 ? 'out-of-stock' : ''}`}>{item?.size}</span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* {
                                                                                                        product?.availableOptions[0]?.sizeObj?.map((item, index) => <li className="list-inline-item" key={index} onClick={() => setSizeAndCountInStock(item?.size, item?.countInStock, item?._id)}>
                                                                                                            <span className="filter-option-value">{item?.size}</span>
                                                                                                        </li>)
                                                                                                    } */}
                                                                                                </ul>
                                                                                            </div>

                                                                                            {/* <table className="variations" cellSpacing={0}>
                                                                                                <tbody>
                                                                                                <tr>
                                                                                                    <td className="value">
                                                                                                        <div className="select_box_colorpicker select_box attribute_pa_colorpicker">
                                                                                                            {
                                                                                                                productColors?.map(c => <div className="select_option_colorpicker select_option" data-value="beige" key={c?._id}>
                                                                                                                    <span className="yith_wccl_value" onClick={() => searchByColor(id, c?.color)} style={{background: c?.colorHex, border: `1px solid rgba(211, 211, 211, 0.96)`}} />
                                                                                                                </div>)
                                                                                                            }
                                                                                                        </div>
                                                                                                        <a className="reset_variations" href="#" style={{visibility: 'hidden'}}>Clear</a> 
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            </table> */}

                                                                                            <div className="product-color">
                                                                                                <div className="color-choose">
                                                                                                    {
                                                                                                        productColors?.map((c, index) => <div key={c?._id}>
                                                                                                            <input  
                                                                                                                type="radio" 
                                                                                                                id={c?.color} 
                                                                                                                name="colors" 
                                                                                                                defaultChecked = {index === 0 ? true : false }
                                                                                                            />
                                                                                                            <label htmlFor={c?.color} onClick={() => searchByColor(id, c?.color)}>
                                                                                                                <span style={{ backgroundColor: c?.colorHex }}></span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>

                                                                                            <form className="cart">
                                                                                                <div className="eltdf-quantity-buttons quantity">
                                                                                                    <label className="screen-reader-text" htmlFor="quantity_60dc64bd25e1d">Black Heals quantity</label>
                                                                                                    <span 
                                                                                                        className="eltdf-quantity-minus icon_minus-06 product-page-eltdf-quantity-minus" 
                                                                                                        style={ qty < 2 ? { pointerEvents: 'none' } : {} }
                                                                                                        onClick={decreaseQuantity}
                                                                                                    />
                                                                                                    <input 
                                                                                                        type="text" 
                                                                                                        id="quantity_60dc64bd25e1d" 
                                                                                                        className="input-text qty text eltdf-quantity-input" 
                                                                                                        title="Qty" 
                                                                                                        value={qty}
                                                                                                        style={{border: 'none'}}
                                                                                                    />
                                                                                                    <span 
                                                                                                        className="eltdf-quantity-plus icon_plus product-page-eltdf-quantity-plus" 
                                                                                                        style={ countInStock < 1 || qty >= countInStock ? { pointerEvents: 'none' } : {} }
                                                                                                        onClick={encreaseQuantity}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="yith-wcwl-add-to-wishlist add-to-wishlist-293 exists wishlist-fragment on-first-load">
                                                                                                    <div className="yith-wcwl-wishlistexistsbrowse btn-add-to-cart">
                                                                                                        <a 
                                                                                                            href="javascript:;"
                                                                                                            // style={ product?.availableOptions[0]?.countInStock < 1  ? { pointerEvents: 'none' } : {} }
                                                                                                            style={ !sizeSelected  ? { pointerEvents: 'none' } : {} }
                                                                                                            onClick={() => addCart(product?._id, qty)}
                                                                                                        >
                                                                                                            { !sizeSelected ? 'Select a size' : 'Add to Cart' }
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="yith-wcwl-add-to-wishlist add-to-wishlist-293 exists wishlist-fragment on-first-load">
                                                                                                    <div className="yith-wcwl-wishlistexistsbrowse btn-add-to-cart">
                                                                                                        <a 
                                                                                                            href="javascript:;"
                                                                                                            // style={ product?.availableOptions[0]?.countInStock < 1  ? { pointerEvents: 'none' } : {} }
                                                                                                            style={ !sizeSelected  ? { pointerEvents: 'none' } : {} }
                                                                                                            onClick={() => addCart(product?._id, qty, true)}
                                                                                                        >
                                                                                                            Buy Now
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="yith-wcwl-add-to-wishlist add-to-wishlist-293 exists wishlist-fragment on-first-load">
                                                                                                    <div className="yith-wcwl-wishlistexistsbrowse btn-add-to-cart">
                                                                                                        <a 
                                                                                                            href="javascript:void()"
                                                                                                            // style={ product?.availableOptions[0]?.countInStock < 1  ? { pointerEvents: 'none' } : {} }
                                                                                                            onClick={() => addWishlist(product?._id, product?.availableOptions[0]?._id, product?.availableOptions[0]?.color)}
                                                                                                        >
                                                                                                            Add To Wishlist
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                    
                                                                                            </form>
                                                                                            <div className="clear"></div>

                                                                                            <div className="product_meta">
                                                                                                <span className="sku_wrapper">SKU: <span className="sku">{product?.availableOptions[0]?.sku}</span></span>
                                                                                                <span className="posted_in">Category: <span className="sku">{product?.category}</span></span>
                                                                                                <span className="tagged_as">Tags: <a href="javascript;;;" rel="tag">{product?.tags}</a></span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="woocommerce-tabs wc-tabs-wrapper">
                                                                                    <Tabs>
                                                                                        <TabList>
                                                                                            <Tab>Description</Tab>
                                                                                            <Tab>Additional information</Tab>
                                                                                        </TabList>

                                                                                        <TabPanel>
                                                                                            <p>{product?.description}</p>
                                                                                        </TabPanel>
                                                                                        <TabPanel>
                                                                                        <p>{product?.additionalInformation}</p>
                                                                                        </TabPanel>
                                                                                    </Tabs>
                                                                                </div>
                                                                                <RelatedProducts color = { selectedColor } />
                                                                                <SearchBar />
                                                                            </div>
                                                                       : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <AddToCartModal />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                product
                &&
                <div>
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-hidden="true" style={{ paddingLeft: '17px', paddingRight: '17px' }}>
                        <div className="modal-dialog modal-dialog-centered" style={{ minWidth: 'unset' }} role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ top: '0', fontSize: '32px' }}>
                                    <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div id="carouselExample" className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target="#carouselExample" data-slide-to={0} className="active" />
                                            <li data-target="#carouselExample" data-slide-to={1} />
                                            <li data-target="#carouselExample" data-slide-to={2} />
                                            <li data-target="#carouselExample" data-slide-to={3} />
                                        </ol>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="d-block w-100" src={`${apiUrl}/images/${product?.availableOptions[0]?.images[0]}`} alt="First slide" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="d-block w-100" src={`${apiUrl}/images/${product?.availableOptions[0]?.images[1]}`}alt="Second slide" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="d-block w-100" src={`${apiUrl}/images/${product?.availableOptions[0]?.images[2]}`} alt="Third slide" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="d-block w-100" src={`${apiUrl}/images/${product?.availableOptions[0]?.images[3]}`} alt="Fourth slide" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="d-block w-100" src={`${apiUrl}/images/${product?.availableOptions[0]?.images[4]}`} alt="Fifth slide" />
                                        </div>
                                    </div>
                                    <a className="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true" />
                                        <span className="sr-only">Next</span>
                                    </a>
                                    </div>
                                </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            }                                                                                                  
            <Footer />
        </section>
    )
}