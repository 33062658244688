function listCardBannerHomeReducer(state = { cardsHome: [] }, action) {
    switch (action.type) {
        case 'CARD_BANNER_LIST_REQUEST':
            return { loading: true }
        case 'CARD_BANNER_LIST_SUCCESS':
            return { loading: false, cardsHome: action?.payload }
        case 'CARD_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addCardHomeReducer(state = {}, action) {
    switch (action.type) {
        case 'CARD_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'CARD_BANNER_CREATE_SUCCESS':
            return { loading: false, cardHome: action?.payload, success: true }
        case 'CARD_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateCardHomeReducer(state = { cardHome: {} }, action) {
    switch(action.type) {
        case 'CARD_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'CARD_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'CARD_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteCardHomeReducer(state = { cardHome: {} }, action) {
    switch(action.type) {
        case 'CARD_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'CARD_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'CARD_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listCardBannerHomeReducer, addCardHomeReducer, updateCardHomeReducer, deleteCardHomeReducer }