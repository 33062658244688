function listWishlistBannerReducer(state = { wishlistBanners: [] }, action) {
    switch (action.type) {
        case 'WISHLIST_BANNER_LIST_REQUEST':
            return { loading: true, wishlistBanners: [] }
        case 'WISHLIST_BANNER_LIST_SUCCESS':
            return { loading: false, wishlistBanners: action?.payload }
        case 'WISHLIST_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addWishlistBannerReducer(state = {}, action) {
    switch (action.type) {
        case 'WISHLIST_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'WISHLIST_BANNER_CREATE_SUCCESS':
            return { loading: false, wishlistBanner: action?.payload, success: true }
        case 'WISHLIST_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateWishlistBannerReducer(state = { wishlistBanner: {} }, action) {
    switch(action.type) {
        case 'WISHLIST_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'WISHLIST_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'WISHLIST_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteWishlistBannerReducer(state = { wishlistBanner: {} }, action) {
    switch(action.type) {
        case 'WISHLIST_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'WISHLIST_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'WISHLIST_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listWishlistBannerReducer, addWishlistBannerReducer, updateWishlistBannerReducer, deleteWishlistBannerReducer }