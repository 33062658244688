import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ordersList, deleteOrder } from '../../../actions/order_actions';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';
import moment from 'moment-js';
import { apiUrl } from '../../../constants/backendUrl';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css'

import AdminSideBar from '../Admin-Sidebar';
import AdminFooter from '../AdminFooter';
import AdminHeader from '../AdminHeader';

const OrdersList = () => {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { success } = useSelector(state => state.delete_order);
    const { orders, loading, error } = useSelector(state => state.orders_list);
    
    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }
        
        dispatch(ordersList());
    },[success])

    const deleteHandler = (orderId) => {
        dispatch(deleteOrder(orderId));
    }

    const confirmToDelete = (orderId) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>A jeni i sigurtë se dëshironi me fshi? </h1>
                        <button onClick={onClose}>Anulo</button>
                        <button
                            onClick={() => {
                                deleteHandler(orderId)
                                onClose();
                            }}
                        >
                        Po, Fshije!
                        </button>
                    </div>
                );
            }
        });
        
    }

    return (
        <div className="wrapper">
            <AdminHeader />
            <AdminSideBar />

            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <h1>Orders</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        {loading ? <div className="lds-facebook"><div></div><div></div><div></div></div> : ''}

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Orders</h3>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <table className="table table-hover text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Data e Porosisë</th>
                                                    <th>Adresa e Porosisë</th>
                                                    <th>Totali i Porosisë</th>
                                                    <th>Statusi i Porosisë</th>
                                                    <th>Gjendja e Porosisë</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { orders?.map(order => <tr key={order?._id}>
                                                    <td>{moment(order?.createdAt).format('YYYY-MM-DD HH:mm:ss') }</td>
                                                    <td>
                                                        {order?.firstName} { order?.lastName } <br />
                                                        { order?.email } <br />
                                                        {order?.address} <br />
                                                        Shipping:{order?.shipping?.toFixed(2)}€ <br />
                                                        {order?.phone} <br />
                                                        {order?.city} <br />
                                                        {order?.country} <br />
                                                    </td>
                                                    <td>{order?.orderItems?.reduce((a, product) => a + (product?.discountPrice ? product?.discountPrice : product?.price) * product.quantity, order?.shipping).toFixed(2)}€</td>
                                                    <td>{order?.status}</td>
                                                    {/* {
                                                        order?.orderItems?.map(item =>
                                                            <img className="img img-responsive img-thumbnail" src={`${apiUrl}/images/${item?.image}`} style={{width: '160px', height: 'auto'}} />

                                                        )    
                                                    } */}
                                                    <td>
                                                        <div className="btn-group">
                                                            <Link to={`/admin/order/${order?._id}`} className="btn btn-info btn-sm mr-1 mb-sm-1">Shikoni Porosinë</Link>
                                                            <button className="btn btn-danger btn-sm mr-1 mb-sm-1" onClick={() => confirmToDelete(order?._id)}>
                                                                Fshij Porosinë
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <AdminFooter />
        </div>
    );
};

export default OrdersList;
