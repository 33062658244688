function listSubCategoryReducer(state = { subCategories: [] }, action) {
    switch (action.type) {
        case 'SUB_CATEGORIES_LIST_REQUEST':
            return { loading: true, subCategories: [] }
        case 'SUB_CATEGORIES_LIST_SUCCESS':
            return { loading: false, subCategories: action?.payload }
        case 'SUB_CATEGORIES_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addSubCategoryReducer(state = {}, action) {
    switch (action.type) {
        case 'SUB_CATEGORY_CREATE_REQUEST':
            return { loading: true }
        case 'SUB_CATEGORY_CREATE_SUCCESS':
            return { loading: false, subCategory: action?.payload, success: true }
        case 'SUB_CATEGORY_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateSubCategoryReducer(state = { subCategory: {} }, action) {
    switch(action.type) {
        case 'SUB_CATEGORY_UPDATE_REQUEST':
            return { loading: true }
        case 'SUB_CATEGORY_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'SUB_CATEGORY_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteSubCategoryReducer(state = { subCategory: {} }, action) {
    switch(action.type) {
        case 'SUB_CATEGORY_DELETE_REQUEST':
            return { loading: true }
        case 'SUB_CATEGORY_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'SUB_CATEGORY_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listSubCategoryReducer, addSubCategoryReducer, updateSubCategoryReducer, deleteSubCategoryReducer }