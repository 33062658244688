import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const blogSlidersList = () => async (dispatch) => {
    try {
        dispatch({type: 'BLOG_SLIDER_LIST_REQUEST'});
        const sliders = await axios.get(`${apiUrl}/blogSliderBanner`);
        dispatch({ type: 'BLOG_SLIDER_LIST_SUCCESS', payload: sliders?.data });
    } catch(error) {
        dispatch({ type: 'BLOG_SLIDER_LIST_FAIL', payload: error });
    }
}

export const addBlogSlider = (postData) => async (dispatch, getState) => {
    try {
        dispatch({type: 'BLOG_SLIDER_CREATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        for (const key of Object.keys(postData.images)) {
          formData.append('images', postData.images[key])
        }

        const sliderData = await axios.post(`${apiUrl}/blogSliderBanner`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        })
        dispatch({ type: 'BLOG_SLIDER_CREATE_SUCCESS', payload: sliderData.data });
    } catch(error) {
        dispatch({ type: 'BLOG_SLIDER_CREATE_FAIL', payload: error?.response?.data?.message });
    }
}

export const updateBlogSlider = (id,postData) => async (dispatch, getState) => {
    try {
        dispatch({type: 'BLOG_SLIDER_UPDATE_REQUEST'});
        const { userSignIn: { userInfo } } = getState();

        const formData = new FormData();
        for (const key of Object.keys(postData.images)) {
          formData.append('images', postData.images[key])
        }

        const sliderData = await axios.patch(`${apiUrl}/blogSliderBanner/${id}`, formData, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'BLOG_SLIDER_UPDATE_SUCCESS', payload: sliderData.data });
    } catch(error) {
        dispatch({ type: 'BLOG_SLIDER_UPDATE_FAIL', payload: error?.response?.data?.message});
    }
}

export const deleteBlogSlider = (sliderId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'BLOG_DELETE_SLIDER_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const {data} = await axios.delete(`${apiUrl}/blogSliderBanner/${sliderId}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'BLOG_DELETE_SLIDER_SUCCESS', payload: data });

    } catch(error) {
        dispatch({ type: 'BLOG_DELETE_SLIDER_FAIL', payload: error });
    }
}
