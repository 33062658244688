import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCardBannerBlog, updateCardBannerBlog } from '../../../actions/blogCard_actions';
import { clear } from '../../../actions/clear_action';
import { useHistory, useParams } from 'react-router-dom';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { apiUrl } from '../../../constants/backendUrl';
import axios from 'axios';
import { useFormik } from 'formik';
import imageCompression from 'browser-image-compression';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddCardBlog(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { success, loading: loadingCreate } = useSelector(state => state.add_card_blog);
    const { update_success, loading } = useSelector(state => state.update_card_blog);

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const validate = values => {
        const errors = {};
        if (!values.image) {
          errors.image = 'This field is required';
        }
        if (!values.title) {
            errors.title = 'This field is required';
        }
        if (!values.subtitle) {
            errors.subtitle = 'This field is required';
        }
        if (!values.text) {
            errors.text = 'This field is required';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
          image: '',
          title: '',
          text: ''
        },
        validate,
        onSubmit: (values, {resetForm}) => {
          if(id) {
            dispatch(updateCardBannerBlog(id, values));
          } else {
              dispatch(createCardBannerBlog(values));
            //   resetForm();
          }
        },
    });

    const handleImageUpload = async (e) => {
        const imageFile = e.target.files[0];      
        const options = {
        //   maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);      
          formik.setFieldValue('image', compressedFile);
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        if(id) {
            axios.get(`${apiUrl}/blogCards/${id}`, {
                headers:
                  { Authorization: 'Bearer ' + userInfo?.token }
            })
            .then(response => {
                formik.setFieldValue('image', response.data.image);
                formik.setFieldValue('title', response.data.title);
                formik.setFieldValue('subtitle', response.data.subtitle);
                formik.setFieldValue('text', response.data.text);
          }).catch(error => console.log(error));
        }
        if(success) {
            Toastr.success('Blog Card u krijua me sukses.');
            history.push('/admin/blog-card-list');
            dispatch(clear()); //per me bo clear staten.
        }
        if(update_success) {
            Toastr.success('Blog Card u editua me sukses.');
            history.push('/admin/blog-card-list');
            dispatch(clear()); //per me bo clear staten.
        }
        return () => {
        }
    }, [success, update_success]);

    return (
        <div>
            <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>{id ? 'Edito Card Blog' : 'Krijo Card Blog'}</h1>
                                </div>
                            </div>
                        </div>
                    </section>
        
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">{id ? 'Edito Card Blog' : 'Krijo Card Blog'}</h3>
                                        </div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="card-blog">Zgjidh foton</label>
                                                    <input 
                                                        type="file"
                                                        name="image"  
                                                        className="form-control" 
                                                        id="card-blog" 
                                                        accept=".png, .jpg, .jpeg"
                                                        onChange={(event) => handleImageUpload(event)}  
                                                    />
                                                    {formik.errors.image ? <div className="text-danger">{formik.errors.image}</div> : null}
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="title">Titulli</label>
                                                    <input 
                                                        name="title" 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="title" 
                                                        placeholder="Titulli" 
                                                        onChange={formik.handleChange}
                                                        value={formik.values.title} 
                                                    />
                                                    {formik.errors.title ? <div className="text-danger">{formik.errors.title}</div> : null}
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="title">Nën Titulli</label>
                                                    <input 
                                                        name="subtitle" 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="title" 
                                                        placeholder="Titulli" 
                                                        onChange={formik.handleChange}
                                                        value={formik.values.subtitle} 
                                                    />
                                                    {formik.errors.subtitle ? <div className="text-danger">{formik.errors.subtitle}</div> : null}
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="text">Text</label>
                                                    <input 
                                                        name="text" 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="text" 
                                                        placeholder="Përshkrimi" 
                                                        onChange={formik.handleChange}
                                                        value={formik.values.text}  
                                                    />
                                                    {formik.errors.text ? <div className="text-danger">{formik.errors.text}</div> : null}
                                                </div>

                                            </div>
                                            <div className="card-footer">
                                                {
                                                    loading || loadingCreate ?
                                                    <button class="btn btn-danger" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span> Loading...</span>
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-danger" disabled={!(formik.isValid && formik.dirty)}>
                                                        <span>{id ? 'Edito' : 'Shto'}</span>
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}