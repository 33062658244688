function listSubscribeReducer(state = { subscribes: [] }, action) {
    switch (action.type) {
        case 'SUBSCRIBES_LIST_REQUEST':
            return { loading: true, subscribes: [] }
        case 'SUBSCRIBES_LIST_SUCCESS':
            return { loading: false, subscribes: action?.payload }
        case 'SUBSCRIBES_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addSubscribeReducer(state = {}, action) {
    switch (action.type) {
        case 'SUBSCRIBE_CREATE_REQUEST':
            return { loading: true }
        case 'SUBSCRIBE_CREATE_SUCCESS':
            return { loading: false, subscribe: action?.payload, success: true }
        case 'SUBSCRIBE_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateSubscribeReducer(state = { subscribe: {} }, action) {
    switch(action.type) {
        case 'SUBSCRIBE_UPDATE_REQUEST':
            return { loading: true }
        case 'SUBSCRIBE_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'SUBSCRIBE_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteSubscribeReducer(state = { subscribe: {} }, action) {
    switch(action.type) {
        case 'SUBSCRIBE_DELETE_REQUEST':
            return { loading: true }
        case 'SUBSCRIBE_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'SUBSCRIBE_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listSubscribeReducer, addSubscribeReducer, updateSubscribeReducer, deleteSubscribeReducer }