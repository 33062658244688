function listCategoryBannerBlogReducer(state = { categoriesBannerBlog: [] }, action) {
    switch (action.type) {
        case 'CATEGORIES_BLOG_BANNER_LIST_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BLOG_BANNER_LIST_SUCCESS':
            return { loading: false, categoriesBannerBlog: action?.payload }
        case 'CATEGORIES_BLOG_BANNER_LIST_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

function addBannerBlogReducer(state = {}, action) {
    switch (action.type) {
        case 'CATEGORIES_BLOG_BANNER_CREATE_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BLOG_BANNER_CREATE_SUCCESS':
            return { loading: false, newCategoryBannerBlog: action?.payload, success: true }
        case 'CATEGORIES_BLOG_BANNER_CREATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {}
        default:
            return state;
    }
}

function updateBannerBlogReducer(state = { newCategoryBannerBlog: {} }, action) {
    switch(action.type) {
        case 'CATEGORIES_BLOG_BANNER_UPDATE_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BLOG_BANNER_UPDATE_SUCCESS':
            return { loading: false, update_success: true }
        case 'CATEGORIES_BLOG_BANNER_UPDATE_FAIL':
            return { loading: false, error: action?.payload }
        case 'CLEAR':
            return {update_success: false}
        default:
            return state;
    }
}

function deleteBannerBlogReducer(state = { newCategoryBannerBlog: {} }, action) {
    switch(action.type) {
        case 'CATEGORIES_BLOG_BANNER_DELETE_REQUEST':
            return { loading: true }
        case 'CATEGORIES_BLOG_BANNER_DELETE_SUCCESS':
            return { loading: false, success: true }
        case 'CATEGORIES_BLOG_BANNER_DELETE_FAIL':
            return { loading: false, error: action?.payload }
        default:
            return state;
    }
}

export { listCategoryBannerBlogReducer, addBannerBlogReducer, updateBannerBlogReducer, deleteBannerBlogReducer }