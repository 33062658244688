import { applyMiddleware, combineReducers, createStore, compose  } from 'redux';
import thunk from 'redux-thunk';
import Cookie from 'js-cookie';

// all reducers:
import { cartReducer } from './reducers/cartReducers';
import { wishlistReducer } from './reducers/wishlistReducers';
import { listCategoryReducer, addCategoryReducer, updateCategoryReducer, deleteCategoryReducer } from './reducers/category_reducers';
import { listProductReducer, getProductReducer, addProductReducer, updateProductReducer, updateProductDetailsReducer, deleteProductReducer } from './reducers/product_reducers';
import { listOrderReducer, addOrderReducer, updateOrderReducer, deleteOrderReducer } from './reducers/orderReducers';
import { listSubscribeReducer, addSubscribeReducer, updateSubscribeReducer, deleteSubscribeReducer } from './reducers/subscribeReducers';
import { listSizeReducer, addSizeReducer, updateSizeReducer, deleteSizeReducer } from './reducers/sizeReducers';
import { listColorReducer, addColorReducer, updateColorReducer, deleteColorReducer } from './reducers/colorReducers';
import { listNewCollectionBannerReducer, addNewCollectionReducer, updateNewCollectionReducer, deleteNewCollectionReducer } from './reducers/newCollectionReducers';
import { listCategoryBannerHomeReducer, addBannerHomeReducer, updateBannerHomeReducer, deleteBannerHomeReducer } from './reducers/categoriesBannerHomeReducer';
import { listTransportBannerHomeReducer, addTransportBannerHomeReducer, updateTransportBannerHomeReducer, deleteTransportBannerHomeReducer } from './reducers/transportBannerReducers';
import { listCardBannerHomeReducer, addCardHomeReducer, updateCardHomeReducer, deleteCardHomeReducer } from './reducers/homeCardReducers';
import { listSliderReducer, addSliderReducer, updateSliderReducer, deleteSliderReducer } from './reducers/homeSliderReducers';
import { listCategoryBannerBlogReducer, addBannerBlogReducer, updateBannerBlogReducer, deleteBannerBlogReducer } from './reducers/categoriesBannerBlogReducers';
import { listBlogSliderReducer, addBlogSliderReducer, updateBlogSliderReducer, deleteBlogSliderReducer } from './reducers/blogSlidersRecucers';
import { listCardBannerBlogReducer, addCardBlogReducer, updateCardBlogReducer, deleteCardBlogReducer } from './reducers/blogCardReducers';
import { listSecondCardBannerBlogReducer, addSecondCardBlogReducer, updateSecondCardBlogReducer, deleteSecondCardBlogReducer } from './reducers/blogSecondCardReducers';
import { listHomeBannerHomeReducer, addHomeBannerReducer, updateHomeBannerReducer, deleteHomeBannerReducer } from './reducers/homeBannerReducers';
import { listNewBannerReducer, addNewBannerReducer, updateNewBannerReducer, deleteNewBannerReducer } from './reducers/newBannerReducers';
import { listShopBannerReducer, addShopBannerReducer, updateShopBannerReducer, deleteShopBannerReducer } from './reducers/shopBannerReducers';
import { listBlogBannerReducer, addBlogBannerReducer, updateBlogBannerReducer, deleteBlogBannerReducer } from './reducers/blogBannerReducers';
import { listLoginBannerReducer, addLoginBannerReducer, updateLoginBannerReducer, deleteLoginBannerReducer } from './reducers/loginBannerReducers';
import { listWishlistBannerReducer, addWishlistBannerReducer, updateWishlistBannerReducer, deleteWishlistBannerReducer } from './reducers/wishlistBannerReducers';
import { listUsersReducer, userRegisterReducer, userSignInReducer, userUpdateReducer, deleteUserReducer } from './reducers/user_reducers';
import { listSubCategoryReducer, addSubCategoryReducer, updateSubCategoryReducer, deleteSubCategoryReducer } from './reducers/subCategoryReducers';
import { listSubSubCategoryReducer, addSubSubCategoryReducer, updateSubSubCategoryReducer, deleteSubSubCategoryReducer } from './reducers/subSubCategory_Reducers';
import { listContactsReducer, addContactReducer, deleteContactReducer } from './reducers/contactReducers';
import { listContactBannerReducer, addContactBannerReducer, updateContactBannerReducer, deleteContactBannerReducer } from './reducers/contactBannerReducers';
import { listCartBannerReducer, addCartBannerReducer, updateCartBannerReducer, deleteCartBannerReducer } from './reducers/bannerCartReducers';
import { listAboutBannerReducer, addAboutBannerReducer, updateAboutBannerReducer, deleteAboutBannerReducer } from './reducers/bannerAboutReducers';
import { listSectionAboutReducer, addSectionAboutReducer, updateSectionAboutReducer, deleteSectionAboutReducer } from './reducers/sectionAboutReducers';
import { listCountriesReducer, addCountryReducer, updateCountryReducer, deleteCountryReducer } from './reducers/countryReducers';
import { listPrivacyPolicyReducer, addPrivacyPolicyReducer, updatePrivacyPolicyReducer, deletePrivacyPolicyReducer } from './reducers/privacy_policy_reducers';
import { listTermsOfServiceReducer, addTermsOfServiceReducer, updateTermsOfServiceReducer, deleteTermsOfServiceReducer } from './reducers/terms_of_service_reducers';

const cartItems = Cookie.getJSON("cartItems") || []; //[] sepse nese skemi elemente ne cartItems me qene by default e zbrazt
const wishlistItems = Cookie.getJSON("wishlistItems") || []; //[] sepse nese skemi elemente ne wishlist me qene by default e zbrazt
const userInfo = Cookie.getJSON("userInfo") || null; //null sepse nese skemi elemente ne userInfo me qene by default e zbrazt

const initialState = { cart: { cartItems }, wishlist: { wishlistItems }, userSignIn: { userInfo } };
const reducer = combineReducers({
    cart: cartReducer,
    
    wishlist: wishlistReducer,

    categories_list: listCategoryReducer,
    add_category: addCategoryReducer,
    update_category: updateCategoryReducer,
    delete_category: deleteCategoryReducer,

    products_list: listProductReducer,
    add_product: addProductReducer,
    get_product: getProductReducer,
    update_product: updateProductReducer,
    update_product_details: updateProductDetailsReducer,
    delete_product: deleteProductReducer,

    orders_list: listOrderReducer,
    add_order: addOrderReducer,
    update_order: updateOrderReducer,
    delete_order: deleteOrderReducer,

    subscribes_list: listSubscribeReducer,
    add_subscribe: addSubscribeReducer,
    update_subscribe: updateSubscribeReducer,
    delete_subscribe: deleteSubscribeReducer,

    sizes_list: listSizeReducer,
    add_size: addSizeReducer,
    update_size: updateSizeReducer,
    delete_size: deleteSizeReducer,

    colors_list: listColorReducer,
    add_color: addColorReducer,
    update_color: updateColorReducer,
    delete_color: deleteColorReducer,

    newCollectionBanner_list: listNewCollectionBannerReducer,
    add_newCollectionBanner: addNewCollectionReducer,
    update_newCollectionBanner: updateNewCollectionReducer,
    delete_newCollectionBanner: deleteNewCollectionReducer,

    categoriesBannerHome_list: listCategoryBannerHomeReducer,
    add_categoriesBannerHome: addBannerHomeReducer,
    update_categoriesBannerHome: updateBannerHomeReducer,
    delete_categoriesBannerHome: deleteBannerHomeReducer,

    transportBannerHome_list: listTransportBannerHomeReducer,
    add_transportBannerHome: addTransportBannerHomeReducer,
    update_transportBannerHome: updateTransportBannerHomeReducer,
    delete_transportBannerHome: deleteTransportBannerHomeReducer,

    cardHome_list: listCardBannerHomeReducer,
    add_cardHome: addCardHomeReducer,
    update_cardHome: updateCardHomeReducer,
    delete_cardHome: deleteCardHomeReducer,

    home_sliders_list: listSliderReducer,
    add_home_slider: addSliderReducer,
    update_home_slider: updateSliderReducer,
    delete_home_slider: deleteSliderReducer,

    blog_sliders_list: listBlogSliderReducer,
    add_blog_slider: addBlogSliderReducer,
    update_blog_slider: updateBlogSliderReducer,
    delete_blog_slider: deleteBlogSliderReducer,

    categories_blog_list: listCategoryBannerBlogReducer,
    add_categories_blog: addBannerBlogReducer,
    update_categories_blog: updateBannerBlogReducer,
    delete_categories_blog: deleteBannerBlogReducer,

    card_blog_list: listCardBannerBlogReducer,
    add_card_blog: addCardBlogReducer,
    update_card_blog: updateCardBlogReducer,
    delete_card_blog: deleteCardBlogReducer,

    second_card_blog_list: listSecondCardBannerBlogReducer,
    add_second_card_blog: addSecondCardBlogReducer,
    update_second_card_blog: updateSecondCardBlogReducer,
    delete_second_card_blog: deleteSecondCardBlogReducer,

    banner_home_list: listHomeBannerHomeReducer,
    add_banner_home: addHomeBannerReducer,
    update_banner_home: updateHomeBannerReducer,
    delete_banner_home: deleteHomeBannerReducer,

    banner_new_list: listNewBannerReducer,
    add_banner_new: addNewBannerReducer,
    update_banner_new: updateNewBannerReducer,
    delete_banner_new: deleteNewBannerReducer,

    banner_shop_list: listShopBannerReducer,
    add_banner_shop: addShopBannerReducer,
    update_banner_shop: updateShopBannerReducer,
    delete_banner_shop: deleteShopBannerReducer,

    banner_blog_list: listBlogBannerReducer,
    add_banner_blog: addBlogBannerReducer,
    update_banner_blog: updateBlogBannerReducer,
    delete_banner_blog: deleteBlogBannerReducer,

    banner_login_list: listLoginBannerReducer,
    add_banner_login: addLoginBannerReducer,
    update_banner_login: updateLoginBannerReducer,
    delete_banner_login: deleteLoginBannerReducer,

    banner_wishlist_list: listWishlistBannerReducer,
    add_banner_wishlist: addWishlistBannerReducer,
    update_banner_wishlist: updateWishlistBannerReducer,
    delete_banner_wishlist: deleteWishlistBannerReducer,
    
    sub_category_list: listSubCategoryReducer,
    add_sub_category: addSubCategoryReducer,
    update_sub_category: updateSubCategoryReducer,
    delete_sub_category: deleteSubCategoryReducer,

    sub_sub_category_list: listSubSubCategoryReducer,
    add_sub_sub_category: addSubSubCategoryReducer,
    update_sub_sub_category: updateSubSubCategoryReducer,
    delete_sub_sub_category: deleteSubSubCategoryReducer,

    contacts_list: listContactsReducer,
    add_contact: addContactReducer,
    delete_contact: deleteContactReducer,

    banner_contact_list: listContactBannerReducer,
    add_banner_contact: addContactBannerReducer,
    update_banner_contact: updateContactBannerReducer,
    delete_banner_contact: deleteContactBannerReducer,

    banner_cart_list: listCartBannerReducer,
    add_banner_cart: addCartBannerReducer,
    update_banner_cart: updateCartBannerReducer,
    delete_banner_cart: deleteCartBannerReducer,

    banner_about_list: listAboutBannerReducer,
    add_banner_about: addAboutBannerReducer,
    update_banner_about: updateAboutBannerReducer,
    delete_banner_about: deleteAboutBannerReducer,

    section_about_list: listSectionAboutReducer,
    add_section_about: addSectionAboutReducer,
    update_section_about: updateSectionAboutReducer,
    delete_section_about: deleteSectionAboutReducer,

    countries_list: listCountriesReducer,
    add_country: addCountryReducer,
    update_country: updateCountryReducer,
    delete_country: deleteCountryReducer,

    privacy_policy_list: listPrivacyPolicyReducer,
    add_privacy_policy: addPrivacyPolicyReducer,
    update_privacy_policy: updatePrivacyPolicyReducer,
    delete_privacy_policy: deletePrivacyPolicyReducer,

    terms_of_service_list: listTermsOfServiceReducer,
    add_terms_of_service: addTermsOfServiceReducer,
    update_terms_of_service: updateTermsOfServiceReducer,
    delete_terms_of_service: deleteTermsOfServiceReducer,

    users_list: listUsersReducer,
    user_register: userRegisterReducer,
    user_update: userUpdateReducer,
    userSignIn: userSignInReducer,
    userDelete: deleteUserReducer

});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;