import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSubSubCategory, updateSubSubCategory } from '../../../actions/subSubCategories_actions';
import { subCategoriesList } from '../../../actions/subCategory_actions';
import { clear } from '../../../actions/clear_action';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { apiUrl } from '../../../constants/backendUrl';
import axios from 'axios';

import '../plugins/fontawesome-free/css/all.min.css';
import '../dist/css/adminlte.min.css';

import AdminSideBar from '../Admin-Sidebar';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';

export default function AddSubCategory(props) {
    const { userInfo } = useSelector(state => state.userSignIn);
    const { subCategories } = useSelector((state) => state.sub_category_list);
    const { success, loading: loadingCreate } = useSelector(state => state.add_sub_sub_category);
    const { update_success, loading } = useSelector(state => state.update_sub_sub_category);

    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const validate = values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'This field is required';
        }
      
        if (!values.subCategoryId) {
          errors.subCategoryId = 'This field is required';
        }
      
        return errors;
    };

    const formik = useFormik({
        initialValues: {
          name: '',
          subCategoryId: '',
        },
        validate,
        onSubmit: (values, {resetForm}) => {
          if(id) {
            dispatch(updateSubSubCategory(id, values));
            resetForm();
          } else {
              dispatch(createSubSubCategory(values));
              resetForm();
          }
        },
    });

    useEffect(() => {
        if(!userInfo || !userInfo?.isAdmin) {
            history.push('/notfound');
        }

        dispatch(subCategoriesList());
        if(id) {
            axios.get(`${apiUrl}/subsubCategories/${id}`, {
                headers:
                  { Authorization: 'Bearer ' + userInfo?.token }
            })
          .then(response => {
              formik.setFieldValue('name', response.data.name);
              formik.setFieldValue('subCategoryId', response.data.subCategory);
          }).catch(error => console.log(error));
        }
        if(success) {
            Toastr.success('Nën Kategoria u krijua me sukses.');
            dispatch(clear()); //per me bo clear staten.
        }
        if(update_success) {
            Toastr.success('Nën Kategoria u editua me sukses.');
            dispatch(clear()); //per me bo clear staten.
        }
        return () => {
        }
    }, [success, update_success]);

    return (
        <div>
            <div className="wrapper">
                <AdminHeader />
                <AdminSideBar />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{textAlign: 'center'}}>
                                    <h1>{id ? 'Edito Filterat' : 'Krijo Filterat'}</h1>
                                </div>
                            </div>
                        </div>
                    </section>
        
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-md-6">
                                    <div className="card card-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">{id ? 'Edito Filterat' : 'Krijo Filterat'}</h3>
                                        </div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="category-name">Emri</label>
                                                    <input 
                                                        name="name" 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="category-name" 
                                                        placeholder="Emri i filterit" 
                                                        onChange={formik.handleChange}
                                                        value={formik.values.name}  
                                                    />
                                                    {formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="category">Nën Kategoritë</label>
                                                    <select
                                                        className="form-select"
                                                        id="category"
                                                        name="subCategoryId"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.subCategoryId}  
                                                        >
                                                        <option selected>Selekto Nën Kategorinë</option>
                                                        {subCategories && subCategories?.map((subCategory) => (
                                                            <option key={subCategory?._id} value={subCategory?._id}>{subCategory?.name}</option>
                                                        ))}
                                                    </select>
                                                    {formik.errors.subCategoryId ? <div className="text-danger">{formik.errors.subCategoryId}</div> : null}
                                                </div>
                                            </div>

                                            <div className="card-footer">
                                                {
                                                    loading || loadingCreate ?
                                                    <button class="btn btn-danger" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <span> Loading...</span>
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-danger" disabled={!(formik.isValid && formik.dirty)}>
                                                        <span>{id ? 'Edito' : 'Shto'}</span>
                                                    </button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <AdminFooter />
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    )
}