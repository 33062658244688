import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productsList } from '../../actions/product_actions';
import { addToCart } from '../../actions/cart_actions';
import { addToWishlist } from '../../actions/wishlist_actions';
import { Link  } from 'react-router-dom';

import Modal from '../OpenModal/OpenModal';
import AddToCartModal from '../AddToCartModal/AddToCartModal';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import { apiUrl } from "../../constants/backendUrl";

import "./style.css";

export default function Products(props) {
  const [product, setProduct] = useState({});
  const [qty, setQty] = useState(1);
  const [openCartModal, setCartModal] = useState('');

  const { wishlistItems } = useSelector(state => state.wishlist);
  const { products, loading, error } = useSelector(state => state.products_list);

  const dispatch = useDispatch();

  function openModal(product) {
    var element = document.getElementById("modal_box409"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
    var header = document.getElementById("eltdf-page-header");
    var mobile_header = document.getElementById("eltdf-mobile-header");
    
    header.style.zIndex = '0';
    mobile_header.style.zIndex = '0';
    element.style.display = 'block';
    element.style.background = 'rgba( 0, 0, 0, 0.8)';

    setProduct(product);
  }

  function openModalCart(id, quantity, color) {
    dispatch(addToCart(id, quantity, color));

    var element = document.getElementById("cart-modal-right-side"); //kjo id eshte tek Modal komponentja por mundemi ketu mu qas sepse ktu e kom thirr at komponente, vetem mduhet me ja shtu klasen open qe me u shfaq ajo.
    var elementOverlay = document.getElementById("la-overlay-global"); //per me shfaq opacity pasi t hapet modali i add cartes
    var header = document.getElementById("eltdf-page-header");
    var mobile_header = document.getElementById("eltdf-mobile-header");

    header.style.zIndex = '0';
    mobile_header.style.zIndex = '0';
    elementOverlay.classList.add('la-overlay-global');
    setCartModal(element.classList.add("cart-flyout-open"));
  }

  function addWishlist(id, checkId, color) {
    const singleProduct = wishlistItems.find(x => x?.product === checkId);

    dispatch(addToWishlist(id, color));
    if(singleProduct?.product !== checkId) { //singleProduct.product -> sepse product eshte id e productit ashtu e kom lon  tek cart_actions-> product: data?._id
      Toastr.success(' Product added to wishlist.');
    } else {
      Toastr.error('The product is already added to wishlist.');
    }
  }

  useEffect(() => {
      dispatch(productsList());
  }, []);

  return (
    <section id="prducts_page">
      <div className="eltdf-row-grid-section-wrapper " style={{ marginTop: '45px', paddingBottom: '20px' }}>
        <div className="eltdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_1537866888126">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="eltdf-elements-holder eltdf-one-column  eltdf-responsive-mode-768 ">
                      <div className="eltdf-eh-item eltdf-eh-custom-4800">
                        <div className="eltdf-eh-item-inner">
                          <div className="eltdf-eh-item-content" style={{ padding: "0 0 0 0" }}>
                            <div className="eltdf-pl-holder eltdf-grid-list eltdf-disable-bottom-space eltdf-standard-layout eltdf-four-columns eltdf-normal-space eltdf-info-below-image  eltdf-product-add-to-cart-in-box     eltdf-hover-type-standard-light custom-text-position-left eltdf-navigation-bellow-slider">
                              <div className="eltdf-pl-outer eltdf-outer-space">
                                {
                                  products?.products?.map(function(product) {
                                    if(product?.sale) {
                                      return <div className="eltdf-pli eltdf-item-space " key={product?._id}>
                                        <div className="eltdf-pli-inner">                                 
                                          <div className="eltdf-pli-image">
                                            { product?.discountPrice ? <span className="eltdf-onsale">Sale</span> : null }
                                            {product?.availableOptions?.slice(0,1)?.map(item => (<img src={`${apiUrl}/images/${item.images[0]}`} className="attachment-full size-full wp-post-image" alt="m" /> )) }
                                            
                                          </div> 
                                          <div className="eltdf-pli-text">
                                            <div className="eltdf-pli-text-outer">
                                              <div className="eltdf-pli-text-inner">
                                                <div className="eltdf-pli-icons-holder">
                                                    {/* { product?.availableOptions[0]?.countInStock > 0 ? <a href="javascript:;" className="yith-cart-button yith-wcqv-button products-cart-button_1234098765" onClick={() => openModalCart(product?._id, qty, product?.availableOptions[0]?.color)}></a> : ''} */}
                                                    <a href="javascript:void" className="yith-quickview-button yith-wcqv-button products-cart-button_1234098765" onClick={() => openModal(product)}>Quick View</a>
                                                    <a 
                                                      href="javascript:;" 
                                                      className="yith-heart-button yith-wcqv-button products-cart-button_1234098765"
                                                      onClick={() => addWishlist(product?._id, product?.availableOptions[0]?._id, product?.availableOptions[0]?.color)}
                                                    ></a>
                                                    <div className="clear" />{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </div>  
                                          <Link 
                                            to={`/product/${product?._id}`}
                                            className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                                          ></Link> 
                                        </div>
                                        <div className="eltdf-pli-text-wrapper">
                                          <h5 itemProp="name" className="entry-title eltdf-pli-title eltdf-pli-title-products">
                                            <Link 
                                              itemProp="url"                             
                                              to={`/product/${product?._id}`}
                                            >{product?.title}</Link>
                                          </h5>
                                          <p className="eltdf-pli-category">{product?.subtitle}</p>
                                          <p className="eltdf-pli-category price-products">
                                            {
                                              product?.discountPrice && 
                                              <a style={{ marginRight: '8px', color: '#a06464' }}>
                                                  {product?.discountPrice?.toFixed(2)}€
                                              </a>
                                            }
                                            <span className="woocommerce-Price-currencySymbol" style={ product?.discountPrice ? { color: '#acabab', textDecoration: 'line-through' } : {} }>{product?.price?.toFixed(2)}</span>€  
                                          </p>
                                        </div>
                                      </div>
                                    }
                                  })
                                }  
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="eltdf-elements-holder-background-outer">
                          <div className="eltdf-elements-holder-background-inner" />
                        </div>
                        <Modal product={product} setProduct={setProduct} />
                        <AddToCartModal  />
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )

  
}
