import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContact } from '../../actions/contact_actions';
import { bannerContactsList } from '../../actions/bannerContact_actions';

import TopHeader from '../TopHeader/TopHeader';
import Slider from '../Slider/Slider';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBar from '../SearchBar/SearchBar';
import Loader from '../Loader/Loader';
import * as Toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import './style.css';

export default function Contact(props) {
    const [postData, setPostData] = useState({
        message: '', name: '', email: ''
    });
    const { success, error } = useSelector(state => state.add_contact);
    const { bannerContacts, loading: bannerContactsLoading } = useSelector(state => state.banner_contact_list);
    const dispatch = useDispatch();

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(createContact({ ...postData }));
    }

    const clearInputs = () => {
        setPostData({ message: '', name: '', email: '' });
    }

    useEffect(() => {
        dispatch(bannerContactsList());
        if(success) {
            Toastr.success('Mesazhi u dërgua me sukses.');
            clearInputs();
            dispatch(clear()); //per me bo clear staten.
        }
    }, [success])
    return (
        bannerContactsLoading
        ?
        <Loader />
        :
        <section id="contact_us">
            <TopHeader />
            {
                bannerContacts?.slice(-1)?.map(bannerContact =>  <Slider image = { bannerContact?.image } link={bannerContact?.link} />)
            }
            <Header />
            <div className="page-template page-template-full-width page-template-full-width-php page page-id-4584 onea-core-1.0 eltdf-social-login-1.0 woocommerce-js onea-ver-1.0.1 eltdf-grid-1200 eltdf-wide-dropdown-menu-content-in-grid eltdf-fixed-on-scroll eltdf-dropdown-animate-height eltdf-header-bottom eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-single-has-pretty-photo eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-fullscreen-search eltdf-search-fade wpb-js-composer js-comp-ver-6.0.5 vc_responsive eltdf-undefined-slider-navigation eltdf-fixed-header-appear">
                <div className="eltdf-wrapper">
                    <div className="eltdf-wrapper-inner">
                        <div className="eltdf-content">
                            <div className="eltdf-content-inner">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="eltdf-contentt">
                <div className="eltdf-content-inner">
                    <div>
                        <div className="eltdf-full-width-inner">
                            <div>
                                <div className="eltdf-page-content-holder eltdf-grid-col-12 contact_us_vc_custom_1554525238">
                                    <div>
                                        <div>
                                            <div className="header-container contact_container">
                                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12">
                                                    <div className="vc_column-inner">
                                                        <div className="wpb_wrapper">
                                                            <div className="wpb_text_column wpb_content_element">
                                                                <div className="wpb_wrapper">
                                                                    <h6>n'Pika Shop</h6>
                                                                    <h6></h6>
                                                                </div>
                                                            </div>
                                                            <div className="wpb_text_column wpb_content_element">
                                                                <div className="wpb_wrapper">
                                                                    <p>
                                                                        {/* email: <br /> */}
                                                                        Please reach out for anything.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-12">
                                                    <div className="vc_column-inner">
                                                        <div className="wpb_wrapper">
                                                            <div className="wpb_text_column wpb_content_element">
                                                                <div className="wpb_wrapper">
                                                                    <h6 className="send_us_message">SEND US A MESSAGE</h6>
                                                                </div>
                                                            </div>
                                                            <div className="contact_vc_empty_space" style={{height: '13px'}}>
                                                                <span className="vc_empty_space_inner"></span>
                                                            </div>
                                                            <div className="wpcf7">
                                                                <div className="screen-reader-response"></div>
                                                            </div>
                                                            <form onSubmit={handleSubmit}>
                                                                <div>
                                                                    <span className="wpcf7-form-control-wrap your-message">
                                                                        <textarea 
                                                                            cols="40" 
                                                                            rows="4" 
                                                                            required
                                                                            className="wpcf7-form-control wpcf7-textarea" 
                                                                            placeholder="Type your message *"
                                                                            value={ postData?.message }
                                                                            onChange={(e) => setPostData({ ...postData, message: e.target.value })}
                                                                        ></textarea>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="wpcf7-form-control-wrap your-name">
                                                                        <input
                                                                            type="text"
                                                                            className="wpcf7-form-control wpcf7-text"
                                                                            placeholder="Your name *"
                                                                            required
                                                                            value={postData?.name}
                                                                            onChange={(e) => setPostData({ ...postData, name: e.target.value })}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="wpcf7-form-control-wrap your-name">
                                                                        <input
                                                                            type="text"
                                                                            className="wpcf7-form-control wpcf7-text"
                                                                            placeholder="E-mail address *"
                                                                            required
                                                                            value={postData?.email}
                                                                            onChange={(e) => setPostData({ ...postData, email: e.target.value })}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <button type="submit" className="wpcf7-form-control wpcf7-submit eltdf-btn-contact_us">
                                                                        <span className="eltdf-btn-text">Send</span>
                                                                    </button>
                                                                    <span className="ajax-loader" />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}