import axios from 'axios';
import { apiUrl } from '../constants/backendUrl';

export const subSubCategoriesList = () => async (dispatch) => {
    try {
        dispatch({  type: 'SUB_SUB_CATEGORIES_LIST_REQUEST' });

        const { data } = await axios.get(`${apiUrl}/subsubCategories`);
        dispatch({ type: 'SUB_SUB_CATEGORIES_LIST_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'SUB_SUB_CATEGORIES_LIST_FAIL', payload: error });
    }
}

export const createSubSubCategory = (dataa) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'SUB_SUB_CATEGORY_CREATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.post(`${apiUrl}/subsubCategories`, dataa, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SUB_SUB_CATEGORY_CREATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'SUB_SUB_CATEGORY_CREATE_FAIL', payload: error });
    }
}

export const updateSubSubCategory = (id,dataa) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'SUB_SUB_CATEGORY_UPDATE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.patch(`${apiUrl}/subsubCategories/${id}`, dataa, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SUB_SUB_CATEGORY_UPDATE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'SUB_SUB_CATEGORY_UPDATE_FAIL', payload: error });
    }
}

export const deleteSubSubCategory = (id) => async (dispatch, getState) => {
    try {
        dispatch({  type: 'SUB_SUB_CATEGORY_DELETE_REQUEST' });
        const { userSignIn: { userInfo } } = getState();

        const { data } = await axios.delete(`${apiUrl}/subsubCategories/${id}`, {
            headers:
              { Authorization: 'Bearer ' + userInfo?.token }
        });
        dispatch({ type: 'SUB_SUB_CATEGORY_DELETE_SUCCESS', payload: data });
    } catch(error) {
        dispatch({ type: 'SUB_SUB_CATEGORY_DELETE_FAIL', payload: error });
    }
}