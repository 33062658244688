import React from "react";
import { Link } from 'react-router-dom';

import "./style.css";

export default function Slider(props) {
    return (
        <section>
            <div className="header-row" id="header-row" style={{padding: '0px', overflow: 'hidden', height: 'auto'}}>
                <div className="container-fluid" style={{padding: '0px'}}>
                    <div className="row"> 
                        <div className="col-xs-12" style={{padding: '0'}}>  
                            <Link to={props.link}>
                                <img src={props.image} alt="company logo" className="eltdf-sticky-header-top_banner" style={{ width: '100%' }} />     
                            </Link>
                        </div>     
                    </div>
                </div>
            </div>
        </section>
    )
}
